@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.container{
    width: 100%
}
@media (min-width: 640px){
    .container{
        max-width: 640px
    }
}
@media (min-width: 768px){
    .container{
        max-width: 768px
    }
}
@media (min-width: 1024px){
    .container{
        max-width: 1024px
    }
}
@media (min-width: 1280px){
    .container{
        max-width: 1280px
    }
}
@media (min-width: 1536px){
    .container{
        max-width: 1536px
    }
}
.\!visible{
    visibility: visible !important
}
.visible{
    visibility: visible
}
.collapse{
    visibility: collapse
}
.static{
    position: static
}
.fixed{
    position: fixed
}
.absolute{
    position: absolute
}
.relative{
    position: relative
}
.sticky{
    position: sticky
}
.left-\[-1px\]{
    left: -1px
}
.top-\[-1px\]{
    top: -1px
}
.\!mr-0{
    margin-right: 0px !important
}
.mb-2{
    margin-bottom: 0.5rem
}
.mb-20{
    margin-bottom: 5rem
}
.block{
    display: block
}
.inline-block{
    display: inline-block
}
.flex{
    display: flex
}
.inline-flex{
    display: inline-flex
}
.table{
    display: table
}
.grid{
    display: grid
}
.\!contents{
    display: contents !important
}
.contents{
    display: contents
}
.hidden{
    display: none
}
.h-\[15px\]{
    height: 15px
}
.border-collapse{
    border-collapse: collapse
}
.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.resize{
    resize: both
}
.flex-wrap{
    flex-wrap: wrap
}
.items-center{
    align-items: center
}
.justify-start{
    justify-content: flex-start
}
.justify-center{
    justify-content: center
}
.gap-2{
    gap: 0.5rem
}
.gap-\[10px\]{
    gap: 10px
}
.gap-\[5px\]{
    gap: 5px
}
.\!rounded{
    border-radius: 0.25rem !important
}
.rounded{
    border-radius: 0.25rem
}
.rounded-\[5px\]{
    border-radius: 5px
}
.rounded-br-\[3px\]{
    border-bottom-right-radius: 3px
}
.rounded-tl-\[3px\]{
    border-top-left-radius: 3px
}
.border{
    border-width: 1px
}
.border-solid{
    border-style: solid
}
.border-\[\#08BBD5\]{
    --tw-border-opacity: 1;
    border-color: rgb(8 187 213 / var(--tw-border-opacity))
}
.border-\[\#CCC\]{
    --tw-border-opacity: 1;
    border-color: rgb(204 204 204 / var(--tw-border-opacity))
}
.bg-\[\#E6F7FF\]{
    --tw-bg-opacity: 1;
    background-color: rgb(230 247 255 / var(--tw-bg-opacity))
}
.bg-\[\#F03C78\]{
    --tw-bg-opacity: 1;
    background-color: rgb(240 60 120 / var(--tw-bg-opacity))
}
.bg-\[\#FFF\]{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.px-\[25px\]{
    padding-left: 25px;
    padding-right: 25px
}
.py-\[23px\]{
    padding-top: 23px;
    padding-bottom: 23px
}
.pl-\[6px\]{
    padding-left: 6px
}
.pr-\[5px\]{
    padding-right: 5px
}
.text-center{
    text-align: center
}
.font-NanumGothic{
    font-family: NanumGothic
}
.text-\[14px\]{
    font-size: 14px
}
.text-\[9px\]{
    font-size: 9px
}
.font-bold{
    font-weight: 700
}
.text-\[\#000\]{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.text-\[\#262626\]{
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity))
}
.text-white{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.underline{
    text-decoration-line: underline
}
.shadow{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.outline{
    outline-style: solid
}
.blur{
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}


@charset "UTF-8";
@import url('https://s.wink.co.kr/resources/ltp/fonts/common/pretendard/static/pretendard-subset.css?v=1.0.0');
/* CSS Document */

@font-face {
    font-family: 'NanumBarunGothic-Light';
    font-style: normal;
    font-weight: 300;
    src: local('Nanum Barun Gothic Light'), local('Nanum Barun Gothic-Light'),
        local('NanumBarunGothic Light');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.ttf')
            format('truetype');
}

@font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 400;
    src: local('Nanum Barun Gothic Regular'),
        local('Nanum Barun Gothic-Regular'), local('NanumBarunGothic Regular');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.ttf')
            format('truetype');
}

@font-face {
    font-family: 'NanumBarunGothic-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Nanum Barun Gothic Bold'), local('Nanum Barun Gothic-Bold'),
        local('NanumBarunGothic Bold');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.ttf')
            format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Thin.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Light.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Regular.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Medium.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Bold.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Black.otf');
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
html {
    height: 100%;
    /*
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
	*/
    min-width: 1260px;
    overflow: auto;
}
/* issue_1435 */
body {
    -webkit-user-select: none;
    -khtml-user-select: none;
}
/* issue_1435 */
body {
    height: 100%;
    color: #666;
    font-size: 16px;
    font-family: 'NanumBarunGothic', sans-serif;
    line-height: 1.6;
    word-break: keep-all;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}
header,
footer,
section,
article,
aside,
nav,
hgroup,
details,
menu,
figure,
figcaption {
    display: block;
}

img,
fieldset,
iframe,
button {
    border: 0 none;
}
ul,
ol {
    list-style: none;
}
em,
address,
i {
    font-style: normal;
}
b,
strong {
    font-weight: normal;
}
a {
    text-decoration: none;
    cursor: pointer;
}

/* Display */
.blind {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}

/* Img */
@keyframes moreLoad {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
    }
}

img.loading {
    vertical-align: top;
    animation: moreLoad 1.8s infinite;
    -webkit-animation: moreLoad 1.8s infinite;
    -ms-animation: moreLoad 1.8s infinite;
    animation-timing-function: linear;
}

/* Title/Text */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h3 {
    margin-bottom: 14px;
    color: black;
    font-size: 32px;
}
h3.line {
    margin-bottom: 32px;
    padding-bottom: 11px;
    border-bottom: 4px solid #d7d7d7;
    position: relative;
}
h3.line:after {
    content: '';
    position: absolute;
    bottom: -7px;
    right: 0;
    width: 33px;
    height: 19px;
    background: url(https://s.wink.co.kr/pc/images/img_leaf.png) no-repeat;
}
h3.no-margin {
    margin-bottom: 0;
}

h4 {
    color: #212121;
    font-size: 30px;
    line-height: 1.4;
    position: relative;
    font-weight: bold;
}
/*h4:after{content:''; position:absolute; bottom:-11px; right:0; width:33px; height:33px; background:url(https://s.wink.co.kr/pc/renewal/img_leaf.png) no-repeat; background-size: contain;}*/
h4.no-line {
    margin-bottom: 0;
    border-bottom-width: 0;
}
h4.no-line:after {
    display: none;
}
h4.no-margin {
    margin-bottom: 0;
}

#ShippingAddress h4.no-line {
    font-size: 24px;
}

h5 {
    margin-bottom: 16px;
    color: #212121;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    position: relative;
}

.hgroup {
    position: relative;
}
.hgroup .right,
.hgroup .btn-type,
.hgroup .btn-type2,
.hgroup .buttons {
    position: absolute;
    right: 0;
    bottom: 15px;
}

a.go {
    padding-right: 14px;
    background: url(https://s.wink.co.kr/pc/images/bu_go.png) 100% 2px no-repeat;
    color: #212121;
    text-decoration: underline;
}
a.go.blu {
    background-image: url(https://s.wink.co.kr/pc/renewalV3/common/go_blue.png);
    color: #00a0e0;
}

.tag {
    display: inline-block;
    height: 28px;
    padding: 0 12px;
    border: 1px solid #e0e0e0;
    border-radius: 14px;
    box-sizing: border-box;
    vertical-align: middle;
    color: #212121;
    font-size: 14px;
    line-height: 28px;
}
.tag.category {
    border-width: 0;
    background: #bdbdbd;
    color: #fff;
}
.tag.off {
    border-width: 0;
    background: #f1f1f1;
}
.tag.eng {
    border-width: 0;
    background: #ffc200;
    color: #fff;
}
.tag.kor {
    border-width: 0;
    background: #4385ff;
    color: #fff;
}
.tag.math {
    border-width: 0;
    background: #1ad49d;
    color: #fff;
}
.tag.notice {
    border-width: 0;
    background: #90a4ae;
    color: #fff;
}
.tag.d-day {
    border-width: 0;
    background: #00bbd4;
    color: #fff;
}

.date {
    display: inline-block;
    padding-left: 23px;
    vertical-align: middle;
    background: url(https://s.wink.co.kr/pc/images/icon_time.png) no-repeat;
    color: #bdbdbd;
    font-size: 14px;
    line-height: 18px;
}

.notice-wrap p {
    margin-bottom: 5px;
    color: #212121;
    font-size: 18px;
}
.notice-wrap li {
    padding-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 10px no-repeat;
    color: #666;
    font-size: 14px;
    line-height: 1.7;
}

p.notice {
    padding-left: 31px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice.png) no-repeat;
}
p.search-result {
    margin-bottom: 10px;
    padding-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 10px no-repeat;
}
p.search-result strong {
    color: #f03c78;
}
p.validation-check {
    margin: 8px 0 0 4px;
    padding-left: 23px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice3.png) 0 3px
        no-repeat;
    color: #e83030;
    font-size: 14px;
}

/* 글자 색상 */
.text-color01 {
    color: #999;
}
.text-color02 {
    color: #00a0e0;
}
.text-color03 {
    color: red;
}
.text-color04 {
    color: #459fda;
}

.underline {
    text-decoration: underline;
}

/* Layout */
#wrap {
    position: relative;
    height: 100%;
    min-height: 1160px;
}
#google-apply-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 700px;
    min-height: 1160px;
}

.fixed .header {
    height: 56px;
    overflow: hidden;
}
.fixed #gnb {
    position: fixed;
    top: 0;
    left: 0;
}
#gnb.fix {
    position: fixed;
    top: 0;
    left: 0;
}
.fixed #lnb-wrap {
    position: fixed;
    top: 56px;
}

.header {
    font-family: 'Pretendard', sans-serif;
    position: relative;
    height: 80px;
    margin: 0 auto;
    z-index: 101;
    min-width: 1200px;
}
.header h1 {
    float: left;
}
.header h1 a {
    display: block;
    width: 106px;
    height: 44px;
    margin-top: 19px;
    background: url(https://s.wink.co.kr/pc/images/logo.png) no-repeat;
    font-size: 0;
    line-height: 0;
}
.header p {
    float: left;
    margin: 22px 0 0 20px;
}
.header ul.util {
    float: right;
    margin-top: 27px;
}
.header ul.util li {
    padding: 0 20px;
}
.header ul.util li:not(:last-child):after {
    content: '';
    height: 20px;
    width: 1px;
    background: #a3a3a3;
    position: absolute;
    margin-left: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.header ul.util li:last-of-type {
    padding-right: 0;
}
.header ul.util li .banner-text {
    color: #333333;
}
.header ul.util li .banner-blue-link {
    color: #0183ff;
}
.header ul.util li .banner-blue-link:hover {
    opacity: 0.8;
}
.header div.select-mode {
    float: right;
    width: auto;
    margin-top: 23px;
    margin-left: 15px;
}
.header div.select-mode > button {
    height: auto;
    padding: 0 26px 0 10px;
    border: 2px solid #1db9ff;
    border-radius: 16px;
    font-family: 'Pretendard', sans-serif;
}
.header div.select-mode button i {
    padding: 0;
    color: #212121;
    font-size: 14px;
    line-height: 28px;
}
.header div.select-mode > button:after {
    content: '';
    position: absolute;
    top: 14px;
    right: 14px;
    width: 8px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode.png')
        no-repeat;
}
.header div.select-mode div {
    top: 2px;
    padding-top: 32px;
    border: 2px solid #1db9ff;
    border-radius: 16px;
}
.header div.select-mode.on > button {
    border-color: #1db9ff;
}
.header div.select-mode.on > button:after {
    content: '';
    position: absolute;
    top: 11px;
    right: 10px;
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode_on.png')
        no-repeat;
}
.header div.select-mode.on div {
    border-radius: 16px;
    padding-bottom: 14px;
    -ms-box-shadow: 3px 0 7px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 0 7px rgba(0, 0, 0, 0.15);
}
.header div.select-mode.on ul {
    max-height: 74px;
}
.header div.select-mode.on ul li:hover {
}
.header div.select-mode.on ul button {
    height: auto;
}
.header div.select-mode.on ul button i {
    line-height: 26px;
    padding-left: 11px;
}
.header ul.util li a span {
    position: relative;
    font-size: 15px;
    color: #a3a3a3;
}

/* 로그인 전 회원가입 버튼 */
.header .btn-join {
    float: right;
    width: 77px;
    margin-top: 23px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 30px;
    color: #212121;
    border: 1px solid #ccc;
    text-align: center;
}
.toast .bi-check-circle-fill {
    background: url(https://us.wink.co.kr/pc/images/icon_check_circle.png) 0 0
        no-repeat;
    width: 22px;
    height: 22px;
}

.toast .bi-x {
    background: url(https://us.wink.co.kr/pc/images/icon_x_close.png) 0 0
        no-repeat;
    width: 15px;
    height: 15px;
}
.password-reset-modal .toast {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: -45px;
    left: 50%;
    padding: 11px;
    min-width: 430px;
    height: 25px;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    color: black;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 4px 0 #00000040;
}

.login-modal .toast {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 90px;
    left: 50%;
    padding: 11px;
    min-width: 260px;
    height: 25px;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    color: black;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 4px 0 #00000040;
}

.toast .bi.bi-x {
    cursor: pointer;
}

#gnb {
    font-family: 'Pretendard', sans-serif;
    min-width: 1260px;
    width: 100%;
    height: 75px;
    background: white;
    position: sticky;
    z-index: 100;
    border-bottom: 1px solid #d7d7d7;
    top: 0;
    margin: 0 auto;
}
#gnb > ul {
    width: 1260px;
    margin: 0 auto;
}

#gnb > ul li {
    float: left;
    width: 210px;
    position: relative;
}
#gnb > ul li a {
    position: relative;
    display: block;
    color: #333333;
    text-align: center;
    font-size: 18px;
    line-height: 75px;
}
#gnb > ul > li.on > a,
#gnb > ul > li.current > a {
    color: #1db9ff;
    background-repeat: no-repeat;
    font-weight: 600;
}
#gnb > ul li:first-child > ul li > ul {
    width: 178px !important;
}

#gnb > ul li.main-logo {
    height: 75px;
}

#gnb > ul li.main-logo a {
    height: 100%;
}

#gnb > ul li.main-logo a img {
    height: 160%;
    position: relative;
    top: -33%;
}

#gnb > ul li > ul {
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    margin: 0;
    transition: max-height 0.5s;
}

#gnb > ul li > ul > div {
    border: 1px solid #d7d7d7;
    background-color: white;
    border-radius: 10px;
}

#gnb > ul li > ul > div > li {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
}

#gnb > ul li > ul > div > li.two-lines {
    height: 60px;
    line-height: 80px;
}

#gnb > ul li > ul > div > li.two-lines a {
    line-height: 15px;
    white-space: pre;
}

#gnb > ul > li > ul li.new > a:after {
    display: inline-block;
    content: '';
    background-image: url('https://s.wink.co.kr/pc/renewalV3/icons/new_blink_square.gif');
    background-size: cover;
    width: 17px;
    height: 17px;
    transform: translate(2px, 4px) scale(0.8);
}

#gnb > ul li > ul > div > li:last-child {
    border-bottom: none;
}
#gnb > ul li.on > ul {
    max-height: 400px;
}
/*#gnb > ul li.on > ul{display:block;}*/
#gnb > ul li > ul li {
    float: none;
    width: auto;
    margin-top: 3px;
    padding: 0 16px;
}
#gnb > ul li > ul li a {
    display: inline-block;
    text-align: left;
    background: transparent !important;
    color: #666;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}
#gnb > ul li > ul li a:before {
    display: none;
}
#gnb > ul li > ul li a:hover {
    color: #1db9ff;
    font-weight: bold;
}
#gnb > ul li > ul li a.current {
    color: #1db9ff;
}
#gnb > ul li > ul li button {
    position: absolute;
    top: 5px;
    left: 154px;
    width: 13px;
    height: 13px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
#gnb > ul li > ul li button.open {
    background: url(https://s.wink.co.kr/pc/images/icon_open.png) 50% 50%
        no-repeat;
}
#gnb > ul li > ul li button.close {
    background: url(https://s.wink.co.kr/pc/images/icon_close.png) 50% 50%
        no-repeat;
}
#gnb > ul li > ul li b {
    font-weight: bold;
}
#gnb > ul li > ul li > ul {
    display: none;
    position: static;
    width: 179px;
    margin: 10px -16px 7px;
    padding: 10px 0;
    border-width: 0;
    box-shadow: none;
    background: #f5f5f5;
}
#gnb > ul li > ul li:last-child > ul {
    margin-bottom: -10px;
}
#gnb > ul li > ul li > ul li {
    padding-left: 25px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 16px 13px
        no-repeat;
}
#gnb > ul li > ul li > ul li:first-child {
    margin-top: 1px;
}
#gnb > ul li > ul li > ul li:last-child {
    margin-bottom: 0;
}
#gnb > ul li > ul li > ul li a {
    background: transparent !important;
}
/*#gnb li:hover > ul,
#gnb li > li:hover > ul{display:block;}*/

.lay-menu {
    position: relative;
    z-index: 101;
}
.lay-menu button.lay-open,
.lay-menu button.lay-close {
    position: absolute;
    top: 0;
    left: 50%;
    width: 56px;
    height: 56px;
    margin-left: 424px;
    padding: 24px 0 0 0;
    box-sizing: border-box;
    background-color: #676969;
    background-position: 50% 12px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -1px;
    cursor: pointer;
    z-index: 101;
}
.lay-menu button.lay-open {
    background-image: url(https://s.wink.co.kr/pc/images/icon_util_gnb.png);
}
.lay-menu button.lay-close {
    background-image: url(https://s.wink.co.kr/pc/images/icon_util_close.png);
}
.lay-menu .container {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: 961px;
    margin-left: -481px;
    border: 1px solid #00a5d5;
    border-top-width: 0;
    box-sizing: border-box;
    background: #fff url(https://s.wink.co.kr/pc/images/gnb_line.gif) repeat-y;
    -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
}
.lay-menu .container .float-box {
    overflow: hidden;
    min-height: 370px;
}
.lay-menu .container .float-box:after {
    display: block;
    content: '';
    clear: both;
}
.lay-menu .menu-list {
    float: left;
    width: 181px;
    height: 100%;
    position: relative;
}
.lay-menu .menu-list > a,
.lay-menu .menu-list .blank {
    display: block;
    height: 56px;
    background: #08bbd5;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
}
.lay-menu .menu-list > span {
    text-indent: -9999px;
}
.lay-menu .menu-list > a:hover {
    background-color: #00a5d5;
}
.lay-menu .menu-list:first-of-type li > ul {
    width: 180px !important;
}
.lay-menu li {
    float: none;
    width: 100%;
    margin-top: 3px;
    padding: 0 16px;
    position: relative;
}
.lay-menu li:first-child {
    margin-top: 10px;
}
.lay-menu li:last-child {
    margin-bottom: 10px;
}
.lay-menu li a {
    color: #666;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}
.lay-menu li a:hover {
    color: #00a5d5;
}
.lay-menu li > ul > li > a {
    padding-left: 11px;
    background: url('https://s.wink.co.kr/pc/images/bu_dot2.png') 0 50%
        no-repeat;
}
.lay-menu li.new a {
    padding-right: 22px;
    background: url('https://s.wink.co.kr/pc/images/icon_new.png') right center
        no-repeat !important;
}
.lay-menu li button.open,
.lay-menu li button.close {
    position: absolute;
    top: 5px;
    left: 154px;
    width: 13px;
    height: 13px;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
.lay-menu li button.open {
    background-image: url(https://s.wink.co.kr/pc/images/icon_open.png);
}
.lay-menu li button.close {
    background-image: url(https://s.wink.co.kr/pc/images/icon_close.png);
}
.lay-menu li > ul {
    display: none;
    width: 100%;
    margin: 10px -16px 7px;
    padding: 1px 0 7px;
    background: #f5f5f5;
}
.lay-menu .etc {
    clear: both; /*padding-top:38px;*/
}
.lay-menu .etc:after {
    display: block;
    content: '';
    clear: both;
}
.lay-menu .etc .menu-list:first-of-type > a {
    border-right: 1px solid #e0e0e0;
}

.lay-floating {
    position: fixed;
    box-sizing: border-box;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lay-floating li {
    padding: 19px 0 17px;
    border-bottom: 1px solid #b0b0b0;
}
.lay-floating li a {
    display: block;
    font-size: 15px;
    color: #585858;
    font-family: 'NanumBarunGothic-Bold';
    padding-left: 45px;
    line-height: 30px;
    background-repeat: no-repeat;
    background-size: 41px 30px;
    cursor: pointer;
}

.lay-floating li a em {
    color: #f03c78;
}

.lay-floating li.lay-report a {
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-report.png');
}

.lay-floating li.lay-freestudy a span {
    color: #f03c78;
}

.lay-floating li.lay-paidstudy a {
    background-image: url('https://s.wink.co.kr/pc/1.1.0/common/floating/bg_lay_paidstudy.png');
    background-size: 30px;
    background-position: 5px 3px;
}

.lay-floating li.lay-recruit a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-recruit.png');
}

.lay-floating li.lay-chat a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/renewal/bg_lay_chat.png');
}

.lay-floating li.lay-counsel a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/images/bg_floating_recruit.png');
}

.lay-floating li.lay-happytalk {
    border-bottom: none;
}

.lay-floating li.lay-happytalk a {
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-happytalk.png');
    letter-spacing: -1px;
    cursor: default;
    line-height: 18px;
}

.lay-floating li.lay-download a {
    background-image: url('https://s.wink.co.kr/pc/renewal/bg_lay_download.png');
    background-size: 30px;
    background-position: 5px 0;
}

.lay-floating .happytalk-area {
    padding-bottom: 25px;
    border-bottom: 1px solid #b0b0b0;
    margin-bottom: 25px;
}

.lay-floating .happytalk-area p {
    font-size: 14px;
    letter-spacing: -1px;
    color: #222;
    margin-bottom: 25px;
    padding-left: 10px;
}
.lay-floating .happytalk-area p strong {
    font-family: 'NanumBarunGothic-Bold';
}

.lay-floating .lay-donation {
    position: relative;
    width: 200px;
}

.lay-floating .lay-donation > img {
    width: 200px;
}

.lay-floating .lay-donation .lay-donation-link {
    position: absolute;
    left: 50%;
    bottom: 17px;
    transform: translateX(-50%);
}

.lay-floating .lay-donation .lay-donation-link img {
    width: 160px;
}

.lay-floating .lay-additional-info {
    width: calc(100% - 4px);
    background-color: #ffffff;
    border-radius: 25px;
    border: 2px solid #dbdbdb;
    padding: 20px 0;
    box-shadow: 0 2px 0 #dbdbdb;
    margin-top: 3px;
}

.lay-floating .lay-reference-room {
    position: relative;
    padding-bottom: 32px;
}

.lay-floating .lay-reference-room-link {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.lay-floating .lay-reference-room-link > img {
    width: 160px;
}

.lay-floating .lay-reference-room > img {
    width: 152px;
}

.lay-floating .lay-group-request {
    position: relative;
    padding-bottom: 55px;
}

.lay-floating .lay-group-request .lay-group-request-text {
    text-align: center;
    font-weight: 700;
    color: #2e2e2e;
    font-size: 20px;
}

.lay-floating .lay-group-request-link {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.lay-floating .lay-group-request-link img {
    width: 160px;
}

.lay-floating .lay-group-request > img {
    width: 152px;
}

.lay-floating .lay-download-parent-app,
.lay-floating .lay-recruit-teacher,
.lay-floating .lay-recruit-promotion-teacher,
.lay-floating .lay-happy-talk,
.lay-floating .lay-close-button,
.lay-floating .lay-reference-room-button,
.lay-floating .lay-free-study {
    cursor: pointer;
}

.lay-floating .lay-reference-room-button {
    margin-top: -30px;
}

.lay-floating .lay-benefit-image .lay-free-study {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.lay-floating .lay-reference-room .lay-reference-room-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 48px;
    background: url('https://s.wink.co.kr/pc/renewalV3/floating_right_banner/reference_room_button_b.png');
    background-size: 200px 48px;
}

.lay-floating .lay-reference-room .lay-reference-room-button:hover {
    background: url('https://s.wink.co.kr/pc/renewalV3/floating_right_banner/reference_room_button_r.png');
    background-size: 200px 48px;
}

.lay-floating .lay-free-study {
    margin-top: -44px;
}

.lay-floating hr.lay-floating-divider {
    width: calc(100% - 40px);
    border: 1px solid #c4c4c4;
    margin: 15px 0;
}

.lay-floating .lay-additional-info ul.floating-menu-list {
    display: flex;
    flex-direction: column;
    gap: 13px;
    font-family: 'Pretendard', sans-serif;
    font-size: 18px;
    color: #2e2e2e;
    line-height: 26px;
}

.lay-floating .lay-additional-info ul.floating-menu-list li {
    display: grid;
    grid-template-columns: 21px 1fr;
    gap: 7px;
    border-bottom: none;
    padding: 0;
    white-space: pre;
}

.lay-floating .lay-additional-info ul.floating-menu-list li span {
    display: flex;
    justify-content: flex-end;
    transform: translateY(3px);
}

.lay-floating .lay-additional-info ul.floating-menu-list li span img {
    height: auto;
    width: 21px;
    -o-object-fit: contain;
       object-fit: contain;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-download-parent-app
    span {
    padding-right: 2px;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-download-parent-app
    span
    img {
    height: auto;
    width: 12px;
    -o-object-fit: contain;
       object-fit: contain;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-group-consultation
    span
    img {
    height: 17px;
    width: 17px;
    transform: translateY(3px);
}

.lay-floating .lay-cs-center {
    margin-top: 5px;
}

.lay-floating .lay-cs-center .lay-cs-center-text {
    text-align: center;
    font-weight: 500;
    color: #2e2e2e;
    font-size: 15px;
    line-height: 1.2;
}

.lay-floating .lay-cs-center .cs-center-tel {
    font-weight: 900;
    font-size: 26px;
    line-height: 1.5;
}

.lay-floating .lay-free-study img {
    width: 200px;
    margin-top: -4px;
}

.lay-floating .lay-free-study .free-study-text {
    display: block;
    font-size: 20px;
    font-weight: bold;
}

.lay-floating .lay-free-study .free-study-text span {
    color: #f03c78;
}

.lay-floating .lay-reference-room-button img {
    width: 200px;
}

.lay-floating .btn-happytalk {
    display: inline-block;
    width: 90%;
    margin: 0 5%;
    background: #ffd02c;
    font-size: 15px;
    color: #222;
    font-family: 'NanumBarunGothic-Bold';
    text-align: center;
    line-height: 55px;
    height: 54px;
    border-radius: 15px;
    cursor: pointer;
}

.lay-floating .cs-area {
    padding: 0 15px 15px;
    margin-top: 15px;
}

.lay-floating .cs-area .tit,
.lay-floating .cs-area .s-tit {
    font-size: 15px;
    color: #222;
}

.lay-floating .cs-area .tit {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 18px;
}

.lay-floating .cs-area .s-tit {
    text-align: center;
}

.lay-floating .cs-area .phone-number {
    display: inline-block;
    margin-top: 5px;
    font-size: 32px;
    color: #08bbd5;
    letter-spacing: -0.5px;
    font-weight: bold;
}

.lay-floating .cs-area .working-time {
    border-radius: 10px;
    text-align: center;
}

#container {
    position: relative;
    top: 0;
    bottom: 0;
    min-width: 1260px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 99;
    flex-direction: column;
}
#container:after {
    content: '';
    display: block;
    clear: both;
}

#lnb-wrap {
    float: left;
    position: relative;
}
#lnb {
    width: 200px;
    padding-top: 44px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    box-sizing: border-box;
}
#lnb h2 {
    margin-bottom: 32px;
    padding-left: 16px;
    color: #212121;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;
}
#lnb > ul > li {
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
}
#lnb li a {
    display: block;
    padding-left: 16px;
    color: #999;
    font-size: 18px;
    line-height: 56px;
}
#lnb li a:hover {
    color: #08bbd5;
}
#lnb li > ul {
    padding: 23px 0 21px;
    border-top: 1px solid #e0e0e0;
    background: #f5f5f5;
}
#lnb li > ul li {
    margin-top: 10px;
    padding-left: 16px;
    border-bottom-width: 0;
}
#lnb li > ul li:first-child {
    margin-top: 0;
}
#lnb li > ul li a {
    padding-left: 14px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot.png) 0 9px no-repeat;
    color: #999;
    font-size: 16px;
    line-height: 1.6;
}
#lnb li > ul li a:hover {
    color: #666 !important;
}
#lnb li > ul li > ul {
    padding: 14px 0 10px;
    border: none;
}
#lnb li > ul li > ul li {
    margin-top: 13px;
    padding-left: 14px;
}
#lnb li > ul li > ul li a {
    padding-left: 10px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot4.png) 0 9px no-repeat;
    font-size: 14px;
    font-weight: normal;
}
#lnb li > ul li > ul li.on a,
#lnb li > ul li > ul li a:hover {
    color: #08bbd5 !important;
}

#lnb li.on a {
    background-color: #08bbd5;
    color: #fff;
}
#lnb li.on > ul li a {
    background-color: transparent;
    color: #999;
}
#lnb li.on > ul li.on a {
    color: #666;
}
#lnb li.on > ul li.on > ul li a {
    color: #999;
}
/*#lnb li{min-height:56px; border-bottom:1px solid #e0e0e0; box-sizing:border-box;}
#lnb li a{display:block; padding-left:16px; color:#999; font-size:18px; font-weight:bold; line-height:56px;}
#lnb li.on{border-bottom-width:0}
#lnb li.on a{background:#08bbd5; color:#fff;}*/
#lnb .as-center {
    padding: 28px 15px 18px;
    background: #f5f7fa;
    color: #999;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e0;
}
#lnb .as-center strong {
    display: block;
    color: #212121;
    font-size: 16px;
}
#lnb .as-center strong a {
    display: table;
    margin: -3px 0 7px -3px;
    color: #00a5d5;
    font-size: 28px;
    font-weight: normal;
    font-weight: bold;
}
#lnb .as-center b {
    color: #212121;
}
/* #lnb-wrap.lnb-fix{position: fixed; top:56px;z-index:5000;} */
/* isseu_1171 */
#lnb-wrap.lnb-fix {
    position: fixed;
    top: 56px;
}
/* // isseu_1171 */

#contents {
    width: 1000px;
    padding-top: 39px;
    background: #fff;
}
#contents section {
    margin-bottom: 90px;
}
#contents section:last-of-type {
    margin-bottom: 0;
}

/* footer */

#footer {
    width: 100%;
    height: 250px;
    background-color: #eeeeee;
    color: #333333;
    font-size: 16px;
    padding: 69px 0 52px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 90px;
    font-family: Pretendard, 'sans-serif';
}

#footer * {
    font-family: Pretendard, 'sans-serif';
}

#footer .footer-info-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 38px;
}

#footer .footer-info-container .footer-menu-list {
    display: flex;
    font-weight: 500;
}

#footer .footer-info-container .footer-menu-list li {
    position: relative;
    margin: 0 20px;
}

#footer .footer-info-container .footer-menu-list li a {
    color: #333333;
}

#footer .footer-info-container .footer-menu-list li:not(:last-of-type):after {
    content: '';
    position: absolute;
    top: 4px;
    right: -20px;
    height: 65%;
    width: 1px;
    background: #a2a2a2;
}

#footer .footer-info-container .footer-menu-list li:first-child {
    margin-left: 0;
}

#footer .footer-info-container .footer-menu-list li:last-of-type {
    margin-right: 0;
}

#footer .footer-info-container .footer-menu-list li a.pink {
    color: #f03c78;
}

#footer .footer-info-container .footer-menu-list li a.blue {
    color: #1d93ff;
}

#footer .footer-info-container .footer-company-info {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-weight: 300;
}

#footer .footer-contact-container {
    font-weight: 500;
}

#footer .footer-contact-container .title {
    line-height: 21px;
}

#footer .footer-contact-container .cs-number {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    margin: 6px 0 3px;
}

#footer .footer-contact-container .time {
    line-height: 26px;
}

#footer .footer-contact-container .family-site-container {
    margin-top: 16px;
    position: relative;
}

#footer .footer-contact-container .family-site-container button {
    padding: 0 22px;
    border-radius: 5px;
    color: #ffffff;
    background: #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    width: 170px;
    height: 39px;
    font-size: 18px;
    cursor: pointer;
}

#footer .footer-contact-container .family-site-container button img {
    transition: transform ease-in-out 0.3s;
}

#footer .footer-contact-container .family-site-container button img.open {
    transform: rotate(180deg);
}

#footer .footer-contact-container .family-site-container .family-site-list {
    position: absolute;
    bottom: 39px;
    height: 0;
    overflow: hidden;
    transition: height ease-in-out 0.4s;
}

#footer
    .footer-contact-container
    .family-site-container
    .family-site-list.open {
    height: 78px;
    transition: height ease-in-out 0.4s;
}

#footer .footer-contact-container .family-site-container .family-site-list li {
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 170px;
    height: 39px;
    cursor: pointer;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
}

#footer
    .footer-contact-container
    .family-site-container
    .family-site-list
    li:not(:first-child) {
    border-top: none;
}

#footer .footer-contact-container .family-site-container .family-site-list a {
    color: #333333;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

/* footer */

/* Button */
.btn-area {
    margin-top: 24px;
    text-align: center;
}
.btn-area:after {
    content: '';
    display: block;
    clear: both;
}
.btn-area.justify .left {
    float: left;
}
.btn-area.justify .right {
    float: right;
}
.btn-area.right {
    text-align: right;
}
.btn-area.bottom {
    margin-top: 40px;
}
.btn-area a {
    margin: 0 2px;
}
/* issue_977 */
.btn-area.bottom .notice-text {
    text-align: center;
    color: red;
    margin-top: 40px;
}
/* // issue_977 */

.btn-type {
    overflow: hidden;
    display: inline-block;
    height: 40px;
    min-width: 80px;
    padding: 0 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #212121;
    font-size: 14px;
    line-height: 42px;
    cursor: pointer;
}
.btn-type:hover {
    border-color: #212121;
}
.btn-type.off {
    border-color: #e5e5e5;
    color: #bdbdbd;
    cursor: default;
}
.btn-type.go {
    border-width: 0;
    background: #95989f;
    color: #fff;
    text-decoration: none;
}
.btn-type.go:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_go3.png) no-repeat;
    vertical-align: middle;
}
.btn-type.go:hover {
    background-color: #575b63;
}
.btn-type.save {
    border-width: 0;
    background: #95989f;
    color: #fff;
    text-decoration: none;
}
.btn-type.save:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 15px;
    margin-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_save.png) no-repeat;
    vertical-align: middle;
}
.btn-type.save:hover {
    background-color: #575b63;
}
.btn-type.transfer {
    border-color: #e0e0e0;
    background: #f5f5f5;
    color: #999;
}
.btn-type.transfer.on {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}
.btn-type.transfer.on:hover {
    background: #e52565;
}
.btn-type.form {
    border-width: 0;
    background: #95989f;
    color: #fff;
}
.btn-type.form:hover {
    background: #575b63;
}
.btn-type.form.off {
    background: #e5e5e5;
    color: #bdbdbd;
}
.btn-type.on {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}

.btn-type2 {
    overflow: hidden;
    display: inline-block;
    height: 48px;
    min-width: 100px;
    padding: 0 24px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #212121;
    font-size: 16px;
    line-height: 50px;
}
.btn-type2:hover {
    border-color: #212121;
}
.btn-type2:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
}
.btn-type2.confirm-num {
    height: 40px; /*margin-left:4px;*/
    padding: 0 20px;
    font-size: 14px;
    line-height: 40px;
    color: #f03c78;
    border-radius: 4px;
}
.btn-type2.confirm-num:hover {
    border-color: #f03c78;
}
.btn-type2.color {
    color: #f03c78;
}
.btn-type2.color:hover {
    border-color: #f03c78;
}
.btn-type2.on {
    border-width: 0;
    background: #f03c78;
    color: #fff !important;
}
.btn-type2.on:hover {
    background: #e52565;
}
.btn-type2.on2 {
    border-width: 0;
    background: #95989f;
    color: #fff !important;
}
.btn-type2.on2:hover {
    background: #575b63;
}
.btn-type2.mms {
    color: #f03c78;
}
.btn-type2.mms:hover {
    border-color: #f03c78;
}
.btn-type2.mms:before {
    width: 19px;
    height: 16px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_mms.png) no-repeat;
}
.btn-type2.mms2:before {
    width: 19px;
    height: 16px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_mms2.png) no-repeat;
}
.btn-type2.qna:before {
    width: 19px;
    height: 19px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_qna.png) no-repeat;
}
.btn-type2.counsel:before {
    width: 14px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_counsel.png)
        no-repeat;
}
.btn-type2.recommend:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_recommend.png)
        no-repeat;
}
.btn-type2.apply:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply2.png)
        no-repeat;
}
.btn-type2.study:before {
    width: 19px;
    height: 18px;
    margin-right: 7px;
    background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_study.png)
        no-repeat 0 0;
}
.btn-type2.search {
    padding: 0 24px;
}
.btn-type2.search:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_search.png)
        no-repeat;
}
.btn-type2.give-box:before {
    width: 23px;
    height: 22px;
    margin: 0 10px 4px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_give_box.png) no-repeat;
}
.btn-type2.lock:before {
    width: 18px;
    height: 19px;
    margin-top: -3px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_lock.png) no-repeat
        0 0;
}
.btn-type2.home:before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_home.png) no-repeat;
}
.btn-type2.free {
    background: #fff;
    color: #f03c78;
}
.btn-type2.free:hover {
    border-color: #f03c78;
}
.btn-type2.free:before {
    width: 19px;
    height: 18px;
    margin: 0 10px 1px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_free.png) no-repeat;
}
.btn-type2.disabled {
    cursor: not-allowed;
    border: 1px solid #ccc;
}

.btn-type3 {
    display: inline-block;
    height: 64px;
    padding: 0 48px;
    background: #00a5d5;
    border-radius: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 66px;
}
.btn-type3:hover {
    background: #006bb2;
}
.btn-type3.apply:before {
    content: '';
    display: inline-block;
    width: 23px;
    height: 21px;
    margin: 0 12px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply.png) no-repeat;
    vertical-align: middle;
}
.btn-type3.modal-footer-btn {
    height: 40px;
    padding: 0 200px;
    line-height: 45px;
}

.btn-play {
    display: inline-block;
    width: 56px;
    height: 56px;
    background: url(https://s.wink.co.kr/pc/images/btn_play.png) no-repeat;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.paging-navigation {
    margin-top: 40px;
    text-align: center;
}
.paging-navigation a.prev,
.paging-navigation a.next {
    display: inline-block;
    width: 10px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/btn_navigation.png) no-repeat;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
.paging-navigation a.prev {
    margin-right: 6px;
    background-position: 0 0;
}
.paging-navigation a.prev:hover {
    background-position: 0 -18px;
}
.paging-navigation a.next {
    margin-left: 6px;
    background-position: -10px 0;
}
.paging-navigation a.next:hover {
    background-position: -10px -18px;
}
.paging-navigation ul {
    display: inline-block;
    vertical-align: middle;
}
.paging-navigation ul:after {
    content: '';
    display: block;
    clear: both;
}
.paging-navigation li {
    float: left;
    margin: 0 4px;
}
.paging-navigation li a {
    display: block;
    width: 36px;
    height: 36px;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    box-sizing: border-box;
    color: #666;
    font-size: 14px;
    line-height: 36px;
}
.paging-navigation li a:hover {
    border-color: #6a6a6a;
    color: #666;
}
.paging-navigation li.on a,
.paging-navigation li.on a:hover {
    border-color: #00a0e0;
    color: #00a0e0;
}

.btn-enroll {
    display: inline-block;
    background: #f03c78;
    color: #fff;
    border: 1px solid #ca3f6e;
    border-radius: 30px;
    width: 393px;
    height: 61px;
    text-decoration: none;
    line-height: 63px;
    font-size: 26px;
    text-align: center;
    -ms-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.2);
}

/* 이전글 다음글 */
.text-navigation {
    margin-top: 64px;
    border: 1px solid #676969;
    border-width: 1px 0;
}
.text-navigation div:first-child {
    border-top: 0 none;
}
.text-navigation div {
    padding: 16px 16px 14px;
    border-top: 1px solid #e0e0e0;
}
.text-navigation div:first-child strong {
    background-image: url(https://s.wink.co.kr/pc/images/icon_text_prev.png);
}
.text-navigation div strong {
    margin-right: 40px;
    padding-left: 25px;
    font-weight: normal;
    color: #757575;
    background: url(https://s.wink.co.kr/pc/images/icon_text_next.png) no-repeat
        0 50%;
}
.text-navigation div strong,
.text-navigation div a {
    vertical-align: middle;
}
.text-navigation div a {
    display: inline-block;
    overflow: hidden;
    max-width: 575px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    color: #212121;
}

/* Tab */
.tab-type {
    height: 48px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 48px;
}
.tab-type:after {
    content: '';
    display: block;
    clear: both;
}
.tab-type li {
    float: left;
}
.tab-type li a {
    display: block;
    border: 1px solid #e0e0e0;
    border-left-width: 0;
    background: #f7f7f7;
    color: #666;
    height: 46px;
}
.tab-type li:first-child a {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left-width: 1px;
}
.tab-type li:last-child a {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.tab-type li.on a {
    border-bottom-width: 0;
    background: #fff;
    color: #f03c78;
}
.tab-type li.on:first-child a {
    border-bottom-left-radius: 0;
}
.tab-type li.on:last-child a {
    border-bottom-right-radius: 0;
}
.tab-type.cell-1 li {
    width: 100%;
}
.tab-type.cell-2 li {
    width: 50%;
}
.tab-type.cell-3 li {
    width: 33.33%;
}
.tab-type.cell-4 li {
    width: 25%;
}
.tab-type.cell-5 li {
    width: 20%;
}
.tab-type.cell-6 li {
    width: 16.66%;
}
.tab-type.cell-7 li {
    width: 14.28%;
}
.tab-type.cell-8 li {
    width: 12.5%;
}
.tab-type.cell-8 li a span {
    font-size: 14px;
}

.login-modal .tab-type li a {
    border-top-right-radius: 15px;
}

.login-modal .tab-type li:first-child a {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
}

.login-modal input {
    border-radius: 15px !important;
}

.login-modal .btn-type.form {
    border-radius: 15px;
}

.login-modal .phone .text-valid {
    padding-left: 8px;
    font-size: 16px;
}

.login-modal.member.layer-wrap .tab-type {
    margin-top: 0;
}

.login-modal input[type='checkbox'] + label:before {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    margin-top: -15px;
}
.login-modal input[type='checkbox']:checked + label:before {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/checked.png)
        6px 6px no-repeat;
    background-size: 18px 18px;
}

.layer-wrap.member.login-modal .save input[type='checkbox'] + label {
    padding-left: 38px;
    line-height: 38px;
    font-size: 15px;
}

.layer-wrap.member.login-modal .id-login + .save {
    height: auto;
}

.layer .login-modal.layer-wrap button.close {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/close_button.png)
        no-repeat;
    width: 34px;
    height: 34px;
}

.layer-wrap.member.login-modal .tab-type {
    margin-bottom: 4px;
}

.layer-wrap.member.login-modal .reset-password {
    position: absolute;
    right: 40px;
    font-size: 15px;
    line-height: 38px;
    cursor: pointer;
}

.layer-wrap.member.password-reset-modal {
    width: 407px;
    overflow: inherit;
    margin-top: -296px;
}

.password-reset-step1 .text-valid {
    position: absolute;
    margin-top: 18px;
    right: 35px;
    font-size: 15px;
}

.password-reset-step1 .text-valid span {
    margin-left: 8px;
    color: #f03c78;
}

.password-reset-step1 .password-reset-banner img {
    width: 90%;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}

.password-reset-modal .code-error {
    display: flex;
    color: #f03c78;
    font-size: 12px;
    white-space: pre;
    margin-top: 10px;
}

.password-reset-modal .code-error > i {
    background: url(https://us.wink.co.kr/pc/images/icon_warning.png) 0 0
        no-repeat;
    width: 14px;
    height: 14px;
}

.password-reset-modal .new-password-wrapper {
    position: relative;
}
.password-reset-modal .new-password-wrapper .warning {
    background-color: #fff2f6;
}
.password-reset-step1 .bi.bi-exclamation-triangle-fill,
.password-reset-step2 .bi.bi-exclamation-triangle-fill {
    margin-right: 5px;
}

.layer-wrap.member.password-reset-modal button.close {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/close_button.png)
        no-repeat;
    width: 34px;
    height: 34px;
}
.layer-wrap.member.password-reset-modal input {
    width: 100%;
    border-radius: 15px !important;
    margin-top: 9px;
}

.layer-wrap.member.password-reset-modal input:disabled {
    border: 1px solid #d9d9d9a6;
    background-color: #f0f0f0;
}

.layer-wrap.member.password-reset-modal input[type='text']:focus,
.layer-wrap.member.password-reset-modal input[type='password']:focus,
.layer-wrap.member.password-reset-modal input[type='error']:focus {
    outline-color: #e54179 !important;
}

.layer-wrap.member.password-reset-modal input[type='error'] {
    background-color: #fff2f6;
}

.btn-type.form.resetmodal-certification {
    position: absolute;
    right: 32px;
    border-radius: 12px;
    margin-top: 9px;
    padding: 0;
    width: 113px;
    background-color: #f15083;
    color: #ffffff;
    line-height: 14px;
}

.btn-type.form.resetmodal-certification:disabled {
    background-color: #f0f0f0;
    color: #7a7a7a;
    border: 1px solid #d9d9d9;
}

.btn-type.form.password-reset-next {
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #f15083;
    color: #ffffff;
    font-size: 15px;
}

.btn-type.form.password-reset-next:disabled {
    background-color: #f0f0f0;
    color: #28282896;
}

.password-reset-step2 .password-reset-banner img {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
}
.password-reset-step2 .bi.bi-eyenew,
.password-reset-step2 .bi.bi-eyere {
    background: url('https://s.wink.co.kr/pc/images/icon_eye_open.png')
        no-repeat;
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.password-reset-step2 .bi.bi-eye-slashnew,
.password-reset-step2 .bi.bi-eye-slashre {
    background: url('https://s.wink.co.kr/pc/images/icon_eye_close.png')
        no-repeat;
    width: 20px;
    height: 20px;
}

.password-reset-step2 .bi.bi-eyenew,
.password-reset-step2 .bi.bi-eye-slashnew,
.password-reset-step2 .bi.bi-eyere,
.password-reset-step2 .bi.bi-eye-slashre {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.tab-nav {
    width: 720px;
    background: #fff;
}
.tab-nav-f {
    z-index: 99;
    position: fixed;
    top: 55px;
    padding-top: 39px;
}
.tab-nav:after {
    content: '';
    display: block;
    clear: both;
}
.tab-nav ul.tab-type2 {
    margin-bottom: 0px;
}
.tab-nav-slogan.slogan {
    padding: 50px 0 0;
}
.tab-type2 {
    height: 48px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 48px;
}
.tab-type2:after {
    content: '';
    display: block;
    clear: both;
}
.tab-type2 li {
    float: left;
}
.tab-type2 li a {
    display: block;
    height: 48px;
    border: 1px solid #e0e0e0;
    border-left-width: 0;
    box-sizing: border-box;
    background: #f7f7f7;
    color: #666;
}
.tab-type2 li:first-child a {
    border-left-width: 1px;
}
.tab-type2 li.on a {
    border-width: 0;
    background: #676969;
    color: #fff;
    position: relative;
}
.tab-type2 li.on a:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 12px;
    height: 5px;
    margin-left: -6px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge.png) no-repeat;
}
.tab-type2 li.on:first-child a {
    margin-left: 0;
}
.tab-type2 li.on:last-child a {
    margin-right: 0;
}
.tab-type2.cell-2 li {
    width: 50%;
}
.tab-type2.cell-3 li {
    width: 33.33%;
}
.tab-type2.cell-4 li {
    width: 25%;
}
.tab-type2.cell-5 li {
    width: 20%;
}
.tab-type2.cell-6 li {
    width: 16.66%;
}
.tab-type2.cell-7 li {
    width: 14.28%;
}

/* Form */
legend {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}
select,
textarea,
input,
label {
    display: inline-block;
    vertical-align: middle;
}
select,
textarea,
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}
input[type='search'] {
    -webkit-appearance: textfield;
}
input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
button i {
    position: relative;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

div.select-mode {
    display: inline-block;
    min-width: 80px;
    position: relative;
    z-index: 1;
    vertical-align: middle;
}
div.select-mode > button {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    line-height: 40px;
}
div.select-mode button i {
    display: block;
    padding: 0 20px 0 10px;
}
div.select-mode > button:after {
    content: '';
    position: absolute;
    top: 17px;
    right: 11px;
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode.png')
        no-repeat;
}
div.select-mode div {
    position: absolute;
    top: 38px;
    left: -10000px;
    border: 1px solid #212121;
    border-top: none;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
}
div.select-mode.on {
    z-index: 2;
}
div.select-mode.on > button {
    border-color: #212121;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
}
div.select-mode.on > button:after {
    content: '';
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode_on.png')
        no-repeat;
    position: absolute;
    top: 16px;
    right: 10px;
}
div.select-mode.on div {
    left: 0;
    width: 100%; /*z-index:103;*/
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
}
/* issue_1111_02 */
div.select-mode.on ul {
    max-height: 78px;
    overflow-y: scroll;
}
.ver4 div.select-mode.on ul {
    max-height: 117px;
}
.ver5 div.select-mode.on ul {
    max-height: 156px;
}
.ver6 div.select-mode.on ul {
    max-height: 195px;
}

/* div.select-mode.on ul{max-height:195px; overflow-y:scroll;} */
.form.finish-form div.select-mode.on ul {
    max-height: 195px;
    overflow-y: scroll;
}

div.select-mode.on.size-1 ul {
    max-height: 40px !important;
}
div.select-mode.on.size-2 ul {
    max-height: 80px !important;
}
div.select-mode.on.size-3 ul {
    max-height: 120px !important;
}
div.select-mode.on.size-4 ul {
    max-height: 160px !important;
}
div.select-mode.on.size-5 ul {
    max-height: 200px !important;
}
div.select-mode.on.size-6 ul {
    max-height: 240px !important;
}
div.select-mode.on.size-7 ul {
    max-height: 280px !important;
}

/* // issue_1111_02 */
div.select-mode.on ul button {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    background: transparent;
    text-align: left;
    color: #999;
    font-size: 14px;
    line-height: 36px;
}
div.select-mode.on ul button:hover {
    background: #f6f6f6;
    color: #212121;
}
div.select-mode.selected > button {
    color: #212121;
}
div.select-mode.off > button {
    border-color: #e0e0e0;
    background: #f0f0f0;
    color: #bdbdbd;
}

textarea {
    width: 100%;
    min-height: 250px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    resize: vertical;
}
textarea + p {
    margin-top: 3px;
    text-align: right;
    color: #999;
    font-size: 14px;
}
textarea:focus {
    border-color: #212121;
    color: #212121;
}
textarea:focus + p b {
    color: #212121;
}
textarea.disabled {
    border-color: #e0e0e0;
    background: #f0f0f0;
    color: #bdbdbd;
}

input::-webkit-input-placeholder {
    color: #cccccc;
}
input:-moz-placeholder {
    color: #cccccc;
}
input::-moz-placeholder {
    color: #cccccc;
}
input:-ms-input-placeholder {
    color: #cccccc;
}

input[type='checkbox'] {
    position: absolute;
    opacity: 0;
}
input[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    margin-right: 28px;
    padding-left: 28px;
    color: #999;
    font-size: 16px;
    cursor: pointer;
}

input[type='checkbox'] + label:before {
    content: '';
    left: 0;
    top: 50%;
    margin-top: -10px;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url('https://s.wink.co.kr/pc/renewalV3/common/check-off.png')
        no-repeat;
}
input[type='checkbox']:checked + label {
    color: #212121;
}
input[type='checkbox']:checked + label:before {
    background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-check-on.svg')
        no-repeat;
}
input[type='checkbox'].disabled + label {
    color: #bdbdbd;
}
input[type='checkbox'].disabled + label:before {
    background: url('https://s.wink.co.kr/pc/renewalV3/common/check-off.png')
        no-repeat;
}

input[type='radio'] {
    position: absolute;
    opacity: 0;
}
input[type='radio'] + label {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    padding-left: 32px;
    color: #999;
    font-size: 16px;
    cursor: pointer;
}
input[type='radio'] + label:before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    background: url('https://s.wink.co.kr/pc/renewalV3/common/bg_util_radio_color.png')
        no-repeat;
}
input[type='radio']:checked + label {
    color: #212121;
}
input[type='radio']:checked + label:before {
    background-position: -24px 0;
}
input[type='radio'].disabled + label {
    color: #bdbdbd;
}
input[type='radio'].disabled + label:before {
    background-position: -48px 0;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='error'] {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #cccccc;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    line-height: normal !important;
}
input[type='text']:focus,
input[type='password']:focus {
    border-color: #212121;
    color: #212121;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.disabled {
    border-color: #cccccc;
    background: #f3f3f3;
    color: #bdbdbd;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input.on {
    color: #212121;
}
input.error,
input.error:focus {
    border-color: #e83030 !important;
}
input.full-size {
    width: 100%;
}

input[type='file'] {
    position: absolute;
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}
/* input file */
.file + .add-file {
    margin-top: 5px;
}
.add-file a {
    font-size: 14px;
    vertical-align: middle;
    text-decoration: underline;
    color: #212121;
}
.add-file .file-remove {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    background: url(https://s.wink.co.kr/pc/images/btn_add_file_remove.png)
        no-repeat 50% 50%;
}

fieldset.name input[type='text'] {
    width: 160px;
}
fieldset.password input[type='password'] {
    width: 230px;
}

fieldset.phone input[type='text'] {
    width: 190px;
}
/* issue_977 */
fieldset.phone input.long[type='text'] {
    width: 278px;
    margin-right: 5px;
}
/* // issue_977 */
fieldset.phone a.btn-type {
    margin-left: 4px;
}
fieldset.phone a.btn-type:first-child {
    margin-left: 0;
    margin-right: 4px;
}
fieldset.phone .text-valid {
    /*margin-left:4px; */
    font-size: 14px;
    line-height: 40px;
    vertical-align: middle;
}
fieldset.phone .text-valid span {
    color: #f15083;
}

fieldset.file input[type='text'] {
    width: 240px;
}
fieldset.file a.btn-type {
    margin-left: 4px;
}

fieldset.email input[type='text'] {
    width: 120px;
}
fieldset.email input[type='text'].single {
    width: 300px;
}
fieldset.email i {
    display: inline-block;
    margin: 0 3px;
    vertical-align: middle;
    font-size: 14px;
}
fieldset.email .select-mode {
    min-width: 150px;
    margin-left: 5px;
}

fieldset.address input[type='text'] {
    width: 470px;
    margin-top: 8px;
}
fieldset.address input[type='text'].post {
    width: 120px;
    margin-top: 0;
    margin-right: 4px;
}

fieldset.school input[type='text'] {
    width: 180px;
}
fieldset.school .select-mode {
    margin-right: 4px;
    margin-left: 20px;
}
fieldset.school .select-mode:first-of-type {
    width: 100px;
    margin-left: 4px;
}
fieldset.school span.option {
    display: block;
    margin-top: 16px;
}

fieldset.children span.option {
    display: block;
    margin-top: 14px;
}

fieldset.year .select-mode:first-of-type {
    width: 100px;
    margin-left: 0;
}
fieldset.year .select-mode {
    margin: 0 4px 0 20px;
}
fieldset.nationality .select-mode {
    width: 190px;
}
fieldset.radio-set input[type='text'] {
    width: 165px;
    margin: 0 4px;
}
fieldset.radio-set .select-mode {
    width: 100px;
    margin: 0 14px 0 4px;
}
fieldset.radio-set .select-mode:last-child {
    margin-right: 0;
}
fieldset.school .select-mode:first-of-type {
    width: 130px;
    margin-left: 0;
}
fieldset.school input.school-name {
    width: 300px;
}

fieldset.select-area .select-mode {
    width: 120px;
}
fieldset.select-area .select-mode.select-sm {
    width: 100px;
}

fieldset.message {
    height: 72px;
    padding: 16px 16px 16px 24px;
    box-sizing: border-box;
}
fieldset.message input[type='text'] {
    width: 350px;
    margin-right: 6px;
    border-width: 0;
}

fieldset.account input[type='text'] {
    width: 300px;
}
fieldset.account .btn-type {
    margin-left: 4px;
    border-width: 0;
    background: #95989f;
    color: #fff;
}
fieldset.account .btn-type:hover {
    background: #575b63;
}

.hidden-textarea {
    display: none;
    margin-top: 20px;
}

/* 양색자 선택 */
ul.family-select:after {
    content: '';
    display: block;
    clear: both;
}
ul.family-select li {
    float: left;
    margin-left: 16px;
    position: relative;
}
ul.family-select li:first-child {
    margin-left: 0;
}
ul.family-select li input[type='radio'] + label {
    width: 72px;
    height: 84px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    background-image: url(https://s.wink.co.kr/pc/images/member/bg_type.png);
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
}
ul.family-select li input[type='radio'] + label:before {
    display: none;
}
ul.family-select li input[type='radio'] + label:after {
    position: absolute;
    top: 60px;
    left: 6px;
    display: block;
    width: 60px;
    height: 24px;
    text-align: center;
    border-radius: 12px;
    background: #d6d6d6;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
}
ul.family-select li input[type='radio']:checked + label:before {
    display: block;
    top: -2px;
    left: auto;
    right: -2px;
    width: 22px;
    height: 22px;
    background: url(https://s.wink.co.kr/pc/renewalV3/common/check.png) 4px 4px
        no-repeat;
    background-size: 14px 14px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: #00a0e0;
}
ul.family-select li:nth-child(1) input[type='radio'] + label {
    background-position: 0 0;
}
ul.family-select li:nth-child(1) input[type='radio'] + label:after {
    content: '엄마';
}
ul.family-select li:nth-child(1) input[type='radio']:checked + label {
    background-position: 0 -72px;
}
ul.family-select li:nth-child(1) input[type='radio']:checked + label:after {
    background: #ff5252;
}
ul.family-select li:nth-child(2) input[type='radio'] + label {
    background-position: -72px 0;
}
ul.family-select li:nth-child(2) input[type='radio'] + label:after {
    content: '아빠';
}
ul.family-select li:nth-child(2) input[type='radio']:checked + label {
    background-position: -72px -72px;
}
ul.family-select li:nth-child(2) input[type='radio']:checked + label:after {
    background: #ff7e27;
}
ul.family-select li:nth-child(3) input[type='radio'] + label {
    background-position: -144px 0;
}
ul.family-select li:nth-child(3) input[type='radio'] + label:after {
    content: '할머니';
}
ul.family-select li:nth-child(3) input[type='radio']:checked + label {
    background-position: -144px -72px;
}
ul.family-select li:nth-child(3) input[type='radio']:checked + label:after {
    background: #ffc624;
}
ul.family-select li:nth-child(4) input[type='radio'] + label {
    background-position: -216px 0;
}
ul.family-select li:nth-child(4) input[type='radio'] + label:after {
    content: '할아버지';
}
ul.family-select li:nth-child(4) input[type='radio']:checked + label {
    background-position: -216px -72px;
}
ul.family-select li:nth-child(4) input[type='radio']:checked + label:after {
    background: #9bdbff;
}
ul.family-select li:nth-child(5) input[type='radio'] + label {
    background-position: -288px 0;
}
ul.family-select li:nth-child(5) input[type='radio'] + label:after {
    content: '기타';
}
ul.family-select li:nth-child(5) input[type='radio']:checked + label {
    background-position: -288px -72px;
}
ul.family-select li:nth-child(5) input[type='radio']:checked + label:after {
    background: #ae88c5;
}

/* 검색 */
div.search {
    margin-bottom: 32px;
    padding: 32px;
    border-radius: 8px;
    background: #f5f7fa;
}
div.search fieldset {
    overflow: hidden;
}
div.search fieldset:after {
    display: block;
    clear: both;
    content: '';
}
div.search fieldset input[type='text'] {
    float: left;
    width: 568px;
    border-width: 2px;
    border-color: #00a5d5;
}
div.search fieldset input[type='text'].on {
    color: #212121;
}
div.search fieldset button {
    float: right;
    height: 40px;
    padding: 0 28px;
    font-size: 14px;
    line-height: 40px;
}

div.search p {
    text-align: center;
    color: #999;
    font-size: 14px;
    line-height: 1.7;
}
div.search p .question-link {
    color: #00a5d5;
    text-decoration: underline;
}
div.search p b {
    color: #f03c78;
}

/* List */

.list-type {
    border-top: 2px solid #676969;
}
.list-type li {
    border-bottom: 1px solid #e0e0e0;
}
.list-type div.title {
    padding: 15px 40px 13px 16px;
    line-height: 1.75;
    position: relative;
    cursor: pointer;
    color: #212121;
}
.list-type a.title {
    display: block;
    padding: 15px 50px 13px 16px;
    box-sizing: border-box;
    color: #212121;
    line-height: 1.75;
    position: relative;
}
.list-type .title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    width: 14px;
    height: 7px;
    margin-top: -3.5px;
    background: url('https://s.wink.co.kr/pc/images/icon_arrow_down.png')
        no-repeat;
}
.list-type .title em.tag {
    margin-right: 5px;
}
.list-type .title span.date {
    position: absolute;
    top: 50%;
    right: 54px;
    height: 56px;
    margin-top: -28px;
    background: none;
    color: #757575;
    font-size: 16px;
    line-height: 56px;
}
.list-type .title img.new {
    margin-bottom: 2px;
    vertical-align: middle;
}
.list-type .ans {
    padding: 32px 16px;
}
.list-type li.on .title {
    background: #f5f5f5;
}
.list-type li.on .title:after {
    background: url('https://s.wink.co.kr/pc/images/icon_arrow_up.png')
        no-repeat;
}
.list-type li.on .ans {
    display: block;
}

/* 약관동의 */
.terms .all-check {
    position: relative;
    padding: 15px 0 13px;
    line-height: 1.75;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.terms .all-check label {
    color: #212121;
}
.terms .all-check label + small {
    font-size: small;
    margin-left: 25px;
}

/* issue_828 */
.agree-wrap {
    margin-top: 40px;
}
.agree-wrap p.title,
.agree-wrap > fieldset label {
    font-weight: bold;
    color: #212121;
}
.agree-wrap p.title {
    padding: 15px 0 13px;
    line-height: 1.75;
    font-size: 16px;
}
.agree-wrap div.agree-box {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
}
.agree-wrap .agree-box {
    /*margin-top:17px;*/
    border-top: 2px solid #676969;
}
.agree-wrap .agree-box > li > div {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 56px;
    color: #666;
}
.agree-wrap .agree-box > li {
    padding: 0 16px;
    border-bottom: 1px solid #e0e0e0;
}
.agree-wrap .agree-box > li > div input[type='checkbox'] + label {
    display: inline-block;
    padding: 0 50px 0 32px;
    margin-right: 0;
    color: #666;
    line-height: 27px;
}
.agree-wrap .agree-box .form-area {
    padding: 15px;
}
.font-color-black {
    color: black;
}
.font-weight-bold {
    font-weight: 700;
}
.agree-wrap .form-area {
    padding: 15px;
}
.agree-wrap
    .form-area.free-benefit-agreement
    input[type='radio']:checked
    + label {
    font-weight: bold;
}

.agree-box .btn-link,
.list-type .btn-link {
    background: #e9e9e9;
    color: #222;
    border-radius: 5px;
    display: inline-block;
    line-height: 20px;
    padding: 5px 10px;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -15px;
}
/* issue_1982 */

/*.agree-wrap .agree-box > li.on > div{background:url('https://s.wink.co.kr/pc/images/icon_arrow_up.png') right 50% no-repeat;}*/
/*.agree-wrap .agree-box > li > div.provision{display:none;overflow-x:hidden;overflow-y:scroll;height:310px;margin:0 -16px;padding:20px 16px;background:#f5f5f5;border-top:1px solid #e0e0e0;font-size:14px;line-height:24px;color:#666;}
.agree-wrap .agree-box > li > div.provision > strong{display:block;margin:24px 0;}
.agree-wrap .agree-box > li > div.provision > strong:first-child{margin-top:0;}*/
/*.agree-wrap .agree-box p{font-size:16px;}*/
/* issue_1982 */

/* Table */
table {
    border-collapse: collapse;
}
caption {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
}

table.form {
    width: 100%;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
table.form th,
table.form td {
    vertical-align: top;
}
table.form th {
    width: 104px;
    padding: 21px 0 0 16px;
    text-align: left;
    color: #212121;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.form tbody.family th {
    padding: 41px 0 0 0px;
    text-align: center;
}
table.form tbody.family th:nth-child(1) {
    width: 15%;
}
table.form tbody.family th:nth-child(2) {
    width: 15%;
}
table.form tbody.family th:nth-child(3) {
    width: 10%;
}
table.form tbody.family th:nth-child(4) {
    width: 30%;
}
table.form tbody.family th:nth-child(5) {
    width: 30%;
}
table.form tbody.family td {
    padding: 12px 5px;
}
table.form th em {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 1px 0 0 4px;
    vertical-align: top;
    background: url(https://s.wink.co.kr/pc/renewalV3/common/bu_important.svg)
        no-repeat;
    text-indent: -9999px;
}
table.form th small {
    display: block;
    color: #757575;
    font-size: 12px;
}
table.form td {
    padding: 12px 0;
}
table.form tr.radio th,
table.form tr.checkbox th,
table.form tr.text th {
    padding-top: 13px;
}
table.form tr:first-child th {
    padding-top: 41px;
}
table.form tr.radio:first-child th,
table.form tr.checkbox:first-child th,
table.form tr.text:first-child th {
    padding-top: 33px;
}
table.form tr:first-child td {
    padding-top: 32px;
}
table.form tr:last-child td {
    padding-bottom: 32px;
}
table.form tr.text td {
    color: #212121;
}

table.form p.notice {
    margin-top: 8px;
    padding-left: 9px;
    background-image: url(https://s.wink.co.kr/pc/images/bu_dot2.png);
    background-position: 0 10px;
    color: #999;
    font-size: 14px;
}
table.form + p.notice {
    padding-left: 27px;
    background-image: url(https://s.wink.co.kr/pc/images/icon_notice2.png);
    background-position: 0 1px;
}
table.form .item {
    margin-top: 10px;
}
table.form .item:first-of-type {
    margin-top: 0;
}
table.form .item label {
    font-size: 14px;
}

/* issue_614 권혁기 2017.06.27 */
.write-wrap table.form tr:last-child th {
    padding-bottom: 32px;
}
.write-wrap table.form tr.text:first-child th {
    padding-top: 41px;
}
.write-wrap .form .photo-add {
    margin-left: 73px;
}

table.tbl-list {
    table-layout: fixed;
    width: 100%;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
table.tbl-list th,
table.tbl-list td {
    padding: 16px 16px 14px;
}
table.tbl-list thead th {
    color: #212121;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.tbl-list tbody th,
table.tbl-list tbody td {
    border-top: 1px solid #e0e0e0;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* issue_1380 2017.10.16 */
table.tbl-list tbody td .user-id {
    display: inline-block;
}
/* issue_1380 2017.10.16 */
table.tbl-list tbody tr:first-child th.text-left {
    border-top: 1px solid #e0e0e0;
}
table.tbl-list tbody th.text-left {
    border-top: 0 none;
    text-align: left;
}
table.tbl-list tbody td.title {
    text-align: left;
}
table.tbl-list tbody td.title a {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #212121;
}
table.tbl-list tbody td.text-left {
    padding-left: 0;
    text-align: left;
}
table.tbl-list tbody td .tag {
    margin-right: 5px;
}

table.tbl-detail {
    width: 100%;
    border-top: 2px solid #676969;
}
table.tbl-detail th,
table.tbl-detail td {
    padding: 16px 16px 14px;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    color: #212121;
}
table.tbl-detail th {
    width: 72px;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.tbl-detail td {
    width: 224px;
}
table.tbl-detail td ul.file li {
    display: inline-block;
    margin-right: 20px;
}
table.tbl-detail td ul.file li a {
    color: #212121;
    font-size: 14px;
    text-decoration: underline;
}

table.qna thead tr th:nth-child(1) {
    width: 24px;
}
table.qna thead tr th:nth-child(2) {
    width: 94px;
}
table.qna thead tr th:nth-child(3) {
    width: 92px;
}
table.qna thead tr th:nth-child(4) {
    width: 295px;
}
table.qna thead tr th:nth-child(5) {
    width: 55px;
}

.relative-box {
    position: relative;
}
table .relative-box .btn-type {
    position: absolute;
    right: 0;
    top: -8px;
}
table .relative-box .btn-type.btn-remove {
    top: 4px;
    min-width: 56px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
}
table .relative-box .go {
    position: absolute;
    right: 0;
    top: 5px;
    line-height: 1.2;
}

.tbl-row {
    border-top: 2px solid #676969;
}
.tbl-row.no-line {
    border-top: 0 none;
}
.tbl-row table {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}
.tbl-row tr:first-child th,
.tbl-row tr:first-child td {
    padding-top: 28px;
}
.tbl-row tr:last-child th,
.tbl-row tr:last-child td {
    padding-bottom: 26px;
}
.tbl-row th,
.tbl-row td {
    padding: 7px 0;
    text-align: left;
    vertical-align: top;
    color: #212121;
}
.tbl-row th {
    padding-left: 16px;
    font-size: 14px;
    font-weight: bold;
}
.tbl-row td small {
    font-size: 14px;
}
.tbl-row td .sub-text {
    color: #999;
}
.tbl-row td.num {
    font-size: 32px;
    line-height: 1.2;
    vertical-align: middle;
    text-align: center;
    color: #f03c78;
}
.tbl-row td.num span {
    display: block;
    font-size: 16px;
}
.tbl-row .middle th,
.tbl-row .middle td {
    vertical-align: middle;
}
.tbl-row .btn-coupon {
    margin-left: 30px;
}

.layer-pay .layer-scroll .scroll > .section .tbl-row {
    width: 560px;
    margin-top: -32px;
}
.layer-pay .layer-scroll .scroll > .section .auto-pay {
    width: 560px;
}
.layer-pay .layer-scroll .scroll > .section .terms {
    width: 560px;
    margin-bottom: 48px;
}

/*질문/답변*/
div.qna {
    border-bottom: 1px solid #e0e0e0;
    background: #f5f5f5;
    color: #212121;
    line-height: 1.6;
}
div.qna p {
    margin: 0 32px;
    padding: 28px 0 26px 88px;
}
div.qna p.q {
    background: url(https://s.wink.co.kr/pc/images/bg_q.png) no-repeat 0 center;
}
div.qna p.a {
    border-top: 1px solid #e0e0e0;
    background: url(https://s.wink.co.kr/pc/images/bg_a.png) no-repeat 0 center;
}

/* 하단 설명 텍스트 box */
.footer-info-box {
    position: relative;
    margin-top: 96px;
    padding: 34px 30px 31px;
    border: 3px solid #e0e0e0;
    border-radius: 7px;
    background-color: #fff;
}
.footer-info-box strong {
    display: block;
    font-size: 24px;
    line-height: 32px;
    color: #212121;
}
.footer-info-box p {
    margin-top: 3px;
}

.footer-info-box.button-on-right .btn-type2 {
    position: absolute;
    right: 5%;
    bottom: 50%;
    transform: translate(0, 50%);
}

/* ul bullet dot style */
.list-style-dot {
    margin-top: 14px;
}
.list-style-dot:first-child {
    margin-top: 0;
}
p.list-style-dot,
.list-style-dot li {
    position: relative;
    padding-left: 8px;
}
p.list-style-dot:after,
.list-style-dot li:after {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 2px;
    height: 2px;
    background-color: #b1b1b1;
}
.list-style-dot.append-text li:after {
    top: 8px;
}
.list-style-dot li img {
    vertical-align: middle;
}
.list-style-dot li em {
    color: #08bbd5;
}

.append-text {
    font-size: 14px;
}
.append-text a {
    text-decoration: underline;
}

/* 하단 체크 */
.check-area {
    margin-top: 12px;
}
.check-area input[type='checkbox'] + label {
    font-size: 14px;
}

/* 문자메시지 */
.chat-wrap {
    margin: -32px 0;
    text-align: left;
}
.chat-wrap p.teacher {
    height: 80px;
    padding: 28px 0 0 24px;
    box-sizing: border-box;
    color: #212121;
    font-size: 24px;
}
.chat-wrap p.teacher em.tag {
    height: 32px;
    margin: 0 3px 2px 0;
    border-radius: 16px;
    font-size: 16px;
    line-height: 34px;
}
.chat-wrap p.teacher em.tag:first-child {
    margin-right: 1px;
}
.chat-wrap .notice {
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding-left: 55px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat
            24px 50%,
        rgba(0, 0, 0, 0.6);
}

.chat-list {
    position: relative;
    height: 360px;
    padding: 24px;
    background: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
}
.chat-list:after {
    content: '';
    display: block;
    clear: both;
}
.chat-list .message {
    display: inline-block;
    margin-top: 8px;
    padding: 13px 16px 11px;
    border-radius: 4px;
    position: relative;
}
.chat-list .message:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 10px;
}
.chat-list .message .media {
}
.chat-list .message .media button {
    background: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    cursor: pointer;
}
.chat-list .message .media span {
    display: inline-block;
    vertical-align: middle;
}
.chat-list .message .media .equalizer {
    position: relative;
    width: 77px;
    height: 30px;
    margin: 0 10px;
    background: url('https://s.wink.co.kr/pc/images/img_media_receive.png')
        no-repeat;
}
.chat-list .message .media .equalizer span {
    position: absolute;
    top: 0;
    left: 0;
    width: 77px;
    height: 30px;
    background: url('https://s.wink.co.kr/pc/images/img_media_receive_on.png')
        no-repeat;
}
.chat-list button.share {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 100%;
    background: url(https://s.wink.co.kr/pc/images/icon_share.png) no-repeat 3px
        50%;
    font-size: 0;
    line-height: 0;
}
.chat-list button.media {
    display: block;
    height: 31px;
    padding-left: 128px;
    background: url(https://s.wink.co.kr/pc/images/icon_media.png) no-repeat;
    color: #212121;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
}
.chat-list button.media.on {
    background-image: url(https://s.wink.co.kr/pc/images/icon_media_on.png);
}
.chat-list span.time {
    position: absolute;
    bottom: 0;
    color: #bdbdbd;
    font-size: 12px;
}
.chat-list span.date {
    clear: both;
    display: table;
    height: 28px;
    margin: 0 auto 32px;
    padding: 0 12px;
    border-radius: 14px;
    background: #e7e7e7;
    color: #757575;
    font-size: 14px;
    line-height: 28px;
}

.chat-list .receive {
    float: left;
    width: 260px;
    margin-left: 64px;
    margin-bottom: 32px;
}
.chat-list .receive figure {
    position: relative;
}
.chat-list .receive figure img {
    position: absolute;
    top: 0;
    left: -64px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.chat-list .receive figure img.default {
    border: 1px solid #e0e0e0;
}
.chat-list .receive figcaption {
    color: #212121;
}
.chat-list .receive figcaption em.tag {
    margin: 0 3px 4px 0;
}
.chat-list .receive .message {
    background: #fff;
    color: #212121;
}
.chat-list .receive .message:first-of-type {
    border-top-left-radius: 0;
}
.chat-list .receive .message:first-of-type:before {
    left: -8px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge_receive.png)
        no-repeat;
}
.chat-list .receive button.share {
    right: -40px;
}
.chat-list .receive span.time {
    right: -40px;
}
.chat-list .receive button.share + span.time {
    right: -78px;
}

.chat-list .transmit {
    float: right;
    width: 260px;
    margin-bottom: 32px;
}
.chat-list .transmit .message {
    float: right;
    clear: both;
    background: #5f7d8c;
    color: #fff;
}
.chat-list .transmit .message:first-of-type {
    border-top-right-radius: 0;
}
.chat-list .transmit .message:first-of-type:before {
    right: -8px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge_transmit.png)
        no-repeat;
}
.chat-list .transmit span.time {
    left: -40px;
}
.chat-list .transmit button.share {
    left: -40px;
}
.chat-list .transmit button.share + span.time {
    left: -78px;
}

.chat-list .call {
    vertical-align: middle;
    margin-right: 5px;
}

.chat-area {
    position: relative;
}
.emoticon-view {
    display: none;
    position: absolute;
    left: 0;
    top: -150px;
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 20px 0 19px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.emoticon-view .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 12px;
    height: 12px;
    text-indent: -999em;
    background: url(https://s.wink.co.kr/pc/images/mykids/btn_emoticon_close.png)
        no-repeat 0 0;
}
.chat-emoticon {
    padding-bottom: 6px;
}
.chat-emoticon .swiper-container {
    width: 100%;
    height: 73px;
    padding: 15px 0 20px;
    border-top: 1px solid #e0e0e0;
}
.chat-emoticon .swiper-slide {
    text-align: center;
    font-size: 0;
}
.chat-emoticon .swiper-slide a {
    display: inline-block;
    width: 20%;
}

/* Message */
.message-box {
    padding: 42px 0 48px;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
}
.message-box strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}
.message-box strong b {
    color: #f03c78;
}
.message-box small {
    display: block;
    margin-top: 30px;
    color: #999;
    font-size: 14px;
    line-height: 1.7;
}
.message-box img.loading {
    display: table;
    margin: 20px auto 0;
}
.message-box .btn-area {
    margin-top: 34px;
}
.message-box .notice-wrap {
    display: table;
    margin: 12px auto 40px;
    padding: 28px 32px 26px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #f5f5f5;
    text-align: left;
}
.message-box.loading {
    padding: 56px 0 62px;
    border-top-width: 0;
}
.message-box.no-line {
    border-width: 0;
}
.message-box.no-line-top {
    border: none;
}
.message-box.search-none {
    margin-top: 32px;
}
.message-box.search-none p {
    padding-top: 110px;
    background: url('https://s.wink.co.kr/pc/images/info/bg_none.gif') 50% 6px
        no-repeat;
}
/*.message-box.breakdown{padding:140px 0;}*/
.message-box.no-data {
    padding: 140px 0;
}
.message-box.no-data.no-line {
    border-bottom: none;
    border-top: none;
}
.message-box.no-data p {
    padding-top: 90px;
    background: url('https://s.wink.co.kr/pc/images/bg_nodata.png') 50% 0
        no-repeat;
}

.message-box.mykids-no-data p {
    padding-top: 80px;
    background: url('https://s.wink.co.kr/pc/images/mykids/icon_content_notice.png')
        50% 0 no-repeat;
}

.message-box.faq p {
    padding-top: 100px;
    background: url('https://s.wink.co.kr/pc/images/bg_nodata2.png') 50% 0
        no-repeat;
}
.message-box.shopping {
    margin-top: -32px;
    border-top-width: 0;
}
.message-box.shopping p {
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_transport.png)
        50% 0 no-repeat;
}

/* 정보 없음
.message-box.no-data {padding:110px 0;}
.message-box.no-data p {padding-top:110px; font-size:24px; background:url(https://s.wink.co.kr/pc/images/customer/icon_no_data.png) no-repeat 50% 0;}
.message-box.no-data p b {color:#f03c78;}

.message-box.no-data2 {padding:110px 0;}
.message-box.no-data2 p {padding-top:110px; font-size:24px; background:url(https://s.wink.co.kr/pc/images/customer/icon_no_data2.png) no-repeat 50% 0;} */

/* swiper css */
.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    direction: ltr;
    backface-visibility: hidden;
    /* Fix of Webkit flickering */
    z-index: 1;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    -webkit-transition-property: -webkit-transform, left, top;
    -webkit-transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    -webkit-transition-timing-function: ease;

    -moz-transition-property: -moz-transform, left, top;
    -moz-transition-duration: 0s;
    -moz-transform: translate3d(0px, 0, 0);
    -moz-transition-timing-function: ease;

    -o-transition-property: -o-transform, left, top;
    -o-transition-duration: 0s;
    -o-transform: translate3d(0px, 0, 0);
    -o-transition-timing-function: ease;
    -o-transform: translate(0px, 0px);

    -ms-transition-property: -ms-transform, left, top;
    -ms-transition-duration: 0s;
    -ms-transform: translate3d(0px, 0, 0);
    -ms-transition-timing-function: ease;

    transition-property: transform, left, top;
    transition-duration: 0s;
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease;
    box-sizing: content-box;
}
.swiper-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    float: left;
    box-sizing: content-box;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
}

.swiper-container {
}
.swiper-slide {
}
.swiper-slide-active {
}
.swiper-slide-visible {
}
.swiper-pagination {
    text-align: center;
    line-height: 1.2;
}
.swiper-pagination-switch {
    display: inline-block;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border: 2px solid #ccc;
    border-radius: 100%;
    background-color: #fff;
}
.swiper-active-switch {
    border: 0 none;
    background-color: #00bbd4;
}
.swiper-visible-switch {
}

/* 댓글 */
section.reply h3 {
    margin-bottom: 8px;
    color: #212121;
    font-size: 20px;
    font-weight: bold;
}
section.reply h3 strong {
    color: #08bbd5;
    font-weight: normal;
}
section.reply fieldset {
    margin: 10px 0 32px;
}
section.reply fieldset button {
    width: 80px;
    height: 80px;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
    color: #999;
    font-size: 14px;
    line-height: 80px;
}
section.reply fieldset textarea {
    width: calc(100% - 88px);
    height: 80px;
    min-height: 0;
    margin-right: 4px;
}
section.reply fieldset textarea:focus ~ button {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}
section.reply .reply-list {
    border-top: 2px solid #676969;
}
section.reply .reply-list li {
    padding: 26px 16px 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
}
section.reply .reply-list li > ul {
    margin: 0 -16px;
}
section.reply .reply-list li > ul li {
    padding-left: 64px;
    border-bottom-width: 0;
    border-top: 1px solid #e0e0e0;
    background: url(https://s.wink.co.kr/pc/images/icon_reply2.png) 38px 29px
        no-repeat;
}
section.reply .reply-list li p {
    margin: 5px 0 26px;
}
section.reply .reply-list li p ~ fieldset {
    margin-top: -8px;
}
section.reply .reply-list li span.writer {
    padding-left: 23px;
    background: url(https://s.wink.co.kr/pc/images/icon_user.png) no-repeat;
    color: #999;
    font-size: 14px;
}
section.reply .reply-list li span.time {
    margin: 0 4px;
    padding-left: 10px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 0 5px no-repeat;
    color: #999;
    font-size: 14px;
}
section.reply .reply-list li span.util {
    position: absolute;
    top: 26px;
    right: 16px;
}
section.reply .reply-list li span.util a {
    color: #666;
    font-size: 14px;
}
section.reply .reply-list li span.util a.cancel {
    text-decoration: underline;
}
section.reply .reply-list li span.util a:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 9px 2px 5px;
    vertical-align: middle;
    background: #bdbdbd;
}
section.reply .reply-list li span.util a:first-child:before {
    display: none;
}
section.reply .reply-list li a.reply {
    color: #08bbd5;
    font-size: 14px;
}
section.reply .reply-list li a.reply.cancel {
    text-decoration: underline;
}
section.reply .reply-list li.notice {
    padding: 16px 16px 14px;
}
section.reply p.notice {
    padding: 25px 16px 22px;
    border-bottom: 1px solid #e0e0e0;
    background: #f6f7fb;
    color: #212121;
}
section.reply p.notice span.date {
    display: table;
    margin-top: 7px;
    padding: 0;
    background: none;
}

/* 로딩 */
body.loading {
    background: none;
}
p.loading {
    padding-top: 250px;
    text-align: center;
    font-size: 18px;
}
p.loading img.loading {
    display: table;
    margin: 20px auto 0;
}

/* 오류 */
body.error {
    background: none;
}

p.error {
    padding: 270px 0 10px;
    background: url(https://s.wink.co.kr/pc/images/bg_error.png) 50% 123px
        no-repeat;
    text-align: center;
}
p.error strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}

/* 권한설정 로그인버튼 */
p.permission {
    padding: 270px 0 10px;
    text-align: center;
}
p.permission strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}

/* 캘린더 날짜 선택 */
.calendar-selector {
    height: 32px;
    margin: 8px 0 32px;
    text-align: center;
    line-height: 32px;
    position: relative;
    z-index: 1;
}
.calendar-selector a,
.calendar-selector button,
.calendar-selector strong,
.calendar-selector .layer-calendar {
    display: inline-block;
    vertical-align: middle;
}
.calendar-selector a.prev,
.calendar-selector a.next {
    width: 32px;
    height: 32px;
    font-size: 0;
    line-height: 0;
}
.calendar-selector a.prev {
    background: url(https://s.wink.co.kr/pc/images/btn_prev.png) no-repeat;
}
.calendar-selector a.prev:hover {
    background: url(https://s.wink.co.kr/pc/images/btn_prev_on.png) no-repeat;
}
.calendar-selector a.next {
    background: url(https://s.wink.co.kr/pc/images/btn_next.png) no-repeat;
}
.calendar-selector a.next:hover {
    background: url(https://s.wink.co.kr/pc/images/btn_next_on.png) no-repeat;
}
.calendar-selector button.today {
    position: absolute;
    top: 0;
    right: 16px;
    width: 56px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    text-align: center;
    color: #212121;
    font-size: 12px;
    cursor: pointer;
}
.calendar-selector button.today:hover {
    border-color: #212121;
}

.layer-calendar {
    margin-top: 3px;
    position: relative;
    z-index: 99;
}
.layer-calendar button.picker {
    margin: 0 12px 2px 6px;
    padding-right: 20px;
    background: url(https://s.wink.co.kr/pc/images/btn_pull_down.png) 100% 50%
        no-repeat;
    color: #212121;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
.layer-calendar button.picker.on {
    background-image: url(https://s.wink.co.kr/pc/images/btn_pull_up.png);
}
.layer-calendar button.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/icon_util_close2.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
}
.layer-calendar .date-picker {
    display: none;
    position: absolute;
    top: 44px;
    left: -95px;
    width: 320px;
    padding: 20px 24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    -ms-box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}
.layer-calendar .date-picker fieldset {
    margin: 12px 0;
    text-align: left;
    line-height: 40px;
}
.layer-calendar .date-picker fieldset .select-mode:first-of-type {
    width: 100px;
    margin-right: 4px;
}
.layer-calendar .date-picker th,
.layer-calendar .date-picker td {
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
}
.layer-calendar .date-picker th {
    color: #212121;
}
.layer-calendar .date-picker td {
    padding: 4px 0 0 8px;
    color: #bdbdbd;
}
.layer-calendar .date-picker td:first-child {
    padding-left: 0;
}
.layer-calendar .date-picker td a {
    display: block;
    height: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    color: #212121;
}
.layer-calendar .date-picker td a:hover {
    background: #f03c78;
    color: #fff;
}
.layer-calendar .date-picker td.today a {
    border: 1px solid #f03c78;
    color: #f03c78;
}
.layer-calendar .date-picker td.today a:hover {
    color: #fff;
}

.layer-calendar.layer-calendar-day .date-picker {
    left: -50px;
}

.calendar-selector.box {
    height: 80px;
    margin: 0 0 20px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background: #f5f5f5;
    line-height: 80px;
}
.calendar-selector.box button.today {
    top: 25px;
}
.calendar-selector.box .layer-calendar .date-picker {
    top: 68px !important;
}

/**/
.wink-user-post {
    border-bottom: 1px solid #e0e0e0;
    overflow: hidden;
}
.wink-user-post:after {
    content: '';
    display: block;
    clear: both;
}
.wink-user-post li {
    float: left;
    width: 220px;
    margin-left: 30px;
}
.wink-user-post li:nth-child(3n + 1) {
    margin-left: 0;
}
.wink-user-post li figure {
    position: relative;
}
.wink-user-post li figure > a {
    position: relative;
    display: block;
    width: 220px;
    height: 123px;
    border-radius: 8px;
    overflow: hidden;
}
.wink-user-post li figure img {
    vertical-align: top;
}
.wink-user-post li figure .thumb img {
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.wink-user-post li figcaption > a {
    display: block;
    margin: 19px 0 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #212121;
    font-size: 18px;
}
.wink-user-post li figcaption p {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 26px;
    font-size: 14px;
    line-height: 1.5;
}
.wink-user-post li .btn-play {
    position: absolute;
    top: 65px;
    right: 2px;
}
.wink-user-post li p.writer {
    margin-bottom: 27px;
    font-size: 14px;
    letter-spacing: -0.03em;
}
.wink-user-post li p.writer strong {
    display: block;
    padding-left: 22px;
    background: url(https://s.wink.co.kr/pc/images/icon_user.png) 0 3px
        no-repeat;
}
.wink-user-post li p.writer em {
    margin-left: 3px;
    padding-left: 9px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 0 7px no-repeat;
    color: #999;
}
.wink-user-post li p.writer em:first-of-type {
    margin-left: 0;
    padding-left: 0;
    background: none;
}
.wink-user-post.section {
    margin-bottom: 90px;
}

/* Layer popup */
.layer-scroll {
    position: relative;
    margin-top: -32px;
}
.layer-scroll:after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    /*background:url(https://s.wink.co.kr/pc/images/customer/bg_gradaition_white.png) repeat-x 0 100%;*/
    background: linear-gradient(transparent 8%, rgba(255, 255, 255, 0.9));
}
.layer-scroll .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    max-height: 480px;
    margin-right: -32px;
    padding: 32px 16px 25px 0;
}
.layer-scroll .scroll.member {
    height: 386px;
}
.layer-scroll .scroll.chart {
    height: 417px;
}
.layer-scroll .scroll.pay {
    height: 325px;
    font-size: 16px;
}
.layer-scroll .scroll.tip {
    height: 367px;
}
.layer-scroll + .btn-area {
    margin-top: 15px !important;
}

.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

/* issue_939 2017.08.21 layer-extension */
.layer .layer-wrap .layer-extension h3 {
    padding-left: 17px;
    margin-bottom: 16px;
}

/* issue_612 layer초기 값*/
.scroll-wrap {
    padding: 15px;
    margin-bottom: 19px;
    border-bottom: 1px solid #e0e0e0;
    height: 230px;
    border-top: 2px solid #676969;
    overflow-x: none;
    overflow-y: auto;
}

.layer .dim {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    transform: translate3d(0, 0, 0);
    z-index: 1;
}
.layer .layer-wrap {
    position: absolute;
    top: 50%;
    left: 50%; /*max-height:560px;*/
    margin-top: -280px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 2;
}
.layer .layer-wrap h2 {
    margin-bottom: 21px;
    color: #f03c78;
    font-size: 24px;
    line-height: 24px;
}
.layer .layer-wrap h2.line {
    margin-bottom: 32px;
    padding-bottom: 21px;
    border-bottom: 2px solid #676969;
}
.layer .layer-wrap h3 {
    margin-bottom: 9px;
    color: #212121;
    font-size: 18px;
}
.layer .layer-wrap .hgroup.line {
    margin-bottom: 32px;
    border-bottom: 2px solid #676969;
}
.layer .layer-wrap .hgroup h2 {
    margin-bottom: 10px;
}
.layer .layer-wrap .hgroup small {
    display: block;
    margin-bottom: 19px;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer .layer-wrap .btn-type2.confirm {
    border-width: 0;
    background: #53b7d2;
    color: #fff;
}
.layer .layer-wrap .btn-type2.confirm:hover {
    background: #575b63;
}
.layer .layer-wrap .btn-type2.confirm.off {
    background: #e5e5e5;
    color: #bdbdbd;
}
.layer .layer-wrap button.close {
    position: absolute;
    top: 31px;
    right: 31px;
    width: 18px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/icon_util_close2.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
    z-index: 3;
    cursor: pointer;
}
.layer .layer-wrap.small {
    width: 480px;
    margin-left: -240px;
    padding: 48px 0;
    text-align: center;
}
.layer .layer-wrap.large {
    width: 624px;
    margin-left: -312px;
    padding: 32px;
}
.layer .layer-wrap.larger {
    width: 784px;
    margin-left: -392px;
    padding: 32px;
}

.layer .layer-wrap.large table.form {
    width: 560px;
}
.layer .layer-wrap.large table.form fieldset.address input[type='text'].post {
    width: 120px;
}
.layer .layer-wrap.large table.form fieldset.address input[type='text'] {
    width: 406px;
}
.layer .layer-wrap.large .layer-scroll table.form {
    margin-top: -32px;
    border-top-width: 0;
}
.layer .layer-wrap.large .hidden-textarea textarea {
    width: 406px;
}

.layer-wrap .section {
    margin-top: 30px;
}
.layer-wrap .section:first-child {
    margin-top: 0 !important;
}
.layer-wrap .section .head {
    margin-bottom: 10px;
}
.layer-wrap .section .head h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #212121;
    line-height: 1.2;
}
.layer-wrap .section .head h3 small {
    font-size: 14px;
    color: #08bbd5;
}
.layer-wrap .section .head .notice {
    margin-bottom: 15px;
    padding-left: 27px;
    font-size: 14px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice2.png) no-repeat 0
        0;
}
.layer-wrap .section .img-box {
    margin-top: 17px;
}
.layer-wrap .layer-scroll .section {
    margin-top: 30px;
}
.layer-wrap table.form + .check-area:last-child {
    margin-bottom: 20px;
}

.layer-download {
    margin-bottom: -6px;
}
.layer-download fieldset.phone {
    margin-bottom: 20px;
    padding-top: 8px;
}
.layer-download fieldset.phone input {
    width: 240px;
}

.layer-download .tip-img {
    width: 100%;
}

.layer-apply p.notice {
    margin-top: 12px;
}
.layer-apply p.notice:after {
    content: '';
    display: block;
    clear: right;
}
.layer-apply p.notice a.go {
    float: right;
    background-position: 100% 5px;
}

.layer-chat figure {
    position: relative;
    text-align: center;
}
.layer-chat figure img {
    vertical-align: top;
}
.layer-chat figcaption {
    position: absolute;
    bottom: 124px;
    right: 20px;
    width: 274px;
    height: 94px;
    padding: 11px 16px 0;
    box-sizing: border-box;
    text-align: left;
    background: rgba(41, 192, 211, 0.9);
    color: #fff;
    font-weight: bold;
    letter-spacing: -0.1px;
}
.layer-chat figcaption:after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: 44px;
    display: block;
    width: 37px;
    height: 50px;
    background: url(https://s.wink.co.kr/pc/images/myteacher/bg_line.png)
        no-repeat;
}

.layer-analysis .select-mode {
    width: 160px;
}
.layer-analysis .chart {
    margin-top: 16px;
    border-radius: 8px;
    overflow: hidden;
}
.layer-analysis .chart img.tmp {
    vertical-align: top;
}

.layer-share ul {
    text-align: center;
}
.layer-share ul:after {
    content: '';
    display: block;
    clear: both;
}
.layer-share li {
    display: inline-block;
    width: 80px;
}
.layer-share li a {
    display: block;
    color: #666;
    font-size: 14px;
}
.layer-share li a:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 12px;
    background: url(https://s.wink.co.kr/pc/images/icon_share3.png) no-repeat;
}
.layer-share li.facebook a:before {
    background-position: 0 0;
}
.layer-share li.cacaostory a:before {
    background-position: -64px 0;
}
.layer-share li.twitter a:before {
    background-position: -128px 0;
}
.layer-share li.mail a:before {
    background-position: -192px 0;
}
.layer-share fieldset {
    margin-top: 26px;
}
.layer-share fieldset label {
    width: 42px;
    margin-right: -4px;
    padding-left: 8px;
    text-align: left;
    color: #212121;
    font-size: 14px;
    font-weight: bold;
}
.layer-share fieldset input[type='text'] {
    width: calc(100% - 174px);
    margin-right: 4px;
    background: #fff;
    color: #212121;
}
.layer-share fieldset a.btn-type {
    border-width: 0;
    background: #95989f;
    color: #fff;
}
.layer-share fieldset a.btn-type:hover {
    background: #575b63;
}

.layer-post fieldset {
    margin-bottom: 16px;
}
.layer-post fieldset p {
    margin-bottom: 11px;
    color: #999;
    font-size: 14px;
}
.layer-post fieldset p b {
    display: block;
    color: #212121;
}
.layer-post fieldset input[type='text'] {
    width: 472px;
    margin-right: 4px;
}
.layer-post fieldset .btn-type {
    border-width: 0;
    background: #95989f;
    color: #fff;
    cursor: pointer;
}
.layer-post fieldset .btn-type:hover {
    background: #575b63;
}
.layer-post fieldset .select-mode {
    width: calc(50% - 4px);
    margin-bottom: 10px;
}
.layer-post fieldset .select-mode:first-of-type {
    margin-right: 4px;
}
.layer-post .scroll-list {
    border-radius: 4px;
    background: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
}
.layer-post .scroll-list.road {
    height: 184px;
}
.layer-post .scroll-list.number {
    height: 234px;
}
.layer-post .scroll-list table {
    width: 100%;
}
.layer-post .scroll-list td {
    padding: 12px 16px 10px 16px;
    color: #999;
    font-size: 14px;
}
.layer-post .scroll-list td a {
    color: #999;
}
.layer-post .scroll-list td:first-child {
    width: 62px;
}
.layer-post .scroll-list tr:hover td {
    background: #e0e0e0;
    color: #212121;
    cursor: pointer;
}
.layer-post .scroll-list tr:hover td a {
    color: #212121;
}
.layer-post .scroll-list.none {
    display: table;
    width: 100%;
}
.layer-post .scroll-list.none p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.layer-report .report-detail {
    margin-bottom: 35px;
    padding: 16px;
    border-radius: 8px;
    background: #f5f5f5;
}
.layer-report .report-detail table {
    width: 100%;
}
.layer-report .report-detail th,
.layer-report .report-detail td {
    padding: 4px 0 2px;
}
.layer-report .report-detail th {
    width: 100px;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
.layer-report fieldset {
    padding: 16px;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
.layer-report fieldset li {
    margin-top: 15px;
}
.layer-report fieldset textarea {
    display: none;
    min-height: 80px;
    margin: 16px 0;
}
.layer-report fieldset input#report4:checked ~ textarea {
    display: block;
}

.layer-play .player {
    height: 404px;
    margin-top: 19px;
    border-radius: 8px;
    overflow: hidden;
}
.layer-play .player p {
    line-height: 404px;
    background: #1f2d30;
    text-align: center;
    color: #fff;
    font-size: 24px;
}
.layer-play .player .video-js {
    background-color: #fff;
}
.layer-play .player .vjs-big-play-button {
    top: 170px;
    left: 315px;
}

.layer-photo .photo-slider {
    width: 720px;
    height: 404px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.layer-photo .photo-slider img {
    vertical-align: middle;
}
.layer-photo .photo-slider a.prev,
.layer-photo .photo-slider a.next {
    position: absolute;
    top: 50%;
    display: block;
    width: 48px;
    height: 48px;
    margin-top: -24px;
    background: url(https://s.wink.co.kr/pc/images/btn_navigation3.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
}
.layer-photo .photo-slider a.prev {
    left: 0;
    background-position: 0 0;
}
.layer-photo .photo-slider a.prev:hover {
    background-position: 0 -48px;
}
.layer-photo .photo-slider a.next {
    right: 0;
    background-position: -48px 0;
}
.layer-photo .photo-slider a.next:hover {
    background-position: -48px -48px;
}
.layer-photo .photo-slider + .btn-area {
    margin-top: 16px;
}
.layer-photo .number-slider {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}
.layer-photo .number-slider strong {
    color: #08bbd5;
}

.layer-photo .photo-slider .slide-box {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}
.layer-photo .photo-slider .slide-box > ul {
    overflow: hidden;
    display: table;
    table-layout: fixed;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: 1000%; /*height:450px;*/
}
.layer-photo .photo-slider .slide-box > ul:after {
    display: block;
    clear: both;
    content: '';
}
.layer-photo .photo-slider .slide-box > ul > li {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    text-align: center; /*float:left;width:624px;*/
}
.layer-photo .photo-slider .slide-box > ul > li span {
    color: #fff;
}
.layer-photo .close {
    cursor: pointer;
}

.layer-special .benefit-wrap {
    border-bottom: 1px solid #e0e0e0;
}
.layer-special .benefit-scroll {
    height: 367px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -32px;
    padding-right: 16px;
}
.layer-special .guideline {
    width: 560px;
    height: 258px;
    padding-top: 28px;
    box-sizing: border-box;
    background: url(https://s.wink.co.kr/pc/images/bg_special.gif) no-repeat;
    text-align: center;
    color: #212121;
    font-size: 18px;
    line-height: 1.5;
}
.layer-special .guideline strong {
    color: #08bbd5;
}
.layer-special .guideline-top {
    width: 560px;
    height: 54px;
    margin: 2px 0 31px;
    text-align: center;
    line-height: 54px;
}
.layer-special .guideline-top strong {
    color: #08bbd5;
    font-size: 24px;
}
.layer-special ol {
    width: 560px;
    padding: 6px 0 27px;
    border-bottom: 1px solid #e0e0e0;
}
.layer-special li > dl dt {
    margin: 24px 0 6px;
    color: #212121;
    font-size: 18px;
}
.layer-special li > dl dt:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 11px 2px 0;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    background: #676969;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
}
.layer-special li > dl dd {
    color: #999;
    font-size: 14px;
}
.layer-special li > dl dd.dot {
    padding-left: 8px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
}
.layer-special li > dl dd strong {
    color: #08bbd5;
    font-weight: bold;
}
.layer-special li > dl dd b {
    color: #212121;
}
.layer-special li:nth-child(1) > dl dt:before {
    content: '1';
}
.layer-special li:nth-child(2) > dl dt:before {
    content: '2';
}
.layer-special li:nth-child(3) > dl dt:before {
    content: '3';
}
.layer-special p.benefit-notice {
    width: 560px;
    margin-top: 12px;
    padding-left: 8px;
    box-sizing: border-box;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer-special p.message-box {
    width: 560px;
    margin-bottom: 36px;
    padding: 28px 32px 26px;
    box-sizing: border-box;
    border-width: 0;
    border-radius: 8px;
    background: #f7f7f7;
    color: #999;
    font-size: 14px;
}
.layer-special p.message-box strong {
    margin-bottom: 5px;
    color: #212121;
    font-size: 18px;
}
.layer-special p.message-box b {
    color: #212121;
}
.layer-special p.message-box.benefit {
    margin: 35px 0 12px;
    text-align: left;
}
.layer-special p.notice {
    width: 560px;
    padding: 0 0 27px 28px;
    box-sizing: border-box;
    background-image: url(https://s.wink.co.kr/pc/images/icon_notice2.png);
    font-size: 14px;
}
.layer-special p.account {
    margin: 36px 0 11px;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer-special p.account strong {
    display: block;
    margin-bottom: 7px;
    color: #08bbd5;
    font-size: 18px;
}
.layer-special table.form {
    width: 560px;
}
.layer-special table.form:last-child {
    border-bottom-width: 0;
}
.layer-special td .select-mode {
    width: 196px;
}

/* issue_855 학습추가 select-class 2017.08.02 */
.select-class .add-class-warp {
    padding: 20px;
    background: #f5f5f5;
    text-align: center;
}
.select-class .add-class-warp h2 {
    font-size: 18px;
    color: #212121;
    margin-bottom: 10px;
}
.select-class .add-class-warp p {
    font-size: 14px;
}
.select-class .add-class-warp .inner {
    text-align: left;
    margin-bottom: 20px;
}
.select-class .add-class-warp .inner:last-child {
    margin-bottom: 0;
}
.select-class .add-class-warp .inner h3 {
    font-size: 16px;
    color: #212121;
    margin-bottom: 0;
}

/* issue_866 우리아이현황  app-setting 2017.08.04 */
.app-setting .footer-info-box {
    margin-top: 40px;
}
.app-setting .footer-info-box:after {
    clear: both;
    display: block;
    content: '';
    overflow: hidden;
}
.app-setting .footer-info-box strong {
    float: left;
    line-height: 40px;
    width: 50%;
}
.app-setting .footer-info-box .footer-form-box {
    float: right;
    width: 50%;
}
.app-setting .footer-info-box .btn-type2 {
    height: 38px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 12px;
    border-radius: 4px;
}
.app-setting .footer-info-box .btn-type2.mms2:before {
    background: none;
    width: 0;
    margin: 0;
}
.app-setting .footer-info-box .footer-check-box {
    margin-top: 10px;
    position: relative;
}
.app-setting .footer-info-box .footer-check-box input[type='checkbox'] + label {
    font-size: 12px;
    line-height: 25px;
    margin-right: 15px;
}
.app-setting .footer-info-box .footer-check-box .view-detail {
    font-size: 12px;
    text-decoration: none;
    color: #666;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
}
.app-setting .footer-info-box .footer-check-box .view-detail:after {
    content: '>';
    display: inline-block;
    margin-left: 3px;
    font-size: 10px;
    line-height: 25px;
}

.view-detail {
    position: relative;
    cursor: pointer;
}
.view-contents {
    position: absolute;
    left: 0;
    padding: 15px;
    border: 1px solid #e0e0e0;
    background: #fff;
    z-index: 500;
    display: none;
}
.view-contents p {
    font-size: 12px;
    line-height: 18px;
}
.view-contents .title {
    font-size: 14px;
    margin-bottom: 10px;
}
.view-contents .view-contents-inner {
    position: relative;
}
.view-contents-inner .view-detail-close {
    position: absolute;
    top: -19px;
    right: -16px;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    font-size: 16px;
}
.view-contents.on {
    display: block;
}

/* sample box */

/* 샘플 */
.sample-box {
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 38px 0 45px;
    border: 3px solid #c1e7e6;
    border-radius: 6px;
    text-align: center;
    background: url(https://s.wink.co.kr/pc/images/mykids/bg_%20jumbotron01.jpg)
        no-repeat 0 0/100% 100%;
}
.sample-box .title {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
}
.sample-box .title .dot {
    position: relative;
    color: #08bbd5;
}
.sample-box .title .dot:after {
    content: '';
    position: absolute;
    left: 50%;
    top: -8px;
    width: 4px;
    height: 4px;
    margin-left: -2px;
    border-radius: 100%;
    background-color: #ff9854;
}
.sample-box p {
    margin-top: 3px;
    line-height: 24px;
}
.sample-box p strong {
    font-weight: bold;
}
.sample-box p.notification {
    color: #f03c78;
    margin-top: 10px;
}
.sample-box p.notification + .btn-area {
    margin-top: 25px;
}
.sample-box .btn-area {
    margin-top: 35px;
}
.sample-box .btn-area .btn-type2 {
    width: 197px;
}

/* issue_888 2017.08.08 */
.top-text {
    padding: 0 5px 5px;
    position: relative;
}
.top-text p {
    margin-bottom: 15px;
}
.top-text small {
    font-size: 11px;
    color: #757575;
}
.top-text .btn-area {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

/* issue_936 내정보 mypage.css 와 동일 drag item */
.dragitem-wrap {
    padding: 32px 0;
    border-bottom: 1px solid #e0e0e0;
}
.dragitem-wrap.no-line {
    border-top: 0 none;
}
.dragitem {
    margin: 0 30px;
}
.dragitem:after {
    display: block;
    content: '';
    clear: both;
}
.dragitem .item.over {
    border: 1px dashed #e0e0e0;
}
.dragitem .item.move {
    border: 1px solid #f03c78;
}
.dragitem .item-add,
.dragitem .item {
    float: left;
    position: relative;
    box-sizing: border-box;
    width: 168px;
    height: 168px;
    margin: 16px 0 0 16px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
}

.dragitem .item.on {
    border: 2px solid #f03c78;
    box-sizing: border-box;
}
.dragitem .item .inner {
    position: relative;
    display: block;
    vertical-align: middle;
    padding: 19px 10px;
    box-sizing: border-box;
}
.dragitem .item.on .inner {
    width: 164px;
    height: 164px;
}

.dragitem .item .name {
    font-size: 24px;
    color: #212121;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.dragitem .item .btn-reverse {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin: -6px 0 0 5px;
    text-indent: -999em;
    vertical-align: middle;
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_reverse.png)
        no-repeat 0 0;
}
.dragitem .item small {
    display: block;
}
.dragitem .item span {
    font-size: 12px;
    padding-left: 20px;
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_candle.png)
        no-repeat 0 50%;
}
.dragitem .item p {
    margin-top: 10px;
    font-size: 14px;
    color: #bdbdbd;
}
.dragitem .item .tag-area {
    margin-top: 4px;
}
.dragitem .item .bubble-study {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    right: 0;
    bottom: 2px;
    padding: 20px 14px 15px;
    border-radius: 6px;
    font-size: 14px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    width: 100%;
    min-width: 164px;
    height: 100%;
    min-height: 164px;
}

.dragitem .item .bubble-study strong {
    font-weight: bold;
    color: #212121;
}
.dragitem .item .bubble-study p {
    margin: 4px 0 2px;
    color: #666;
}
.dragitem .item .bubble-study span {
    padding-left: 0;
    color: #bdbdbd;
    background: none;
}

.dragitem .item .inner:hover .bubble-study,
.dragitem .item .bubble-study:hover {
    display: block;
}
.dragitem .item.on .bubble-study {
    display: block;
    background-color: #fff;
}

.dragitem .item .btn-delete {
    display: none;
    position: absolute;
    right: -12px;
    top: -12px;
    width: 33px;
    height: 33px;
    text-indent: -999em;
    background: url(https://s.wink.co.kr/pc/images/mypage/btn_delete.png)
        no-repeat 0 0;
}
.dragitem .item:hover .btn-delete,
.dragitem .item.on .btn-delete {
    display: inline-block;
}

.dragitem .item-add {
    background-color: #f7f7f7;
    color: #666;
}
.dragitem .item-add span {
    display: inline-block;
    position: relative;
    margin-top: 55px;
    padding-top: 47px;
    padding-left: 0;
    background: none;
}
.dragitem .item-add span:before,
.dragitem .item-add span:after {
    content: '';
    position: absolute;
    background-color: #ccc;
}
.dragitem .item-add span:before {
    left: 50%;
    top: 0;
    width: 1px;
    height: 31px;
}
.dragitem .item-add span:after {
    left: 50%;
    top: 15px;
    width: 31px;
    height: 1px;
    margin-left: -15px;
}

.dragitem .item.not-move {
    background-color: #f5f5f5;
}
.dragitem .item.not-move .name {
    color: #999;
}
.dragitem .item.not-move:hover .btn-delete {
    display: block;
}

.dragitem .item.ui-sortable-helper {
    border: 2px solid #f03c78;
    opacity: 0.8;
    background-color: #fff;
}
.dragitem .item.ui-sortable-helper .btn-delete {
    display: none;
}
.dragitem .item.ui-sortable-placeholder {
    visibility: visible !important;
    border: 1px solid transparent;
    background: url(https://s.wink.co.kr/pc/images/mypage/bg_dragitem.png)
        no-repeat 0 0 / cover;
}
/* //내정보 mypage.css 와 동일 drag item */

/* issue_936 2017.08.21 mypage-main */
.mypage-main .panel-list {
    margin-top: 32px;
}
/* issue 936 mypage-main .warning-box border-top 속성추가 */
.mypage-main .warning-box {
    padding: 16px;
    border-top: 1px solid #e0e0e0;
}
/* // issue 936 mypage-main .warning-box */
.mypage-main .warning-box::after {
    clear: both;
    content: '';
    display: block;
}
.mypage-main .warning-box .warning {
    float: left;
}
.mypage-main .warning-box .btn-area {
    float: right;
    margin-top: 0;
}
.mypage-main .warning-box .btn-area .btn-type {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 0;
}

.mypage-kid-info .dragitem .item {
    cursor: default;
}
.mypage-kid-info .dragitem .item.on {
    border: 1px solid #e0e0e0;
}
.mypage-kid-info .dragitem .item .btn-delete {
    display: inline-block;
}

/* issue_1048 로그인 레이어에서 사용중인 디자인 부분 css만 member.css에서 옮겨옴 */
.layer-wrap.member {
    width: 400px;
    margin-left: -200px;
    padding: 32px;
}
.layer-wrap.member .id-login input {
    width: 100%;
}
.layer-wrap.member .btn-type2.login {
    width: 100%;
    margin-top: 20px;
}
/* // issue_1048 */

.layer-wrap.pink-confirm .btn-type2.confirm {
    background-color: #f03c78;
}

@media (max-width: 960px) {
    body {
        background-position: 0 0;
    }
    #lnb-wrap {
        left: 0 !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 1360px) {
    footer:after {
        margin-left: 376px;
    }
    .footer:after {
        display: none;
    }
}

/* to-updating */
.to-updating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(233, 233, 233, 0.85);
    text-align: center;
    margin-right: 414px;
}

.to-updating .copy {
    font-size: 30px;
    font-family: 'NanumBarunGothic-Bold';
    color: #222;
}

/* video warp */
.video-warp {
    cursor: pointer;
}

.video-warp::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 56px;
    height: 59px;
    margin: -30px 0 0 -28px;
    background: url('https://s.wink.co.kr/pc/images/main/btn_video_play2.png')
        no-repeat 0 0/56px;
    cursor: pointer;
}

.new-wrapper {
    width: 100%;
}

.new-wrapper .red-line {
    color: #f03c78;
    border-bottom: 1px solid #f03c78;
}

.new-wrapper .black-line {
    color: #222;
    border-bottom: 1px solid #222;
    font-weight: 800;
}

#wrap .main-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #ffdb49;
}

#wrap .main-wrapper .main-top-container {
    width: 1260px;
}

#wrap .main-wrapper .main-top-container img {
    width: 100%;
    display: flow-root;
}

#wrap .main-wrapper .main-top-container .arrow {
    width: 50px;
    height: 20px;
    position: absolute;
    bottom: 25px;
}

.lay-floating-wrapper {
    position: absolute;
    z-index: 102;
}

.lay-floating-wrapper .slider-area {
    position: absolute;
    top: 660px;
    width: 242px;
    height: 110px;
    left: 50%;
    margin-left: -840px;
    border: 1px solid #ccc;
    overflow: hidden;
}

.lay-floating-wrapper .slider-area ul {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area ul::after {
    content: '';
    clear: both;
    display: block;
}

.lay-floating-wrapper .slider-area li {
    float: left;
    width: 242px;
    height: 110px;
}

.lay-floating-wrapper .slider-area li a {
    display: block;
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area li img {
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area li.list-none a {
    line-height: 347px;
    background: #fff;
}

.lay-floating-wrapper .main-dots {
    bottom: 10px;
}

.lay-floating-wrapper .main-dots li {
    float: none;
    width: 20px;
    height: 20px;
}

.main-warpper .pop-banner .pop-banner-title {
    display: inline-block;
    width: 234px;
    height: 45px;
    background-color: #f03c78;
    border-radius: 24px;
    font-size: 1.3em;
    line-height: 45px;
}

.new-wrapper .section {
    position: relative;
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
}

.about-danbiedu {
    color: #222;
    line-height: 1.6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.about-danbiedu .center {
    text-align: center;
}

.about-danbiedu .history-text {
    padding: 50px 0;
}

.about-danbiedu .img-area img {
    vertical-align: top;
}

.about-danbiedu .text {
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 1.8;
}

.about-danbiedu .point-text {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 20px;
}

.about-danbiedu .article {
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    background: #fcfcfc;
    border-radius: 10px;
    padding: 60px 40px;
}

.hole-section .text-area {
    height: 104px;
    background: #08bbd5;
    text-align: center;
}

.hole-section .text-area .title {
    line-height: 104px;
    color: #fff;
    font-size: 36px;
    font-family: 'NanumBarunGothic-Bold';
}

.hole-section .img-area,
.hole-section .sub-title {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
}

.hole-section .sub-title {
    padding: 50px 0 60px;
    font-size: 23px;
}

.hole-section .sub-title em {
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-01 {
    padding: 44px 31px;
}

.about-danbiedu .section-01 > .text-area .title {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 42px;
    color: #00aac8;
    margin: 50px 0;
    text-align: center;
}

.about-danbiedu .section-01 > .text-area .sub-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 30px;
}

.about-danbiedu .section-01 .article {
    padding: 60px 40px 40px;
    margin-bottom: 8px;
}

.about-danbiedu .section-01 .article .title {
    font-size: 24px;
    margin-bottom: 40px;
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-01 .img-area {
    margin-top: 20px;
}

.about-danbiedu .section-02 {
    padding: 44px 60px;
}

.about-danbiedu .section-02 .text-area {
    text-align: center;
    margin-bottom: 70px;
}

.about-danbiedu .section-02 .text {
    margin-bottom: 40px;
}

.about-danbiedu .section-02 .text-area .title {
    font-size: 32px;
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-02 .point-text {
    margin: 40px 0;
}

.about-danbiedu .section-02 .article {
    margin: 50px 76px;
}

.about-danbiedu .section-02 .article .text {
    font-size: 20px;
    font-family: 'NanumBarunGothic-Bold';
    margin-bottom: 0;
}

.about-danbiedu .section-02 .article .text em {
    color: #f03c78;
}

.about-danbiedu .section-06 {
    margin-bottom: 50px;
}

/* new-btn-area */
.new-btn-area {
    padding: 20px 0;
    text-align: center;
}

.new-btn-area .new-btn {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 100px;
    height: 50px;
    text-align: center;
    line-height: 52px;
    margin: 0 5px;
    color: #222;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.new-btn-area .new-btn.btn-confirm {
    background: #f03c78;
    border-color: #f03c78;
    color: #fff;
}
.new-btn-area .new-btn.btn-apply {
    padding: 0 20px;
}
.new-btn-area .new-btn.btn-apply:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply2.png)
        no-repeat;
}

/* section-menu */
.section-menu ul {
    border-left: 1px solid #e0e0e0;
}
.section-menu ul::after {
    clear: both;
    display: block;
    content: '';
}

.section-menu li {
    float: left;
    text-align: center;
}

.section-menu li:last-child a {
    border-right: 1px solid #e0e0e0;
}

.section-menu li a {
    display: block;
    position: relative;
    color: #666;
    font-size: 0.9em;
    height: 48px;
    line-height: 48px;
    background: #fcfcfc;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    font-weight: bold;
    border-left: none;
    border-right: none;
}

.section-menu li a::before {
    content: '';
    position: absolute;
    top: 0;
    right: -43px;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-right: 21px solid transparent;
    border-left: 21px solid #e0e0e0;
    z-index: 10;
}

.section-menu li a::after {
    content: '';
    position: absolute;
    top: 0;
    right: -43px;
    border-top: 24px solid transparent;
    border-bottom: 23px solid transparent;
    border-right: 23px solid transparent;
    border-left: 20px solid #fcfcfc;
    z-index: 11;
}

.section-menu li:last-child a:after,
.section-menu li:last-child a:before {
    display: none;
}

.section-menu li.on a {
    background: #ffdc52;
    color: #222;
}

.section-menu li.on a:after {
    border-left-color: #ffdc52;
}

/* card style */
.card {
    display: block;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d2d2d2;
    overflow: hidden;
    margin-bottom: 10px;

    box-sizing: border-box;

    transition-duration: 0.5s;
    transition-property: height;

    height: 75px;
}

.card:nth-child(1).opened {
    height: 365px;
}
.card:nth-child(2).opened {
    height: 370px;
}
.card:nth-child(3).opened {
    height: 370px;
}
.card:nth-child(4).opened {
    height: 370px;
}
.card:nth-child(5).opened {
    height: 370px;
}
.card:nth-child(6).opened {
    height: 370px;
}
.card:nth-child(7).opened {
    height: 370px;
}
.card:nth-child(8).opened {
    height: 370px;
}

.card.parent:nth-child(1).opened {
    height: 370px;
}
.card.parent:nth-child(2).opened {
    height: 370px;
}
.card.parent:nth-child(3).opened {
    height: 340px;
}
.card.parent:nth-child(4).opened {
    height: 340px;
}
.card.parent:nth-child(5).opened {
    height: 340px;
}
.card.parent:nth-child(6).opened {
    height: 340px;
}
.card.parent:nth-child(7).opened {
    height: 340px;
}
.card.parent:nth-child(8).opened {
    height: 340px;
}

.card:last-child {
    margin-bottom: 0;
}

.card .card-top {
    padding: 0 35px;
}
.card .card-contents {
    padding: 16px 14px;
}
.card .card-bottom {
    padding: 10px;
}

.card .card-top {
    position: relative;
    background: #f4f4f4;
}

.card .card-top .title {
    margin-right: 50%;
    font-size: 15px;
    font-family: 'NanumBarunGothic-Bold';
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card .card-top .util-area {
    position: absolute;
    top: 50%;
    right: 5px;
}

.card .card-top .util-area::after {
    clear: both;
    display: block;
    content: '';
}

.card .card-top .util-area .price {
    color: #00a0e0;
    font-size: 18px;
}

.card .card-top .util-area .before-price {
    color: #00a0e0;
    font-size: 15px;
    -webkit-text-decoration: line-through solid #222;
            text-decoration: line-through solid #222;
}

.card .card-top .util-area .after-price {
    color: #f03c78;
    font-weight: bold;
    font-size: 16px;
}

.card .card-top .util-area .discount-price {
    display: inline-block;
    padding: 7px 8px;
    position: relative;
    font-size: 11px;
    line-height: 12px;
    height: 26px;
    box-sizing: border-box;
    vertical-align: middle;
    background-color: #f03c78;
    color: #fff;
    margin: 0 20px 0 7px;
    border-radius: 5px 0 0 5px;
}

.card .card-top .util-area .discount-price::after {
    position: absolute;
    top: -8px;
    right: -15px;
    content: '';
    height: 0px;
    width: 0px;
    border-left: 15px solid #f03c78;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.card .card-top .util-area button {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 15px 5px;
    border-radius: 15px;
    border: 1px solid #d2d2d2;
    color: #565656;
    margin-left: 10px;
    vertical-align: middle;
    box-sizing: border-box;
}

/* .card.wink */
.card.wink {
    border: 2px solid #bee4be;
    margin-bottom: 25px;
}
.card.wink .card-top {
    background-color: #e0fbe0;
}

/* .card.event */
.card .card-top .tooltip img {
    vertical-align: middle;
    margin: 0 0 5px 15px;
}
/* .card.event */
.card .card-top .title-span {
    font-size: 24px;
    color: #333333;
}

.card .sale-text p {
    margin-bottom: 10px;
}
.card .sale-text p em {
    color: #f03c78;
}

/* select-mode */
/* 회원정보 등록 완료 */
.select-mode select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
         appearance: menulist;

    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    line-height: normal !important;
    width: 100%;
}

/* info-rec */
.info-rec {
    display: inline-block;
    padding-left: 25px;
    background: url('https://s.wink.co.kr/pc/images/icon_notice_color_rec.png')
        no-repeat 0 0 /20px;
    line-height: 21px;
}

/* new-layer */
.new-layer {
}

.new-layer .layer-wrap {
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    min-width: 380px;
}

.new-layer .layer-wrap .lay-container {
    padding: 32px;
}

.event-popup-content {
    text-align: center;
}

.event-popup-content .checkbox-area {
    margin-top: 25px;
}

.event-popup-content .content-wrapper a {
    display: inline-block;
}

.new-layer.layer .layer-wrap button.close {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-size: 18px;
    background-position: center;
}

.accordion {
    font-size: 16px;
    line-height: 56px;
    color: #666;
}

.accordion .accordion-animation {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.accordion .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion .accordion-header input[type='checkbox'] + label {
    display: inline-block;
    padding: 0 50px 0 32px;
    margin-right: 0;
    color: #666;
    line-height: 27px;
}

.accordion .accordion-body {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
}

.accordion .accordion-body.active {
    height: 500px;
}

.accordion .accordion-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(https://s.wink.co.kr/pc/images/icon_arrow_down.png) center
        center no-repeat;
    cursor: pointer;
}

.accordion .accordion-icon.active {
    background: url(https://s.wink.co.kr/pc/images/icon_arrow_up.png) center
        center no-repeat;
}

.accordion iframe {
    width: 100%;
    height: 100%;
}

.transparent-checkbox::before {
    width: 16px !important;
    height: 16px !important;
    background: transparent !important;
    border: 1px solid white;
    margin-right: 10px;
}

@charset "UTF-8";

/********************************************************* customer  ********************************************************************/
/* common */
/* input file */
.form input[type='text'].full-size {
  width: 97%;
}

.form ~ .list-type {
  margin-top: 40px;
}
.form + .list-style-dot {
  font-size: 14px;
  color: #999;
}
.form .list-style-dot {
  font-size: 14px;
  color: #999;
}
.form-caption {
  margin-bottom: 8px;
  font-size: 18px;
}
.form-caption .go {
  margin-left: 5px;
}

.form .photo-add {
  position: relative;
  width: 142px;
  height: 189px;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
}
.form .photo-add img {
  width: 142px;
  height: 189px;
}
.form .photo-add .btn-revise {
  position: absolute;
  left: 50%;
  bottom: -60px;
  margin-left: -40px;
}
.form .photo-add .btn-add {
  position: absolute;
  left: 50px;
  top: 50%;
  margin: -30px 0 0 -15px;
  padding-top: 47px;
  font-size: 16px;
  color: #999;
  background: none;
}
.form .photo-add .btn-add:after,
.form .photo-add .btn-add:before {
  content: '';
  position: absolute;
  background-color: #ccc;
}
.form .photo-add .btn-add:after {
  width: 1px;
  height: 31px;
  left: 50%;
  top: 0;
}
.form .photo-add .btn-add:before {
  width: 31px;
  height: 1px;
  top: 15px;
  left: 50%;
  margin-left: -15px;
}

fieldset.name input[type='text'] {
  width: 190px;
}
fieldset.graduation .select-mode {
  width: 130px;
}

/* 공유 */
.share {
  display: inline-block;
  margin-left: 10px;
  width: 17px;
  height: 18px;
  text-indent: -999em;
  vertical-align: middle;
  background: url(https://s.wink.co.kr/pc/images/icon_share.png) no-repeat 0 0;
}

/* 당첨자 발표일 */
.issue {
  display: inline-block;
  padding-left: 23px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 18px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice4.png) no-repeat;
  color: #bdbdbd;
}

/* 이벤트 확인 글 */
.event-check-text {
  margin-top: 18px;
}
.event-check-text .user {
  color: #212121;
}

/* 잎사귀 있는 라인 */
.leaf-line {
  position: relative;
  border-top: 4px solid #d7d7d7;
}
.leaf-line:after {
  content: '';
  position: absolute;
  top: -16px;
  right: 0;
  width: 33px;
  height: 19px;
  background: url(https://s.wink.co.kr/pc/images/img_leaf.png) no-repeat;
}

/* accordion menu */
.list-type a.title strong {
  color: #f03c78;
}
.list-type a.title label {
  color: #212121;
}
.list-type a.title label:before {
  top: 50%;
  margin-top: -11px;
}

.accordion input[type='checkbox'].on + label:before {
  background-position: -20px 0;
}
.accordion input[type='checkbox'].on + label {
  color: #212121;
}

/* //common */

/* ex 로그인 ~ 전 텍스트 */
.info-text-box.border-none {
  border: none;
}
.info-text-box {
  padding: 45px 0 48px;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
}
.info-text-box .title {
  font-size: 24px;
  line-height: 32px;
  color: #212121;
}
.info-text-box .text {
  margin-top: 5px;
  line-height: 24px;
}
.info-text-box .ex-text {
  margin-top: 30px;
  font-size: 14px;
  line-height: 24px;
  color: #999;
}

/* 설명 테이블 */
.tbl-info {
  width: 100%;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e0e0e0;
}
.tbl-info th,
.tbl-info td {
  padding: 30px 5px 27px 16px;
  border-top: 1px solid #e0e0e0;
  vertical-align: top;
  text-align: left;
}
.tbl-info th {
  font-size: 16px;
  font-weight: normal;
  color: #212121;
}
.tbl-info th:first-child {
  border-right: 1px solid #e0e0e0;
}
.tbl-info tr:first-child th {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}
.tbl-info tr:first-child th:first-child {
  font-size: 16px;
  font-weight: normal;
  font-family: 'NanumBarunGothic';
}
.tbl-info .faq-box {
  position: relative;
}
.tbl-info .faq-box .btn-type {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
}

/* 이벤트 큰이미지 리스트 */
.event-big-list li:first-child {
  background: none;
}
.event-big-list li:last-child {
  padding-bottom: 0;
}
.event-big-list li {
  padding: 32px 0;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 0;
}
.event-big-list .view-wrap .view-header {
  padding: 0 200px 20px 0;
  border-top: 0 none;
  background-color: #fff;
}
.event-big-list .view-wrap .view-header .right {
  right: 0;
  top: 0;
}
.event-big-list .view-wrap .view-body {
  padding: 0;
}

/* 점선 리스트 */
.dotted-list li {
  padding: 10px 0;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0
    bottom;
}

/* 이미지 있는 공지사항 */
.thumb-notice {
  display: table;
  position: relative;
  width: 100%;
  min-height: 150px;
}
.thumb-notice > img {
  position: absolute;
  left: 0;
  top: 0;
  vertical-align: top;
  width: 219px;
  height: 100%;
}
.thumb-notice .info-area {
  display: table-cell;
  width: 100%;
  padding-left: 250px;
  padding-right: 40px;
  vertical-align: middle;
  box-sizing: border-box;
}
.thumb-notice .info-area .title {
  display: block;
  overflow: hidden;
  max-width: 430px;
  font-size: 20px;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #212121;
}
.thumb-notice .info-area .info-body .date {
  margin-right: 21px;
  background-image: url(https://s.wink.co.kr/pc/images/icon_calendar.png);
}
.thumb-notice .info-area .info-foot {
  margin-top: 23px;
}

/* 앱 다운로드 */
.app-down {
  position: relative;
  border-radius: 6px;
}
.app-down h4 {
  margin-bottom: 52px;
  padding-bottom: 0;
  border-bottom-width: 0;
  font-size: 44px;
  color: #fff;
}
.app-down h4:after {
  display: none;
}
.app-down h4 small {
  display: block;
  margin-bottom: 13px;
  color: #a7b3be;
  font-size: 20px;
  line-height: 24px;
}
.app-down .btn-type2 {
  display: table;
  margin-top: 24px;
  position: absolute;
  bottom: 30%;
  left: 3%;
}
.app-down p {
  margin-top: 26px;
  line-height: 26px;
  color: #a7b3be;
}

/* 앱설치시 설명 */
.app-guide li {
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) 0 100% repeat-x;
}
.app-guide li figure {
  padding: 40px 0;
}
.app-guide li figure:after {
  content: '';
  display: block;
  clear: both;
}
.app-guide li img {
  vertical-align: top;
}
.app-guide li figcaption {
  width: 344px;
}
.app-guide li figcaption strong {
  display: block;
  margin: 14px 0 4px;
  color: #212121;
  font-size: 24px;
  line-height: 1.6;
}
.app-guide li:nth-child(odd) img {
  float: left;
}
.app-guide li:nth-child(odd) figcaption {
  float: right;
}
.app-guide li:nth-child(even) img {
  float: right;
}
.app-guide li:nth-child(even) figcaption {
  float: left;
}

/* 선생님 모집 안내 */
.teacher-recruit {
  margin-bottom: 32px;
  padding: 35px 32px;
  border-radius: 6px;
  background: url(https://s.wink.co.kr/pc/images/customer/bg_teacher.png)
    no-repeat 100% 100% #fcf5d4;
}
.teacher-recruit h4 {
  margin-bottom: 17px;
  padding-bottom: 0;
  border-bottom-width: 0;
  font-size: 32px;
  color: #212121;
}
.teacher-recruit h4 small {
  display: block;
  margin-bottom: 4px;
  color: #666;
  font-size: 20px;
}
.teacher-recruit p {
  line-height: 28px;
}

/* 모집기간, 교육일정, 전화, 문의 메일 등 */
.dl-style {
  margin-top: 5px;
}
.dl-style:first-child {
  margin-top: 0;
}
.dl-style:after {
  display: block;
  content: '';
  clear: both;
}
.dl-style dt {
  float: left;
  clear: both;
  width: 85px;
  font-size: 14px;
  line-height: 25px;
  color: #212121;
  font-weight: bold;
}
.dl-style dd {
  float: left;
}

/*.append-text {font-size:14px;}*/

/* 전형절차 */
.model-process {
  position: relative;
  width: 545px;
  height: 304px;
  background: url(https://s.wink.co.kr/pc/images/customer/bg_order_line.png)
    no-repeat 100% 50%;
}
.model-process .level {
  display: table;
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #f5f5f5;
}
.model-process .level div {
  display: table-cell;
  vertical-align: middle;
}
.model-process .level strong {
  display: block;
  color: rgba(33, 33, 33, 0.87);
}
.model-process .level span {
  font-size: 14px;
  color: #999;
}
.model-process .level.level01 {
  left: 0;
  top: 0;
}
.model-process .level.level02 {
  left: 168px;
  top: 0;
}
.model-process .level.level03 {
  left: 336px;
  top: 0;
}
.model-process .level.level04 {
  left: 336px;
  bottom: 0;
}
.model-process .level.level05 {
  left: 168px;
  bottom: 0;
}
.model-process .level.level06 {
  left: 0;
  bottom: 0;
}

.model-process .gap01,
.model-process .gap02 {
  position: absolute;
  font-size: 14px;
}
.model-process .gap01 {
  left: 295px;
  top: 105px;
}
.model-process .gap01:after {
  content: '';
  position: absolute;
  left: 50%;
  top: -45px;
  width: 1px;
  height: 35px;
  background-color: #ccc;
}
.model-process .gap01:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -48px;
  width: 7px;
  height: 7px;
  margin-left: -3.5px;
  border-radius: 100%;
  background-color: #ccc;
}
.model-process .gap02 {
  right: 44px;
  top: 140px;
}
.model-process .gap02:after {
  content: '';
  position: absolute;
  right: -44px;
  top: 50%;
  width: 36px;
  height: 1px;
  background-color: #ccc;
}
.model-process .gap02:before {
  content: '';
  position: absolute;
  right: -47px;
  top: 50%;
  width: 7px;
  height: 7px;
  margin-top: -3.5px;
  border-radius: 100%;
  background-color: #ccc;
}

/* 지원서 작성하기 STEP */
.apply-step {
  margin-bottom: 20px;
  padding: 30px 0 29px;
  border-radius: 6px;
  background-color: #f5f7fa;
}
.apply-step ol {
  text-align: center;
}
.apply-step li {
  display: inline-block;
  position: relative;
  font-size: 18px;
  margin-left: 50px;
  padding: 0 60px 0 50px;
  text-align: left;
  color: #757575;
}
.apply-step li:first-child {
  margin-left: 0;
}
.apply-step li:last-child {
  padding-right: 0;
}
.apply-step li:last-child:after {
  display: none;
}
.apply-step li.on {
  color: #00a5d5;
}
.apply-step li small {
  display: block;
  font-size: 14px;
}
.apply-step li:before,
.apply-step li:after {
  content: '';
  position: absolute;
}
.apply-step li:before {
  left: 0;
  top: 0;
  width: 44px;
  height: 44px;
}
.apply-step li:after {
  right: 0;
  top: 50%;
  width: 13px;
  height: 25px;
  margin-top: -12.5px;
  background: url(https://s.wink.co.kr/pc/images/customer/bullet_step_arrow.png);
}
.apply-step li.on:after {
  background-image: url(https://s.wink.co.kr/pc/images/customer/bullet_arrow_on.png);
}

.apply-step li:nth-child(1) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step1.png)
    no-repeat 0 50%;
}
.apply-step li:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step2.png)
    no-repeat 0 50%;
}
.apply-step li:nth-child(3) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step3.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(1) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step1_on.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step2_on.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(3) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step3_on.png)
    no-repeat 0 50%;
}

/* 정보입력 */
#contents .write-wrap section {
  margin-bottom: 50px;
}
#contents .write-wrap .language-area section {
  margin: 0;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}
#contents .write-wrap .language-area section:first-of-type {
  border-top: 0 none;
}

.language-area {
  border-top: 2px solid #676969;
}
.language-area section .title {
  padding-left: 16px;
  font-size: 18px;
  color: #212121;
}
.language-area table.form {
  border-top: 0;
}
.language-area table.form tr:first-child th {
  padding-top: 35px;
}
.language-area fieldset.select-area .select-mode {
  width: 140px;
}

.write-wrap h5 {
  margin-bottom: 0;
  padding-bottom: 15px;
}
.write-wrap .form + .form {
  border-top: 0 none;
}
.write-wrap .form:first-child th {
  padding-top: 35px;
}
.write-wrap fieldset input {
  width: 180px;
}
.write-wrap fieldset.name input {
  width: 180px;
}
.write-wrap fieldset.family input {
  width: 100%;
}
.write-wrap fieldset.phone input.confirm-num {
  width: 101px;
}
.write-wrap fieldset.phone .btn-type {
  padding: 0 20px;
}
.write-wrap .btn-remove {
  position: absolute;
  right: 0;
  top: -6px;
}
.write-append-box {
  margin-top: 40px;
  padding: 14px 10px 13px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  background-color: #f5f7fa;
}

/* 지원 텍스트 정보 상자 */
.support-box {
  margin-top: 20px;
  padding: 30px 15px;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e9e9e9;
}
.support-box strong {
  font-size: 18px;
  color: #212121;
}
.support-box p {
  margin-top: 10px;
  font-size: 14px;
}

/* popup 내용 */
/* 경품 내용 */
.product-box {
  margin: 7px 32px 0;
  padding: 22px 20px 23px;
  border: 1px solid #e3e3e5;
  border-radius: 6px;
  font-size: 18px;
  color: #999;
  background-color: #f5f7fa;
}
.product-box p {
  display: inline-block;
  padding-left: 44px;
  text-indent: -44px;
  color: #08bbd5;
  word-break: keep-all;
  text-align: left;
}

/* faq */
.faq-area {
  position: relative;
  border-top: 2px solid #676969;
}
.faq-area:after {
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  /*background:url(https://s.wink.co.kr/pc/images/customer/bg_gradaition_white.png) repeat-x 0 100%;*/
  background: linear-gradient(transparent, #fff);
}
.faq-area ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 425px;
  margin-right: -32px;
  padding-right: 16px;
  padding-top: 28px;
}
.faq-area li {
  width: 560px;
  margin-top: 25px;
}
.faq-area li:first-child {
  margin-top: 0;
}
.faq-area li:last-child {
  padding-bottom: 40px;
}
.faq-area li .title {
  display: block;
  margin-bottom: 7px;
  padding-left: 25px;
  color: #212121;
  background: url(https://s.wink.co.kr/pc/images/customer/img_text_q.png)
    no-repeat 0 3px;
}
.faq-area li p {
  font-size: 14px;
  line-height: 22px;
}

.select-category {
}
fieldset.select-category .select-mode {
  width: 160px;
  margin-right: 4px;
}

.customer-report fieldset.email .select-mode {
  min-width: 150px;
}

.customer-report .cursor {
  cursor: pointer;
}
.customer-report .custom-link {
  text-decoration: underline;
}

.customer-report fieldset.select-category .select-mode {
  width: 320px;
}

.customer-report .btn-area.right .pink {
  background-color: #f03c78;
  color: #fff;
}

/*************************************************************************************apply_teacher***********************************************************************************/

.apply-teacher .header {
  margin: 0 auto;
  width: 320px;
}

/* login 아이디 로그인, 휴대폰 로그인*/
.vertical-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.apply-teacher .teacher-login section {
  width: 500px;
  margin: 0 auto;
}
.teacher-login h2 {
  margin-bottom: 21px;
  color: #f03c78;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.teacher-login .tab-type {
  margin-top: 15px;
  margin-bottom: 0;
}
.teacher-login .tab-type + fieldset.phone input {
  width: 50%;
}
.teacher-login .tab-type + fieldset.phone input.name {
  width: 100%;
}
.teacher-login .tab-type + fieldset input {
  margin-top: 8px;
}
.teacher-login .tab-type + fieldset > a {
  margin-top: 8px;
  width: 48%;
  padding: 0;
  margin-left: 0;
}
.teacher-login .tab-type li.on a {
  border: 1px solid #e0e0e0;
}
.teacher-login .tab-type li:first-child a {
  border-bottom-left-radius: 8px;
}
.teacher-login .tab-type li:last-child a {
  border-bottom-right-radius: 8px;
}
.teacher-login .id-login + .save {
  margin-top: 12px;
  height: 20px;
}
.teacher-login .phone + .save {
  margin-top: 12px;
  height: 20px;
}
.teacher-login .save label {
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}
.teacher-login .save input[type='checkbox'] + label {
  vertical-align: top;
}
.teacher-login .save input[type='checkbox'] + label:before {
  top: 0;
}
.teacher-login input {
  width: 100%;
}
.teacher-login .btn-type2.login {
  width: 100%;
  margin-top: 20px;
}
.teacher-login .btn-type2.login:before {
  margin: -3px 9px 0 0;
  width: 17px;
  height: 18px;
  background: url('https://s.wink.co.kr/pc/images/member/icon_btn_lock.png')
    no-repeat;
}
.teacher-login .small-join {
  margin-top: 15px;
  text-align: center;
}
.teacher-login .small-join a {
  padding-right: 13px;
  background: url(https://s.wink.co.kr/pc/images/bu_go.png) 100% 3px no-repeat;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  text-decoration: underline;
}
.apply-teacher fieldset.radio-set .select-mode {
  width: 165px;
}
.apply-teacher label.bh-rel {
  margin-left: 7px;
  width: 58px;
}
/* join 정보입력 */
.teacher-join {
  height: 1280px;
}

/* step */
.btn-area.bottom {
  margin-bottom: 50px;
}

.apply-teacher header {
  position: static;
}

.apply-teacher .container {
  width: 960px;
  box-sizing: border-box;
  margin: 0 auto;
}
.apply-teacher #contents {
  padding: 0;
  width: 100%;
}
.apply-step {
  margin-bottom: 20px;
  padding: 30px 0 29px;
  border-radius: 6px;
  background-color: #f5f7fa;
}
.apply-step ol {
  text-align: center;
}
.apply-step li {
  display: inline-block;
  position: relative;
  font-size: 18px;
  margin-left: 50px;
  padding: 0 60px 0 50px;
  text-align: left;
  color: #757575;
}
.apply-step li:first-child {
  margin-left: 0;
}
.apply-step li:last-child {
  padding-right: 0;
}
.apply-step li:last-child:after {
  display: none;
}
.apply-step li.on {
  color: #00a5d5;
}
.apply-step li small {
  display: block;
  font-size: 14px;
}
.apply-step li:before,
.apply-step li:after {
  content: '';
  position: absolute;
}
.apply-step li:before {
  left: 0;
  top: 0;
  width: 44px;
  height: 44px;
}
.apply-step li:after {
  right: 0;
  top: 50%;
  width: 13px;
  height: 25px;
  margin-top: -12.5px;
  background: url(https://s.wink.co.kr/pc/images/customer/bullet_step_arrow.png);
}
.apply-step li.on:after {
  background-image: url(https://s.wink.co.kr/pc/images/customer/bullet_arrow_on.png);
}

.apply-step li:nth-child(1) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step1.png)
    no-repeat 0 50%;
}
.apply-step li:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step2.png)
    no-repeat 0 50%;
}
.apply-step li:nth-child(3) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step3.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(1) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step1_on.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step2_on.png)
    no-repeat 0 50%;
}
.apply-step li.on:nth-child(3) {
  background: url(https://s.wink.co.kr/pc/images/customer/img_step3_on.png)
    no-repeat 0 50%;
}

.write-wrap h5 {
  margin-bottom: 0;
  padding-bottom: 15px;
}
.write-wrap .form + .form {
  border-top: 0 none;
}
.write-wrap .form:first-child th {
  padding-top: 35px;
}
.write-wrap fieldset input {
  width: 180px;
}
.write-wrap fieldset.name input {
  width: 180px;
}
.write-wrap fieldset.family input {
  width: 100%;
}
.write-wrap fieldset.phone input.confirm-num {
  width: 101px;
}
.write-wrap fieldset.phone .btn-type {
  padding: 0 20px;
}
.write-wrap .btn-remove {
  position: absolute;
  right: 0;
  top: -6px;
}
.write-append-box {
  margin-top: 40px;
  padding: 14px 10px 13px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  background-color: #f5f7fa;
}
.write-wrap .form .photo-add {
  margin: 0 auto;
}
#contents .write-wrap section {
  margin-bottom: 50px;
}
#contents .write-wrap .language-area section {
  margin: 0;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}
#contents .write-wrap .language-area section:first-of-type {
  border-top: 0 none;
}

.form input[type='text'].full-size {
  width: 97%;
}

.form ~ .list-type {
  margin-top: 40px;
}
.form + .list-style-dot {
  font-size: 14px;
  color: #999;
}
.form .list-style-dot {
  font-size: 14px;
  color: #999;
}
.form-caption {
  margin-bottom: 8px;
  font-size: 18px;
}
.form-caption .go {
  margin-left: 5px;
}

.form .photo-add {
  position: relative;
  width: 142px;
  height: 189px;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
}
.form .photo-add img {
  width: 142px;
  height: 189px;
}
.form .photo-add .btn-revise {
  position: absolute;
  left: 50%;
  bottom: -60px;
  margin-left: -40px;
}
.form .photo-add .btn-add {
  position: absolute;
  left: 50px;
  top: 50%;
  margin: -30px 0 0 -15px;
  padding-top: 47px;
  font-size: 16px;
  color: #999;
  background: none;
}
.form .photo-add .btn-add:after,
.form .photo-add .btn-add:before {
  content: '';
  position: absolute;
  background-color: #ccc;
}
.form .photo-add .btn-add:after {
  width: 1px;
  height: 31px;
  left: 50%;
  top: 0;
}
.form .photo-add .btn-add:before {
  width: 31px;
  height: 1px;
  top: 15px;
  left: 50%;
  margin-left: -15px;
}

.form .photo-add {
  position: relative;
  width: 142px;
  height: 189px;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
}
.form .photo-add .btn-add {
  position: absolute;
  left: 50px;
  top: 50%;
  margin: -30px 0 0 -15px;
  padding-top: 47px;
  font-size: 16px;
  color: #999;
  background: none;
}
.form .photo-add .btn-add:before {
  width: 31px;
  height: 1px;
  top: 15px;
  left: 50%;
  margin-left: -15px;
}
.form .photo-add .btn-add:after {
  width: 1px;
  height: 31px;
  left: 50%;
  top: 0;
}

.form .photo-add .btn-add:after,
.form .photo-add .btn-add:before {
  content: '';
  position: absolute;
  background-color: #ccc;
}

/***************************************************************************************in**************************************************************************************/

/* common */
h3.line {
  margin-bottom: 24px;
  padding-bottom: 20px;
}
h3.no-margin {
  margin-bottom: 0;
}
button {
  padding: 0;
  background: none;
}
input {
  font-family: 'NanumBarunGothic';
}

/* 검색영역 */
div.search {
  margin-top: 20px;
  margin-bottom: 0;
}
.search .keyword-box {
  overflow: hidden;
  margin-top: 20px;
}
.search .keyword-box:after {
  display: block;
  clear: both;
  content: '';
}
.search .keyword-box > strong {
  float: left;
  width: 65px;
  margin-right: 14px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #666;
}
.search .keyword-box > ul {
  float: left;
  overflow: hidden;
  width: 550px;
}
.search .keyword-box > ul:after {
  display: block;
  clear: both;
  content: '';
}
.search .keyword-box > ul > li {
  float: left;
  margin-top: 6px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_divider.gif') right
    center no-repeat;
  padding-right: 9px;
  margin-left: 9px;
}
.search .keyword-box > ul > li:first-child {
  margin-left: 0;
}
.search .keyword-box > ul > li:nth-child(7n) {
  margin-left: 0;
}
.search .keyword-box > ul > li:nth-child(6n) {
  background: none;
}
.search .keyword-box > ul > li:nth-child(-n + 6) {
  margin-top: 0;
}
.search .keyword-box > ul > li a {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #666;
}

.btn-box {
  margin-top: 40px;
  text-align: center;
}
.btn-box a:last-child {
  margin-left: 4px;
}
.btn-box .btn-type2:before {
  width: 19px;
  height: 18px;
  margin: -2px 10px 0 0;
}
.btn-box .btn-type2.result:before {
  margin: -3px 10px 0 0;
  background: url('https://s.wink.co.kr/pc/images/info/icon_btn_result.png')
    no-repeat;
}
.btn-box .btn-type2.message:before {
  background: url('https://s.wink.co.kr/pc/images/info/btn_search.png')
    no-repeat;
}
.btn-box .btn-type2.file:before {
  background: url('https://s.wink.co.kr/pc/images/info/icon_file.png') no-repeat;
}
.btn-box .btn-type2.refresh {
  color: #f03c78;
}
.btn-box .btn-type2.refresh:hover {
  border: 1px solid #f03c78;
}
.btn-box .btn-type2.refresh:before {
  background: url('https://s.wink.co.kr/pc/images/info/icon_refresh.png')
    no-repeat;
}
.btn-area .btn-type2.question:before {
  margin: -2px 10px 0 0;
  width: 19px;
  height: 18px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_question.png')
    no-repeat;
}
.tag > .depth {
  display: inline-block;
  margin: 0 6px;
  width: 6px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_depth.png') 0 7px
    no-repeat;
  text-indent: -9999px;
}

/* tab margin */
.search + .tab-type {
  margin-top: 32px;
  margin-bottom: 16px;
}
h3 + .tab-type {
  margin-top: 20px;
  margin-bottom: 11px;
}
h3 + .tab-type.nomargin {
  margin-bottom: 0;
}

/* // common */

/* placeholder font, color */
::-webkit-input-placeholder {
  font-family: 'NanumBarunGothic';
  font-size: 14px;
  line-height: 26px;
  color: #999;
}
:-ms-input-placeholder {
  font-family: 'NanumBarunGothic';
  font-size: 14px;
  line-height: 26px;
  color: #999;
}
::-moz-placeholder {
  opacity: 1;
  font-family: 'NanumBarunGothic';
  font-size: 14px;
  line-height: 26px;
  color: #999;
}
:-moz-placeholder {
  opacity: 1;
  font-family: 'NanumBarunGothic';
  font-size: 14px;
  line-height: 26px;
  color: #999;
}

.recommend-info {
  margin-bottom: 96px;
}
.recommend-info h4 {
  margin-bottom: 0;
}

/* 질문하기 */
.text-box {
  position: relative;
  padding: 33px 0 60px;
}
.text-box > strong {
  font-size: 24px;
  line-height: 38px;
  color: #212121;
}
.text-box > p {
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}
.text-box > a {
  position: absolute;
  bottom: 60px;
  right: 0;
}

/* 질문 리스트 */
.tab-type + .qna-wrap {
  margin-top: 24px;
}
.qna-wrap > li {
  position: relative;
  padding: 32px 16px 24px;
  border-bottom: 1px solid #e0e0e0;
}
.qna-wrap > li .tag {
  margin-right: 1px;
}
.qna-wrap > li .user-info {
  position: absolute;
  top: 25px;
  right: 16px;
}
.qna-wrap > li .qna-list > li {
  position: relative;
  margin-top: 20px;
  padding-left: 56px;
  line-height: 28px;
  min-height: 42px;
}
.qna-wrap > li .qna-list > li a {
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}
.qna-wrap > li .qna-list > li a:hover {
  text-decoration: underline;
}
.qna-wrap > li .qna-list > li .new {
  position: absolute;
  top: 0;
  left: 27px;
  width: 20px;
  height: 20px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_new.png') no-repeat;
  text-indent: -9999px;
}
.qna-wrap > li .qna-list .q {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  background: url('https://s.wink.co.kr/pc/images/info/icon_qna_q.png') 0 50%
    no-repeat;
}
.qna-wrap > li .qna-list .q:hover {
  text-decoration: underline;
}
.qna-wrap > li .qna-list .q:hover strong {
  text-decoration: underline;
  text-decoration-color: #08bbd5;
}
.qna-wrap > li .qna-list.master .q {
  margin-top: 10px;
  padding-left: 26px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_icon_q.png') 0 4px
    no-repeat;
  line-height: 28px;
}
.qna-wrap > li .qna-list .q strong {
  color: #08bbd5;
}
.qna-wrap > li .qna-list .q p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #666;
}
.qna-wrap > li .qna-list .a {
  -webkit-line-clamp: 2;
  line-height: 1.2em;
  height: 3.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  background: url('https://s.wink.co.kr/pc/images/info/icon_qna_a.png') 0 50%
    no-repeat;
}
.qna-wrap > li .button {
  overflow: hidden;
  display: block;
  margin-top: 20px;
}
.qna-wrap > li .button:after {
  display: block;
  clear: both;
  content: '';
}
.qna-wrap > li .button button {
  margin-right: 16px;
  padding: 0 0 0 26px;
  background: none;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  color: #666;
  cursor: pointer;
}
.qna-wrap > li .button button.answer {
  background: url('https://s.wink.co.kr/pc/images/info/icon_answer.png') 0 50%
    no-repeat;
}
.qna-wrap > li .button button.like {
  background: url('https://s.wink.co.kr/pc/images/info/icon_like.png') 0 50%
    no-repeat;
}
.qna-wrap > li .button button.share {
  background: url('https://s.wink.co.kr/pc/images/info/icon_share.png') 0 50%
    no-repeat;
}
.qna-wrap > li .button button .new {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_new.png') 50% -2px no-repeat;
  text-indent: -9999px;
}

/* 질문 상세 */
.question-view {
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;
}
.question-view .view-box {
  padding: 24px 16px 18px;
}
.question-view .view-box > p {
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}
.question-view .view-user {
  overflow: hidden;
  margin-top: 17px;
}
.question-view .view-user:after {
  display: block;
  clear: both;
  content: '';
}
.question-view .view-user > ul {
  float: left;
}
/*.question-view .view-user > ul:last-child{float:right;}*/
.question-view .view-header .tag {
  background: #fff;
}
.question-view .view-header > strong {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}
.question-view .answer-box {
  padding: 24px 16px 20px;
  border-top: 1px solid #e0e0e0;
}
.question-view .answer-box p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 28px;
  color: #666;
}
.question-view .answer-box figure img {
  max-width: 100%;
  margin-top: 24px;
  border-radius: 10px;
  vertical-align: top;
}
.question-view .answer-box figure + p {
  margin-top: 24px;
}

.question-view .answer-box .view-user {
  margin-top: 42px;
}
.question-view .answer-box .view-user .share button {
  width: 17px;
  text-indent: -9999px;
}
.question-view ul.answer-box {
  padding: 0;
}
.question-view .answer-box > li {
  padding: 24px 16px 20px;
  border-top: 1px solid #e0e0e0;
}
.question-view .answer-box > li:first-child {
  border-top: none;
}
.question-view + .text-desc {
  margin-top: 42px;
  padding-left: 0;
  font-size: 18px;
  line-height: 62px;
  color: #212121;
}
.question-view + .text-desc:after {
  display: none;
}
.question-view + .text-desc span {
  margin-left: 6px;
  color: #08bbd5;
}
.question-view + .text-desc .btn-type {
  top: 11px;
  background: #f03c78;
}
.question-view + .text-desc textarea {
  min-height: auto;
  height: 120px;
  border-radius: 0;
  font-size: 14px;
  line-height: 26px;
}
.question-view + .text-desc .btn-area {
  height: 48px;
  margin: 40px 0;
}
.question-view + .text-desc .btn-area a {
  vertical-align: top;
}
.question-view + .text-desc .btn-area a.cancel {
  color: #f03c78;
}
.question-view + .text-desc .btn-area a.cancel:hover {
  border-color: #f03c78;
}
.question-view .tag.question {
  background: #f0f0f0;
  color: #757575;
  border: none;
}
.text-desc + .question-view {
  margin-top: 0;
}
.answer-list > li {
  padding: 32px 16px;
}

.recomm-question {
  margin-top: 56px;
}
.info-detail-container .recomm-question {
  margin-top: 30px;
}
.recomm-question > strong {
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #212121;
}
.recomm-question .slide-box {
  position: relative;
  overflow: hidden;
  margin-top: 16px;
}
.recomm-question .slide-box:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  height: 340px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0.8) 100%
  );
}

.recomm-question .slide-box > ul {
  overflow: hidden;
  position: relative;
  width: 200%;
  height: 279px;
}
.recomm-question .slide-box > ul:after {
  display: block;
  clear: both;
  content: '';
}
.recomm-question .slide-box > ul > li {
  position: relative;
  width: 540px;
  height: 279px;
  float: left;
  margin-right: 16px;
  padding: 30px 24px 24px;
  background: #fff;
  box-sizing: border-box;
  border-bottom: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.recomm-question .slide-box > ul > li .user-info {
  top: 30px;
  right: 24px;
}
.recomm-question .recomm-slide {
  position: relative;
}
.recomm-question .recomm-slide .btn-arrow a {
  opacity: 0;
  position: absolute;
  top: 50%;
  margin-top: -24px;
  width: 46px;
  height: 46px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.recomm-question .recomm-slide:hover .btn-arrow a {
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.recomm-question .recomm-slide .btn-arrow a span {
  z-index: 1;
  display: block;
  height: 46px;
  border: 1px solid #e0e0e0;
  opacity: 0.8;
  text-indent: -9999px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.recomm-question .recomm-slide .btn-arrow a span:hover {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.recomm-question .recomm-slide .btn-arrow .btn-prev {
  left: 0;
}
.recomm-question .recomm-slide .btn-arrow .btn-prev span {
  background: #fff url('https://s.wink.co.kr/pc/images/info/icon_prev_off.png')
    no-repeat;
}
.recomm-question .recomm-slide .btn-arrow .btn-prev span:hover {
  background: #fff url('https://s.wink.co.kr/pc/images/info/icon_prev_on.png')
    no-repeat;
}
.recomm-question .recomm-slide .btn-arrow .btn-next {
  right: 0;
}
.recomm-question .recomm-slide .btn-arrow .btn-next span {
  background: #fff url('https://s.wink.co.kr/pc/images/info/icon_next_off.png')
    no-repeat;
}
.recomm-question .recomm-slide .btn-arrow .btn-next span:hover {
  background: #fff url('https://s.wink.co.kr/pc/images/info/icon_next_on.png')
    no-repeat;
}
.recomm-question .recomm-slide .qna-wrap > li .qna-list > li {
  margin-top: 23px;
}
.recomm-question .recomm-slide .qna-wrap > li .button {
  margin-top: 23px;
}
.recomm-question + .btn-area {
  margin-top: 32px;
}

/**/
.entry .form {
  border-top: none;
}
.entry .btn-area {
  margin-top: 40px;
}
.entry .btn-area .cancel {
  color: #f03c78;
}
.entry .btn-area .cancel:hover {
  border-color: #f03c78;
}

/* 육아교육 백과 */
.info-nav > li {
  overflow: hidden;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
}
.info-nav > li:after {
  display: block;
  clear: both;
  content: '';
}
.info-nav > li:last-child {
  border-bottom: none;
}
.info-nav > li > a {
  float: left;
  width: 148px;
  padding-left: 32px;
  font-size: 18px;
  line-height: 32px;
  color: #212121;
}
.info-nav > li > a:hover {
  text-decoration: underline;
  color: #08bbd5;
}
.info-nav > li:nth-child(1) > a {
  background: url('https://s.wink.co.kr/pc/images/info/icon_info_nav.png') 0 50%
    no-repeat;
}
.info-nav > li:nth-child(2) > a {
  background: url('https://s.wink.co.kr/pc/images/info/icon_info_nav2.png') 0
    50% no-repeat;
}
.info-nav > li:nth-child(3) > a {
  background: url('https://s.wink.co.kr/pc/images/info/icon_info_nav3.png') 0
    50% no-repeat;
}
.info-nav > li:nth-child(4) > a {
  background: url('https://s.wink.co.kr/pc/images/info/icon_info_nav4.png') 0
    50% no-repeat;
}
.info-nav > li:nth-child(5) > a {
  background: url('https://s.wink.co.kr/pc/images/info/icon_info_nav5.png') 0
    50% no-repeat;
}
.info-nav > li > ul {
  overflow: hidden;
  float: left;
  width: 180px;
}
.info-nav > li > ul > li {
  margin-top: 10px;
}
.info-nav > li > ul > li:first-child {
  margin-top: 0;
}
.info-nav > li > ul > li a {
  display: inline-block;
  padding-right: 14px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_arrow_right.png') 100%
    8px no-repeat;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
  text-decoration: underline;
}
.info-nav > li > ul > li a:hover {
  background: url('https://s.wink.co.kr/pc/images/info/bg_arrow_right_on.png')
    100% 8px no-repeat;
  color: #08bbd5;
}

/* 카테고리 리스트 */
.category-wrap {
  overflow: visible;
  margin-top: 20px;
  margin-bottom: 16px;
}
.category-wrap:after {
  display: block;
  clear: both;
  content: '';
}
.category-wrap .category-box {
  float: left;
  overflow: visible;
}
.category-wrap .category-box:after {
  display: block;
  clear: both;
  content: '';
}
.category-wrap .category-box > strong {
  float: left;
  margin-right: 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  color: #212121;
}
.category-wrap .category-box > .select-mode {
  float: left;
  margin-right: 8px;
  width: 160px;
}
.category-wrap .category-box > .select-mode.on div {
  z-index: 103;
}
.category-wrap > ul {
  overflow: visible;
  float: right;
}
.category-wrap > ul:after {
  display: block;
  clear: both;
  content: '';
}
.category-wrap > ul li {
  position: relative;
  float: left;
  margin-left: 30px;
}
.category-wrap > ul li:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -14px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  background: #bdbdbd;
  border-radius: 10px;
}
.category-wrap > ul li a {
  font-size: 16px;
  line-height: 40px;
  color: #666;
}
.category-wrap > ul li.on a {
  color: #f03c78;
}
.category-wrap > ul li.on:before {
  left: -10px;
  background: #f03c78;
}
.category-wrap + .qna-wrap > li {
  padding-bottom: 32px;
}
.master-box {
  position: relative;
  margin-top: 17px;
  padding: 25px 25px 18px 23px;
  background: #f5f7fa;
  border-radius: 5px;
}
.master-box > p {
  margin-top: 10px;
}
.master-box > p > a {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}
.master-box > p > a:hover {
  text-decoration: underline;
}
.master-box .edit {
  position: absolute;
  bottom: 20px;
  right: 32px;
}
.qna-wrap .master-box .user-info {
  position: relative;
  top: auto;
  right: auto;
  margin-top: 12px;
}

/* 부모아이 진단 */
.check-list li {
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}
.check-list li:first-child {
  margin-top: 0;
}
.check-list li > dl dt {
  position: relative;
  padding: 0 24px;
  line-height: 61px;
}
.check-list li > dl dt .tag {
  margin-right: 10px;
}
.check-list li > dl dt .btn-box {
  margin-top: -20px;
  position: absolute;
  top: 50%;
  right: 24px;
  line-height: normal;
}
.check-list li > dl dt .btn-box .btn-type.test {
  color: #f03c78;
}
.check-list li > dl dt .btn-box .btn-type.test:hover {
  border: 1px solid #f03c78;
}
.check-list li > dl dd {
  padding: 18px 24px 24px;
  border-top: 1px solid #e0e0e0;
  color: #666;
}
.check-list-type p {
  font-size: 16px;
  line-height: 26px;
  color: #666;
}
.check-list-type ol > li {
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.check-list-type ol > li:first-child {
  margin-top: 6px;
}
.check-list-type ol > li > p {
  padding: 20px 24px 18px;
}
.check-list-type ol > li > ul {
  padding: 0 24px 22px;
  border-top: 1px solid #e0e0e0;
}
.check-list-type ol > li > ul li {
  margin-top: 16px;
}
.check-list-type2 {
  margin-top: -3px;
}
.check-list-type2 ol {
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.check-list-type2 ol > li {
  position: relative;
  padding: 0 25px;
  border-top: 1px solid #e0e0e0;
  font-size: 16px;
  line-height: 62px;
  color: #212121;
}
.check-list-type2 ol > li:first-child {
  border-top: none;
}
.check-list-type2 ol > li em {
  margin-right: 5px;
  font-weight: bold;
  color: #f03c78;
}
.check-list-type2 ol > li input[type='checkbox'] + label {
  position: static;
  padding-left: 0;
  color: #212121;
}
.check-list-type2 ol > li input[type='checkbox'] + label:before {
  top: 50%;
  margin-top: -10px;
  right: 25px;
  left: auto;
}
.check-result .result-box {
  padding: 10px 25px 48px;
}
.check-result .result-box > strong {
  display: block;
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  text-align: center;
}
.check-result .result-box > p {
  margin-top: 30px;
  padding: 24px 30px;
  background: #f5f5f5;
  border-radius: 5px;
  font-size: 16px;
  line-height: 28px;
  color: #666;
}
.check-result.single-padding .total-wrap {
  padding-bottom: 32px;
}
.check-result.single-padding .message-box {
  margin-top: 4px;
}
.check-result .total-wrap {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 21px 8px 0; /*padding:0 24px 27px;*/
  box-sizing: border-box;
  border-spacing: 24px 0;
}
.check-result .result-desc {
  display: table-cell;
  vertical-align: middle;
  padding: 0 40px 0 32px;
  background: #f5f5f5;
  border-radius: 5px;
  font-size: 16px;
  line-height: 28px;
  color: #666;
}
.check-result .result-desc li span {
  color: #f03c78;
}
.check-result .message-box {
  margin-top: 32px;
  padding: 40px 16px 30px;
  text-align: left;
}
.check-result .message-box .notice {
  padding-left: 31px;
  background: url('https://s.wink.co.kr/pc/images/icon_notice.png') no-repeat;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
}
.check-result .message-box .notice.tip {
  margin-top: -11px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_tip.png') no-repeat;
}
.check-result .message-box p {
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.check-result .message-box .border-box {
  margin: 0 -16px 40px;
  padding: 0 16px 27px;
  border-bottom: 1px solid #e0e0e0;
}
.check-result .message-box .border-box strong {
  font-size: 18px;
  line-height: 32px;
  color: #212121;
}
.check-result .message-box .graph .title {
  overflow: hidden;
}
.check-result .message-box .graph .title:after {
  display: block;
  clear: both;
  content: '';
}
.check-result .message-box .graph .title strong {
  float: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #666;
}
.check-result .message-box .graph .title span {
  float: right;
}
.check-result .message-box .graph figure {
  margin-top: 21px;
}
.check-result .message-box .notice-list {
  margin-top: 7px;
}
.check-result .message-box .notice-list li {
  margin-top: 5px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.check-result .message-box .notice-list li em {
  margin-right: 6px;
  font-weight: bold;
}
.check-result .message-box + .origin {
  border-top: none;
  margin-top: 0;
}
.check-result .origin {
  position: relative;
  margin-top: 26px;
  padding: 12px 0 0 7px;
  border-top: 1px solid #e9e9e9;
  font-size: 14px;
  line-height: 32px;
  color: #999;
}
.check-result .origin:before {
  display: block;
  content: '';
  width: 2px;
  height: 2px;
  background: #b1b1b1;
  position: absolute;
  top: 25px;
  left: 0;
}
.check-result .tip {
  display: block;
  margin-top: 18px;
  padding-left: 28px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_tip.png') 0 4px
    no-repeat;
  font-size: 18px;
  line-height: 30px;
  color: #212121;
}

.my-page-out .check-list {
  padding: 0 16px;
  border-top: 2px solid #676969;
}
.my-page-out .check-list li {
  border: none;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0;
  padding: 15px 0;
}

.total {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 178px;
}
.total > strong {
  display: block;
  font-size: 20px;
  line-height: 22px;
  color: #212121;
  text-align: center;
}
.total .total-box {
  margin: 12px auto 0;
  width: 124px;
  height: 124px;
  border: 2px solid #08bbd5;
  border-radius: 100px;
  text-align: center;
}
.total .total-box > span {
  font-size: 22px;
  line-height: 124px;
  color: #bdbdbd;
}
.total .total-box > span em {
  font-size: 36px;
  color: #08bbd5;
}

.question-num {
  margin-top: 30px;
  padding-top: 26px;
  border-top: 1px solid #e9e9e9;
}
.question-num .total {
  width: 240px;
  text-align: center;
}
.question-num .total strong {
  font-size: 18px;
  line-height: 30px;
}
.question-num .total strong span {
  color: #f03c78;
}
.question-num .total .total-box {
  width: 111px;
  height: 111px;
  margin: 8px auto 0;
}
.question-num .total .total-box > span {
  font-size: 19px;
  line-height: 111px;
}
.question-num .total .total-box > span em {
  font-size: 32px;
}
.question-num + .result-desc {
  display: block;
  padding: 33px;
  margin-top: 32px;
}

.type-desc {
  margin-top: 32px;
}
.type-desc li {
  margin-top: 26px;
}
.type-desc li:first-child {
  margin-top: 0;
}
.type-desc li > p {
  margin-top: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.type-desc li dl {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.type-desc li dl dt {
  padding: 26px 0;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #212121;
}
.type-desc li dl dt span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
  font-size: 16px;
  line-height: 17px;
  color: #f03c78;
}
.type-desc li dl dt span > em {
  display: block;
  font-size: 50px;
  line-height: 50px;
}
.type-desc li dl dt strong {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  text-align: left;
}
.type-desc li dl dt strong.result-title {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  color: #08bbd5;
}
.type-desc li dl dt strong.result-title.highlight {
  color: #f03c78;
}
.type-desc li dl dd {
  padding: 26px 40px 26px 32px;
  border-top: 1px solid #e9e9e9;
  font-size: 16px;
  line-height: 28px;
  color: #666;
}

/* 교육정보 */
.edu-list {
  margin-top: 6px;
}
.edu-list table td .tag.notice {
  margin-right: 10px;
}
.edu-list table td .new {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  background: url('https://s.wink.co.kr/pc/images/info/bg_icon_new.png')
    no-repeat;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
}
.edu-list table td.reply {
  position: relative;
  padding-left: 30px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_answer.png') 0 50%
    no-repeat;
}

/* issue_1067 */
/* .edu-list table td.reply:after{display:block;content:'';position:absolute;top:15px;left:22px;width:5px;height:5px;background:#f03c78;border-radius:5px;} */
.edu-list table td.reply.new:after {
  display: block;
  content: '';
  position: absolute;
  top: 15px;
  left: 22px;
  width: 5px;
  height: 5px;
  background: #f03c78;
  border-radius: 5px;
}
/* // issue_1067 */

.edu-list table td.like {
  padding-left: 26px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_like.png') 0 50%
    no-repeat;
}
.edu-list table td.title > div {
  position: relative;
}
.edu-list table td.title > div a {
  width: calc(100% - 30px);
}
.edu-detail .view-body {
  margin-bottom: 32px;
  padding: 32px 16px 20px;
  border-bottom: 1px solid #e0e0e0;
}
.edu-detail .view-body p {
  margin-top: 26px;
}
.edu-detail .view-body img {
  max-width: 100%;
}
.edu-detail .view-body .small-btn {
  margin-top: 78px;
}
.edu-detail .view-body .small-btn li.share button {
  margin-left: 24px;
  padding-left: 24px;
}
.edu-detail .reply-area {
  height: 200px;
  background: #ccc;
  line-height: 200px;
  text-align: center;
  color: #fff;
}
.edu-detail .btn-area {
  margin-top: 32px;
}

/**/
.view_detail {
}
.view_detail .tag-list {
  overflow: hidden;
  margin-top: 50px;
}
.question-view .view_detail .tag-list {
  margin-top: 0px;
}
.view_detail .tag-list:after {
  display: block;
  clear: both;
  content: '';
}
.view_detail .tag-list li {
  float: left;
  margin-right: 10px;
  padding: 0 10px;
  background: #f0f0f0;
  border-radius: 4px;
}
.view_detail .tag-list li a {
  display: block;
  font-size: 14px;
  line-height: 32px;
  color: #757575;
}

.view-header {
  position: relative;
  padding: 18px 150px 20px 16px;
  border-top: 2px solid #676969;
  background-color: #f5f5f5;
}
.view-header .tag {
  margin-right: 6px;
}
.view-header .title {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}
.view-header .info-time {
  overflow: hidden;
  margin-top: 8px;
}
.view-header .info-time:after {
  display: block;
  clear: both;
  content: '';
}
.view-header .info-time span {
  float: left;
  font-size: 14px;
  line-height: 18px;
  color: #bdbdbd;
}
.view-header .info-time .date {
  margin-right: 10px;
  background-image: url(https://s.wink.co.kr/pc/images/icon_calendar.png);
}
.view-header .info-time .reply {
  padding-left: 24px;
  background: url(https://s.wink.co.kr/pc/images/icon_reply.png) no-repeat;
}
.view-header .small-btn {
  position: absolute;
  top: 22px;
  right: 16px;
}
.view-header .small-btn li.share button {
  width: 17px;
  text-indent: -9999px;
}

/* 좋아요-공유버튼 */
.small-btn {
  overflow: hidden;
}
.small-btn:after {
  display: block;
  clear: both;
  content: '';
}
.small-btn li {
  float: left;
}
.small-btn li button {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  cursor: pointer;
}
.small-btn li.like button {
  padding-left: 26px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_like.png') no-repeat;
  background-size: 21px 19px;
}
.small-btn li.share button {
  margin-left: 5px;
  background: url('https://s.wink.co.kr/pc/images/info/icon_share.png')
    no-repeat;
}

/* 작성 아이디 시간 */
.user-info {
  overflow: hidden;
}
.user-info:after {
  display: block;
  clear: both;
  content: '';
}
.user-info > li {
  float: left;
  padding-left: 10px;
  margin-left: 7px;
  background: url('https://s.wink.co.kr/pc/images/info/blt.png') 0 50% no-repeat;
  font-size: 14px;
  line-height: 28px;
  color: #999;
}
.user-info > li:first-child {
  background: none;
  margin-left: 0;
  padding-left: 0;
}

/* 수정삭제 버튼 */
.edit {
  overflow: hidden;
}
.edit:after {
  display: block;
  clear: both;
  content: '';
}
.edit li {
  float: left;
}
.edit li:before {
  display: inline-block;
  content: '';
  vertical-align: middle;
  width: 1px;
  height: 12px;
  background: #bdbdbd;
}
.edit li:first-child:before {
  display: none;
}
.edit li:last-child button {
  padding-right: 0;
}
.edit li button {
  padding: 0 10px;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  cursor: pointer;
}

.line-box {
  border-top: 2px solid #666;
  border-bottom: 1px solid #e0e0e0;
}
.line-box > li {
  border-bottom: 1px solid #e0e0e0;
}
.line-box > li:last-child {
  border-bottom: none;
}
.sub-box {
  margin-top: 90px;
}
.sub-box h4 {
  margin-bottom: 0;
  font-size: 28px;
  line-height: 40px;
  color: #212121;
}
.sub-box .line-box {
  margin-top: 7px;
}

.icon-master {
  background: url('https://s.wink.co.kr/pc/images/info/icon_logo.png') no-repeat;
  padding-left: 65px;
}

.text-desc {
  position: relative;
  margin-top: 16px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 46px;
  color: #666;
}
.text-desc span {
  color: #f03c78;
}
.text-desc:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
  width: 2px;
  height: 2px;
  background: #b1b1b1;
}
.text-desc .btn-type {
  position: absolute;
  top: -5px;
  right: 0;
}
.text-desc.new-margin {
  margin-top: -10px;
}

/*********************************************************************************member************************************************************************************/

h3.line {
  padding-bottom: 20px;
}
/* Common */
.message-box.recommend {
  border-top-width: 0;
  padding-top: 123px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/img_message.gif) 50%
    16px no-repeat;
}
.message-box.not-taken {
  border-top-width: 0;
  padding-top: 123px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/img_message2.gif) 50%
    16px no-repeat;
}
.message-box.join {
  border-top-width: 0;
  padding-top: 123px;
  padding-bottom: 40px;
  background: url(https://s.wink.co.kr/pc/images/member/bg_member.png) 50% 18px
    no-repeat;
}
.message-box.join-finish {
  border-top-width: 0;
  padding-top: 100px;
  padding-bottom: 46px;
  background: url(https://s.wink.co.kr/pc/images/member/bg_member_finish.png)
    50% 15px no-repeat;
}

.btn-box {
  margin-top: 40px;
  text-align: center;
}
.btn-box a {
  margin-right: 4px;
}
.btn-box a:last-child {
  margin-right: 0;
}

table.form th.parent-select {
  padding-top: 12px;
}
table.pw-form td {
  padding: 8px 0;
}

/* form - 약관동의 */
table.form.join-form {
  margin-top: 40px;
}
table.form.join-form input[type='text'] {
  width: 230px !important;
}
.message-box + table.form.join-form input.disabled {
  width: 128px !important;
  margin-right: 8px;
}
.phone .item .confirm.disabled {
  width: 128px !important;
  margin-right: 8px;
}
table.form.join-form fieldset a.btn-type {
  margin-left: 8px;
}
table.form.join-form fieldset a.btn-type2 {
  margin-left: 8px;
  margin-right: 8px;
}
table.form.join-form fieldset.name input[type='text'] {
  width: 200px !important;
}
table.form.join-form fieldset.phone > div:nth-child(3) a.btn-type {
  margin-left: 0;
}

/*
.agree-wrap{margin-top:40px;}
.agree-wrap > fieldset label{font-weight:bold;color:#212121;}
.agree-wrap .agree-box{margin-top:17px;border-top:2px solid #676969;}
.agree-wrap .agree-box > li{padding:0 16px;border-bottom:1px solid #e0e0e0;}
.agree-wrap .agree-box > li > div{display:block;background:url('https://s.wink.co.kr/pc/images/icon_arrow_down.png') right 50% no-repeat;font-size:16px;line-height:56px;color:#666;cursor:pointer;}
.agree-wrap .agree-box > li.on > div{background:url('https://s.wink.co.kr/pc/images/icon_arrow_up.png') right 50% no-repeat;}
.agree-wrap .agree-box > li > div input[type=checkbox] + label{display:inline-block;padding-left:32px;margin-right:0;color:#666;}
.agree-wrap .agree-box > li > div input[type=checkbox] + label:before{top:50%;margin-top:-10px;}
.agree-wrap .agree-box > li > .provision{display:none;overflow-x:hidden;overflow-y:scroll;height:310px;margin:0 -16px;padding:20px 16px;background:#f5f5f5;border-top:1px solid #e0e0e0;font-size:14px;line-height:24px;color:#666;}
.agree-wrap .agree-box > li > .provision > li{margin-bottom:24px;}
.agree-wrap .agree-box > li > .provision > li strong{display:block;margin-bottom:24px;}
*/

/* 이용약관 */
section.clause {
  margin-bottom: 40px !important;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.clause h5 {
  margin-bottom: 25px;
  font-size: 18px;
}
.clause strong {
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
.clause ol {
  margin-top: 12px;
}
.clause ol > li ol {
  margin-top: 0;
}
.clause ol + p {
  margin-top: 24px;
}
.clause > ul > li {
  margin-bottom: 24px;
}
.clause > ul > li p {
  margin-top: 12px;
}

/* 아이정보 등록 */
.regster-box {
  margin-top: 52px;
}
.regster-box h5 {
  margin-bottom: 0;
}
.regster-box p {
  margin-top: 10px;
  padding: 18px 0;
  background: #f5f5f5;
  border-radius: 8px;
  font-size: 16px;
  line-height: 40px;
  color: #212121;
  text-align: center;
}
.regster-box p > strong {
  display: block;
  font-size: 18px;
  line-height: 44px;
}
.regster-box p > span {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #999;
}
.regster-box p > span > em {
  color: #08bbd5;
}

/* form - 가입완료 */
table.form.finish-form {
  margin-top: 16px;
}
table.form.finish-form fieldset.name input[type='text'] {
  width: 200px !important;
}
table.form.finish-form .select-mode {
  min-width: 70px;
}
table.form.finish-form .select-mode:first-child {
  min-width: 100px;
}
table.form.finish-form .select-mode + span {
  margin: 0 20px 0 4px;
}
.recomm-register {
  margin-top: 73px;
}
.recomm-register h5 {
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 0;
}
.recomm-register span {
  font-size: 14px;
}
.recomm-register fieldset {
  margin-top: 8px;
  padding: 32px 0;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e9e9e9;
}
.recomm-register fieldset input[type='text'] {
  width: 270px;
}
.recomm-register fieldset a {
  margin-left: 4px;
}

/* issue_1789925 */
.layer-popup-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  background: #fff;
  z-index: 2;
  overflow: hidden;
  transform: translate(-50%, -50%);
  margin-top: 0;
  min-width: 380px;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: black;
}
.popup .layer-content {
  padding: 3px;
}
.popup .layer-content a {
  display: inline-block;
}
.popup .layer-content a img {
  width: 100%;
  vertical-align: middle;
}
.popup .checkbox-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.popup .checkbox-area div {
  text-align: center;
  width: 59%;
  height: 100%;
  font-size: 15px;
  padding: 10px 0 10px 0;
  background-color: #f03c78;
}
.popup .checkbox-area div:first-child {
  background-color: rgb(217, 217, 217);
}

/* layer popup */
.layer-wrap.member.small {
  padding: 50px 0 48px;
}
.layer-wrap.member.check {
  padding: 48px 0 46px;
}
/* issue_1048 */
/* .layer-wrap.member{width:400px;margin-left:-200px;padding:32px;} */
/* //issue_1048 */
.layer-wrap.member .tab-type {
  margin-top: 15px;
}
.layer-wrap.member .tab-type + fieldset.phone input {
  width: 206px;
}
.layer-wrap.member .tab-type + fieldset.phone input.name {
  width: 100%;
}
.layer-wrap.member .tab-type + fieldset input {
  margin-top: 8px;
}
.layer-wrap.member .tab-type + fieldset > a {
  margin-top: 8px;
  width: 122px;
  padding: 0;
}
/* issue_1048 */
/* .layer-wrap.member .id-login input{width:100%;} */
/* // issue_1048 */
.layer-wrap.member .id-login + .save {
  margin-top: 12px;
  height: 20px;
}
.layer-wrap.member .save label {
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}
.layer-wrap.member .save input[type='checkbox'] + label {
  vertical-align: top;
}

/* issue_1048 */
/* .layer-wrap.member .btn-type2.login{width:100%;margin-top:20px;} */
/* // issue_1048 */
.layer-wrap.member.login-modal .login-button {
  width: 100%;
}
.layer-wrap.member.login-modal .login-button img {
  width: 100%;
  padding-top: 20px;
}
.layer-wrap.member .small-join {
  padding: 24px;
  margin-top: 48px;
  background: #fcf5d4
    url('https://s.wink.co.kr/pc/images/member/bg_layer_login.png') 247px 57px
    no-repeat;
  border-radius: 8px;
}
.layer-wrap.member .small-join strong {
  font-size: 18px;
  line-height: 36px;
  color: #212121;
}
.layer-wrap.member .small-join p {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 22px;
  color: #666;
}
.layer-wrap.member .small-join a {
  padding-right: 13px;
  background: url('https://s.wink.co.kr/pc/images/bu_go.png') 100% 3px no-repeat;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  text-decoration: underline;
}
.layer-wrap.member fieldset.phone {
  margin-bottom: 12px;
}
.layer-wrap.member fieldset.phone .text-valid {
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
  line-height: 40px;
}
.layer-wrap.member .btn-area {
  margin-top: 24px;
}
.layer-wrap.member .layer-center {
  text-align: center;
}
.layer-wrap.member .register-info > strong {
  display: block;
  margin-top: 25px;
  font-size: 18px;
  line-height: 26px;
  color: #666;
}
.layer-wrap.member .register-info > strong em {
  color: #212121;
}
.layer-wrap.member .register-info > strong + p {
  margin-top: 8px;
}
.layer-wrap.member .register-info > p {
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}
.layer-wrap.member .register-info > p strong {
  font-weight: bold;
  color: #212121;
}
.layer-wrap.member .register-info > a {
  margin-top: 26px;
}
.layer-wrap.check .layer-center p {
  margin-top: 24px;
}
.layer-wrap.check .btn-area {
  margin-top: 24px;
}
.layer-wrap.check .btn-area .form {
  border-width: 0;
  background: #95989f;
  color: #fff;
}
.layer-wrap.check .btn-area .form:hover {
  background: #575b63;
}
.layer-wrap.large .regster-box {
  /*border-top:2px solid #676969;*/
  width: 560px;
  margin-top: 0;
}
.layer-wrap.large .regster-box > strong {
  display: block;
  padding: 42px 0 36px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
}
.layer-wrap.large .regster-box p {
  margin-top: 0;
}
/*.layer-wrap.large .agree-box{margin-top:23px;}*/
.layer-wrap.large .agree-box > li > .provision {
  height: 79px;
}
.layer-wrap.large .join-form {
  margin-top: 0;
  border-top: none;
}
.layer-wrap.large .join-form .parent-select {
  width: 100px;
  padding-top: 8px;
}
.layer-wrap.large .join-form fieldset.phone {
  margin-bottom: 0;
}
.layer-wrap.large .join-form tr:last-child td {
  padding-bottom: 56px;
}
.layer-wrap.large .finish-form input[type='text'] {
  width: 200px !important;
}
.layer-wrap.large .pw-form {
  margin-top: 10px;
}
.layer-wrap.large .pw-form input {
  width: 303px !important;
}
.layer-wrap.large .pw-form .item {
  margin-top: 8px;
}
.layer-wrap.large .pw-form .item input {
  width: 173px !important;
}
.layer-wrap.large .pw-form .item .btn-type.form {
  padding: 0 22px;
}
.layer-wrap.large .btn-type.form.big {
  width: 100px;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}

.layer-scroll {
  position: relative;
  border-top: 2px solid #676969;
  margin-top: 15px;
}
.layer-scroll > .scroll {
  position: relative;
  font-size: 0;
}
.layer-scroll .scroll .agree-wrap {
  width: 560px;
  margin-top: 47px;
  padding-bottom: 20px;
}
/*.layer-scroll .scroll .agree-wrap .agree-box{margin-top:15px;}*/
.layer-scroll .scroll table.join-form {
  width: 560px;
}
.layer-scroll .scroll table.join-form input {
  width: 250px !important;
}
.layer-scroll .scroll table.join-form th {
  width: 100px;
}
.layer-scroll .scroll table.join-form .phone input {
  width: 211px !important;
}
.layer-scroll .scroll table.join-form .phone input.layer-disabled {
  width: 114px !important;
}
.layer-scroll .scroll table.finish-form {
  width: 560px;
  margin-bottom: 20px;
}
.layer-scroll .scroll table.finish-form div.select-mode {
  overflow: hidden;
}
.layer-scroll .scroll table.finish-form div.select-mode.on {
  overflow: visible;
}
.layer-scroll .scroll table.form.join-form fieldset a.btn-type {
  margin-left: 8px;
}
.layer-scroll .scroll table.form.join-form fieldset a.btn-type2 {
  margin-left: 8px;
}
.layer-scroll .scroll table.form.join-form fieldset.phone .text-valid {
  margin-left: 8px;
}

.layer .layer-wrap.large .layer-scroll table.finish-form {
  margin: 20px 0;
}
/***************************************************************************************mykid************************************************************************************/

/* common */

/* btn */
.btn-type2.graph {
  color: #f03c78;
}
.btn-type2.graph:before {
  width: 19px;
  height: 18px;
  margin-right: 7px;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_graph.png)
    no-repeat 0 0;
}
.btn-type2.graph:hover {
  border-color: #f03c78;
}

.btn-type2.pie {
  color: #f03c78;
}
.btn-type2.pie:before {
  width: 19px;
  height: 18px;
  margin-right: 7px;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_pie.png)
    no-repeat 0 0;
}
.btn-type2.pie:hover {
  border-color: #f03c78;
}

.btn-type2.book {
  color: #f03c78;
}
.btn-type2.book:before {
  width: 19px;
  height: 18px;
  margin-right: 7px;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_book.png)
    no-repeat 0 0;
}
.btn-type2.book:hover {
  border-color: #f03c78;
}

.btn-type2.plus {
  color: #f03c78;
}
.btn-type2.plus:before {
  width: 14px;
  height: 14px;
  margin: -3px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_plus.png)
    no-repeat 0 0;
}
.btn-type2.plus:hover {
  border-color: #f03c78;
}

/* 페이지 비 활성화 */
.paging-navigation.disabled a,
.paging-navigation.disabled .on a,
.paging-navigation.disabled a:hover,
.paging-navigation.disabled .on a:hover {
  border-color: #e1e1e1;
  color: #c2c2c2;
}
.paging-navigation.disabled .prev:hover {
  background: url(https://s.wink.co.kr/pc/images/btn_navigation.png) no-repeat;
}
.paging-navigation.disabled .next:hover {
  background-position: -10px 0;
}

.accordion .ans {
  display: none;
}
/* //common */

/* swipe checkbox */
.btn-switch label {
  position: relative;
  width: 61px;
  height: 25px;
  margin: 0;
  padding: 0;
  border: 2px solid #ccc;
  border-radius: 16px;
  background-color: #ccc;
  transition: all 0.3s ease-in-out;
}
.btn-switch input[type='checkbox'] + label {
  margin: 0;
  padding: 0;
  text-align: right;
}
.btn-switch input[type='checkbox'] + label:after {
  content: 'OFF';
  display: inline-block;
  margin-right: 8px;
  font-size: 12px;
  color: #fff;
}
.btn-switch input[type='checkbox'] + label:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 0 none;
  border-radius: 100%;
  background: none #fff;
  transition: all 0.2s ease-in-out;
}

.btn-switch input[type='checkbox']:checked + label {
  border-color: #08bbd5;
  text-align: left;
  background: none #08bbd5;
}
.btn-switch input[type='checkbox']:checked + label:after {
  content: 'ON';
  margin-left: 10px;
}
.btn-switch input[type='checkbox']:checked + label:before {
  left: 36px;
}

.notice + section {
  margin-top: 40px;
}

/* 종합 분석 */
.analysis-list li:first-child {
  background: none;
}
.analysis-list li {
  padding: 32px 0 26px 30px;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 0;
}
.analysis-list li:last-child {
  padding-bottom: 0;
}
.analysis-list .title {
  margin-bottom: 12px;
  text-indent: -30px;
  font-size: 18px;
}
.analysis-list .title .num {
  margin-right: 5px;
  color: #f03c78;
}
.analysis-list .title small {
  font-size: 14px;
  color: #999;
}
.analysis-list .title em {
  color: #212121;
}
.analysis-list .text {
  line-height: 26px;
}

.sticker-area {
  background: url(https://s.wink.co.kr/pc/1.1.0/mykids/sticker_area.png)
    no-repeat;
  height: 300px;
  position: relative;
}

.sticker-area .sticker-num {
  position: absolute;
  top: 105px;
}

.sticker-area .sticker-num.type1 {
  left: 65px;
}

.sticker-area .sticker-num.type2 {
  left: 330px;
}

.sticker-area .sticker-num.type3 {
  right: 75px;
}

.sticker-area .total-sticker {
  width: 73px;
  position: absolute;
  bottom: 9px;
  left: 54%;
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.sticker-area .sticker-num .count {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  color: #ff578f;
  line-height: 1.1;
}

/* 출석 현황 */
.attend {
  position: relative;
  height: 450px;
  background: url(https://s.wink.co.kr/pc/1.1.0/mykids/attendance_rate.png)
    no-repeat;
}

.attend .attendance-num {
  position: absolute;
  color: #fff;
  font-weight: bold;
  font-size: 45px;
  width: 55px;
  text-align: center;
}

.attend .attendance-num.type1 {
  top: 165px;
  left: 140px;
  color: #08bbd5;
}

.attend .attendance-num.type2 {
  top: 55px;
  right: 175px;
}

.attend .attendance-num.type3 {
  bottom: 95px;
  right: 110px;
  width: 160px;
}

.attend p {
  font-size: 14px;
  color: #999;
}
.attend ul {
  margin-top: 30px;
}
.attend ul:after {
  display: block;
  content: '';
  clear: both;
}
.attend li {
  float: left;
  width: 33.333%;
  font-size: 18px;
  text-align: center;
  color: #212121;
}
.attend li span {
  display: block;
  width: 114px;
  height: 114px;
  margin: 0 auto 15px;
  border: 2px solid #08bbd5;
  border-radius: 100%;
  font-size: 32px;
  line-height: 120px;
  color: #08bbd5;
}

/* 학습 수행률 */
.perform-graph {
  padding-bottom: 24px;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 100%;
}
.perform-graph:after {
  display: block;
  content: '';
  clear: both;
}
.perform-graph > div {
  float: left;
  width: 340px;
}
.perform-graph > div:nth-child(even) {
  float: right;
}
.perform-graph > div .title {
  margin-bottom: 14px;
  font-size: 20px;
}
.perform-graph .no-data {
  box-sizing: border-box;
  height: 226px;
  padding-top: 128px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  line-height: 24px;
  text-align: center;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_content_notice.png)
    no-repeat 50% 50px;
}

.perform-book {
  position: relative;
  padding: 44px 0;
  border-bottom: 1px solid #e0e0e0;
}
.perform-book strong {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
}
.perform-book .btn-type2 {
  position: absolute;
  right: 0;
  top: 32px;
}

/* 관심 영역 */
.interest-area {
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.interest-area:after {
  display: block;
  content: '';
  clear: both;
}
.interest-area > div {
  float: left;
  width: 100%;
}
.interest-area > div:nth-child(even) {
  float: right;
}
.interest-area > div .title {
  margin-bottom: 14px;
  font-size: 20px;
}
.interest-area .graph-area {
  height: 340px;
}
.interest-area .message-box.mykids-no-data {
  display: table;
  width: 100%;
  height: 226px;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.interest-area .message-box.mykids-no-data p {
  display: table-cell;
  vertical-align: middle;
  background-position: 50% 52px;
  line-height: 1.5;
}

.graph-content {
  margin-top: 18px;
}
.graph-content h6 {
  font-size: 14px;
  text-align: center;
  color: #212121;
}
.graph-content table {
  width: 100%;
  margin-top: 11px;
  border-top: 2px solid #676969;
}
.graph-content tr:first-child td {
  padding-top: 20px;
}
.graph-content td {
  padding: 6px 0 5px;
  text-align: center;
  vertical-align: middle;
}
.graph-content td a {
  text-decoration: underline;
  color: #212121;
}
.graph-content .left {
  padding-left: 16px;
  text-align: left;
}

/* media content */
.media-list .swiper-container {
  height: 300px;
}
.media-list .swiper-pagination {
  margin-top: 43px;
}
.media-list li:first-child {
  margin-top: 0;
}
.media-list li {
  margin-top: 16px;
}
/* .media-list .media-info p {width: 152px;} */

.media-area {
  display: table;
  position: relative;
  width: 100%;
}
.media-area .thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 164px;
  height: 92px;
  border-radius: 6px;
  overflow: hidden;
}
.media-area .thumb:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 92px;
  background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.5));
}
.media-area .thumb img {
  width: 164px;
  height: 92px;
}
.media-area .thumb .time {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 14px;
  color: #fff;
}
.media-area .thumb .btn-video {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 67px;
  height: 67px;
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_thumb_play.png)
    no-repeat 0 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-indent: -999em;
}

.media-area .media-info {
  display: table-cell;
  height: 92px;
  padding-left: 188px;
  text-align: left;
  vertical-align: middle;
}
.media-area .media-info .tag-area {
  margin-bottom: 5px;
}
.media-area .media-info p {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #212121;
}
.media-area .media-info .foot {
  margin-top: 7px;
  font-size: 14px;
  color: #999;
}

.media-area.disabled .thumb img {
  filter: grayscale(100%);
  opacity: 0.6;
}
.media-area.disabled .thumb .btn-audio,
.media-area.disabled .thumb .btn-video {
  filter: grayscale(100%);
}
.media-area.disabled .tag {
  color: #bdbdbd;
  background-color: #f1f1f1;
}
.media-area.disabled .media-info p,
.media-area.disabled .media-info .foot {
  color: #bdbdbd;
}
.media-area.disabled .audio-content {
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_audio_disabled.png)
    no-repeat 0 0;
}
.media-area.disabled .audio-content .btn-audio {
  opacity: 0.5;
}

.audio-content {
  position: absolute;
  width: 164px;
  height: 92px;
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_audio.png) no-repeat
    0 0;
}
.audio-content .btn-audio {
  position: absolute;
  left: 16px;
  top: 50%;
  z-index: 1;
  width: 26px;
  height: 26px;
  text-indent: -999em;
  cursor: pointer;
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_thumb_audio_play.png)
    no-repeat 0 0;
  transform: translateY(-50%);
}
.audio-content .btn-audio.btn-stop {
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_thumb_audio_stop.png);
}
.audio-content .audio {
  position: absolute;
  left: 46px;
  top: 30px;
  width: 102px;
  height: 34px;
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_sound.png) no-repeat
    0 0;
}
.audio-content .audio span {
  position: absolute;
  left: 0;
  top: 0;
  height: 34px;
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_sound_on.png)
    no-repeat 0 0;
}

/* 주 리스트 */
.week-list > ul > li {
  margin-top: 8px;
}
.week-list li:first-child {
  margin-top: 0;
}
.week-list li .title {
  display: block;
  position: relative;
  height: 52px;
  padding-left: 35px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  line-height: 52px;
  color: #212121;
  background-color: #fff;
}
.week-list li .title:before {
  content: '';
  position: absolute;
  left: 14px;
  top: 21px;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ebebeb;
}
.week-list li.on.week01 .title:before {
  background-color: #ffc200;
}
.week-list li.on.week02 .title:before {
  background-color: #4385ff;
}
.week-list li.on.week03 .title:before {
  background-color: #00bbd4;
}
.week-list li.on.week04 .title:before {
  background-color: #a186be;
}
.week-list li .title:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 21px;
  width: 17px;
  height: 10px;
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_off.png)
    no-repeat 0 0;
}
.week-list li.on .title {
  background-color: #f5f5f5;
}
.week-list li.on .title:after {
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_on.png)
    no-repeat 0 0;
}

.week-list li .title.disabled {
  color: #bdbdbd;
}
.week-list li .title.disabled:after {
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_disabled.png)
    no-repeat 0 0;
}
.week-list li .title.week-sample ~ .ans .btn-type {
  border-color: #bdbdbd;
  color: #bdbdbd;
}

/* study content */
.study-list li {
  padding: 33px 16px;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 0;
}
.study-list li:first-child {
  background: none;
}

.study-content {
  display: table;
  position: relative;
  width: 100%;
}
.study-content .thumb {
  overflow: hidden;
  position: absolute;
  width: 272px;
  height: 153px;
  border-radius: 6px;
}
.study-content .thumb:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.study-content .thumb img {
  width: 272px;
  height: 153px;
  vertical-align: top;
}
.study-content .thumb .stamp {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.study-content .study-info {
  display: table-cell;
  height: 153px;
  padding-left: 300px;
  vertical-align: middle;
}
.study-content .study-info .tag-area {
  margin-bottom: 5px;
}
.study-content .study-info .tag-area .tag {
  margin-right: 5px;
  font-size: 16px;
  line-height: 30px;
}
.study-content .study-info .tag-area .tag.category {
  border-width: 0;
  color: #212121;
  background-color: #f1f1f1;
}
.study-content .study-info .btn-area {
  margin-top: 20px;
  text-align: left;
}
.study-content .study-info .btn-area .btn-type {
  min-width: 90px;
  color: #f03c78;
}
.study-content .study-info .btn-area .btn-type:hover {
  border-color: #f03c78;
}

.study-notice {
  padding-left: 42px;
  height: 40px;
  margin-top: 15px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 40px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat
    16px 50% #f7f7f7;
}

/* stamp */
.stamp {
  display: inline-block;
  width: 91px;
  height: 91px;
  font-size: 20px;
  line-height: 95px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}
.stamp.finish {
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_stamp.png) no-repeat
    0 0;
}
.stamp.before {
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_stamp1.png) no-repeat
    0 0;
}

/* 책 */
.book-cover {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 133px;
  margin: 16px 0;
  padding-right: 6px;
  vertical-align: middle;
  box-sizing: border-box;
  background: url(https://s.wink.co.kr/pc/images/mykids/bg_book.png) no-repeat 0
    0;
  background-size: cover;
}
.book-cover:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 7px;
  height: 133px;
  background: #000;
  background: linear-gradient(to right, #fff, #000);
  opacity: 0.3;
}
.book-cover img {
  max-width: 100%;
  vertical-align: top;
}

.book-info dt {
  font-size: 18px;
  line-height: 28px;
  color: #212121;
}
.book-info dd {
  margin-top: 5px;
  line-height: 26px;
}

/* 성향 분석 */
.tendency .list-style-dot {
  font-size: 14px;
  color: #999;
}
.tendency .list-style-dot:only-child {
  padding-bottom: 27px;
  border-bottom: 1px solid #e0e0e0;
}
.tendency .graph-area {
  margin-top: 10px;
}

/* 과목별 학습 테이블 */
.study-tbl-info {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  background-color: #fff;
}
.study-tbl-info span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 27px 0 12px;
  border: 2px solid #00bbd4;
  border-radius: 100%;
  line-height: 44px;
  vertical-align: middle;
  color: #00bbd4;
}
.study-tbl-info span:last-child {
  margin-right: 0;
}

/* 과목별 학습 Calendar */
.calendar-area .study-calendar .rc-calendar-calendar-body .rc-calendar-table {
  table-layout: fixed;
}
.calendar-area .study-calendar .calendar-contents {
  height: 100%;
  text-align: left;
  border-bottom: 1px solid #fff;
}
.calendar-area .study-calendar .calendar-contents p {
  padding-left: 8px;
}
.calendar-area .study-calendar .calendar-contents .teach-text {
  margin-left: 5px;
  font-size: 100%;
}
.calendar-area .study-calendar .calendar-contents .calendar-subject-item {
  text-align: center;
}
.calendar-area .study-calendar .calendar-contents .calendar-subject-item span {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
}
.calendar-area .study-calendar .rc-calendar-date.subject {
  padding-right: 0;
  background: none;
  margin: 0 1px;
}
.calendar-area .study-calendar .rc-calendar-date .calendar-attendance {
  background: #ededed;
}
.calendar-area .study-calendar-text {
  color: #999;
  font-size: 14px;
}
.calendar-area .study-calendar-text .status-text span {
  border-radius: 5px;
  display: inline-block;
  text-indent: -999px;
  width: 30px;
  margin: 5px 5px 0 0;
}
.calendar-area .study-calendar-text .attendance span {
  background: #ededed;
  border: 0.5px solid;
}
.calendar-area .study-calendar-text .kor.complete span {
  background: #ee3f77;
}
.calendar-area .study-calendar-text .kor span {
  border: 0.5px solid #ee3f77;
}
.calendar-area .study-calendar-text .math.complete span {
  background: #4588fa;
}
.calendar-area .study-calendar-text .math span {
  border: 0.5px solid #4588fa;
}

.calendar-area .study-calendar-text .eng.complete span {
  background: #2cd29e;
}
.calendar-area .study-calendar-text .eng span {
  border: 0.5px solid #2cd29e;
}
.calendar-area .study-calendar-text .eng-type2.complete span {
  background: #febf34;
}
.calendar-area .study-calendar-text .eng-type2 span {
  border: 0.5px solid #febf34;
}

/* 학습 테이블 */
.tbl-study table {
  width: 100%;
}
.tbl-study th,
.tbl-study td {
  height: 46px;
  padding: 5px 0;
  text-align: center;
  vertical-align: middle;
}
.tbl-study .tbl-head th {
  border-bottom: 2px solid #676969;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.tbl-study .tbl-body {
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
}
.tbl-study .tbl-body .scroll {
  overflow-y: scroll;
  height: 392px;
}
.tbl-study .tbl-body th,
.tbl-study .tbl-body td {
  border-right: 1px solid #e0e0e0;
}
.tbl-study .tbl-body th {
  border-left: 1px solid #e0e0e0;
}
.tbl-study img {
  vertical-align: middle;
}
.tbl-study .teach th,
.tbl-study .teach td {
  background-color: #f5f7fa;
}

.teach-text {
  margin-top: 13px;
  padding-left: 19px;
  font-size: 14px;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_teacher.png)
    no-repeat 0 2px / 13px 15px;
}

/* 선생님 추천 */
.teacher-content .list-style-dot {
  font-size: 14px;
  color: #999;
}
.teacher-content .message-box {
  margin-top: 25px;
}

.content-list {
  margin-top: 25px;
  padding-bottom: 30px;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e0e0e0;
}
.content-list ul {
  margin-left: -16px;
  font-size: 0;
}
.content-list li {
  display: inline-block;
  width: 229px;
  margin: 32px 0 0 16px;
  font-size: 16px;
  vertical-align: top;
}
.content-list li .thumb {
  overflow: hidden;
  position: relative;
  width: 229px;
  height: 129px;
  margin-bottom: 16px;
  border-radius: 6px;
}
.content-list li .thumb img {
  width: 229px;
  height: 129px;
  vertical-align: top;
}
.content-list li .thumb .stamp {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.content-list li .thumb.content-finish:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.content-list li p {
  color: #212121;
}
.content-list li p .tag {
  margin-right: 5px;
}

/* O X 텍스트 아이콘 */
.o,
.x {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.o {
  overflow: hidden;
  width: 16px;
  height: 16px;
  border: 2px solid #dbdbdb;
  border-radius: 100%;
  text-indent: -999em;
}
.x {
  width: 18px;
  height: 18px;
  text-indent: -999em;
  transform: rotate(45deg);
}
.x:after,
.x:before {
  display: block;
  content: '';
  position: absolute;
  background-color: #dadada;
}
.x:after {
  left: 0;
  top: 50%;
  width: 18px;
  height: 2px;
  transform: translateY(-50%);
}
.x:before {
  left: 50%;
  top: 0;
  width: 2px;
  height: 18px;
  transform: translateX(-50%);
}

/* 학습기 설정 */
.learning-setting {
  margin-bottom: 40px;
}
.learning-setting:after {
  display: block;
  content: '';
  clear: both;
}
.learning-setting div {
  float: left;
  box-sizing: border-box;
  width: 352px;
  height: 235px;
  border: 1px solid #e1e1e1;
  padding: 30px 0 0 30px;
  border-radius: 6px;
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_setting.png)
    no-repeat 248px 131px #f5f7fa;
}
.learning-setting div:nth-child(even) {
  float: right;
  background-image: url(https://s.wink.co.kr/pc/images/mykids/icon_setting2.png);
}
.learning-setting div a {
  display: inline-block;
  margin-bottom: 3px;
  padding-right: 20px;
  font-size: 24px;
  color: #f03c78;
  background: url(https://s.wink.co.kr/pc/images/mykids/bu_go_setting.png)
    no-repeat 100% 8px;
}
.learning-setting div p {
  line-height: 24px;
}

/* 학부모 앱 */
.app-wrap > p {
  height: 40px;
  margin-bottom: 30px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 44px;
  text-align: center;
  color: #212121;
  background-color: #f5f7fa;
}
.app-wrap ul {
  margin-left: -32px;
  font-size: 0;
}
.app-wrap li {
  display: inline-block;
  width: 218px;
  margin-left: 32px;
  word-break: keep-all;
  vertical-align: top;
}
.app-wrap li:before {
  content: '';
  display: block;
  width: 100%;
  height: 123px;
  margin-bottom: 23px;
  border-radius: 6px;
}
.app-wrap li strong {
  font-size: 18px;
  color: #212121;
}
.app-wrap li p {
  margin-top: 7px;
  font-size: 14px;
}

.app-wrap li:nth-child(1):before {
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_app.png) no-repeat
    50% 50% #9d9abf;
}
.app-wrap li:nth-child(2):before {
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_app2.png) no-repeat
    50% 50% #eecc7a;
}
.app-wrap li:nth-child(3):before {
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_app4.png) no-repeat
    50% 50% #8ecdf6;
}
.app-wrap li:nth-child(4):before {
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_app5.png) no-repeat
    50% 50% #e8d1aa;
}
.app-wrap li:nth-child(5):before {
  background: url(https://s.wink.co.kr/pc/images/mykids/icon_app5.png) no-repeat
    50% 50% #e8d1aa;
}

/* 콘텐츠 자동 재생 설정 */
.autoplay-content {
  margin-top: 30px;
  border-top: 1px solid #e0e0e0;
}
.autoplay {
  padding: 35px 0 40px;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 0;
}
.autoplay:first-child {
  background: none;
}
.autoplay .head {
  position: relative;
}
.autoplay .head h4 {
  margin-bottom: 5px;
  padding-bottom: 0;
  border-bottom: 0;
  font-size: 24px;
  color: #212121;
}
.autoplay .head h4:after {
  display: none;
}
.autoplay .head .right {
  position: absolute;
  right: 0;
  top: 0;
}

/* 자동설정 시간 요일 설정상태 */
.setting-wrap {
  margin-top: 30px;
}
.setting-wrap h5 {
  margin-bottom: 15px;
  font-size: 18px;
  font-family: 'NanumBarunGothic', sans-serif;
  color: #666;
}
.setting:first-of-type {
  margin-top: 0;
}
.setting {
  position: relative;
  height: 56px;
  margin-top: 8px;
  padding: 0 17px;
  border-radius: 3px;
  line-height: 56px;
  background-color: #f5f5f5;
}
.setting:after {
  display: block;
  content: '';
  clear: both;
}
.setting span {
  float: left;
  color: #212121;
}
.setting ul {
  float: left;
  position: relative;
  margin-left: 14px;
  padding-left: 15px;
}
.setting ul:after {
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
  width: 1px;
  height: 17px;
  background-color: #e0e0e0;
}
.setting li {
  float: left;
  margin-left: 23px;
  color: #bdbdbd;
}
.setting li.on {
  color: #00bbd4;
}
.setting li:first-child {
  margin-left: 0;
}
.setting .right {
  position: absolute;
  right: 14px;
  top: 0;
}
.setting .btn-type {
  min-width: inherit;
  height: 32px;
  margin-left: 3px;
  font-size: 12px;
  line-height: 32px;
  background-color: #fff;
}

/* 시청시간 제한 */
.time-limit {
  margin-top: 30px;
  border-top: 1px solid #e0e0e0;
}
.time-limit .limit {
  padding: 35px 0 40px;
  border-bottom: 1px solid #e0e0e0;
}
.time-limit .limit .head {
  position: relative;
}
.time-limit .limit .head h4 {
  margin-bottom: 5px;
  padding-bottom: 0;
  border-bottom: 0;
  font-size: 24px;
  color: #212121;
}
.time-limit .limit .head h4:after {
  display: none;
}
.time-limit .limit .head .right {
  position: absolute;
  right: 0;
  top: 0;
}
.time-limit .limit .select-limit {
  margin-top: 37px;
}
.time-limit .limit .select-limit li {
  margin-top: 15px;
}
.time-limit .limit .select-limit li:first-child {
  margin-top: 0;
}

/* layer */
.layer .layer-wrap .hgroup {
  padding-bottom: 19px;
  border-bottom: 2px solid #676969;
}
.layer .layer-wrap .hgroup small {
  margin-bottom: 0;
}
.layer .layer-wrap .hgroup small strong {
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

/* 자동 설정 */
.layer .layer-wrap.small .layer-setting {
  text-align: left;
  padding: 0 32px;
}

.layer-setting .content-select {
  width: 416px;
  margin-top: -8px;
}
.layer-setting .content-select:after {
  display: block;
  content: '';
  clear: both;
}
.layer-setting .content-select li {
  float: left;
  margin-top: 8px;
}
.layer-setting .content-select li:nth-child(even) {
  float: right;
}
.layer-setting .content-select li label {
  width: 202px;
  height: 38px;
  margin: 0;
  padding: 0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  color: #666;
  background-color: #fff;
}
.layer-setting .content-select li label:before {
  display: none;
}
.layer-setting .content-select li input:checked + label {
  color: #fff;
  background-color: #00bbd4;
}

.layer-setting fieldset.time {
  padding-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;
}
.layer-setting fieldset.time .select-mode {
  width: 100%;
}

/* 시간 요일 설정 */
.layer-setting .time-setting-wrap {
  width: 416px;
  margin: 23px 0 30px;
  padding: 24px 0 32px;
  border: 1px solid #e0e0e0;
  border-width: 1px 0;
}
.layer-setting .time-setting-wrap .btn-area {
  margin-top: 24px;
}
.layer-setting .time-setting-wrap .btn-area .btn-type2.confirm {
  min-width: 80px;
  height: 40px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 42px;
}
.layer-setting .time-setting {
  margin-top: 16px;
}
.layer-setting .time-setting:first-of-type {
  margin-top: 0;
}
.layer-setting .time-setting .btn-area {
  margin-top: 8px;
  text-align: right;
}
.layer-setting .time-setting .btn-type {
  clear: both;
  min-width: 56px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
}

.layer-setting .select-item:after {
  display: block;
  content: '';
  clear: both;
}
.layer-setting .select-item .select-mode {
  float: right;
  width: 128px;
}
.layer-setting .select-item .select-day {
  float: left;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.layer-setting .select-item .select-day li {
  float: left;
  border-left: 1px solid #e0e0e0;
}
.layer-setting .select-item .select-day li:first-child {
  border-left: 0 none;
}
.layer-setting .select-item .select-day li label {
  width: 39px;
  height: 38px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
}
.layer-setting .select-item .select-day li label:before {
  display: none;
}
.layer-setting .select-item .select-day li input:checked + label {
  color: #fff;
  background-color: #00bbd4;
}

/* 자동설정 설정 내역 */
.layer-setting .setting-info h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #212121;
}
.layer-setting .setting-list {
  position: relative;
}
.layer-setting .setting-list:after {
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  background: url(https://s.wink.co.kr/pc/images/customer/bg_gradaition_white.png)
    repeat-x 0 100%;
}
.layer-setting .setting-list h4 {
  margin-bottom: 15px;
  padding-bottom: 0;
  border-bottom: 0 none;
  font-size: 16px;
  color: #666;
}
.layer-setting .setting-list h4:after {
  display: none;
}
.layer-setting .setting-list .section {
  width: 416px;
  margin-top: 0;
  padding: 22px 0 16px;
  border-bottom: 1px solid #e0e0e0;
}
.layer-setting .setting-list .section:first-child {
  padding-top: 0;
}

/*학부모 TIP*/
.layer-tip .head,
.layer-tip .img-box {
  width: 560px;
}

/**************************************************************************** mypage ********************************************************************************************/

/* common */

.btn-type2.king:before {
  width: 15px;
  height: 14px;
  margin: 0 8px 2px 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_king.png)
    no-repeat;
}

/* 타이들 */
.hgroup .go {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -17px;
  background-position: 100% 6px;
}
.hgroup .more {
  padding-right: 22px;
  color: #212121;
  background: url(https://s.wink.co.kr/pc/images/mypage/bu_more.png) no-repeat
    100% 4px;
}

/* 텍스트 버튼 */
.go.red {
  color: #f03c78;
  background-image: url(https://s.wink.co.kr/pc/images/mypage/bu_go_red.png);
}

/* 회원정보 비밀번호 입력 영역 */
.message-box.password-box p {
  margin-bottom: 30px;
  padding-top: 100px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_lock.png)
    no-repeat 50% 0;
}
.message-box.password-box input {
  width: 240px;
}
.message-box.add-kid-box {
  padding-top: 185px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_add.png)
    no-repeat 50% 70px;
}
.message-box.coupon {
  padding: 150px 0;
}
.message-box.coupon p {
  padding-top: 85px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_coupon.png)
    no-repeat 50% 0;
}
.message-box.study-box {
  padding-top: 180px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_document.png)
    no-repeat 50% 70px;
}
.message-box.message-pay {
  padding-top: 127px;
  border-bottom: 1px solid #e9e9e9;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_pay.png)
    50% 28px no-repeat;
}
.message-box .notice {
  display: inline-block;
  margin-top: 45px;
}
.message-box .more-area {
  margin-top: 20px;
}
.message-box.no-data2 {
  padding: 240px 0 140px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_document.png)
    no-repeat 50% 130px;
}
.message-box.shopping {
  margin-top: -32px;
  border-top-width: 0;
}
.message-box.shopping p {
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_transport.png)
    50% 0 no-repeat;
}
.message-box.as p {
  padding-top: 114px;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_as.png)
    no-repeat 50% 0;
}

/* 회원정보 */
.phone-number .btn-type {
  margin-left: 10px;
}

/* 배송지 관리 */
.address-setting {
  padding-top: 24px;
  border-top: 2px solid #676969;
}

/* 테그 진행 상태 */
.tag {
  background-color: #fff;
}
.tag.pay {
  color: #00a0e0;
  background-color: #fff;
}
.tag.ing {
  color: #f15083;
  background-color: #fff;
}
.tag.finish {
  color: #999;
  background-color: #f0f0f0;
}
.tag.integrated-delivery {
  border-color: rgba(0, 160, 224, 0.24);
  color: #00a0e0;
  background-color: rgba(0, 160, 224, 0.08);
}
.tag-group {
  display: flex;
  -moz-column-gap: 3px;
       column-gap: 3px;
}

/* 버튼 */
.btn-type2.add-kid:before {
  width: 20px;
  height: 18px;
  margin: -2px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_addkid.png)
    no-repeat 0 0;
}
.btn-type2.binoculars {
  color: #f03c78;
  background-color: #fff;
}
.btn-type2.binoculars:hover {
  border-color: #f03c78;
}
.btn-type2.binoculars:before {
  width: 19px;
  height: 18px;
  margin: -2px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_binoculars.png)
    no-repeat 0 0;
}
.btn-type2.book:before {
  width: 19px;
  height: 18px;
  margin: -2px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_book.png)
    no-repeat 0 0;
}

/* 비밀번호 변경 */
.layer-pw-reverse fieldset.password input {
  width: 410px;
}

.layer-wrap.large fieldset.form-message .select-mode {
  width: 406px;
}
fieldset.form-message .select-mode {
  width: 304px;
}
fieldset.form-bank-select .select-mode {
  width: 195px;
}
fieldset.form-bank-num input {
  width: 290px;
  margin-right: 5px;
}

/* tbl list */
.tbl-list .name {
  text-decoration: underline;
  color: #08bbd5;
}

/* //common */

section .hgroup + .message-box {
  border-top: 0 none;
}

/* 내정보 */
.my-info .hgroup .btn-type2 {
  position: static;
  height: 40px;
  line-height: 40px;
  margin-left: 11px;
}
.my-info .address {
  font-size: 14px;
  color: #666;
}
.my-info .address em {
  color: #999;
}
.my-info .address span {
  margin-left: 8px;
  padding-left: 10px;
  background: url(https://s.wink.co.kr/pc/images/mypage/bu_bar.gif) no-repeat 0
    50%;
}
.my-info .user-info-title {
  width: 90%;
  word-break: break-all;
}

/* 내정보 학습 정보 */
.info-state {
  margin-top: 30px;
  padding: 23px 0 26px;
  border-radius: 5px;
  background-color: #f7f7f7;
}
.info-state ul {
  text-align: center;
  font-size: 0;
}
.info-state li {
  display: inline-block;
  position: relative;
  width: 25%;
  font-size: 14px;
}
.info-state li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 80px;
  background-color: #ebebeb;
}
.info-state li:first-child:before {
  display: none;
}
.info-state li a {
  display: inline-block;
  position: relative;
  padding-top: 66px;
  color: #666;
}
.info-state li a:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 100%;
}
.info-state li:nth-child(1) a:before {
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_write.png)
    no-repeat 50% 50% #ffc200;
}
.info-state li:nth-child(2) a:before {
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_day.png) no-repeat
    50% 50% #4385ff;
}
.info-state li:nth-child(3) a:before {
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_document.png)
    no-repeat 50% 50% #00bbd4;
}
.info-state li:nth-child(4) a:before {
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_transport.png)
    no-repeat 50% 50% #a186be;
}
.info-state li a em {
  color: #f03c78;
}

/* drag item */
/*.dragitem-wrap {padding: 32px 0; border-top: 2px solid #676969; border-bottom: 1px solid #e0e0e0;}
.dragitem-wrap.no-line {border-top: 0 none;}
.dragitem {margin: -16px 0 0 -16px;}
.dragitem:after {display: block; content: ""; clear: both;}
.dragitem .item.over {border: 1px dashed #e0e0e0;}
.dragitem .item.move {border: 1px solid #f03c78;}
.dragitem .item-add,
.dragitem .item {float: left; position: relative; box-sizing: border-box; width: 168px; height: 168px; margin: 16px 0 0 16px; border: 1px solid #e0e0e0; border-radius: 6px; text-align: center; background-color: #fff; cursor: pointer;}
.dragitem .item:hover {border: 2px solid #f03c78; box-sizing: border-box;}
.dragitem .item .inner {display: table-cell; width: 168px; height: 168px; vertical-align: middle;}
.dragitem .item:hover .inner {width: 166px; height: 166px;}

.dragitem .item .name {font-size: 24px; color: #212121;}
.dragitem .item .btn-reverse {display: inline-block; width: 21px; height: 21px; margin: -6px 0 0 5px; text-indent: -999em; vertical-align: middle; background: url(https://s.wink.co.kr/pc/images/mypage/icon_reverse.png) no-repeat 0 0;}
.dragitem .item small {display: block;}
.dragitem .item span {font-size: 14px; padding-left: 20px; background: url(https://s.wink.co.kr/pc/images/mypage/icon_candle.png) no-repeat 0 50%;}
.dragitem .item p {margin-top: 10px; font-size: 14px; color: #bdbdbd;}
.dragitem .item .tag-area {position: relative; margin-top: 4px;}
.dragitem .item .bubble-study {display: none; position: absolute; left: -16px; top: 30px; box-sizing: border-box; width: 195px; padding: 20px 14px 15px; border: 1px solid #dedede; border-radius: 6px; box-shadow: 0 0 5px #efefef; font-size: 14px; text-align: left; background-color: #fff;}
.dragitem .item .bubble-study:after {content: ""; position: absolute; left: 50%; top: -10px; width: 19px; height: 11px; margin-left: -10px; background: url(https://s.wink.co.kr/pc/images/wink/img_bubble_top.png) no-repeat 0 0;}
.dragitem .item .bubble-study strong {color: #212121;}
.dragitem .item .bubble-study p {margin-bottom: 5px; color: #666;}
.dragitem .item .bubble-study span {padding-left: 0; color: #bdbdbd; background: none;}
.dragitem .item:hover .bubble-study {display: block;}

.dragitem .item .btn-delete {display: none; position: absolute; right: -12px; top: -12px; width: 33px; height: 33px; text-indent: -999em; background: url(https://s.wink.co.kr/pc/images/mypage/btn_delete.png) no-repeat 0 0;}

.dragitem .item-add {background-color: #f7f7f7; color: #666;}
.dragitem .item-add span {display: inline-block; position: relative; margin-top: 55px; padding-top: 47px; padding-left: 0; background: none;}
.dragitem .item-add span:before,
.dragitem .item-add span:after {content: ""; position: absolute; background-color: #ccc;}
.dragitem .item-add span:before {left: 50%; top: 0; width: 1px; height: 31px;}
.dragitem .item-add span:after {left: 50%; top: 15px; width: 31px; height: 1px; margin-left: -15px;}

.dragitem .item.not-move {background-color: #f5f5f5;}
.dragitem .item.not-move .name {color: #999;}
.dragitem .item.not-move:hover .btn-delete {display: block;}

.dragitem .item.ui-sortable-helper {border: 2px solid #f03c78; opacity: .8; background-color: #fff;}
.dragitem .item.ui-sortable-helper .btn-delete {display: none;}
.dragitem .item.ui-sortable-placeholder {visibility: visible !important; border: 1px solid transparent; background: url(https://s.wink.co.kr/pc/images/mypage/bg_dragitem.png) no-repeat 0 0 / cover;}*/

/* 경고 */
.warning-box {
  position: relative;
  margin-top: 10px;
  padding: 16px 110px 16px 16px;
  background-color: #f7f7f7;
}
.warning-box p.warning {
  padding-left: 26px;
  font-size: 14px;
  color: #999;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat 0 0;
}
.warning-box p.warning span {
  color: #f03c78;
}
.warning-box .btn-type {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -20px;
}

/* 결제내역 */
.payment-box li {
  position: relative;
  padding: 32px 215px 32px 16px;
  border-bottom: 1px solid #e0e0e0;
}
.payment-box li p {
  font-size: 18px;
  color: #212121;
}
.payment-box li p a {
  color: #08bbd5;
}
.payment-box li p a:hover {
  text-decoration: underline;
}
.payment-box li .card {
  position: absolute;
  right: 16px;
  top: 32px;
  text-align: right;
  font-size: 18px;
  color: #212121;
}
.payment-box li .card em {
  color: #f03c78;
}
.payment-box li .card .num {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: #999;
}

/* 상품 내역 - 배송,A/S */
.use-list li {
  position: relative;
  padding: 32px 110px 32px 16px;
  border-bottom: 1px solid #e0e0e0;
}
.use-list li p {
  margin-top: 5px;
  font-size: 18px;
  color: #212121;
}
.use-list li p a {
  color: #212121;
}
.use-list li p a:hover {
  text-decoration: underline;
}
.use-list li p .code-num {
  text-decoration: underline;
  color: #08bbd5;
}
.use-list li .btn-type {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -20px;
}

.panel-body .delivery-btn-box a {
  float: right;
  margin-top: 10px;
}
.panel-body .default-selected {
  text-align: center;
}

/* 날짜,이름 */
.date-area {
  margin-top: 5px;
  font-size: 14px;
}
.date-area span:first-child {
  margin-left: 0;
  padding-left: 0;
  background: none;
}
.date-area span:first-child:after {
  display: none;
}
.date-area span {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
}
.date-area span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1px;
  height: 10px;
  background-color: #e0e0e0;
}

/* 가족 아이콘 */
.family-icon {
  position: relative;
  text-align: center;
}
.family-icon .name {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 1;
  min-width: 48px;
  height: 24px;
  padding: 0 6px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  transform: translateX(-50%);
}

.family-icon.mother .name {
  background-color: #ff5252;
}
.family-icon.father .name {
  background-color: #ff7e27;
}
.family-icon.grandma .name {
  background-color: #ffc624;
}
.family-icon.grandfa .name {
  background-color: #9bdbff;
}
.family-icon.etc .name {
  background-color: #ae88c5;
}

/* 아이정보 */
.kid-info .list-style-dot li {
  font-size: 14px;
  line-height: 24px;
}
.kid-info .dragitem-wrap {
  margin-top: 30px;
}

/* 쿠폰 등록 */
.coupon-add {
  margin-bottom: 30px;
  padding: 32px;
  border-radius: 8px;
  text-align: center;
  background: #f5f5f5;
}
.coupon-add label {
  font-size: 14px;
  font-weight: bold;
}
.coupon-add input {
  width: 350px;
  margin: 0 5px;
}
.coupon-add .btn-type {
  color: #fff;
  background-color: #95989f;
}

/* 등록한 쿠폰 리스트 */
.coupon-list {
  border-top: 2px solid #676969;
}
.coupon-list li {
  position: relative;
  padding: 30px 0 30px 255px;
  border-bottom: 1px solid #e0e0e0;
}
.coupon-list .coupon {
  position: absolute;
  left: 16px;
  top: 30px;
  width: 209px;
  height: 108px;
  box-sizing: border-box;
  padding: 28px 0 24px;
  text-align: center;
  background: url(https://s.wink.co.kr/pc/images/mypage/bg_coupon.png) no-repeat
    0 0;
}
.coupon-list .coupon .num {
  font-size: 40px;
  line-height: 40px;
  color: #08bbd5;
}
.coupon-list .coupon small {
  font-size: 18px;
  line-height: 18px;
}
.coupon-list .coupon .txt {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: bold;
  color: #c2c2c2;
}
.coupon-list .coupon-info {
  position: relative;
}
.coupon-list .coupon-info dl:after {
  display: block;
  content: '';
  clear: both;
}
.coupon-list .coupon-info dt,
.coupon-list .coupon-info dd {
  float: left;
  margin-top: 5px;
}
.coupon-list .coupon-info dt:first-child,
.coupon-list .coupon-info dt:first-child + dd {
  margin-top: 0;
}
.coupon-list .coupon-info dt {
  clear: both;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.coupon-list .coupon-info .state {
  position: absolute;
  right: 16px;
  top: 0;
  color: #f03c78;
}

.coupon-list .use-none .coupon .num {
  color: #bdbdbd;
}
.coupon-list .use-none .coupon .txt {
  color: #e0e0e0;
}
.coupon-list .use-none .coupon-info dt,
.coupon-list .use-none .coupon-info dd {
  color: #bdbdbd;
}
.coupon-list .use-none .coupon-info .state {
  color: #999;
}

/* 탈퇴 사유 */
.out-ex {
  padding: 32px 16px;
  border-top: 2px solid #676969;
  border-bottom: 1px solid #e0e0e0;
}
.out-ex ul {
  margin-top: -13px;
}
.out-ex ul:after {
  display: block;
  content: '';
  clear: both;
}
.out-ex li {
  float: left;
  width: 229px;
  margin-top: 13px;
}
.out-text {
  margin-top: 20px;
}
.out-text label {
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.out-text textarea {
  min-height: initial;
  height: 100px;
  margin-top: 10px;
}

.btn-type3.btn-out.border {
  border: 1px solid #ccc;
  color: #212121;
  background-color: #fff;
}
.btn-type3.btn-out.border:hover {
  border-color: #212121;
}
.btn-type3.btn-out.text-two-line {
  padding-top: 8px;
  line-height: 26px;
}

/* 체크박스 확인 리스트 */
/*.check-list {border-top: 2px solid #676969;}
.check-list li {position: relative; padding: 24px 60px 24px 16px; border-bottom: 1px solid #e0e0e0;}
.check-list li label {position: static; margin: 0; padding: 0; color: #212121;}
.check-list li label:before {left: auto; right: 16px; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);}

/* 결제 내역 */
/*.panel-list li {margin-top: 15px;}
.panel-list li:first-child {margin-top: 0;}

.panel-box {border: 1px solid #e0e0e0; border-radius: 6px;}
.panel-head {position: relative; padding: 17px 15px 12px; border-bottom: 1px solid #e0e0e0; background-color: #f0f0f0;}
.panel-head:after {display: block; content: ""; clear: both;}
.panel-head.no-bg {color: #212121; background-color: transparent;}
.panel-head.center {text-align: center;}
.panel-head .tag {float: right; margin-top: -4px;}
.panel-head .bar {position: relative; margin-left: 15px; padding-left: 15px;}
.panel-head .bar:after {content: ""; position: absolute; left: 0; top: 3px; width: 1px; height: 12px; background-color: #ccc;}
.panel-head .btn-control {position: absolute; right: 15px; top: 15px;}
.panel-head .btn-control a {display: inline-block; position: relative; padding-left: 10px; margin-left: 7px; font-size: 14px; color: #666;}
.panel-head .btn-control a:after {content: ""; position: absolute; left: 0; top: 6px; width: 1px; height: 10px; background-color: #bdbdbd;}
.panel-head .btn-control a:first-child:after {display: none;}
.panel-body {position: relative; padding: 25px 16px;}
.panel-body .name {text-decoration: underline; color: #08bbd5;}
.panel-body span {color: #999;}
.panel-body em {color: #f03c78;}
.panel-body small {font-size: 14px; color: #999;}
.panel-body .ex-text {position: absolute; right: 0; bottom: 1px;}
.panel-body .btn-type {border: 0 none; color: #fff; background-color: #95989f;}
.panel-body .radio-list-col li {position: relative; margin-top: 15px;}
.panel-body .radio-list-col li:first-child {margin-top: 0;}
.panel-body .radio-list-col li .go {position: absolute; right: 0; top: 2px; background-position: 100% 5px;}

.panel-body .tbl-row {border-top: 0 none;}
.panel-body .tbl-row table {border-bottom: 0 none;}
.panel-body .tbl-row th,
.panel-body .tbl-row td,
.panel-body .tbl-row th:first-child,
.panel-body .tbl-row th:first-child + td,
.panel-body .tbl-row tr:last-child th,
.panel-body .tbl-row tr:last-child td {padding: 6px 0 5px;}

.panel-foot {padding: 17px 25px 20px; border-radius: 0 0 6px 6px; background-color: #f5f7fa;}
.panel-foot .list-style-dot {margin-top: 8px;}
.panel-foot .list-style-dot li {font-size: 14px;}

.end .panel-head  {color: #999;}
.end .panel-body .tbl-row th,
.end .panel-body .tbl-row td,
.end .panel-body .tbl-row span {color: #bdbdbd;}
.end .panel-body .tbl-row em {color: #bdbdbd;}
.end .panel-body .tbl-row small {color: #bdbdbd;}
.end .panel-body .tbl-row .name {color: #08bbd5;}*/

/* 배송 내역 상세 */
.delivery-detail .delivery-detail-hgroup {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  justify-content: flex-end;
}

/* 결제 내역 상세 */
.detail-area .hgroup {
  padding-bottom: 10px;
}
.detail-area .hgroup h5 {
  margin-bottom: 0;
}
.detail-area .hgroup .right {
  position: absolute;
  right: 0;
  bottom: 13px;
}
.detail-area .hgroup .date {
  font-size: 16px;
  color: #666;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_calendar.png)
    no-repeat 0 0;
}

.detail-area .hgroup .btn-type.print {
  margin-right: 10px;
  position: static;
}

.detail-area .hgroup .btn-type.change-card {
  position: static;
}

.layer-wrap.print {
  width: 1100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  margin-top: 0 !important;
}

.layer-wrap.print .layer-print small {
  margin-left: 40px;
  font-size: 20px;
  color: #746262;
}

.layer-wrap.print .layer-print .layer-scroll {
  margin: 20px 35px;
}

.layer-wrap.print .layer-print .layer-scroll .scroll {
  padding: 20px 32px 20px 0;
  height: 250px;
}

.layer-wrap.print .layer-print .layer-scroll .scroll .print-table {
  border: 1px solid #d2d2d2;
}

.layer-wrap.print .layer-print .layer-scroll .scroll .print-table > thead {
  background-color: #e0e0e0;
}

.layer-wrap.print .layer-print .layer-scroll .scroll .print-table th,
.layer-wrap.print .layer-print .layer-scroll .scroll .print-table td {
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
}

.layer-wrap.print .layer-print .layer-scroll .scroll .print-table th:last-child,
.layer-wrap.print
  .layer-print
  .layer-scroll
  .scroll
  .print-table
  td:last-child {
  border-right: none;
}

.layer-wrap.print
  .layer-print
  .layer-scroll
  .scroll
  .print-table
  > thead
  th:nth-child(1),
.layer-wrap.print
  .layer-print
  .layer-scroll
  .scroll
  .print-table
  > tbody
  td:nth-child(1) {
  width: 80px;
  padding-left: 35px;
}

.layer-wrap.print .layer-print .bottom-area {
  border-top: 2px solid #676969;
  margin: 40px 35px 0;
  padding-top: 20px;
}

.layer-wrap.print .layer-print .bottom-area > small {
  margin: 0;
}

.layer-wrap.print .layer-print .bottom-area .print-radio-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.layer-wrap.print .layer-print .bottom-area .print-radio-group label {
  color: #212121;
  font-size: 18px;
  margin-bottom: 15px;
}

.layer-wrap.print .layer-print .bottom-area .print-radio-group em {
  color: #ff0000;
}

.layer-wrap.print .layer-print .bottom-area .warning-text,
.layer-wrap.preview .notice-area {
  margin-top: 30px;
  font-size: 17px;
  padding: 20px;
  background-color: #e0e0e0;
  font-weight: bold;
}

.layer-wrap.preview .layer-print small {
  font-size: 16px;
}

.layer-wrap.print .layer-print .bottom-area .preview-btn-area {
  text-align: center;
  margin-top: 50px;
}

.layer-wrap.print .layer-print .bottom-area .preview-btn-area .preview-button {
  border-radius: 15px;
  padding: 20px 25px;
  background-color: #f03c78;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}

.layer-wrap.print
  .layer-print
  .bottom-area
  .preview-btn-area
  .preview-button:disabled {
  background-color: #c0c0c0;
}

.layer-wrap.print .layer-scroll:after {
  height: 30px;
}

.layer-wrap.preview {
  width: 900px;
  padding: 20px 25px;
}

.layer-wrap.preview .layer-scroll {
  border-bottom: 2px solid #676969;
}

.layer-wrap.preview .scroll {
  padding: 10px 120px 5px;
  margin-top: 0;
}

.layer-wrap.preview .scroll .section {
  padding: 15px 20px;
  position: relative;
  min-height: 725px;
}

.layer-wrap.preview .scroll .section .print-wrap {
  border: 1px solid #000;
}

.layer-wrap.preview .scroll .section .title {
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .wrapper {
  border-top: 1px solid #000;
}

.layer-wrap.preview .scroll .section .wrapper .sub-title {
  font-size: 12px;
  border-bottom: 1px solid #000;
  padding: 2px 0 2px 10px;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .wrapper .contents-area > *:last-child {
  border-bottom: none;
}

.layer-wrap.preview .scroll .section .wrapper .user-info,
.layer-wrap.preview .scroll .section .wrapper .product-info,
.layer-wrap.preview .scroll .section .wrapper .payment-info {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  text-align: center;
  border-bottom: 1px solid #000;
}

.layer-wrap.preview .scroll .section .wrapper .row {
  display: flex;
  width: 100%;
}

.layer-wrap.preview .scroll .section .wrapper .row-title {
  font-size: 10px;
  width: 30%;
  border-right: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .wrapper .row-contents {
  flex: 2 1 auto;
  font-size: 10px;
  width: 70%;
  border-right: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .wrapper .row-contents.col {
  flex-direction: column;
}

.layer-wrap.preview .scroll .section .wrapper .single .row-title {
  width: 15%;
}

.layer-wrap.preview .scroll .section .wrapper .single .row-contents {
  width: 85%;
}

.layer-wrap.preview .scroll .section .wrapper .row-contents:last-child {
  border: none;
}

.layer-wrap.preview
  .scroll
  .section
  .wrapper
  .payment-info
  .row
  .item-2:nth-child(1) {
  width: 15%;
}
.layer-wrap.preview
  .scroll
  .section
  .wrapper
  .payment-info
  .row
  .item-2:nth-child(2) {
  width: 85%;
}

.layer-wrap.preview
  .scroll
  .section
  .wrapper
  .payment-info
  .row
  .item-3:nth-child(1) {
  width: 15%;
}
.layer-wrap.preview
  .scroll
  .section
  .wrapper
  .payment-info
  .row
  .item-3:nth-child(2) {
  width: 30%;
}
.layer-wrap.preview
  .scroll
  .section
  .wrapper
  .payment-info
  .row
  .item-3:nth-child(3) {
  width: 55%;
}

.layer-wrap.preview .scroll .section .warning-text {
  font-size: 10px;
  text-align: center;
  color: red;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .footer-area {
}

.layer-wrap.preview .scroll .section .footer-area > p {
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .footer-area > p:last-child {
  margin-bottom: 0;
}

.layer-wrap.preview .scroll .section .footer-area .create-date {
  text-align: right;
  margin-right: 130px;
  margin-bottom: 5px;
}

.layer-wrap.preview .scroll .section .footer-area .create-date > span {
  font-family: 'NanumBarunGothic-Bold', sans-serif;
  font-size: 12px;
  margin-left: 50px;
}

.layer-wrap.preview .scroll .section .footer-area .company {
  text-align: right;
  margin-right: 130px;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.preview .scroll .section .footer-area .company .company-stamp {
  margin-left: 130px;
  background: url(https://us.wink.co.kr/pc/renewalV3/common/danbi_stamp.png)
    no-repeat;
  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: contain;
  position: relative;
  padding-top: 10px;
}

.layer-wrap.preview .next-page {
  font-size: 50px;
  position: absolute;
  top: 50%;
  right: 5%;
}

.layer-wrap.preview .prev-page {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 5%;
}

.layer-wrap.preview .scroll .extra {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 97%;
  position: absolute;
  bottom: 0;
  font-size: 10px;
}

.layer-wrap.preview .preview-btn-area {
  margin-top: 15px;
  text-align: center;
}

.layer-wrap.preview .preview-btn-area > button {
  border-radius: 15px;
  padding: 10px 25px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
}

.layer-wrap.preview .preview-btn-area .prev-button {
  background-color: #c0c0c0;
}

.layer-wrap.preview .preview-btn-area .preview-button {
  background-color: #f03c78;
  margin-left: 10px;
}

.layer-wrap.pink-confirm .body > h2 {
  padding-left: 40px;
  text-align: left;
  margin-bottom: 50px;
}

.layer-wrap.pink-confirm .body > p {
  margin-bottom: 50px;
}

/* A/S 신청 내역 */
table + .as-info {
  border-top: 0 none;
}
.as-info {
  padding: 30px 16px;
  border: 1px solid #e0e0e0;
  border-width: 1px 0;
  background-color: #f5f5f5;
}
.as-info dt:first-child {
  margin-top: 0;
}
.as-info dt {
  margin-top: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.as-info dd {
  margin-top: 4px;
}

/* 윙크 추천하기 팝업 */
.layer-sns p {
  margin-top: 25px;
}
.layer-sns p strong {
  font-weight: bold;
}
.layer-sns fieldset.url input {
  width: 170px;
  margin: 0 5px 0 10px;
}
.layer-sns .sns-wrap {
  margin: 20px 0 30px;
}

/* sns */
.sns-wrap .sns {
  display: inline-block;
  position: relative;
  min-width: 64px;
  margin: 0 10px;
  padding-top: 75px;
  font-size: 14px;
  color: #666;
}
.sns-wrap .sns:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  border-radius: 100%;
}
.sns-wrap .sns.facebook:after {
  background: url(https://s.wink.co.kr/pc/images/mypage/img_sns_facebook.png)
    no-repeat 50% 50% #3b579d;
}
.sns-wrap .sns.kakao:after {
  background: url(https://s.wink.co.kr/pc/images/mypage/img_sns_kakao.png)
    no-repeat 50% 50% #fcc500;
}
.sns-wrap .sns.twitter:after {
  background: url(https://s.wink.co.kr/pc/images/mypage/img_sns_twitter.png)
    no-repeat 50% 50% #3dc1f2;
}
.sns-wrap .sns.mail:after {
  background: url(https://s.wink.co.kr/pc/images/mypage/img_sns_mail.png)
    no-repeat 50% 50% #3392ff;
}

/* 정기결제 선택 */
.auto-pay > p {
  margin-bottom: 16px;
}
.auto-pay .auto-pay-select {
  padding: 16px;
  border-top: 2px solid #676969;
}
.auto-pay .auto-pay-select strong {
  margin-right: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.auto-pay .auto-pay-select label {
  margin-right: 32px;
}
.auto-pay .auto-pay-info {
  padding: 20px 16px;
  background-color: #f5f5f5;
}
.auto-pay .auto-pay-info li {
  position: relative;
  padding-left: 9px;
  font-size: 14px;
  line-height: 24px;
}
.auto-pay .auto-pay-info li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 2px;
  height: 2px;
  background-color: #888;
}
.auto-pay .check-area {
  margin-top: 12px;
}
.auto-pay .check-area label {
  font-size: 14px;
}
.auto-pay .check-area span {
  color: #212121;
}
.auto-pay .check-area span em {
  color: #f03c78;
}

.auto-pay .auto-pay-info .underline {
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

/* 특별회원 신청정보 */
.layer-specail-info .section,
.special-bank {
  width: 720px;
}
.special-bank .special-info {
  position: relative;
  margin-bottom: 30px;
}
.special-bank .special-info dl {
  padding-left: 16px;
}
.special-bank .special-info dl:after {
  display: block;
  content: '';
  clear: both;
}
.special-bank .special-info dt,
.special-bank .special-info dd {
  line-height: 25px;
}
.special-bank .special-info dt:first-child {
  margin-left: 0;
}
.special-bank .special-info dt {
  float: left;
  margin-left: 36px;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.special-bank .special-info dd {
  float: left;
  margin-left: 16px;
}
.special-bank .special-info .go {
  position: absolute;
  right: 0;
  top: 3px;
  line-height: 1.2;
}
.special-bank .tbl-row {
  padding: 17px 0 19px;
  border: 0 none;
  border-radius: 6px;
  background-color: #f5f5f5;
}
.special-bank .tbl-row table {
  border: 0 none;
}
.special-bank .tbl-row table th,
.special-bank .tbl-row table td {
  padding: 0;
  vertical-align: middle;
}
.special-bank .tbl-row table th:first-child {
  background: none;
}
.special-bank .tbl-row table th {
  padding-left: 16px;
  background: url(https://s.wink.co.kr/pc/images/mypage/bu_bar2.gif) no-repeat 0
    6px;
}
.special-bank .tbl-row table td {
  padding-right: 16px;
  font-size: 18px;
  color: #212121;
  text-align: right;
}
.special-bank .tbl-row table td span {
  color: #f03c78;
}
.special-bank .notice {
  margin-top: 16px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice2.png) no-repeat 0 0;
}

/***************************************************************************myteacher****************************************************************************************************/

/* Common */
.message-box.recommend {
  border-top-width: 0;
  padding-top: 123px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/img_message.gif) 50%
    16px no-repeat;
}
.message-box.not-taken {
  border-top-width: 0;
  padding-top: 123px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/img_message2.gif) 50%
    16px no-repeat;
}

/* 우리선생님 */
.my-teacher figure:after {
  content: '';
  display: block;
  clear: both;
}
.my-teacher figure img {
  float: left;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
}
.my-teacher figure.default img {
  border: 1px solid #e0e0e0;
}
.my-teacher figcaption {
  float: left;
  margin-left: 32px;
  padding-top: 22px;
}
.my-teacher figcaption strong {
  display: block;
  margin-bottom: 9px;
  color: #212121;
  font-size: 24px;
}
.my-teacher figcaption em.tag {
  height: 32px;
  margin: 0 5px 2px 10px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
}
.my-teacher figcaption em.tag:first-of-type {
  margin-left: 0;
}
.my-teacher figcaption b {
  color: #212121;
}
.my-teacher li {
  margin-top: 8px;
  padding: 20px 32px;
  border-radius: 8px;
  background: #f5f5f5;
  line-height: 1.6;
}
.my-teacher li:first-child {
  margin-top: 20px;
  position: relative;
}
.my-teacher li:first-child:before {
  content: '';
  position: absolute;
  top: -9px;
  left: 54px;
  display: block;
  width: 19px;
  height: 9px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/bg_edge.png)
    no-repeat;
}
.my-teacher li span.date {
  display: table;
  margin-top: 12px;
}
.my-teacher li span.text-overflow {
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  max-height: 72px;
}

.teacher-option {
  margin: 32px 0 -38px;
}
.teacher-option li {
  position: relative;
  padding: 35px 0 34px 0;
  background: url('https://s.wink.co.kr/pc/images/myteacher/bg_dot.png')
    repeat-x;
}
.teacher-option li b {
  display: block;
  margin-bottom: 6px;
  color: #212121;
  font-size: 24px;
}
.teacher-option li a.btn-type2 {
  position: absolute;
  bottom: 40px;
  right: 0;
}

.teacher-prev li {
  padding: 32px 0 2px 0;
  background: url('https://s.wink.co.kr/pc/images/myteacher/bg_dot.png') bottom
    repeat-x;
}
.teacher-prev li:only-child {
  padding-bottom: 0;
  background: none;
}
.teacher-prev li:first-child {
  padding-top: 0;
}
.teacher-prev li figure:after {
  content: '';
  display: block;
  clear: both;
}
.teacher-prev li figure img {
  float: left;
  width: 88px;
  height: 88px;
  border-radius: 50%;
}
.teacher-prev li figure.default img {
  border: 1px solid #e0e0e0;
}
.teacher-prev li figcaption {
  float: left;
  margin: 0 0 26px 32px;
}
.teacher-prev li figcaption strong {
  display: block;
  margin-bottom: 2px;
  color: #212121;
  font-size: 24px;
}
.teacher-prev li figcaption strong em.tag {
  margin: 0 0 6px 2px;
}
.teacher-prev li figcaption span.lesson:before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 14px;
  margin: 0px 15px 2px 10px;
  background: #c8c8c8;
  vertical-align: middle;
}
.teacher-prev li figcaption span.lesson:first-of-type:before {
  display: none;
}
.teacher-prev li figcaption a.go {
  display: table;
  margin-top: 14px;
  background-position: 100% 6px;
}

figure.recommend {
  position: relative;
}
figure.recommend:after {
  content: '';
  display: block;
  clear: both;
}
figure.recommend img {
  float: left;
  width: 128px;
  height: 128px;
  border-radius: 8px;
}
figure.recommend figcaption {
  float: left;
  width: 560px;
  margin-left: 32px;
}
figure.recommend figcaption strong {
  display: block;
  margin-bottom: 6px;
  color: #212121;
  font-size: 24px;
}
figure.recommend figcaption strong em.tag {
  height: 32px;
  margin: 0 0 2px -2px;
  border-radius: 16px;
  line-height: 32px;
}
figure.recommend figcaption strong em.tag:first-of-type {
  margin-left: 2px;
}
figure.recommend figcaption span.schedule {
  display: block;
  margin-top: 14px;
  color: #212121;
  font-size: 14px;
}
figure.recommend figcaption span.schedule i {
  display: inline-block;
  margin-left: 6px;
  padding-left: 25px;
  background: url('https://s.wink.co.kr/pc/images/icon_calendar.png') 0 2px
    no-repeat;
  vertical-align: middle;
  color: #bdbdbd;
}
figure.recommend figcaption span.text-overflow {
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  max-height: 47px;
}
figure.recommend figcaption .btn-play {
  position: absolute;
  top: 70px;
  left: 70px;
}

.recomm-teacher-list {
  margin-top: -90px;
}
.recomm-teacher-list p {
  height: 40px;
  border-radius: 4px;
  text-align: center;
  background: #f5f7fa;
  color: #212121;
  font-size: 14px;
  line-height: 40px;
}
.recomm-teacher-list p:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 9px 2px 0;
  vertical-align: middle;
  background: url(https://s.wink.co.kr/pc/images/icon_smile.png) no-repeat;
}
.recomm-teacher-list li {
  padding-bottom: 2px;
  background: url(https://s.wink.co.kr/pc/images/myteacher/bg_dot.png) 0 100%
    repeat-x;
}
.recomm-teacher-list li figure {
  padding: 32px 0;
}
.recomm-teacher-list li figure:after {
  content: '';
  display: block;
  clear: both;
}
.recomm-teacher-list li figure img {
  float: left;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  vertical-align: top;
}
.recomm-teacher-list li figcaption {
  float: left;
  width: 600px;
  margin-left: 32px;
  padding-top: 10px;
  position: relative;
}
.recomm-teacher-list li figcaption strong {
  display: block;
  color: #212121;
  font-size: 24px;
}
.recomm-teacher-list li figcaption em.tag {
  margin: 0 0 2px 0;
}
.recomm-teacher-list li figcaption .btn-type {
  position: absolute;
  top: 24px;
  right: 0;
}

.guide-non-member {
  height: 286px;
  margin-bottom: 35px;
  text-align: center;
  background: url('https://s.wink.co.kr/pc/images/myteacher/bg_non_member.jpg')
    no-repeat;
  line-height: 1.5;
}
.guide-non-member em {
  display: block;
  padding: 43px 0 4px;
  color: #212121;
  font-size: 24px;
  font-weight: bold;
}
.guide-non-member em strong {
  color: #08bbd5;
  position: relative;
}
.guide-non-member em strong:before,
.guide-non-member em strong:after {
  content: '';
  position: absolute;
  top: -9px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #fda164;
}
.guide-non-member em strong:before {
  left: 9px;
}
.guide-non-member em strong:after {
  right: 9px;
}
.guide-non-member b {
  display: block;
  font-weight: bold;
}
.guide-non-member span.btn {
  display: block;
  margin-top: 34px;
}
.guide-non-member span.btn a {
  width: 197px;
  margin: 0 3px;
  letter-spacing: -0.5px;
}

/*****************************************************************************************wink***************************************************************************/

/* common */

/* mypage css style 버튼과 동일 */
.btn-type2.binoculars {
  color: #f03c78;
  background-color: #fff;
}
.btn-type2.binoculars:hover {
  border-color: #f03c78;
}
.btn-type2.binoculars:before {
  width: 19px;
  height: 18px;
  margin: -2px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_binoculars.png)
    no-repeat 0 0;
}
.btn-type2.book:before {
  width: 19px;
  height: 18px;
  margin: -2px 10px 0 0;
  background: url(https://s.wink.co.kr/pc/images/mypage/icon_btn_book.png)
    no-repeat 0 0;
}
/* //mypage 버튼과 동일 */

/* 고객센터 이벤트 상세와 동일 */
.view-header {
  position: relative;
  padding: 25px 270px 20px 16px;
  border-top: 2px solid #676969;
  background-color: #f5f5f5;
}
.view-header .tag {
  margin-right: 6px;
}
.view-header .title {
  color: #212121;
  display: block;
}
.view-header .date {
  display: block;
  margin-top: 8px;
  background-image: url(https://s.wink.co.kr/pc/images/icon_calendar.png);
}
.view-header .right {
  position: absolute;
  right: 16px;
  top: 25px;
  width: 255px;
  height: 25px;
}

/* 이벤트 당첨자발표버튼 영역이 깨져 수정 */
.view-header .user-post > * {
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  line-height: 25px;
  vertical-align: middle;
}
.view-header .user-post > *:first-child {
  margin-left: 0;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
/* 이벤트 당첨자발표버튼 영역이 깨져 수정 */

.view-body {
  padding: 32px 16px;
}
.view-body img {
  width: 100%;
}
.view-body .user-info {
  font-size: 16px;
  color: #212121;
  font-family: 'NanumBarunGothic-Bold';
  margin-bottom: 32px;
}
/*.view-body img{max-width:100%;}*/
/* //고객센터 이벤트 상세와 동일 */

/* 내정보 mypage.css 결제 내역 과 동일 */
.panel-list li {
  margin-top: 15px;
}
.panel-list li:first-child {
  margin-top: 0;
}

.panel-list .total-payments {
  padding-top: 20px;
}

.panel-list .student-select {
  margin-bottom: 30px;
}

.panel-list .student-select .student-select-label {
  margin-right: 10px;
  font-size: 20px;
}

.panel-list .student-select .select-mode {
  width: 50%;
  top: -3px;
}

.panel-list .student-select .select-mode.on ul {
  max-height: 200px;
}

.panel-list .student-select .select-mode.on ul > li:first-child {
  margin-top: 15px;
}

.panel-box {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #fff;
}
.panel-head {
  padding: 17px 15px 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f0f0f0;
}
.panel-head.no-bg {
  color: #212121;
  background-color: transparent;
  font-weight: bold;
}
.panel-head.center {
  text-align: center;
}
.panel-head .bar {
  position: relative;
  margin-left: 15px;
  padding-left: 15px;
}
.panel-head .bar:after {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 1px;
  height: 12px;
  background-color: #ccc;
}
.panel-head .btn-control {
  position: absolute;
  right: 15px;
  top: 15px;
}
.panel-head .btn-control a {
  display: inline-block;
  position: relative;
  padding-left: 10px;
  margin-left: 7px;
  font-size: 14px;
  color: #666;
}
.panel-head .btn-control a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 1px;
  height: 10px;
  background-color: #bdbdbd;
}
.panel-head .btn-control a:first-child:after {
  display: none;
}
.panel-body {
  position: relative;
  padding: 25px 16px;
}
.panel-body .name {
  text-decoration: underline;
  color: #00a0e0;
}
.panel-body span {
  color: #999;
}
.panel-body em {
  color: #f03c78;
}
.panel-body small {
  font-size: 14px;
  color: #999;
}
.panel-body .ex-text {
  position: absolute;
  right: 0;
  bottom: 1px;
}
.panel-body .btn-type {
  border: 0 none;
  color: #fff;
  background-color: #95989f;
}
.panel-body .radio-list-col li {
  position: relative;
  margin-top: 15px;
}
.panel-body .radio-list-col li:first-child {
  margin-top: 0;
}
.panel-body .radio-list-col li .go {
  position: absolute;
  right: 0;
  top: 2px;
  background-position: 100% 5px;
}

.panel-body .radio-list-col .description .extra {
  font-family: NanumBarunGothic;
}

.panel-body .radio-list-col label,
.panel-body .radio-list-col .description .bold,
.panel-body .radio-list-col .bottom-description {
  font-family: NanumBarunGothic-Bold;
}

.panel-body .radio-list-col .description .underline {
  text-decoration: underline;
}

.panel-body > em.description {
  background-color: #fffdd6;
  padding: 2%;
}

.panel-body .tbl-row {
  border-top: 0 none;
}
.panel-body .tbl-row table {
  border-bottom: 0 none;
}
.panel-body .tbl-row th,
.panel-body .tbl-row td,
.panel-body .tbl-row th:first-child,
.panel-body .tbl-row th:first-child + td,
.panel-body .tbl-row tr:last-child th,
.panel-body .tbl-row tr:last-child td {
  padding: 6px 0 5px;
}

.panel-foot {
  padding: 17px 25px 20px;
  border-radius: 0 0 6px 6px;
  background-color: #f5f7fa;
}
.panel-foot .list-style-dot {
  margin-top: 8px;
}
.panel-foot .list-style-dot li {
  font-size: 14px;
}

.end .panel-head {
  color: #999;
}
.end .panel-body .tbl-row th,
.end .panel-body .tbl-row td,
.end .panel-body .tbl-row span {
  color: #bdbdbd;
}
.end .panel-body .tbl-row em {
  color: #bdbdbd;
}
.end .panel-body .tbl-row small {
  color: #bdbdbd;
}
.end .panel-body .tbl-row .name {
  color: #08bbd5;
}
/* //내정보 mypage.css 결제 내역 과 동일 */

/* mykids.css 와 동일 주 리스트 */
.hgroup + .week-list {
  margin-top: 20px;
}
.week-list > ul > li {
  margin-top: 8px;
}
.week-list > ul > li:last-child .paid-list li:last-child {
  padding-bottom: 0;
}
.week-list li:first-child {
  margin-top: 0;
}
.week-list li .title {
  display: block;
  position: relative;
  height: 52px;
  padding-left: 35px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  line-height: 52px;
  color: #212121;
  background-color: #fff;
}
.week-list li .title:before {
  content: '';
  position: absolute;
  left: 14px;
  top: 21px;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ebebeb;
}
.week-list li.on.week01 .title:before {
  background-color: #ffc200;
}
.week-list li.on.week02 .title:before {
  background-color: #4385ff;
}
.week-list li.on.week03 .title:before {
  background-color: #00bbd4;
}
.week-list li.on.week04 .title:before {
  background-color: #a186be;
}
.week-list li .title:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 21px;
  width: 17px;
  height: 10px;
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_off.png)
    no-repeat 0 0;
}
.week-list li.on .title {
  background-color: #f5f5f5;
}
.week-list li.on .title:after {
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_on.png)
    no-repeat 0 0;
}

.week-list li .title.disabled {
  color: #bdbdbd;
}
.week-list li .title.disabled:after {
  background: url(https://s.wink.co.kr/pc/images/mykids/btn_week_disabled.png)
    no-repeat 0 0;
}
.week-list li .title.week-sample ~ .ans .btn-type {
  border-color: #bdbdbd;
  color: #bdbdbd;
}
/* //mykids.css 와 동일 주 리스트 */

.comprehensive-class .panel-foot .notice.none-contact {
  margin-top: 5px;
}
.single-class .panel-foot .notice.none-contact {
  margin-top: 5px;
}

.btn-type2.application {
  color: #f03c78;
}
.btn-type2.application:hover {
  border-color: #f03c78;
}
.btn-type2.application:before {
  width: 19px;
  height: 18px;
  margin: 0 8px 2px 0;
  background: url(https://s.wink.co.kr/pc/images/wink/icon_btn_application.png)
    no-repeat 0 0;
}

/* swiper slide button */

/* swiper slide pagination */
.pagination-wrap button {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 0 none;
  text-indent: -999em;
  vertical-align: middle;
  background: url(https://s.wink.co.kr/pc/images/wink/btn_slider_stop.gif)
    no-repeat 0 0;
}
.pagination-wrap button.on {
  background: url(https://s.wink.co.kr/pc/images/wink/btn_slider_play.gif)
    no-repeat 0 0;
}

/* form */
fieldset.form-message .select-mode {
  width: 304px;
} /* mypage.css 와 동일 */
fieldset.title input[type='text'] {
  width: 100%;
}
fieldset.subject ul {
  margin-top: -15px;
}
fieldset.subject ul:after {
  display: block;
  content: '';
  clear: both;
}
fieldset.subject li {
  float: left;
  width: 134px;
  margin-top: 15px;
}
fieldset.child-info-select .select-mode {
  width: 250px;
}
fieldset.coupon input {
  width: 424px;
}
fieldset.study-select .select-mode {
  width: 250px;
}

/* 결제페이지 추천인정보등록 */
fieldset.recommender-area > .recommender-input::after {
  clear: both;
  content: '';
  display: block;
}
fieldset.recommender-area > .recommender-input input {
  width: 100%;
}
fieldset.recommender-area > .recommender-input .left {
  width: 470px;
}
/* 결제페이지 추천인정보등록 */

h4 small {
  font-size: 16px;
  color: #666;
}
h5 small {
  font-size: 14px;
  color: #666;
}

.message-box.payment p {
  padding-top: 100px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_payment_finish.png)
    no-repeat 50% 0;
}
.message-box.ars p {
  padding-top: 100px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_ars.png) no-repeat 50%
    0;
}
.message-box.receive p {
  padding-top: 100px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_receive.png) no-repeat
    50% 0;
}

.message-box .complete-desc {padding: 2rem 0 0 0; font-size: 1.25rem; line-height: 1.5; font-family: 'Pretendard', sans-serif; }
.message-box .complete-desc strong.title {font-size: 2.25rem; font-weight: 800; font-family: 'Pretendard', sans-serif; line-height: 1.6; }
.message-box .complete-desc .additional div {
  display: flex;
  text-align: left;
  padding-top: 20px;
}
.message-box .complete-desc .additional div .check {
  margin-right: 15px;
  height: 22px;
  width: 22px;
}

span.notice.type02 {
  display: inline-block;
}
.notice.type02 {
  padding-left: 26px;
  color: #666;
  background: url(https://s.wink.co.kr/pc/images/icon_notice2.png) no-repeat 0
    50%;
}

/* //common */

/* 윙크 텝 2줄 텝메뉴 5개 */
.tab-type.two-line {
  height: inherit;
  line-height: inherit;
}
.tab-type.two-line li a {
  display: table-cell;
  height: 62px;
  line-height: 22px;
  vertical-align: middle;
}
.tab-type.two-line.cell-5 li a {
  width: 144px;
}
.tab-type.two-line.cell-1 li a {
  width: 720px;
}
.tab-type.two-line.cell-1 li.on a {
  border-bottom-width: 1px;
  border-radius: 8px;
}

/* 윙크 한눈에 보기 */
.wink-visual {
  margin-bottom: 32px;
}
.wink-visual img {
  display: block;
}

/* tab fixed */
.tab-fixed {
  height: 48px;
}
.tab-fixed .tab-type2 {
  margin-bottom: 0;
}
.tab-fixed.fixed .tab-type2 {
  position: fixed;
  z-index: 10;
  padding-top: 32px;
  background-color: #fff;
}

/* slogan */
.slogan {
  padding: 26px 0 0;
  text-align: center;
}
.slogan h4 {
  margin: 0;
  padding: 0;
  border-width: 0;
  font-size: 32px;
  color: #00a5d5;
}
.slogan h4:after {
  display: none;
}
.slogan strong {
  display: block;
  color: #212121;
  font-size: 20px;
}
.slogan small {
  display: block;
  margin: 5px 0 -3px;
  font-size: 14px;
  line-height: 1.5;
}
.slogan small em {
  color: #f03c78;
}
/*.slogan p{color:#212121; font-size:20px;}
.slogan strong{display:block; font-size:32px; color:#00a5d5;}
.slogan small{display:block; padding-bottom:8px; font-size:14px; line-height:22px;}
.slogan small em{color:#f03c78;}*/

/* img text box */
.img-text-box:after {
  display: block;
  content: '';
  clear: both;
}
.img-text-box strong {
  font-size: 24px;
  color: #212121;
}
.img-text-box p {
  margin-top: 14px;
  line-height: 24px;
}
.img-text-box p:first-of-type {
  margin-top: 4px;
}
.img-text-box img {
  float: right;
}
.img-text-box .gray-box {
  margin-top: 70px;
}

/* gray box */
.gray-box {
  clear: both;
  margin-top: 40px;
  padding: 27px 32px;
  border-radius: 6px;
  background-color: #f5f5f5;
}
.gray-box .title {
  font-size: 18px;
  color: #212121;
}
.gray-box .notice {
  margin: 0 0 10px;
  font-size: 16px;
  color: #212121;
}
.gray-box li {
  position: relative;
  margin-top: 7px;
  padding-left: 11px;
  font-size: 14px;
  word-break: keep-all;
  text-align: left;
}
.gray-box li:first-child {
  margin-top: 0;
}
.gray-box li:after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 2px;
  height: 2px;
  background-color: #aeaeae;
}
.gray-box p {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
}
.gray-box p em {
  color: #08bbd5;
}
.gray-box p strong {
  color: #212121;
}

.gray-box.tbl-in {
  padding: 13px 15px;
}
.gray-box.tbl-in .tbl-row {
  border-top: 0 none;
}
.gray-box.tbl-in .tbl-row table {
  border-bottom: 0 none;
}
.gray-box.tbl-in .tbl-row th,
.gray-box.tbl-in .tbl-row td {
  padding: 8px 0;
  vertical-align: middle;
}

/* 하단 색상 컨텐츠 박스 */
h3 + .color-box {
  margin: 0 0 35px;
}
.color-box {
  position: relative;
  margin-top: 35px;
  border-radius: 3px;
  background-color: #f5f7fa;
}
p.color-box {
  padding: 11px 0 10px;
  font-size: 14px;
  text-align: center;
}
div.color-box {
  padding: 33px 31px;
}
div.color-box strong {
  font-size: 24px;
  color: #212121;
}
div.color-box span {
  color: #08bbd5;
}
div.color-box .btn-type2 {
  position: absolute;
  right: 24px;
  bottom: 37px;
}
div.color-box .btn-type2.qna.on {
  background-color: #00a5d5;
}
div.color-box .btn-type2.qna.on:hover {
  background-color: #006bb2;
}

/* wink section */
#contents .wink-section {
  margin-bottom: 0;
}
.wink-section {
  padding: 54px 0;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.gif) repeat-x 0 0;
}
.wink-section:first-of-type {
  background: none;
}
.wink-section:last-of-type {
  padding-bottom: 0;
}
.wink-section .hgroup {
  position: relative;
}
.wink-section .hgroup .notice {
  position: absolute;
  right: 0;
  bottom: 13px;
  padding-left: 28px;
  font-size: 14px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat 0 0;
}
.wink-section > p {
  margin-bottom: 26px;
}
.wink-section > p em {
  color: #212121;
}
.wink-section > p strong {
  font-weight: bold;
  color: #212121;
}
.wink-section h5 {
  margin-bottom: 4px;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.4;
}
.wink-section h5:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section h5 small {
  font-size: 16px;
  color: #08bbd5;
}
.wink-section h5 small.notice {
  float: right;
  display: inline-block;
  height: 20px;
  margin-top: 14px;
  padding-left: 28px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) 0 0 no-repeat;
  font-size: 14px;
  color: #666;
  line-height: 20px;
}

/* 원형 숫자 리스트 */
.wink-explanation {
  padding-top: 14px;
}
.wink-explanation li {
  margin-top: 20px;
  padding-left: 36px;
  text-indent: -36px;
  word-break: keep-all;
}
.wink-explanation li:first-child {
  margin-top: 4px;
}
.wink-explanation li .num {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 9px;
  border-radius: 100%;
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
  text-indent: 0;
  color: #fff;
  background-color: #676969;
}
.wink-explanation li em {
  color: #08bbd5;
}
.wink-explanation li img {
  margin: 5px 0 4px;
}
.wink-explanation li:nth-child(3) p {
  display: table;
  width: 680px;
  height: 112px;
  margin-top: 10px;
  padding-bottom: 12px;
}
.wink-explanation li:nth-child(3) p b {
  display: table-cell;
  height: 112px;
  padding-left: 10px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-weight: bold;
  line-height: 1.5;
  text-indent: 0;
}
.wink-explanation li:nth-child(3) p b:first-child {
  padding-left: 0;
}
.wink-explanation li:nth-child(3) p.ko {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_subject_ko.jpg)
    no-repeat;
}
.wink-explanation li:nth-child(3) p.ko b {
  width: 128px;
}
.wink-explanation li:nth-child(3) p.en {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_subject_en.jpg)
    no-repeat;
}
.wink-explanation li:nth-child(3) p.en b {
  width: 105px;
}

/* 윙크 구성 */
.wink-compose ul {
  margin-top: 35px;
  text-align: center;
}
.wink-compose ul:first-child {
  margin-top: 0;
}
.wink-compose li {
  display: inline-block;
  position: relative;
  margin-left: 30px;
  vertical-align: top;
  font-size: 14px;
}
.wink-compose li:first-child {
  margin-left: 0;
}
.wink-compose li:after {
  content: '';
  position: absolute;
  left: -37px;
  top: 80px;
  width: 21px;
  height: 21px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_plus.png)
    no-repeat 0 0;
}
.wink-compose li:first-child:after {
  display: none;
}
.wink-compose ul:first-child li:last-child:after {
  left: -25px;
}
.wink-compose li img {
  display: block;
  margin: 0 auto 7px;
}

/* 윙크 한눈에 보기 */
.wink-banner {
  height: 200px;
  margin-top: 46px;
  padding: 47px 0 0 32px;
  box-sizing: border-box;
  color: #fce100;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}
.wink-banner strong {
  display: block;
  color: #fff;
  font-size: 32px;
}
.wink-banner.compose {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_banner.jpg)
    no-repeat;
}
.wink-banner.compose strong {
  position: relative;
}
.wink-banner.compose strong:before,
.wink-banner.compose strong:after {
  content: '';
  position: absolute;
  top: -5px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.wink-banner.compose strong:before {
  left: 75px;
}
.wink-banner.compose strong:after {
  left: 104px;
}
.wink-banner.price {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_banner2.jpg)
    no-repeat;
}
.wink-banner.teacher {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_banner3.jpg)
    no-repeat;
}
.wink-banner.app {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_banner4.jpg)
    no-repeat;
}

ol.what-is-wink {
  padding-top: 4px;
}
ol.what-is-wink li {
  display: table;
  width: 100%;
  height: 88px;
  margin-top: 4px;
  border-radius: 8px;
  overflow: hidden;
}
ol.what-is-wink li p {
  display: table-cell;
  padding: 0 20px 0 108px;
  vertical-align: middle;
  background: #f5f5f5;
  position: relative;
}
ol.what-is-wink li p:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 88px;
  height: 88px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 88px;
}
ol.what-is-wink li p b {
  color: #08bbd5;
}
ol.what-is-wink li:nth-child(1) {
  margin-top: 0;
}
ol.what-is-wink li:nth-child(1) p:before {
  content: '01';
  background: #ffc500;
}
ol.what-is-wink li:nth-child(2) p:before {
  content: '02';
  background: #4685f9;
}
ol.what-is-wink li:nth-child(3) p:before {
  content: '03';
  background: #00c0d1;
}
ol.what-is-wink li:nth-child(4) p:before {
  content: '04';
  background: #a487bf;
}

.wink-compose-tit {
  float: left;
  width: 350px;
}
.wink-compose-tit h5 {
  margin-top: 75px;
}
.compose-feature {
  display: table;
  float: right;
  width: 350px;
  height: 322px;
  margin-bottom: 40px;
  position: relative;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose.jpg)
    no-repeat;
}
.compose-feature h6 {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  color: #212121;
  font-size: 24px;
  font-weight: bold;
}
.compose-feature h6 strong {
  color: #08bbd5;
  font-weight: bold;
}
.compose-feature li {
  display: none;
}
.compose-feature-detail {
  clear: both;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_danbi.png) 100%
    100% no-repeat;
}
.compose-feature-detail:after {
  content: '';
  display: block;
  clear: both;
}
.compose-feature-detail li {
  display: table;
  float: left;
  width: 226.6px;
  height: 307px;
  margin-left: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 3px;
  border-style: solid;
}
.compose-feature-detail li p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-top: 133px;
  font-size: 14px;
}
.compose-feature-detail li:nth-child(1) {
  margin-left: 0;
  border-color: #ffc500;
  background: #fcf9ee;
}
.compose-feature-detail li:nth-child(1) p {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose.png) 50%
    27px no-repeat;
}
.compose-feature-detail li:nth-child(2) {
  border-color: #4685f9;
  background: #f6f8fc;
}
.compose-feature-detail li:nth-child(2) p {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose2.png) 50%
    28px no-repeat;
}
.compose-feature-detail li:nth-child(3) {
  border-color: #00c0d1;
  background: #f3f9fa;
}
.compose-feature-detail li:nth-child(3) p {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose3.png) 50%
    27px no-repeat;
}
.compose-feature-detail li:nth-child(4) {
  margin-top: 20px;
  margin-left: 0;
  border-color: #a487bf;
  background: #f8f5fb;
}
.compose-feature-detail li:nth-child(4) p {
  padding-top: 142px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose4.png) 50%
    29px no-repeat;
}
.compose-feature-detail li:nth-child(5) {
  margin-top: 20px;
  border-color: #f75959;
  background: #fbf6f6;
}
.compose-feature-detail li:nth-child(5) p {
  padding-top: 142px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_compose5.png) 50%
    33px no-repeat;
}

.funny-wink:after {
  content: '';
  display: block;
  clear: both;
}
.funny-wink p {
  margin-bottom: 26px;
}
.funny-wink p strong {
  display: block;
  margin: 22px 0;
  color: #212121;
  font-weight: bold;
}
.funny-wink figure {
  float: left;
  width: 350px;
}
.funny-wink figure img {
  height: 233px;
  vertical-align: top;
  border-radius: 8px;
}
.funny-wink figure figcaption {
  margin-top: 20px;
  padding: 0 16px;
  text-align: center;
  font-size: 14px;
}
.funny-wink figure:nth-of-type(2) {
  float: right;
}

p.wink-price {
  margin-bottom: 49px;
  padding-top: 253px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_danbi2.png) 50% 0
    no-repeat;
  text-align: center;
}
p.wink-price strong {
  display: block;
  margin-bottom: 3px;
  color: #212121;
  font-size: 28px;
  font-weight: normal;
}
p.wink-price strong b {
  color: #f03c78;
}
p.wink-price + .tab-type {
  margin-bottom: 34px;
}

.tbl-wink-price {
  padding-top: 3px;
}
.tbl-wink-price table {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
.tbl-wink-price th,
.tbl-wink-price td {
  padding: 9px 0 7px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
}
.tbl-wink-price th small {
  display: block;
  font-size: 12px;
}
.tbl-wink-price td {
  text-align: center;
}
.tbl-wink-price td.l {
  padding-left: 10px;
  text-align: left;
}
.tbl-wink-price thead th {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
}
.tbl-wink-price thead th:first-child,
.tbl-wink-price thead th:last-child {
  border-right-width: 0;
}
.tbl-wink-price thead th:nth-child(1) {
  width: 70px;
}
.tbl-wink-price thead th:nth-child(2) {
  width: 199px;
}
.tbl-wink-price thead th:nth-child(3) {
  width: 129px;
}
.tbl-wink-price thead th:nth-child(4) {
  width: 90px;
}
.tbl-wink-price thead th:nth-child(5) {
  width: 129px;
}
.tbl-wink-price thead th:nth-child(6) {
  width: 99px;
}
.tbl-wink-price tbody th,
.tbl-wink-price tbody td {
  border-bottom: 1px solid #e0e0e0;
  color: #212121;
}
.tbl-wink-price tbody td del {
  color: #757575;
}
.tbl-wink-price tbody td strong {
  font-weight: bold;
}
.tbl-wink-price tbody td strong b {
  font-size: 20px;
  font-weight: bold;
}
.tbl-wink-price tbody td b {
  color: #00b8d4;
}
.tbl-wink-price tbody td b.total {
  color: #462e9e;
}
.tbl-wink-price tbody tr.dotted td,
.tbl-wink-price tbody th.dotted,
.tbl-wink-price tbody td.dotted {
  border-bottom-width: 0;
  background: url(https://s.wink.co.kr/pc/images/bg_dotted.png) 0 100% repeat-x;
}
.tbl-wink-price tbody th.class-type1 {
  background-color: #faf2f5 !important;
}
.tbl-wink-price tbody th.class-type2 {
  background-color: #faf6f2 !important;
}
.tbl-wink-price tbody th.class-type3 {
  background-color: #f2f9fa !important;
}
.tbl-wink-price tbody tr:last-child th,
.tbl-wink-price tbody tr:last-child td {
  border-bottom: 2px solid #e0e0e0;
}
.tbl-wink-price table.age-type1 thead th {
  background: #ff8221;
}
.tbl-wink-price table.age-type1 thead th:first-child {
  background: #ff6f00;
}
.tbl-wink-price table.age-type2 thead th {
  background: #ffbf3d;
}
.tbl-wink-price table.age-type2 thead th:first-child {
  background: #ffb520;
}
.tbl-wink-price table.age-type3 thead th {
  background: #5b95ff;
}
.tbl-wink-price table.age-type3 thead th:first-child {
  background: #4385ff;
}
.tbl-wink-price p {
  margin-top: 12px;
  padding-left: 6px;
  background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 10px no-repeat;
  color: #999;
  font-size: 14px;
}
.tbl-wink-price ol {
  margin-top: 35px;
  padding: 28px 32px;
  background: #f5f5f5;
  border-radius: 8px;
}
.tbl-wink-price ol li {
  position: relative;
  margin-top: 7px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 1.5;
}
.tbl-wink-price ol li:before {
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  background: #27beb7;
  color: #fff;
  line-height: 22px;
}
.tbl-wink-price ol li:nth-child(1):before {
  content: '1';
}
.tbl-wink-price ol li:nth-child(2):before {
  content: '2';
}

.wink-system-diagram {
  height: 218px;
  margin-top: 48px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_system.png)
    no-repeat;
}
.wink-system-diagram:after {
  content: '';
  display: block;
  clear: both;
}
.wink-system-diagram li {
  float: left;
  width: 218px;
  margin-left: 33px;
  text-align: center;
  color: #fff;
}
.wink-system-diagram li:first-child {
  margin-left: 0;
}
.wink-system-diagram dt {
  padding: 27px 0 4px;
  font-size: 40px;
}
.wink-system-diagram dt small {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.wink-system-diagram dd {
  font-size: 14px;
  line-height: 1.7;
}

.wink-section.system h5 {
  font-weight: bold;
}
.wink-section.system h5 em {
  color: #f03c78;
}
.wink-section.system p {
  margin-bottom: 26px;
  color: #212121;
}
.wink-section.system p.notice {
  height: 40px;
  padding: 0;
  border-radius: 4px;
  background: #f5f7fa;
  text-align: center;
  color: #212121;
  font-size: 14px;
  line-height: 40px;
}
.wink-section.system small.notice {
  display: block;
  margin-bottom: 11px;
  padding-left: 8px;
  background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
  color: #999;
  font-size: 14px;
}

.wink-section.system figure.feature {
  display: table;
  width: 100%;
  margin-bottom: 34px;
}
.wink-section.system figure.feature img {
  display: table-cell;
  vertical-align: middle;
}
.wink-section.system figure.feature figcaption {
  display: table-cell;
  width: 363px;
  padding-left: 32px;
  vertical-align: middle;
}
.wink-section.system figure.feature figcaption b {
  display: block;
  color: #08bbd5;
}

.wink-section.system ul:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section.system li {
  float: left;
  width: 226px;
  margin-left: 21px;
  line-height: 1.5;
}
.wink-section.system li:first-child {
  margin-left: 0;
}
.wink-section.system li figure:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section.system li figure img {
  float: left;
  margin-left: 21px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  vertical-align: top;
}
.wink-section.system li figure img:first-of-type {
  margin-left: 0;
}
.wink-section.system li figcaption {
  margin-top: 20px;
  padding-left: 10px;
  background: url(https://s.wink.co.kr/pc/images/bu_dot5.png) 0 8px no-repeat;
  color: #757575;
}
.wink-section.system .video-list li a {
  display: block;
  position: relative;
}
.wink-section.system .video-list li img {
  width: 224px;
  height: 125px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  vertical-align: top;
}
.wink-section.system .video-list li span.btn-play {
  position: absolute;
  bottom: 5px;
  right: 3px;
  width: 44px;
  height: 44px;
  background-size: contain;
}
.wink-section.system .video-list + p.figcaption {
  margin: 20px 0 0;
  padding-left: 10px;
  background: url(https://s.wink.co.kr/pc/images/bu_dot5.png) 0 8px no-repeat;
  color: #757575;
}
.wink-section.system .figure-list li figure img {
  width: 224px;
  height: 149px;
}
.wink-section.system .figure-list.master li:nth-child(1) {
  width: 473px;
}
.wink-section.system .figure-list.master li:nth-child(2) {
  margin-bottom: 34px;
}
.wink-section.system .figure-list.master li:nth-child(3) {
  width: 100%;
  margin-left: 0;
}
.wink-section.system .figure-list2 li {
  width: 350px;
  margin: 0 0 34px;
}
.wink-section.system .figure-list2 li:nth-child(even) {
  float: right;
}
.wink-section.system .figure-list2 li figure img {
  width: 348px;
  height: 231px;
}

.wink-section.teacher small.notice {
  display: block;
  clear: both;
  padding: 12px 0 0 8px;
  border-top: 1px solid #e0e0e0;
  background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 21px no-repeat;
  color: #999;
  font-size: 14px;
}
.wink-section.teacher .wink-teacher-tit {
  float: left;
  width: 343px;
}
.wink-section.teacher .wink-teacher-tit h5 {
  margin-top: 11px;
}
.wink-section.teacher .wink-teacher-tit strong {
  display: block;
  margin-top: 18px;
  color: #08bbd5;
  font-weight: bold;
}
.wink-section.teacher figure {
  float: right;
  margin-bottom: 47px;
}
.wink-section.teacher ol:after,
.wink-section.teacher ul:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section.teacher li {
  float: left;
  width: 350px;
}
.wink-section.teacher li:nth-child(even) {
  float: right;
}
.wink-section.teacher ul {
  margin-top: 30px;
}
.wink-section.teacher ul li {
  margin-top: 20px;
  padding: 16px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 8px;
}
.wink-section.teacher ul li figure {
  float: none;
  margin: 0;
}
.wink-section.teacher ul li figure img {
  width: 318px;
  height: 212px;
  border-radius: 4px;
  vertical-align: top;
}
.wink-section.teacher ul li figcaption {
  margin: 24px 0 8px;
  text-align: center;
  color: #212121;
  line-height: 1.5;
}
.wink-section.teacher ul li figcaption b {
  color: #f03c78;
}
.wink-section.teacher ol li {
  font-size: 20px;
  font-weight: bold;
}
.wink-section.teacher ol li:before {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border-radius: 50%;
  background: #676969;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 38px;
}
.wink-section.teacher ol li:nth-child(1):before {
  content: '01';
}
.wink-section.teacher ol li:nth-child(2):before {
  content: '02';
}
.wink-section.teacher ol li a {
  display: block;
  margin-top: 16px;
  position: relative;
}
.wink-section.teacher ol li a img {
  width: 348px;
  height: 195px;
  border-radius: 8px;
  vertical-align: top;
}
.wink-section.teacher ol li a span.btn-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -28px 0 0 -28px;
}
.wink-section.teacher p.recruit {
  margin-top: 20px;
  padding: 27px 32px;
  border-radius: 8px;
  background: #f5f7fa;
  position: relative;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
.wink-section.teacher p.recruit strong {
  display: block;
  margin-bottom: 6px;
  font-size: 24px;
  font-weight: normal;
}
.wink-section.teacher p.recruit a.btn-type2 {
  position: absolute;
  bottom: 32px;
  right: 32px;
  border-width: 0;
  background-color: #00a5d5;
  color: #fff;
}
.wink-section.teacher p.recruit a.btn-type2:hover {
  background-color: #006bb2;
}

.wink-section.teacher2:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section.teacher2 .wink-teacher-tit {
  float: left;
  width: 415px;
}
.wink-section.teacher2 .wink-teacher-tit h5 {
  margin-top: 35px;
}
.wink-section.teacher2 .wink-teacher-tit p {
  line-height: 1.5;
}
.wink-section.teacher2 figure {
  float: right;
}
.wink-section.teacher2 figure img {
  vertical-align: top;
  margin-right: -8px;
}

.wink-section.app p {
  margin: -26px 0 26px;
  text-align: center;
}
.wink-section.app ul:after {
  content: '';
  display: block;
  clear: both;
}
.wink-section.app li {
  float: left;
  width: 222px;
  height: 506px;
  margin-left: 27px;
}
.wink-section.app li figcaption {
  text-align: center;
}
.wink-section.app li figcaption strong {
  display: block;
  margin: 18px 0 2px;
  color: #212121;
  font-size: 24px;
}
.wink-section.app li:nth-child(1) {
  margin-left: 0;
}
.wink-section.app li:nth-child(1) figure {
  margin-left: -9px;
}
.wink-section.app li:nth-child(3) figure {
  margin-right: -9px;
}
.wink-section.app li:nth-child(4) {
  margin-left: 125px;
}

/* 학습 전용 단말기 슬라이드 */
.wink-handset-slider .swiper-container {
  width: 720px;
  height: 405px;
}
.wink-handset-slider .swiper-slide {
  text-align: center;
}
.wink-handset-slider .pagination {
  margin-top: 15px;
  text-align: center;
}
.wink-handset-slider .pagination span {
  overflow: hidden;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  margin: 0 3px;
  padding: 0;
  border: 2px solid #e0e0e0;
  border-radius: 100%;
  cursor: pointer;
}
.wink-handset-slider .pagination span:nth-child(1) {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_handset_pagi.jpg)
    no-repeat 50% 1px;
}
.wink-handset-slider .pagination span:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_handset_pagi2.jpg)
    no-repeat 50% 1px;
}
.wink-handset-slider .pagination span:nth-child(3) {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_handset_pagi3.jpg)
    no-repeat 50% 1px;
}
.wink-handset-slider .pagination span:nth-child(4) {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_handset_pagi4.jpg)
    no-repeat 50% 1px;
}
.wink-handset-slider .pagination span:nth-child(5) {
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_handset_pagi5.jpg)
    no-repeat 50% 1px;
}
.wink-handset-slider .pagination span:hover {
  border-color: #00a5d5;
}
.wink-handset-slider .pagination .swiper-active-switch {
  border-color: #f03c78;
}

/* 학습 전용 단말기 설명 */
.wink-handset-info-wrap > p {
  text-align: center;
}
.wink-handset-infos {
  overflow: hidden;
  position: relative;
  margin-top: 25px;
}
.wink-handset-infos .handset-base {
  position: relative;
  height: 548px;
}
.wink-handset-infos .handset-base .btn-more {
  position: absolute;
  width: 38px;
  height: 38px;
  text-indent: -999em;
  background: url(https://s.wink.co.kr/pc/images/wink/btn_handset_more.png)
    no-repeat 0 0;
}
.wink-handset-infos .handset-base .btn-top {
  left: 290px;
  top: 30px;
}
.wink-handset-infos .handset-base .btn-left {
  left: 52px;
  top: 153px;
}
.wink-handset-infos .handset-base .btn-right {
  left: 529px;
  top: 153px;
}
.wink-handset-infos .handset-base .btn-bottom {
  left: 290px;
  bottom: 110px;
}

.wink-handset-infos .handset-info {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
}
.wink-handset-infos .handset-info .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 18px;
  height: 18px;
  padding: 0;
  text-indent: -999em;
  background: url(https://s.wink.co.kr/pc/images/wink/btn_handset_close.png)
    no-repeat 0 0;
}
.wink-handset-infos .handset-info em {
  font-size: 18px;
  color: #ffc500;
}
.wink-handset-infos .handset-info p {
  font-size: 14px;
  color: #fff;
}
.wink-handset-infos .handset-info p span {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
}

/* 학습 전용 단말기 파워 설명 */
.wink-handset-infos .handset-power {
  position: absolute;
  left: 276px;
  top: 57px;
}
.wink-handset-infos .handset-power em {
  float: left;
  padding-top: 67px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_handset_power.png)
    no-repeat 50% 0;
}
.wink-handset-infos .handset-power p {
  float: left;
  margin-left: 19px;
}

/* 학습 전용 단말기 왼쪽 버튼 설명 */
.wink-handset-infos .handset-left {
  position: absolute;
  left: 40px;
  top: 150px;
  padding-left: 100px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_handset_left_btn.png)
    no-repeat 0 21px;
}
.wink-handset-infos .handset-left em {
  position: absolute;
  left: 0;
  top: -15px;
}
.wink-handset-infos .handset-left p {
  margin-top: 60px;
}

/* 학습 전용 단말기 오른쪽 버튼 설명 */
.wink-handset-infos .handset-right {
  position: absolute;
  right: 140px;
  top: 150px;
  padding-right: 100px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_handset_right_btn.png)
    no-repeat 100% 21px;
}
.wink-handset-infos .handset-right em {
  position: absolute;
  right: -10px;
  top: -15px;
}
.wink-handset-infos .handset-right p {
  margin-top: 60px;
  text-align: right;
}

/* 학습 전용 단말기 전면 버튼 설명 */
.wink-handset-infos .handset-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 255px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_handset_bottom_btn.png)
    no-repeat 70px 8px;
}
.wink-handset-infos .handset-bottom em {
  position: absolute;
  left: 280px;
  bottom: 140px;
}
.wink-handset-infos .handset-bottom p {
  float: left;
  margin-left: 42px;
}
.wink-handset-infos .handset-bottom p:last-child {
  position: absolute;
  left: 520px;
  bottom: 90px;
  margin-left: 0;
}

/* 과목별 학습 */
.wink-subject {
  height: 184px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_subject.gif)
    no-repeat;
}
.wink-subject:after {
  content: '';
  display: block;
  clear: both;
}
.wink-subject dl {
  float: left;
  display: table;
  width: 336px;
  position: relative;
}
.wink-subject dl:nth-of-type(2) {
  float: right;
}
.wink-subject dt {
  position: absolute;
  top: 164px;
  left: 0;
  width: 336px;
  text-align: center;
  color: #212121;
}
.wink-subject dd {
  display: table-cell;
  width: 152px;
  height: 152px;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 1.3;
}
.wink-subject dd:nth-of-type(2) {
  padding-left: 32px;
}
.wink-subject dd small {
  display: block;
  margin-top: 7px;
  font-size: 14px;
  font-weight: normal;
}
.wink-subject-reading {
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_subject2.gif)
    no-repeat;
}
.wink-subject-reading:after {
  content: '';
  display: block;
  clear: both;
}
.wink-subject-reading dl {
  float: left;
  width: 152px;
  margin-left: 37px;
  text-align: center;
  overflow: hidden;
}
.wink-subject-reading dl:first-child {
  margin-left: 0;
}
.wink-subject-reading dt {
  height: 152px;
  color: #fff;
  font-size: 24px;
  line-height: 156px;
}
.wink-subject-reading dd {
  margin: 21px 0 35px;
  font-size: 14px;
  line-height: 1.5;
}
.wink-subject-reading dd b {
  color: #212121;
}

/* 윙크 구성 요소 */
.wink-words {
  margin-top: 40px;
}
.wink-words:first-of-type {
  margin-top: 0;
  padding-top: 3px;
}

.wink-words-head {
  position: relative;
  padding: 15px 31px 14px;
  border-radius: 6px;
}
.wink-words-head h6 {
  margin-bottom: 2px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: inherit;
}
.wink-words-head p {
  font-size: 14px;
  color: #fff;
}

.wink-words-head.bg01 {
  background-color: #ffc500;
}
.wink-words-head.bg02 {
  background-color: #00c0d1;
}
.wink-words-head.bg03 {
  background-color: #a487bf;
}

.wink-words-content:after {
  display: block;
  content: '';
  clear: both;
}
.wink-words-content > li {
  float: left;
  width: 50%;
  margin-top: 24px;
}
.wink-words-content > li img {
  float: left;
  margin-right: 20px;
}
.wink-words-content > li .text {
  display: table-cell;
  height: 107px;
  vertical-align: middle;
}
.wink-words-content > li .text strong {
  display: inline-block;
  line-height: 24px;
  color: #212121;
}
.wink-words-content > li .text p {
  margin-top: 5px;
  font-size: 14px;
  line-height: 22px;
}
.wink-words-content > li .text li {
  position: relative;
  margin-top: 4px;
  padding-left: 8px;
  font-size: 14px;
}
.wink-words-content > li .text li:after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 2px;
  height: 2px;
  background-color: #8a8a8a;
}

.wink-words-content.single > li {
  float: none;
  width: 100%;
}

/* 윙크 커리큘럼 테이블 */
.wink-tbl-curriculum {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-top: 2px solid #676969;
}
.wink-tbl-curriculum thead th {
  padding: 18px 0 17px;
  font-size: 14px;
  font-weight: bold;
  color: #212121;
}
.wink-tbl-curriculum tbody th,
.wink-tbl-curriculum tbody td {
  padding: 28px 0;
  border: 1px solid #e0e0e0;
  text-align: center;
  vertical-align: top;
}
.wink-tbl-curriculum tbody th small,
.wink-tbl-curriculum tbody td small {
  display: block;
  font-size: 14px;
  color: #666;
}
.wink-tbl-curriculum tbody th {
  font-weight: normal;
  color: #212121;
}
.wink-tbl-curriculum tbody td {
  word-break: keep-all;
}
.wink-tbl-curriculum tbody .left {
  padding-left: 16px;
  padding-right: 5px;
  text-align: left;
}
.wink-tbl-curriculum tbody td strong {
  color: #f03c78;
}
.wink-tbl-curriculum tbody td li {
  position: relative;
  margin-top: 4px;
  padding-left: 8px;
  font-size: 14px;
}
.wink-tbl-curriculum tbody td li:after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 2px;
  height: 2px;
  background-color: #8a8a8a;
}

/* 프로세스 */
.wink-tbl-process {
  padding-top: 16px;
}
.wink-tbl-process table {
  width: 717px;
}
.wink-tbl-process thead th strong {
  display: block;
  width: 128px;
  height: 55px;
  margin: 0 auto;
  border-radius: 27.5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 55px;
  position: relative;
}
.wink-tbl-process thead th strong:after {
  content: '';
  position: absolute;
  top: 18px;
  right: -22px;
  display: block;
  width: 18px;
  height: 20px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_arrow.png) no-repeat;
  font-size: 0;
  line-height: 0;
}
.wink-tbl-process thead th:nth-child(1) {
  width: 77px;
}
.wink-tbl-process thead th:nth-child(2) {
  width: 152px;
  padding-right: 8px;
}
.wink-tbl-process thead th:nth-child(2) strong {
  background: #4685f9;
}
.wink-tbl-process thead th:nth-child(3) {
  width: 152px;
  padding-right: 8px;
}
.wink-tbl-process thead th:nth-child(3) strong {
  background: #00c0d1;
}
.wink-tbl-process thead th:nth-child(4) {
  width: 152px;
  padding-right: 8px;
}
.wink-tbl-process thead th:nth-child(4) strong {
  background: #a487bf;
}
.wink-tbl-process thead th:nth-child(5) {
  width: 152px;
  padding-right: 8px;
}
.wink-tbl-process thead th:nth-child(5) strong {
  background: #f75959;
}
.wink-tbl-process thead th:nth-child(5) strong:after {
  display: none;
}
.wink-tbl-process tbody th,
.wink-tbl-process tbody td {
  font-size: 14px;
}
.wink-tbl-process tbody th {
  padding-top: 8px;
  vertical-align: middle;
}
.wink-tbl-process tbody td {
  padding: 8px 8px 0 0;
  vertical-align: top;
}
.wink-tbl-process tbody td p {
  display: table;
  width: 100%;
  border-radius: 8px;
  background: #f7f7f7;
  text-align: center;
  color: #212121;
  line-height: 1.4;
}
.wink-tbl-process tbody td p span {
  display: table-cell;
  height: 56px;
  padding-top: 4px;
  box-sizing: border-box;
  vertical-align: middle;
}
.wink-tbl-process tbody td p small {
  display: block;
  color: #666;
}
.wink-tbl-process tbody tr:nth-child(1) td {
  padding-top: 24px;
}
.wink-tbl-process tbody tr:nth-child(1) td:nth-of-type(1) p span {
  height: 248px;
}
.wink-tbl-process tbody tr:nth-child(2) td:nth-of-type(3) p span {
  height: 120px;
}
.wink-tbl-process tbody tr:nth-child(7) td:nth-of-type(1) p span {
  height: 120px;
}

.wink_process_en {
  margin-top: -8px;
  padding: 17px 0 0 33px;
  background: url(https://s.wink.co.kr/pc/images/wink/img_wink_danbi3.png) 570px
    0 no-repeat;
}
.wink_process_en strong {
  display: block;
  width: 556px;
  height: 60px;
  border-radius: 30px;
  background: #83c6c9;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 62px;
}
.wink_process_en ul.notice {
  margin: 15px 0 25px;
}
.wink_process_en ul.notice li {
  margin: 1px 0 0 2px;
  padding-left: 8px;
  background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
  font-size: 14px;
}
.wink_process_en ul.notice li b {
  font-weight: bold;
}
.wink_process_en ul.notice li small {
  display: block;
  color: #999;
  font-size: 12px;
}
.wink_process_en ul.feature {
  margin: 0 0 22px -13px;
}
.wink_process_en ul.feature:after {
  content: '';
  display: block;
  clear: both;
}
.wink_process_en ul.feature li {
  float: left;
  width: 154px;
  margin-right: 20px;
}
.wink_process_en ul.feature li figure {
  position: relative;
}
.wink_process_en ul.feature li img {
  vertical-align: top;
}
.wink_process_en ul.feature li img.icon {
  position: absolute;
  top: 111px;
  left: 50%;
  margin-left: 34px;
}
.wink_process_en ul.feature li figcaption {
  margin-top: 12px;
  text-align: center;
  color: #212121;
}
.wink_process_en p.notice {
  text-align: center;
  background: none;
  color: #999;
  font-size: 14px;
}
.wink_process_en p.notice:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 8px 2px 0;
  vertical-align: middle;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat;
}

/* 윙크 멀티북 */
.wink-multibook ul:after {
  display: block;
  content: '';
  clear: both;
}
.wink-multibook li {
  float: left;
  margin-left: 20px;
}
.wink-multibook li:first-child {
  margin-left: 0;
}
.wink-multibook p {
  position: relative;
  margin-top: 5px;
  padding-left: 9px;
  font-size: 14px;
  color: #999;
}
.wink-multibook p:after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 2px;
  height: 2px;
  background-color: #b1b1b1;
}

/* 윙크 과목별 학습 학습 놀이터 */
.wink-section.study {
  padding: 24px 0px 0px 0px;
}
.wink-playground {
  margin-top: 32px;
}
.wink-playground:after {
  display: block;
  content: '';
  clear: both;
}
.wink-playground li {
  float: left;
  box-sizing: border-box;
  width: 165px;
  margin-left: 20px;
  border: 4px solid #ccc;
  border-radius: 8px;
  text-align: center;
}
.wink-playground li:first-child {
  margin-left: 0;
}
.wink-playground li strong {
  display: block;
  position: relative;
  padding: 21px 0 45px;
  font-weight: bold;
  color: #fff;
}
.wink-playground li strong:after {
  content: '';
  position: absolute;
  left: 47px;
  top: 55px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
}
.wink-playground li p {
  box-sizing: border-box;
  height: 136px;
  padding-top: 45px;
}

.wink-playground li:nth-child(1) {
  border-color: #ffc500;
}
.wink-playground li:nth-child(2) {
  border-color: #4685f9;
}
.wink-playground li:nth-child(3) {
  border-color: #00c0d1;
}
.wink-playground li:nth-child(4) {
  border-color: #a487bf;
}
.wink-playground li:nth-child(1) strong {
  background-color: #ffc500;
}
.wink-playground li:nth-child(2) strong {
  background-color: #4685f9;
}
.wink-playground li:nth-child(3) strong {
  background-color: #00c0d1;
}
.wink-playground li:nth-child(4) strong {
  background-color: #a487bf;
}
.wink-playground li:nth-child(1) strong:after {
  background: url(https://s.wink.co.kr/pc/images/wink/icon_wink_playground.png)
    no-repeat 50% 50% #f5f5f5;
}
.wink-playground li:nth-child(2) strong:after {
  background: url(https://s.wink.co.kr/pc/images/wink/icon_wink_playground2.png)
    no-repeat 50% 50% #f5f5f5;
}
.wink-playground li:nth-child(3) strong:after {
  background: url(https://s.wink.co.kr/pc/images/wink/icon_wink_playground3.png)
    no-repeat 50% 100% #f5f5f5;
}
.wink-playground li:nth-child(4) strong:after {
  background: url(https://s.wink.co.kr/pc/images/wink/icon_wink_playground4.png)
    no-repeat 50% 50% #f5f5f5;
}

/* 연령별 학습 */
/*.wink-visual.age-study{padding:33px 240px 33px 50px; border-radius:6px; background:url(https://s.wink.co.kr/pc/images/wink/bg_wink_visual.png) no-repeat 480px 100% #f6f7fb;}*/
/*.wink-age-content-wrap .list-style-dot{font-size:14px;}
.wink-age-content{margin-top:32px; padding-left:12px; padding-bottom:24px; border-bottom:1px solid #e0e0e0;}
.wink-age{margin-top:32px; padding-bottom:24px; border-bottom:1px solid #e0e0e0; position:relative;}
.wink-age li{position:absolute; display:table; width:134px; height:134px; padding-top:3px; border-radius:50%;}
.wink-age li p{display:table-cell; height:100%; vertical-align:middle; text-align:center; color:#fff; font-weight:bold; line-height:1.25;}
.wink-age li p small{display:block; margin-top:4px; font-size:14px; font-weight:normal;}
.wink-age li:before{position:absolute; top:3px; left:-1px; display:block; width:30px; height:30px; border-radius:50%; background:#666; text-align:center; color:#fff; font-weight:bold; line-height:32px; -webkit-box-shadow:0 3px 0 0 rgba(0,0,0,0.25); -moz-box-shadow:0 3px 0 0 rgba(0,0,0,0.25); box-shadow:0 3px 0 0 rgba(0,0,0,0.25);}
.wink-age li:nth-child(1):before{content:'1';}
.wink-age li:nth-child(2):before{content:'2';}
.wink-age li:nth-child(3):before{content:'3';}
.wink-age li:nth-child(4):before{content:'4';}
.wink-age li:nth-child(5):before{content:'5';}
.wink-age li:nth-child(6):before{content:'6';}
.wink-age li:nth-child(1) p small{color:#e8def4;}
.wink-age li:nth-child(2) p small{color:#d3f4f9;}
.wink-age li:nth-child(3) p small{color:#fffbec;}
.wink-age li:nth-child(4) p small{color:#e0f9ee;}
.wink-age li:nth-child(5) p small{color:#f9dfd9;}

.wink-age.low{height:347px; background:url(https://s.wink.co.kr/pc/images/wink/img_wink_age_study.jpg) no-repeat;}
.wink-age.low li:nth-child(1){top:4px; left:304px;}
.wink-age.low li:nth-child(2){top:4px; left:517px;}
.wink-age.low li:nth-child(3){top:210px; left:468px;}
.wink-age.low li:nth-child(4){top:210px; left:256px;}
.wink-age.low li:nth-child(5){top:210px; left:37px;}

.wink-age.middle{height:349px; background:url(https://s.wink.co.kr/pc/images/wink/img_wink_age_study2.jpg) no-repeat;}
.wink-age.middle li:nth-child(1){top:4px; left:284px;}
.wink-age.middle li:nth-child(2){top:4px; left:467px;}
.wink-age.middle li:nth-child(3){top:136px; left:574px;}
.wink-age.middle li:nth-child(4){top:208px; left:415px; width:138px; height:138px;}
.wink-age.middle li:nth-child(4) p{color:#212121;}
.wink-age.middle li:nth-child(4) p small{color:#f75959;}
.wink-age.middle li:nth-child(5){top:210px; left:228px;}
.wink-age.middle li:nth-child(5) p small{color:#e0f9ee;}
.wink-age.middle li:nth-child(6){top:210px; left:37px;}
.wink-age.middle li:nth-child(6) p small{color:#f9dfd9;}

.wink-age.high{height:349px; background:url(https://s.wink.co.kr/pc/images/wink/img_wink_age_study3.jpg) no-repeat;}
.wink-age.high li:nth-child(1){top:4px; left:284px;}
.wink-age.high li:nth-child(2){top:4px; left:467px;}
.wink-age.high li:nth-child(3){top:136px; left:574px;}
.wink-age.high li:nth-child(4){top:208px; left:415px; width:138px; height:138px;}
.wink-age.high li:nth-child(4) p{color:#212121;}
.wink-age.high li:nth-child(4) p small{color:#f75959;}
.wink-age.high li:nth-child(5){top:210px; left:228px;}
.wink-age.high li:nth-child(5) p small{color:#e0f9ee;}
.wink-age.high li:nth-child(6){top:210px; left:37px;}
.wink-age.high li:nth-child(6) p small{color:#f9dfd9;}*/

/* 사용자 후기 *
.wink-user-post{border-bottom:1px solid #e9e9e9;}
.wink-user-post ul{margin-left:-31px;}
.wink-user-post ul:after{display:block; content:""; clear:both;}
.wink-user-post li{float:left; width:219px; margin:0 0 30px 31px; overflow:hidden;}
.wink-user-post li .thumb{position:relative; height:123px; margin-bottom:20px; overflow:hidden; border-radius:8px;}
.wink-user-post li .thumb img{overflow:hidden;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);}
.wink-user-post li .thumb .btn-play{position:absolute; right:10px; bottom:10px; width:32px; height:32px; padding:0; background:url(https://s.wink.co.kr/pc/images/wink/btn_play_thumb.png) no-repeat 0 0;}
.wink-user-post li .title{display:block; overflow:hidden; margin-bottom:5px; font-size:18px; white-space:nowrap; text-overflow:ellipsis; word-break:break-all; color:#212121;}
.wink-user-post li p{display:block; display:-webkit-box; overflow:hidden; height:66px; font-size:14px; -webkit-line-clamp:3; -webkit-box-orient:vertical; text-overflow:ellipsis;}
.wink-user-post li .user{margin-top:25px; font-size:14px; color:#999;}
.wink-user-post li .user b{display:block; padding-left:22px; color:#666; background:url(https://s.wink.co.kr/pc/images/icon_user.png) no-repeat 0 4px;}*/

/* 사용후 학부모 만족도 그래프 */
.wink-satisfy-wrap {
  padding-top: 30px;
  border-top: 2px solid #676969;
}
.satisfy-container {
  margin-top: 25px;
}
.satisfy-content:after {
  display: block;
  content: '';
  clear: both;
}
.satisfy {
  float: right;
  width: 350px;
}
.satisfy:first-child {
  float: left;
}
.satisfy .satisfy-head {
  position: relative;
  box-sizing: border-box;
  height: 50px;
  padding-left: 100px;
  border-radius: 6px 6px 0 0;
  line-height: 50px;
  background-color: #ffe04d;
}
.satisfy .satisfy-head img {
  position: absolute;
  left: 31px;
  bottom: 0;
}
.satisfy .satisfy-head strong {
  font-weight: bold;
  color: #f03c78;
}
.satisfy .satisfy-body {
  overflow: hidden;
  box-sizing: border-box;
  height: 190px;
  padding: 0 16px 16px;
  border-radius: 0 0 6px 6px;
  background-color: #f5f7fa;
}

.bar-graph-wrap {
  padding-top: 24px;
}
.bar-graph-wrap .graph-info {
  position: relative;
  width: 286px;
  margin: 0 16px;
  padding-top: 85px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_graph_line.png)
    no-repeat 50% 0;
}
.bar-graph-wrap .graph-info .bar {
  position: absolute;
  left: 0;
  top: 30px;
  height: 10px;
  border-radius: 0 3px 3px 0;
  background-color: #a186be;
}
.bar-graph-wrap .graph-info .bar span {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 14px;
  color: #a186be;
}
.bar-graph-wrap .graph-info ul {
  margin: 0 -6px;
}
.bar-graph-wrap .graph-info ul:after {
  display: block;
  content: '';
  clear: both;
}
.bar-graph-wrap .graph-info li {
  float: left;
  margin-left: 29px;
  font-size: 12px;
}
.bar-graph-wrap .graph-info li:first-child {
  margin-left: 0;
}

.bar-graph-wrap .graph-text {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}
.bar-graph-wrap .graph-text:after {
  display: block;
  content: '';
  clear: both;
}
.bar-graph-wrap .graph-text span {
  display: inline-block;
  margin-left: 10px;
  padding-left: 70px;
  font-size: 14px;
  vertical-align: middle;
}
.bar-graph-wrap .graph-text span:nth-child(1) {
  margin-left: 0;
  padding-left: 0;
}
.bar-graph-wrap .graph-text span:nth-child(2) {
  background: url(https://s.wink.co.kr/pc/images/wink/bg_gt_left.png) no-repeat
    0 50%;
}
.bar-graph-wrap .graph-text span:nth-child(3) {
  right: 0;
  color: #f03c78;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_gt_right.png) no-repeat
    0 50%;
}

.satisfy .satisfy-body .star-graph {
  margin-top: 70px;
}
.star-graph {
  position: relative;
  width: 293px;
  height: 62px;
  margin: 0 auto;
  background: url(https://s.wink.co.kr/pc/images/wink/icon_star_base.png)
    no-repeat 0 0;
}
.star-graph .inner {
  position: absolute;
  height: 62px;
  background: url(https://s.wink.co.kr/pc/images/wink/icon_star_bar.png)
    no-repeat 0 0;
}

.wink-satisfy-video {
  position: relative;
  margin-top: 20px;
}
.wink-satisfy-video button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 95px;
  height: 95px;
  padding: 0;
  background: url(https://s.wink.co.kr/pc/images/wink/btn_play_big.png)
    no-repeat 0 0;
  transform: translate(-50%, -50%);
}
.wink-satisfy-video .info {
  position: absolute;
  left: 32px;
  bottom: 32px;
  color: #fff;
}
.wink-satisfy-video .info strong {
  display: block;
  font-size: 28px;
  line-height: 32px;
}
.wink-satisfy-video .info span {
  font-family: 'NanumBarunGothic-Light';
  font-size: 18px;
}

/* 이용 후기 테이블 */
.tbl-post .title a {
  width: calc(100% - 40px);
}
.tbl-post .title div {
  position: relative;
}
.tbl-post .title i {
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 25px;
  font-size: 14px;
  color: #757575;
  background: url(https://s.wink.co.kr/pc/images/wink/icon_answer.png) no-repeat
    0 50%;
}

/* 하단 검색 */
.notice-search-box {
  margin-top: 40px;
  text-align: center;
}
.notice-search-box .select-mode {
  width: 120px;
}
.notice-search-box input[type='text'] {
  width: 344px;
  margin: 0 5px;
}
.notice-search-box .btn-type3 {
  height: 40px;
  padding: 0 28px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 40px;
}

/* 체험신청 */
.wink-experience .list-style-dot {
  font-size: 14px;
  color: #999;
}
.wink-experience .list-style-dot:after {
  top: 8px;
}
.wink-experience .top-content {
  box-sizing: border-box;
  height: 360px;
  padding-top: 41px;
  text-align: center;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_experience.jpg)
    no-repeat 50% 0;
}
.wink-experience .top-content .info {
  margin-bottom: 22px;
  font-family: 'NanumBarunGothic-Light';
  font-size: 28px;
  line-height: 38px;
}
.wink-experience .top-content .info span {
  color: #212121;
}
.wink-experience .top-content .info strong {
  font-family: 'NanumBarunGothic', sans-serif;
  color: #f03c78;
}
.wink-experience .top-content .bubble {
  position: relative;
  box-sizing: border-box;
  width: 560px;
  height: 64px;
  margin: 0 auto;
  border-radius: 35px;
  box-shadow: 2px 2px 2px #e1dcdc;
  line-height: 64px;
  background: url(https://s.wink.co.kr/pc/images/wink/icon_wink_notice.png)
    no-repeat 24px 50% #fff;
}
.wink-experience .top-content .bubble:after {
  content: '';
  position: absolute;
  left: 330px;
  bottom: -18px;
  width: 22px;
  height: 18px;
  margin-left: -11px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_bubble.png) no-repeat 0
    0;
}
.wink-experience .top-content .bubble span {
  color: #f03c78;
}
.wink-experience .free-study {
  padding: 50px 0;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}
.wink-experience .free-study > strong {
  font-size: 28px;
  color: #212121;
}
.wink-experience .free-study > strong span {
  color: #08bbd5;
}
.wink-experience .free-study > small {
  display: block;
  margin-bottom: 10px;
}
.wink-experience .free-study p {
  line-height: 30px;
  color: #212121;
}
.wink-experience .free-study p strong {
  font-weight: bold;
}
.wink-experience .free-study .free-content {
  position: relative;
  margin-top: 35px;
  padding-top: 25px;
}
.wink-experience .free-study .free-content .bubble {
  box-sizing: border-box;
  position: absolute;
  left: 152px;
  top: 0;
  width: 133px;
  height: 117px;
  padding: 38px 0 0 16px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_experience_bubble.png)
    no-repeat 50% 0;
}
.wink-experience .free-study .free-content .bubble strong {
  display: block;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: #ffe816;
}
.wink-experience .free-study .free-content + p {
  line-height: 26px;
}
.wink-experience .wink-words-head {
  margin: 56px 0 32px;
  padding: 19px 0 18px 160px;
}
.wink-experience .wink-words-head img {
  position: absolute;
  left: 24px;
  bottom: 0;
}
.wink-experience .wink-words-head p {
  font-size: 16px;
}
.wink-experience .wink-words-head.bg03 p {
  height: 50px;
  line-height: 50px;
}
.wink-experience .wink-compose {
  padding-bottom: 28px;
  border-bottom: 1px solid #e0e0e0;
}

.wink-experience .wink-user-post {
  border-bottom-width: 0;
}
.wink-experience .wink-user-post li {
  float: none;
  width: auto;
  margin: 32px 0 0 0;
  border-bottom: 1px solid #e0e0e0;
}
.wink-experience .wink-user-post li:first-child {
  margin-top: 0;
}
.wink-experience .wink-user-post li figure:after {
  content: '';
  display: block;
  clear: both;
}
.wink-experience .wink-user-post li figure > a {
  float: left;
  position: relative;
}
.wink-experience .wink-user-post li figure > a .btn-play {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-size: contain;
}
.wink-experience .wink-user-post li figure figcaption {
  float: right;
  width: 469px;
}
.wink-experience .wink-user-post li figure figcaption > a {
  margin: -4px 0 6px 0;
  white-space: normal;
  text-overflow: clip;
  overflow: visible;
}
.wink-experience .wink-user-post li figure figcaption p {
  display: block;
  margin-bottom: 23px;
  text-overflow: clip;
  overflow: visible;
}
.wink-experience .wink-user-post li p.writer {
  position: relative;
  text-align: right;
}
.wink-experience .wink-user-post li p.writer strong {
  position: absolute;
  top: 0;
  left: 251px;
}
.wink-experience .btn-type3 {
  background-color: #f03c78;
}
.wink-experience .btn-type3:hover {
  background-color: #e52565;
}

/* 선택 panel */
.select-panel-list .text {
  margin-bottom: 32px;
  font-size: 24px;
  text-align: center;
}
.select-panel-list .text em {
  color: #08bbd5;
}
.select-panel-list li:first-child {
  margin-top: 0;
}
.select-panel {
  position: relative;
  padding-left: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.select-panel > input[type='radio'] + label {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 100%;
  margin: 0;
  padding: 0;
}
.select-panel > input[type='radio'] + label:before {
  left: 50%;
  top: 50%;
  margin: -12px 0 0 -12px;
}
.select-panel .tbl-row {
  border-top: 0 none;
  border-left: 1px solid #e0e0e0;
}
.select-panel .tbl-row table {
  border-bottom: 0 none;
}
.select-panel .tbl-row th,
.select-panel .tbl-row td {
  vertical-align: middle;
}
.select-panel .tbl-row tr:first-child th,
.select-panel .tbl-row tr:first-child td {
  padding-top: 18px;
}
.select-panel .tbl-row tr:last-child th,
.select-panel .tbl-row tr:last-child td {
  padding-bottom: 19px;
}
.select-panel .tbl-row th,
.select-panel .tbl-row td {
  padding: 3px 0;
}
.select-panel .tbl-row th {
  padding-left: 24px;
}
.select-panel .tbl-row td.left {
  padding-left: 24px;
}

.layer-wrap .select-panel-list {
  width: 560px;
}

.panel-tbl {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.panel-tbl .name {
  display: block;
  height: 63px;
  font-size: 18px;
  line-height: 63px;
  text-align: center;
  color: #212121;
}
.panel-tbl table {
  width: 100%;
  margin: 0 -1px;
}
.panel-tbl table td {
  padding: 27px 0;
  border: 1px solid #e0e0e0;
  border-width: 1px 0 0 1px;
  text-align: center;
  vertical-align: middle;
}
.panel-tbl table td:first-child {
  border-left: 0 none;
}
.panel-tbl table td.left {
  padding-left: 24px;
  text-align: left;
}
.panel-tbl table td.select {
  font-weight: bold;
  color: #212121;
}
.panel-tbl table td input[type='radio'] + label {
  margin: 0;
  padding: 0;
}
.panel-tbl table td input[type='radio'] + label:before {
  margin: -12px 0 0 -12px;
}
.panel-tbl table td strong {
  color: #f03c78;
}
.panel-tbl table td small {
  font-size: 14px;
  color: #999;
}
.panel-tbl table td .warring-box {
  margin: 26px 0 -27px -24px;
  padding: 17px 25px 22px;
  background-color: #f5f7fa;
}
.panel-tbl table td .warring-box .notice {
  color: #212121;
}
.panel-tbl table td.warring {
  background-color: #f5f7fa;
  padding-left: 24px;
  text-align: left;
}
.panel-tbl table td .list-style-dot {
  margin-top: 5px;
}
.panel-tbl table td .list-style-dot li {
  font-size: 14px;
}
.panel-tbl table td .tag .ko {
  color: #4385ff;
}
.panel-tbl table td .tag .math {
  color: #1ad49d;
}
/* issue_964 */
.panel-tbl.case {
  margin-top: 26px;
  font-size: 14px;
}
.panel-tbl.case .name {
  height: 50px;
  line-height: 50px;
}
.panel-tbl.case table td {
  padding: 10px 0;
}
.panel-tbl.case table td.member-event {
  text-align: left;
  padding-left: 17px;
}
.panel-tbl.case table td .notice {
  text-align: left;
  margin-left: 10px;
}
.panel-tbl.case table td.left {
  padding-left: 10px;
}
.panel-tbl.case table td.select {
  font-weight: inherit;
  color: inherit;
}
/* //issue_964 */

/* 유료 학습 신청 학습 선택 */
.wink-paid-age > .notice {
  margin-bottom: 25px;
  font-size: 14px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice2.png) no-repeat 0 0;
}
.wink-paid-age .top-box {
  margin-bottom: 48px;
  padding: 28px 29px 26px;
  border: 3px solid #c1e7e6;
  border-radius: 3px;
  background: url(https://s.wink.co.kr/pc/images/wink/bg_wink_visual2.png)
    no-repeat 100% 100% #e8f4f6;
}
.wink-paid-age .top-box strong {
  font-size: 18px;
  color: #212121;
}
.wink-paid-age .top-box p {
  line-height: 24px;
}
.wink-paid-age .level {
  margin-bottom: 44px;
  text-align: center;
}
.wink-paid-age .level strong,
.wink-paid-age .level em {
  display: inline-block;
  height: 40px;
  padding: 0 19px;
  border-radius: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 42px;
  color: #fff;
}
.wink-paid-age .level strong {
  background-color: #eb4d83;
}
.wink-paid-age .level em {
  background-color: #ff9933;
}
.wink-paid-age .level p {
  margin-top: 13px;
  font-family: 'NanumBarunGothic-Light';
  font-size: 24px;
  line-height: 34px;
  color: #212121;
}

.wink-paid-age .panel-box {
  margin-top: 35px;
}
.wink-paid-age .panel-head {
  font-size: 18px;
  text-align: center;
}
.wink-paid-age .panel-body .radio-list-col label {
  margin-right: 0;
  color: #212121;
}
.wink-paid-age .panel-body .radio-list-col .tag {
  margin-right: 5px;
  color: #212121;
}

.wink-paid-age ul.age-feature.total {
  width: 100%;
}
.wink-paid-age ul.age-feature:after {
  content: '';
  display: block;
  clear: both;
}
.wink-paid-age ul.age-feature li {
  float: left;
  width: 172px;
  text-align: center;
}
.wink-paid-age ul.age-feature li img {
  vertical-align: top;
}
.wink-paid-age ul.age-feature li figcaption {
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.4;
}

table.age-feature {
  width: 100%;
}
table.age-feature td {
  width: 170px;
  padding: 24px 0 20px;
  border-top: 1px solid #e0e0e0;
  vertical-align: top;
  text-align: center;
}
table.age-feature tr:first-child td {
  padding-top: 0;
  border-top-width: 0;
}
table.age-feature tr:first-child td:first-child {
  width: 178px;
  vertical-align: middle;
}
table.age-feature tr:last-child td {
  padding-bottom: 0;
}
table.age-feature td img {
  vertical-align: top;
}
table.age-feature td figcaption {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;
}

/* 결제 수단 선택 */
.payment-content {
  margin-bottom: 35px;
}
.payment-content .list-style-dot {
  font-size: 11px;
  line-height: 26px;
  background: #f5f5f5;
  padding: 20px;
}
.payment-content .tbl-row {
  border-top: 1px solid #e0e0e0;
}
.payment-content .tbl-row table {
  border-bottom: 0 none;
}
.payment-content .tbl-row td small {
  font-size: 14px;
  color: #999;
}
.payment-content .check-area span {
  color: #212121;
}
.payment-content .check-area span em {
  color: #f03c78;
}

.payment-content .list-style-dot .underline {
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.payment-content .payment-money {
  padding: 23px;
  border: 3px solid #bdbdbd;
  border-radius: 6px;
}
.payment-content .payment-money dl {
  display: flex;
  justify-content: center;
  text-align: center;
}
.payment-content .payment-money dl div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.payment-content .payment-money dl div span {
  color: #f03c78;
}
.payment-content .payment-money dt,
.payment-content .payment-money dd {
  display: inline-block;
  color: #212121;
  vertical-align: middle;
}
.payment-content .payment-money dt {
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
}
.payment-content .payment-money dd {
  font-size: 22px;
}
.payment-content .payment-money dd em {
  color: #00a0e0;
}
.payment-content .payment-money dd small {
  font-size: 14px;
  color: #666;
}
.payment-content .payment-money dd span {
  font-family: 'NanumBarunGothic-Light';
}
.payment-content .payment-money .coupon-info {
  margin-top: 12px;
  border-radius: 6px;
  line-height: 30px;
  text-align: left;
  background-color: #f5f5f5;
  padding: 10px;
}
.payment-content .payment-money .coupon-info span {
  display: inline-block;
  padding-left: 30px;
  font-size: 14px;
  color: #999;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat 0
    50%;
}
.payment-content .payment-money .coupon-info.center {
  text-align: center;
}
.payment-money-info {
  margin: 50px 0 40px;
}

/* layer */
.layer .text-content {
  margin: 15px 0 45px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.layer .bottom {
  padding: 25px 20px;
  margin-top: 30px;
  border-top: 1px solid #e0e0e0;
  word-break: break-all;
}
.layer .layer-wrap .bottom p {
  line-height: 24px;
}
.layer .layer-wrap .bottom h2 {
  color: #212121;
  font-size: 22px;
  margin-bottom: 10px;
}
.layer .layer-wrap .bottom h3 {
  margin: 10px 0;
}

.layer .notice.bottom {
  margin: 32px 0 14px;
  padding: 14px 0 0 28px;
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
  background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat 0
    15px;
}

/* learn-info */
.layer .learn-info {
  margin-top: 25px;
}
.layer .learn-info strong {
  font-size: 18px;
  color: #212121;
}
.layer .learn-info .list-style-dot {
  margin-top: 10px;
}
.layer .learn-info .list-style-dot li {
  font-size: 14px;
}
.layer .learn-info .list-style-dot li span {
  color: #08bbd5;
}

.layer-wrap.large .text-content,
.layer-wrap.large .gray-box,
.layer-wrap.large .notice {
  box-sizing: border-box;
  width: 560px;
}

/* issue_977 */
.guide-area {
  text-align: center;
  padding: 42px 0 48px;
  color: #212121;
}
.guide-area .logo {
  width: 43px;
  height: 18px;
  vertical-align: middle;
}
/* // issue_977 */

/* issue_1118 */
.apply-free-study-notice {
  text-align: center;
}
.apply-free-study-notice p {
  font-size: 16px;
  line-height: 1.6;
  color: #212121;
}
.apply-free-study-notice p em {
  color: #f03c78;
}
.apply-free-study-notice .small {
  font-size: 13px;
}
.apply-free-study-notice .btn-type2 {
  padding: 9px 22px;
  line-height: 22px;
  height: 60px;
}
.apply-free-study-notice .btn-type2 em {
  color: #fce100;
}
/* //issue_1118 */

.wink-paid-study .age-feature.total {
  width: 100%;
}

#contents.wink-paid-study {
  width: 1000px;
}
#contents.wink-paid-study .no-line.title {
  border-bottom: 2px solid #676969;
  font-size: 25px;
  color: #222;
  font-weight: 600;
}

#contents.wink-paid-study .paid-study-wifi {
  padding-bottom: 10px;
}

/****************************************************************************** wink-free-study *************************************************************/

#contents.wink-free-study {
  width: 1000px;
  padding-bottom: 50px;
  font-family: 'Pretendard', sans-serif;
}

#contents.wink-free-study #request-study-form-id {
  scroll-margin-top: 80px;
}

#contents.wink-free-study * {
  font-family: 'Pretendard', sans-serif !important;
}

#contents.wink-free-study h3 {
  background: #fff;
  margin-bottom: 0;
}

#contents.wink-free-study-right-after-join {
  padding-top: 0;
}

.wink-free-study .section-01 {
  position: relative;
}

.wink-free-study .section-01 .btn-area {
  width: 100%;
  margin: 0;
  background-color: #f8f6f3;
}

.wink-free-study .section-01 .btn-area .btn-freestudy {
  font-size: 40px;
  font-weight: bold;
  border-radius: 40px;
  background-color: #3656a9;
  color: white;
  width: 480px;
  height: 80px;
  line-height: 2.1;
  display: inline-block;
  box-shadow: 0 3px 1px grey;
}
.wink-free-study .section-01 .btn-area .btn-freestudy::after {
  background: url(https://s.wink.co.kr/pc/images/info/bg_arrow_circle_right.jpg)
    no-repeat;
  background-size: contain;
  display: inline-block;
  content: '';
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-left: 12px;
  margin-bottom: 7px;
  border-radius: 50%;
}

.wink-free-study .section-02 {
  margin-bottom: 20px;
}

.wink-free-study .section-menu {
  margin-bottom: 15px;
}

.wink-free-study .section-menu li {
  width: 33.3333%;
}

.wink-free-study table.form {
  border-top: none;
}

.wink-free-study .wink-config {
  text-align: center;
  position: relative;
}

.wink-free-study .wink-config img {
  width: 25%;
}

.wink-free-study .wink-config img:first-child {
  margin-right: 80px;
}

.wink-free-study .wink-config .plus-icon {
  position: absolute;
  width: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.wink-free-study .hgroup {
  padding: 12px 0;
}

.wink-free-study .hgroup .title {
  border-bottom: 2px solid #676969;
  font-size: 30px;
  color: #222;
  font-weight: 600;
}

.wink-free-study .check-wrap .hgroup .title {
  font-size: 30px;
}

.wink-free-study fieldset.name input[type='text'],
.wink-free-study fieldset.phone input.long[type='text'],
.wink-free-study fieldset.phone,
.wink-free-study fieldset.phone.long input[type='text'],
.wink-free-study fieldset.address input[type='text'] {
  width: 386px;
}

.wink-free-study fieldset.join-code input[type='text'] {
  width: 304px;
}

.wink-free-study fieldset.phone input[type='text'],
.wink-free-study fieldset.address input[type='text'].post {
  width: 244px;
}

.wink-free-study fieldset.address input[type='text'].post {
  margin-right: 7px;
}

.wink-free-study fieldset.year .select-mode {
  margin: 0 9px 0 25px;
}

.wink-free-study fieldset.year .select-mode:nth-of-type(1) {
  width: 107px;
  margin-left: 0;
}

.wink-free-study fieldset.year .select-mode:nth-of-type(2),
.wink-free-study fieldset.year .select-mode:nth-of-type(3) {
  width: 91px;
}

.wink-free-study div.select-mode > button {
  height: 46px;
  border: 1px solid #cccccc;
}

.wink-free-study div.select-mode.on > button {
  border-bottom: none;
}

.wink-free-study div.select-mode div {
  border-color: #ccc;
}

.wink-free-study fieldset.phone a.btn-type {
  margin-left: 7px;
}

.wink-free-study .phone .item .confirm.disabled {
  width: 244px !important;
  margin-right: 7px !important;
}

.wink-free-study .phone .item .confirm {
  width: 244px;
  margin-right: 7px;
}

.wink-free-study fieldset.phone .text-valid {
  position: absolute;
  top: 16px;
  left: 180px;
  display: inline-block;
  color: #f59c00;
  font-size: 12px;
  line-height: 18px;
  margin: 0 8px;
}

.wink-free-study fieldset.phone .text-valid span {
  color: #0fbee3;
  display: block;
  font-size: 18px;
  font-weight: 400;
}

.wink-free-study .btn-type2 {
  padding: 0 15px;
  background: #fff;
  color: #222;
}

.wink-free-study .new-btn-area .new-btn.btn-confirm {
  width: 190px;
  padding: 14px 18px 14px 24px;
  font-size: 18px;
  background: #09bad5;
  border-color: #09bad5;
  line-height: normal;
}

.wink-free-study .new-btn-area .new-btn.btn-confirm img {
  margin-left: 5px;
  margin-bottom: 2px;
  transform: scale(1.2);
}

.wink-free-study table.form p.notice {
  color: #f03c78;
}

.wink-free-study .btn-type2.on {
  background: #09bad5;
  border-color: #09bad5;
}
.wink-free-study > div > img {
  vertical-align: top;
}

.wink-free-study section h4 {
  padding: 0 15px 13px;
}

.wink-free-study .agree-wrap {
  margin: 40px 20px 0;
}

.wink-free-study input[type='text'] {
  height: 46px;
  font-size: 18px;
  padding: 11px 14px;
}

.wink-free-study input[type='text']:focus {
  outline-color: #09bad5;
}
.wink-free-study input::-moz-placeholder {
  font-family: 'Pretendard', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #cccccc;
}
.wink-free-study input::placeholder {
  font-family: 'Pretendard', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #cccccc;
}
.wink-free-study input:-ms-input-placeholder {
  font-family: 'Pretendard', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #cccccc;
}
.wink-free-study input::-moz-placeholder {
  opacity: 1;
  font-family: 'Pretendard', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #cccccc;
}
.wink-free-study input:-moz-placeholder {
  opacity: 1;
  font-family: 'Pretendard', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #cccccc;
}

.wink-free-study input[type='checkbox']:not(:checked) + label:before {
  background: url('https://s.wink.co.kr/pc/renewalV3/common/check-off.png')
    no-repeat;
}

.wink-free-study .btn-type,
.wink-free-study .btn-type2.confirm-num {
  height: 46px;
  line-height: 46px;
  width: 135px;
  font-size: 18px;
  padding: 0;
}

.wink-free-study .btn-type:hover {
  background-color: #09bad5;
}

.wink-free-study .btn-type {
  background: #09bad5;
}

.wink-free-study .btn-type2 {
  background-color: #bcbcbc;
  color: white;
}
.wink-free-study .btn-type2:hover {
  border-color: #bcbcbc;
}

.wink-free-study .btn-type.on {
  background-color: #09bad5;
}

.wink-free-study table.form {
  border-bottom: none;
}

.wink-free-study table.form th {
  font-size: 18px;
  padding-right: 20px;
}

.wink-free-study table.form .item {
  position: relative;
}

.wink-free-study table.form p.notice {
  background: none;
  margin-left: 0;
  padding-left: 0;
}

.wink-free-study input[type='checkbox'] + label {
  font-size: 18px;
}

.wink-free-study .terms .all-check label,
.wink-free-study
  .agree-wrap
  .agree-box
  > li
  > div
  input[type='checkbox']
  + label {
  color: #333;
}

.wink-free-study .agree-wrap .agree-box {
  border-top: 1px solid #cccccc;
  padding-top: 16px;
}

.wink-free-study .agree-wrap .agree-box > li {
  padding: 0 16px 0 9px;
  border-bottom: none;
}

.wink-free-study .agree-wrap .agree-box > li .accordion-header {
  height: 40px;
  line-height: 40px;
}

.wink-free-study input[type='radio'] + label {
  padding-left: 26px;
}

.wink-free-study input[type='radio'] + label:before {
  top: 3px;
  width: 18px;
  height: 19px;
  background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-radio-off.svg')
    no-repeat 0 0;
}

.wink-free-study input[type='radio']:checked + label:before {
  background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-radio-on.svg')
    no-repeat 0 0;
}

/****************************************************************************** wink-free-study end *************************************************************/
.check-wrap .fixed-btn input[type='radio']:not(:checked) + label:before {
  background-position: -48px 0;
}

.wink-paid-study .hgroup {
  padding: 12px 0;
}

.wink-paid-study .hgroup .title {
  font-size: 23px;
  color: #222;
}

.wink-free-study .check-wrap,
.wink-paid-study .check-wrap {
  margin: 0;
}

.wink-paid-study .check-wrap .level-setting {
  margin-bottom: 10px;
}

.wink-paid-study .check-wrap .level-setting li {
  margin-top: 5px;
}

.wink-free-study .check-wrap .description {
  margin: 20px 0;
  font-size: 15px;
}

.wink-paid-study .check-wrap .notice-wrap {
  padding: 12px;
}

.wink-paid-study .check-wrap .notice-wrap .notice-title {
  position: relative;
  padding-left: 30px;
  line-height: 24px;
  color: #666666;
}

.wink-paid-study .check-wrap .notice-wrap .description {
  font-size: 15px;
  margin-left: 29px;
  color: #666666;
}

.wink-paid-study .check-wrap .notice-wrap .notice-title::before {
  width: 24px;
  height: 24px;
  background: url('https://s.wink.co.kr/pc/images/icon_notice.png') no-repeat;
  content: '';
  position: absolute;
  left: 0;
}
.wink-free-study .check-wrap .panel-box .description,
.wink-paid-study .check-wrap .panel-box .description {
  padding: 0;
  color: #565656;
  font-size: 14px;
}

.wink-free-study .check-wrap .panel-box .panel-body > .description,
.wink-paid-study .check-wrap .panel-box .panel-body > .description {
  margin: 0px 18px 20px;
  color: #565656;
  display: inline-block;
}

.wink-free-study .check-wrap .panel-box input[type='radio'] + label,
.wink-paid-study .check-wrap .panel-box input[type='radio'] + label {
  font-size: 15px;
  color: #212121;
}

.wink-free-study .bottom-description,
.wink-paid-study .bottom-description {
  font-size: 15px;
  color: #212121;
  padding-left: 32px;
  margin: 17px 6px 0;
  display: inline-block;
}

.wink-free-study .bottom-description:before,
.wink-paid-study .bottom-description:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 21px;
  border-radius: 4px;
  top: 2px;
  left: -4px;
  background: #00a0e0
    url('https://us.wink.co.kr/pc/renewalV3/common/exclamation_mark.png') -1.5px
    0px no-repeat;
  background-size: 20px 20px;
}

/****************************************************************************** wink-paid-study *************************************************************/

.wink-paid-study .section-menu {
  margin-bottom: 45px;
}
.wink-paid-study .section-menu li {
  width: 25%;
}

.wink-paid-study .color-box {
  background: none;
  padding: 0;
  border-radius: 0;
  margin: 0 0 40px 0;
}

.wink-paid-study .check-wrap .hgroup {
  margin-bottom: 15px;
}

.wink-paid-study .check-wrap .panel-list {
  padding: 32px 0 0;
  border-top: 2px solid #676969;
}

.wink-paid-study .card {
  font-family: 'Pretendard', sans-serif;
  line-height: 1.5;
  color: #212121;
}

.wink-paid-study .card .card-top {
  height: 75px;
  line-height: 75px;
}

.wink-paid-study .card.recommend {
  border: 1px solid #09bad5;
}

.wink-paid-study .card.recommend .card-top {
  background: #ddf5f8;
}

.wink-paid-study .card .card-top .title {
  font-size: 24px;
  font-weight: bold;
}

.wink-paid-study .card.opened.on .card-top {
  font-family: 'Pretendard', sans-serif;
  border-bottom: 1px solid #dadada;
}

.wink-paid-study .card:nth-child(1).opened.on .card-top {
  border-bottom: none;
}

.wink-paid-study .card .card-top .title-area .recommended-area {
  position: relative;
  top: -3px;
  margin-right: 10px;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 18px;
  background: #09bad5;
  border-radius: 5px;
}

.wink-paid-study .card .card-top .title-area .title-span.bold {
  font-family: 'Pretendard', sans-serif;
  font-weight: 700;
  color: #333333;
}

.wink-paid-study .card .card-top .title-area .select-btn {
  font-family: 'Pretendard', sans-serif;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  line-height: 18px;
  padding: 5px 15px 5px;
  border-radius: 15px;
  color: #333;
  margin-left: 10px;
  vertical-align: middle;
  box-sizing: border-box;
  position: absolute;
  right: 30px;
  top: 24px;
  background-color: #fff;
  width: 70px;
  height: 30px;
}

.wink-paid-study .card.on .card-top .title-area .select-btn {
  background-color: #f03c78;
  color: #fff;
  border: 1px solid #f03c78;
  font-weight: 500;
}

.wink-paid-study .card .card-top .util-area {
  font-size: 18px;
  margin-top: -28px;
}

.wink-paid-study .card .normal-price {
  display: flex;
}

.wink-paid-study .card .replace-price {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  margin-right: 5px;
  font-size: 24px;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
}

.wink-paid-study .card .replace-price em {
  font-family: 'Pretendard', sans-serif;
  font-weight: 700;
  margin-left: 10px;
}

.wink-paid-study .card .replace-price.normal {
  font-size: 24px;
}

.wink-paid-study .card .replace-price .label {
  font-weight: 400;
  font-size: 20px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: black;
  background: #d9d9d9;
  border-radius: 5px;
    margin-top: 3px;
}

.wink-paid-study .card .replace-price .prev-price {
  background: url(https://s.wink.co.kr/pc/1.1.0/common/bg_price_replace_arrow.png)
    0 center no-repeat;
  background-size: 100%;
  padding: 2px 18px 0 5px;
  margin-left: 12px;
    font-size: 20px;
}

.wink-paid-study .card .special-price {
  font-family: 'Pretendard', sans-serif;
  font-weight: 700;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  margin-left: 125px;
}

.wink-paid-study .card .special-price .label {
  font-weight: 500;
  width: 60px;
  height: 30px;
  border-radius: 5px;
  background: #ff1e7c;
  color: white;
  font-size: 19px;
  text-align: center;
  line-height: 30px;
  margin-top: 3px;
}

.wink-paid-study .card .special-price .price {
  margin-left: 12px;
  font-size: 24px;
  color: #ff1e7c;
}

.wink-paid-study .divide-line {
  margin: 0 20px;
  background: #cecece;
}

.wink-paid-study .card-contents > div {
  margin-bottom: 3px;
  word-break: keep-all;
}

.wink-paid-study .card-contents .event-price-img > img {
  height: 1.2em;
}

.wink-paid-study .card-contents > .sub-title {
  font-size: 24px;
  font-weight: bold;
}

.wink-paid-study .card-contents > div:last-child {
  margin-bottom: 0;
}

.wink-paid-study .card-contents > div.study-area {
  font-size: 18px;
  position: relative;
  padding-left: 88px;
  margin-top: 10px;
}

.wink-paid-study .card-contents > div.study-area span {
  position: absolute;
  top: 0;
  left: 0;
}

.wink-paid-study .card-contents > div.price-notice-area {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0;
  padding: 10px 20px 20px 20px;
}

.wink-paid-study .card-contents .product-notice {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  list-style-type: disc;
  margin: 30px 0 0 20px;
  font-size: 20px;
  color: #333333;
}

.wink-paid-study .card-contents .product-notice .product-infos > p.bold {
  font-weight: 700;
}

.wink-paid-study .card-contents .product-notice .product-infos .infos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wink-paid-study .card-contents .product-notice .product-infos .infos.line {
  flex-wrap: nowrap;
  flex-direction: column;
}

.wink-paid-study
  .card-contents
  .product-notice
  .product-labels
  .labels.line
  > li,
.wink-paid-study
  .card-contents
  .product-notice
  .product-infos
  .infos.line
  > span {
  width: 100%;
}

.wink-paid-study .card-contents .product-notice .product-infos .infos > span,
.wink-paid-study .card-contents .product-notice .product-labels .labels > li {
  width: 50%;
}

.wink-paid-study .card-contents .product-notice .product-labels {
  margin-top: 18px;
}

.wink-paid-study .card-contents .product-notice .product-labels .labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
}

.wink-paid-study .card-contents .product-notice .product-labels .labels.line {
  flex-wrap: nowrap;
}

.wink-paid-study
  .card-contents
  .product-notice
  .product-labels
  .labels
  > li
  span {
  position: relative;
  left: -10px;
}

.wink-paid-study
  .card-contents
  .product-notice
  .product-labels
  .labels
  > li.emphasis {
  color: #f03c78;
}

.wink-paid-study .card-contents .product-notice .labels > li.emphasis-blue {
  list-style: none;
  color: #07bbd4;
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
}

.wink-paid-study
  .card-contents
  .product-notice
  .labels
  > li.emphasis-blue
  > img {
  width: 18px;
  padding-bottom: 1px;
  margin-right: 2px;
}

.wink-paid-study
  .card-contents
  .product-notice
  .labels
  > li.emphasis-blue
  > span {
  margin-left: 13px;
}

.wink-paid-study .card-contents > p.keyword-ungeneral {
  color: #f03c78;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.wink-paid-study .card-contents > p.keyword-general {
  padding-left: 10px;
  background: url('https://s.wink.co.kr/app/parents/images/bu_dot2.png')
    no-repeat 0 6px / 5px;
  font-size: 18px;
}

.wink-paid-study .card-contents > p.study-area em,
.wink-paid-study .card-contents > p.keyword-general em {
  color: #f03c78;
  font-weight: bold;
}

.wink-paid-study .card-contents > p.keyword-general span {
  display: block;
}

.wink-paid-study .already-study {
  text-align: center;
}
.wink-paid-study .already-study .bottom-description {
  margin: 0 auto;
}

.wink-paid-study .shop-category {
}

.wink-paid-study .shop-category .product-select {
  border: 1px solid #d2d2d2;
  margin-bottom: 30px;
}

.wink-paid-study .shop-category .product-select::after {
  clear: both;
  display: block;
  content: '';
}

.wink-paid-study .shop-category .product-select li {
  float: left;
  width: 50%;
  background: #fff;
  font-size: 18px;
  color: #00a0e0;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.wink-paid-study .shop-category .product-select li.on {
  background: #00a0e0;
  color: #fff;
}

.wink-paid-study .shop-category .product-list .product-info {
  margin: 45px 0;
  color: #212121;
}

.wink-paid-study .shop-category .product-list .product-info li {
  font-size: 14px;
}

.wink-paid-study .shop-category .product-list .product {
  font-size: 24px;
  margin-bottom: 10px;
}

.wink-paid-study.step-2 .new-btn-area {
  padding: 75px 0;
  text-align: center;
}

.wink-paid-study.step-2 .new-btn-area .new-btn {
  display: inline;
  padding: 16px 18px;
  min-width: 100px;
  height: 50px;
  text-align: center;
  margin: 0 5px;
  color: #222;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.wink-paid-study.step-2 .new-btn-area .new-btn.btn-confirm {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  background: #09bad5;
  border-color: #09bad5;
  color: #fff;
}

/****************************************************************************** wink-paid-study  end *************************************************************/

/* 윙크 탄생배경 */
.wink-history {
  padding: 39px 0 133px;
  width: 900px;
  margin: 0 auto;
}

.wink-history .btn-type2 {
  height: auto;
  line-height: 42px;
  padding: 21px 49px;
  border-radius: 64px;
  font-size: 30px;
  margin: 0 20px;
}

/* 윙크 한눈에 보기 */

.wink-looks .wink-looks-device {
  position: relative;
  padding-bottom: 30px;
}

.wink-looks .link-btn {
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 350px;
  margin-left: -175px;
  background: #f03c78;
  font-size: 23px;
  font-weight: bold;
  color: #fff;
  padding: 9px 0;
  text-align: center;
  border-radius: 28px;
  border: 1px solid #ca3f6e;
}

.wink-looks .wink-looks-price > img {
  vertical-align: bottom;
}
.wink-looks .wink-looks-price .btn-area {
  background: #f8f8f8;
  padding: 0 0 45px;
  margin: -1px 0 0;
  z-index: 10;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.wink-looks .wink-looks-price .btn-area .link-btn {
  display: block;
  position: relative;
  bottom: auto;
  left: auto;
  margin: 0 auto 15px;
  border-radius: 46px;
  line-height: 31px;
}

.wink-looks .wink-looks-price .btn-area .link-btn span {
  color: #ffff00;
}
.wink-looks .wink-looks-price .btn-area .link-btn.white {
  background: #fff;
  border-color: #dadada;
  color: #202021;
}

.wink-looks .wink-looks-price .btn-area .link-btn.white span {
  color: #202021;
  font-weight: bold;
}

/* 회원정보 등록 완료 */
.member-issue-finish-pop .finish-form .select-mode ~ span {
  position: relative;
  display: inline-block;
  color: #999;
  line-height: 40px;
  padding-left: 5px;
  font-size: 16px;
  vertical-align: middle;
}

/* 윙크소개 - 과목별 학습 */
.subject-section .subject-section-step .section-step-top {
  background: #ffd83c;
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0;
  text-align: center;
  color: #212121;
  border-radius: 10px 10px 0 0;
  margin-bottom: 8px;
}

.subject-section .subject-section-step .section-step-top .step-title span {
  font-weight: normal;
  display: inline-block;
  margin-left: 5px;
}

.subject-section .subject-section-step .section-step-media {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 8px;
}

.subject-section .subject-section-step .section-step-media img {
  width: 100%;
}

.subject-section .subject-section-step .section-step-media.no-media {
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  height: 140px;
  padding: 58px 0 57px;
}

.subject-section .subject-section-step .section-step-media.no-media > div {
  text-align: center;
}

.subject-section .subject-section-step .section-step-media.no-media .info-rec {
  color: #f03c78;
  font-weight: bold;
}

.subject-section img {
  vertical-align: middle;
}

.subject-section .subject-section-step .section-step-media .step-media-title {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  width: calc(100% - 2px);
  box-sizing: border-box;
  padding: 16px;
  font-size: 20px;
  color: #767676;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.6);
}

.subject-section
  .subject-section-step
  .section-step-media
  .step-media-title
  span {
  font-weight: normal;
  font-size: 15px;
  margin-left: 15px;
}

.subject-section .subject-section-step .section-step-bottom {
  margin-bottom: 30px;
}

.subject-section .new-btn {
  border-radius: 26px;
  padding: 0 50px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

/* teacher-interview */

.teacher-interview .video-area {
  position: relative;
  width: 100%;
}

.teacher-interview .video-area .video-warp {
  position: relative;
}

.teacher-interview .video-area.on .video-warp::after {
  display: none;
}

.teacher-interview .video-area.on:hover .video-warp::after {
  opacity: 0.8;
  display: block;
  background: url('https://s.wink.co.kr/pc/images/main/btn_video_pause.png')
    no-repeat 0 0/56px;
}

.teacher-interview .video-area .video-warp img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.teacher-interview .video-area .video-warp video {
  width: 100%;
}

.teacher-interview .video-area .video-warp .running-time {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 56px;
  margin: 29px 0 0 -28px;
  z-index: 500;
  color: #fff;
  text-align: center;
}

.teacher-interview .video-area.on .video-warp .running-time {
  display: none;
}

.payment-method li {
  margin-top: 0;
}

.single-payment-purchase-subject {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-payment-purchase-subject em {
  cursor: pointer;
}

.single-payment-purchase-subject > div {
  width: 80%;
}

.layer .layer-wrap.small.single-payment-popup {
  margin-top: -139px;
  padding: 0;
}

.single-payment-purchase-detail {
  padding: 20px;
  margin-top: 50px;
}

.single-payment-purchase-detail .title {
  position: absolute;
  top: 15px;
  font-size: 20px;
  color: #00a0e0;
}

.single-payment-id {
  color: #00a0e0;
  text-decoration: none;
}

.single-payment-purchase-detail .purchase-list-container > div {
  display: flex;
  margin-bottom: 10px;
  text-align: left;
}

.single-payment-purchase-detail .purchase-list-container > div > div {
  padding: 5px 0;
}

.single-payment-purchase-detail .purchase-list-container > div .category {
  width: 10%;
  padding-right: 15px;
  word-break: keep-all;
}

.single-payment-purchase-detail
  .purchase-list-container
  > div
  .category-content {
  width: 90%;
  text-align: left;
  word-break: break-word;
}

.single-payment-purchase-detail
  .purchase-list-container
  > div:first-of-type
  .category-content {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
}

.single-payment-purchase-detail
  .purchase-list-container
  > div
  .category-content {
  border-bottom: 1px solid #d7d7d7;
}

.google-apply-container input {
  width: 250px;
}

.google-apply-container {
  width: 700px;
  margin: 0 auto;
}

.google-apply-info-container {
  padding: 3%;
  border-bottom: 1px solid black;
}

.google-apply-info-container > div > span:first-child {
  display: inline-block;
  width: 120px;
}

.google-apply-info-container > div {
  margin-bottom: 15px;
}

.google-apply-info-container .description {
  display: block;
  margin-left: 120px;
}

.google-apply-info-container .address-container .address-input-container {
  display: inline-block;
}

.google-apply-info-container .address-container > span {
  vertical-align: top;
}
.google-apply-info-container .address-container .address-input-container input {
  margin-bottom: 7px;
}

.google-apply-info-container
  .address-container
  .address-input-container
  input:not(:first-child) {
  display: block;
  width: 450px;
}

.google-apply-info-container
  .address-container
  .address-input-container
  .btn-type {
  position: relative;
  top: -4px;
}

.google-apply-info-container .google-apply-gender-radio-container {
  display: inline-block;
}

.google-apply-footer-container {
  background-color: #ecfdfd;
  padding: 3%;
}

.google-apply-footer-container .cs-center-info-container span {
  position: relative;
  left: 5px;
}

.google-apply-footer-container .cs-center-info-container .cs-center-number {
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  font-size: 18px;
  border-radius: 10px;
  font-weight: bold;
}

.google-apply-footer-container .company-info-container {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  font-size: 14px;
  line-height: 22px;
}

.google-apply-fixed-header {
  position: fixed;
  z-index: 999;
}

.google-apply-fixed-header img {
  width: 100%;
  height: 210px;
}

.google-apply-info-wrapper,
.google-apply-complete-info-wrapper {
  position: relative;
  top: 210px;
}

.google-apply-info-container .panel-list .panel-box .panel-head .subject {
  color: #08bbd5;
}

.google-apply-info-container .panel-list .radio-list-col .step {
  color: black;
  font-weight: bold;
}

.google-apply-info-container .panel-list .panel-box .level-sub-description {
  padding-left: 30px;
}

.google-apply-info-container .panel-body .radio-list-col label {
  font-family: 'NanumBarunGothic';
}

.google-apply-info-container .panel-body .radio-list-col {
  padding-bottom: 5px;
}

.google-apply-info-container .student-birth-container {
  display: flex;
}

.google-apply-info-container .student-birth-input-container {
  display: inline-block;
}

.google-apply-info-container .student-birth-input-container .description {
  margin-left: 0;
}

.google-apply-container .google-apply-submit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  position: relative;
  top: -20px;
}

.google-apply-container .google-apply-submit-button-container div {
  width: 20%;
  background-color: #f03c78;
  height: 30%;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.google-apply-info-container .check-all {
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}

.google-apply-info-container .each-agreement {
  position: relative;
  left: 10px;
  top: -8px;
}

.google-apply-info-container .each-agreement > div {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  line-height: 55px;
}

.google-apply-info-container.agreement-container {
  padding: 3% 3% 0 3%;
  border-bottom: none;
}

.google-apply-complete-phrases-container {
  text-align: center;
}

.google-apply-complete-phrases-container .google-apply-complete-phrases1 {
  font-size: 25px;
  padding-bottom: 20px;
}

.google-apply-complete-phrases-container .google-apply-complete-phrases2 {
  font-size: 18px;
  padding-bottom: 40px;
}

.google-apply-complete-phrases-container
  .google-apply-complete-phrases2
  span.text-emphasis {
  color: #f03c78;
}

.google-apply-complete-info-container > div {
  padding: 0 20px;
}

.google-apply-complete-info-container .google-apply-complete-info-title {
  font-size: 22px;
  color: #00b1e5;
  line-height: 40px;
  padding-left: 40px;
}

.google-apply-complete-info-container
  .google-apply-complete-info-title.delivery {
  background: url('https://s.wink.co.kr/pc/images/google_apply/icon_delivery.png')
    no-repeat 5px 50% #f2f2f2;
}

.google-apply-complete-info-container .google-apply-complete-info-title.notice {
  background: url('https://s.wink.co.kr/pc/images/google_apply/icon_notice.png')
    no-repeat 5px 50% #f2f2f2;
}

.google-apply-complete-info-container .personal-info div {
  line-height: 42px;
}

.google-apply-complete-info-container .personal-info strong {
  width: 20%;
  color: black;
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
}

.google-apply-complete-info-container .personal-info p {
  display: inline-block;
  width: 70%;
}

.google-apply-info-container.agreement-container
  .each-agreement
  .agreement-button {
  position: relative;
  top: 5px;
  float: right;
  right: 10px;
  border: 1px solid #08bbd5;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  color: #08bbd5;
  height: 40px;
  line-height: 40px;
}

.google-apply-complete-info-container
  .personal-info
  div:not(:last-child):after {
  content: '';
  display: block;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  border-bottom: 1px solid #e8e8e8;
}

.google-apply-complete-info-container .google-apply-precautions {
  background-color: #f9f9f9;
  line-height: 30px;
  margin-bottom: 80px;
}

.google-apply-complete-info-container .google-apply-precautions p {
  position: relative;
  padding-left: 15px;
}

.google-apply-complete-info-container .google-apply-precautions p:before {
  content: '\318D';
  position: absolute;
  top: 0;
  left: 0;
}

.google-apply-incorrect-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  flex-direction: column;
}

.google-apply-incorrect-container .button-container {
  margin-top: 15px;
}

.google-apply-incorrect-container .button-container .custom-button {
  padding: 10px 20px;
  background-color: #f03c78;
  color: white;
  border-radius: 10px;
}

.google-apply-container .text-emphasis {
  color: #f03c78;
}

.google-apply-info-container .radio-list-col .step {
  font-weight: bold;
}

.google-apply-footer-container .company-info-container .agreement {
  font-weight: bold;
}

#mainContainer .content-area {
  min-width: 1260px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fffef9;
  flex-direction: column;
}

#mainContainer .content-area .main-content-item .main-content-item-wrapper {
  position: relative;
  width: 1260px;
}

#mainContainer .content-area .main-content-item img {
  display: flow-root;
  width: 100%;
}

#mainContainer .content-area .video-section {
  width: 1100px;
  position: relative;
}

#mainContainer .content-area .video-section .video-list-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container {
  width: 50%;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  video {
  width: 100%;
  height: auto;
  -o-object-fit: fill;
     object-fit: fill;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  video[poster] {
  width: 100%;
  height: auto;
  -o-object-fit: fill;
     object-fit: fill;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .play-button.selected.isPlaying {
  display: none;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .video-wrapper:hover
  .play-button.selected.isPlaying {
  display: block;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .video-wrapper {
  position: relative;
  width: 100%;
  height: 280px;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .video-wrapper
  video {
  background: white;
  width: 100%;
  height: 100%;
}

#mainContainer
  .content-area
  .video-section
  .video-list-container
  .video-container
  .video-text {
  margin-top: 10px;
  text-align: center;
  font-size: 23px;
  color: black;
}

#mainContainer .bottom-area .video-container {
  position: absolute;
  left: 49%;
  transform: translate(-50%, 0);
  width: 690px;
  height: 430px;
  bottom: 28.14%;
}

#mainContainer .bottom-area > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#mainContainer .bottom-area .fixed-height > div {
  height: 100%;
}
#mainContainer .bottom-area .fixed-height div img {
  display: flow-root;
}

#mainContainer .bottom-area .first {
  background: url(https://s.wink.co.kr/pc/renewal/main/top_img_bg.png) 0 0
    no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .second {
  background: url(https://s.wink.co.kr/pc/renewal/main/bottom_img_bg.png) 0 0
    no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .danbi-char {
  position: absolute;
  width: 150px !important;
  height: 120px;
  bottom: 34.5%;
  right: 17%;
}

#mainContainer .bottom-area .first img,
#mainContainer .bottom-area .second img {
  width: 100%;
}

#mainContainer .bottom-area .video-container .video-wrapper {
  width: 100%;
  height: 100%;
}

#mainContainer .bottom-area .video-container video {
  background: white;
  width: 100%;
  height: 100%;
}

#mainContainer .bottom-area .video-container video[poster] {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

#mainContainer .bottom-area .video-container .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

#mainContainer .bottom-area .video-container .play-button.selected.isPlaying {
  display: none;
}

#mainContainer
  .bottom-area
  .video-container
  .video-wrapper:hover
  .play-button.selected.isPlaying {
  display: block;
}

#mainContainer .bottom-area .content-container {
  position: relative;
  width: 1920px;
}

#mainContainer .bottom-area .content-container .video-content {
  position: relative;
  background-color: #ebebeb;
}

#mainContainer .bottom-area .button-container {
  position: absolute;
  bottom: 0.4%;
  width: 510px;
  height: 80px;
  cursor: pointer;
}

#mainContainer .bottom-area .button-container img {
  width: 100%;
  height: 100%;
}

.lay-floating-wrapper .admin-banner {
  width: 242px;
  height: 110px;
  position: absolute;
  top: 660px;
  left: 5%;
}

.lay-floating-wrapper .admin-banner img {
  width: 100%;
  height: 100%;
}

.lay-floating .lay-additional-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lay-floating .lay-close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.lay-floating .lay-close-button img {
  width: 29px;
  height: 29px;
}

.right-floating {
  width: 242px;
  position: absolute;
  left: 50%;
  top: 100px;
  margin-left: 600px;
}

.right-floating img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.right-floating div {
  position: absolute;
  bottom: 30px;
  font-size: 30px;
  background-color: aqua;
  width: 120px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  border-radius: 15px;
  color: white;
  animation: rightFloatingButtonBgColor steps(1) 1.5s infinite;
  cursor: pointer;
}

.left-floating {
  position: fixed;
  left: 15px;
  top: 300px;
  cursor: pointer;
}

@keyframes rightFloatingButtonBgColor {
  0%,
  100% {
    background-color: #f45389;
  }
  50% {
    background-color: #efbc09;
  }
}

.mypage-main {
  padding-bottom: 30px;
}

.mypage-main h3.line {
  color: black;
  margin-bottom: 70px;
}

.my-page-child-description {
  color: red;
  padding-top: 32px;
}

.apply-study-sub-description {
  margin-top: 120px;
  padding: 12px 12px 25px 0;
}

.apply-study-sub-description p:first-child {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 8px;
}
.apply-study-sub-description .margin-top-bottom-20px {
  margin: 20px 0 20px 0;
}

.wink-free-study .wink-free-study-desc img {
  display: block;
}

.wink-free-study .wink-free-study-desc .button-container {
  display: flex;
  width: 100%;
  height: 169px;
  background-image: url('https://s.wink.co.kr/marketing/banner/pc/freeapply/free_apply_button_background.png');
}

.wink-free-study .wink-free-study-desc .button-container .btn {
  margin: auto;
  cursor: pointer;
}

.wink-free-study .disabled-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000050;
  z-index: 1;
}

.wink-free-study .add-brother-container {
  background: #ffffff;
  margin: 80px 0 30px;
}

.wink-free-study .add-brother-container .guide-message {
  text-align: center;
  font-size: 14px;
}

.wink-free-study .add-brother-container .brother-button {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  font-size: 1.1rem;
}

.wink-free-study .add-brother-container .brother-button:active {
  background: #EEEEEE;
}

.wink-free-study .add-brother-container .brother-button .brother-button__txt {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.wink-free-study .add-brother-container .brother-button .brother-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 58px;
}

.wink-free-study .add-brother-container .brother-button.add-brother-button {
  border: 1px solid #09BAD5;
}

.wink-free-study .add-brother-container .brother-button.add-brother-button .highlight {
  color: #09BAD5;
  font-weight: bold;
}

.wink-free-study .add-brother-container .brother-button.add-brother-button .brother-button__icon {
  background: #09BAD5;
}

.wink-free-study .add-brother-container .brother-button.minus-brother-button {
  border: 1px #CCCCCC solid;
}

.wink-free-study .add-brother-container .brother-button.minus-brother-button .brother-button__icon {
  background: #CCCCCC;
}

.subject-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subject-content-container .top-container {
  position: relative;
  width: 100%;
}

.subject-content-container .top-container img {
  width: 100%;
}

.subject-content-container .bottom-container img {
  width: 100%;
}

.subject-content-container .subject-next-button {
  background-color: #fc669b;
  position: absolute;
  bottom: 90px;
  height: 90px;
  font-size: 30px;
  padding: 0 40px;
  line-height: 90px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 60px;
  text-align: center;
  width: 350px;
}

#contents.padding-top-only {
  padding: 39px 0 0 0;
}

#contents .app-description {
  padding: 0 50px;
}

#contents .app-description .app-guide li figure img {
  width: 100%;
}

#contents .teacher-recruit-top {
  width: 100%;
}

.btn-box.no-margin .btn-type2:before {
  display: none;
}

.no-pointer a {
  cursor: default;
}

.display-block {
  display: block;
}

#mainContainer .bottom-area .first-section {
  background: url(https://s.wink.co.kr/pc/renewal/marketing/section_01_bg.jpg) 0
    0 no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .second-section {
  background: url(https://s.wink.co.kr/pc/renewal/marketing/section_02_bg.jpg) 0
    0 no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .third-section {
  background: url(https://s.wink.co.kr/pc/renewal/marketing/section_03_bg.jpg) 0
    0 no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .fourth-section {
  background: url(https://s.wink.co.kr/pc/renewal/marketing/section_05_bg.jpg) 0
    0 no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .fifth-section {
  background: url(https://s.wink.co.kr/pc/renewal/marketing/section_04_bg.jpg) 0
    0 no-repeat;
  background-size: 100% 100%;
}

#mainContainer .bottom-area .third-section .content-section img {
  display: flow-root;
}

#mainContainer .bottom-area .content-section {
  width: 1100px;
}

.agreement-collapse {
  background: url('https://s.wink.co.kr/pc/images/icon_arrow_down.png') center
    center no-repeat;
  position: absolute;
  right: 10px;
  width: 14px;
  height: 56px;
  top: 0;
  cursor: pointer;
}

.agreement-collapse.active {
  background: url('https://s.wink.co.kr/pc/images/icon_arrow_up.png') center
    center no-repeat;
}

.agreement-toggle {
  width: 100%;
  height: 0;
  transition: height 0.5s ease;
}

.agreement-toggle.active {
  height: 500px;
}

.agreement-toggle iframe {
  width: 100%;
  height: 100%;
}

#contents.free-study-complete section {
  margin-bottom: 40px;
}

.free-study-complete .wink-books-banner-container {
  cursor: pointer;
  position: relative;
}

.free-study-complete .wink-books-banner-container > img {
  width: 100%;
  display: inline-block;
  min-height: 200px;
}

.free-study-complete .wink-books-banner-container .wink-books-banner-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  position: relative;
  top: -200px;
  left: 0;
  margin-bottom: -70px;
}

.free-study-complete
  .wink-books-banner-container
  .wink-books-banner-buttons
  .wink-books-banner-button {
  width: 400px;
}

.wink-books-banner-toast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  height: 380px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: Noto Sans KR, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #212121;
}

.wink-books-banner-toast .check-icon img {
  width: 55px;
  margin-bottom: 20px;
}

.wink-books-banner-toast .text-strong {
  font-weight: 700;
}

.subject-content-item img {
  display: flow-root;
  width: 1260px;
}

.subject-container.content-area {
  min-width: 1260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.subject-container.content-area h4 {
  padding-top: 40px;
  width: 100%;
}

.subject-container.content-area h4:after {
  right: 31%;
}

.subject-container.content-area h4 span {
  position: relative;
  left: 50%;
  margin-left: -580px;
  font-size: 20px;
  color: #4d4d4f;
  top: -10px;
}

.subject-container.content-area .subject-content-item {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
}

.subject-container.content-area .subject-content-item .youtube-video-container {
  position: absolute;
  height: 562px;
  bottom: 61%;
  left: 50%;
  transform: translate(-50%);
  width: 994px;
}

.subject-container.content-area
  .subject-content-item
  .youtube-video-container
  .overlay-img {
  position: absolute;
  height: 562px;
  z-index: 4;
}

.subject-container.content-area
  .subject-content-item
  .youtube-video-container
  .overlay-img.playing {
  display: none;
}

.subject-container.content-area
  .subject-content-item
  .youtube-video-container
  .youtube-video-wrapper {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.subject-container.content-area
  .subject-content-item
  .youtube-video-container
  .youtube-video-wrapper
  .youtube-play-button {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  cursor: pointer;
}

.subject-container.content-area
  .subject-content-item
  .youtube-video-container
  .youtube-video-wrapper
  .youtube-play-button.playing {
  display: none;
}

.subject-container.content-area.math .youtube-video-container {
  bottom: 68.5%;
}

.subject-container.content-area.en .youtube-video-container {
  bottom: 1.6%;
}

.subject-container.content-area .next-subject-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.subject-container.content-area .next-subject-container img {
  cursor: pointer;
  width: 450px;
}

.subject-container.content-area .shortcut-button-container {
  cursor: pointer;
  position: absolute;
}

.subject-container.content-area.kor .shortcut-button-container {
  bottom: 3.4%;
  right: 50%;
  transform: translate(430px);
}

.subject-container.content-area.math .next-subject-container,
.subject-container.content-area.en .next-subject-container,
.subject-container.content-area.prepare .next-subject-container,
.subject-container.content-area.kor .next-subject-container {
  position: absolute;
  bottom: 0;
}

.subject-container.content-area.math .shortcut-button-container {
  bottom: 3.8%;
  right: 50%;
  transform: translate(458px);
}

.reference-room-container {
  width: 100%;
  min-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reference-room-container .reference-room-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reference-room-container h4 {
  padding-top: 40px;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #dfdfdf;
}

.reference-room-container h4 span {
  position: relative;
  left: 50%;
  margin-left: -580px;
}

.reference-room-container .description {
  padding: 160px 30px 120px 30px;
  font-size: 23px;
  text-align: center;
  position: relative;
}

.reference-room-container .description .description-bg {
  position: absolute;
  top: 0;
  left: -480px;
}

.reference-room-container .description .danger {
  font-weight: bold;
  color: black;
}

.reference-room-content:after {
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}

.reference-room-container .menu {
  width: 100%;
}

.reference-room-container .menu .tab-type2 {
  height: 60px;
  line-height: 60px;
}

.reference-room-container .menu .tab-type2 li a {
  height: 60px;
}

.reference-room-container .menu .tab-type2 li {
  background-color: #fcfcfc;
}

.reference-room-container .menu .tab-type2 li.on a {
  background-color: #f45087;
}

.reference-room-container .menu .select-box-container {
  display: flex;
  justify-content: flex-end;
}

.reference-room-container .menu .select-box-container .select-mode {
  min-width: 210px;
}

.reference-room-container .content-list-container {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.reference-room-container .content-list-container .content-item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 45px 0;
}

.reference-room-container .content-list-container .content-item:after {
  content: '';
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid #eeeeee;
  width: 92%;
  left: 4%;
}

.reference-room-container .content-list-container .content-item .thumbnail {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.reference-room-container
  .content-list-container
  .content-item
  .thumbnail
  .thumbnail-img {
  width: 100%;
  height: 100%;
}

.reference-room-container
  .content-list-container
  .content-item
  .thumbnail-container
  .recommend {
  position: absolute;
  top: 30px;
  left: -20px;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 30px;
  padding-top: 10px;
  width: 730px;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .title-container {
  width: 100%;
  position: relative;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .title-container
  .title {
  font-size: 20px;
  color: black;
  font-weight: bold;
  position: relative;
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .title-container
  img {
  position: relative;
  top: -5px;
  left: 5px;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .title
  img {
  transform: translate(10px, 8px);
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .item-description {
  height: 54%;
  padding-top: 5px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: pre-wrap;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .button-container
  div {
  cursor: pointer;
}

.reference-room-container
  .content-list-container
  .content-item
  .content-wrapper
  .button-container
  .preview-button {
  margin-right: 15px;
}

.layer .layer-wrap.reference-room-preview {
  padding: 50px 0 0 0;
  height: 70%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  width: 800px;
}

.layer .layer-wrap.reference-room-preview .ReactPDF__Document,
.layer .layer-wrap.reference-room-preview .react-pdf__Document {
  margin: 0 50px 50px 50px;
}

.layer-wrap.reference-room-preview img {
  width: 100%;
  overflow: scroll;
}

.layer-wrap.reference-room-download {
  margin: 0 !important;
  transform: translate(-50%, -50%);
  width: 800px !important;
}

.layer-wrap.reference-room-download .reference-room-header {
  color: #f03c78;
  display: flex;
  text-align: center;
  padding-bottom: 20px;
}

.layer-wrap.reference-room-download .reference-room-download-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-wrap.reference-room-download .reference-room-header div:nth-child(1),
.layer-wrap.reference-room-download .reference-room-download-title,
.layer-wrap.reference-room-download .reference-room-header div:nth-child(3) {
  width: 200px;
}

.layer-wrap.reference-room-download .reference-room-header div:nth-child(2) {
  width: 336px;
}

.layer-wrap.reference-room-download .contents-container {
  display: flex;
  text-align: center;
  padding: 5px 0;
  border-top: 1px solid #676969;
  border-bottom: 1px solid #676969;
}

.layer-wrap.reference-room-download
  .contents-container
  .reference-room-download-item {
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.reference-room-download-item-container {
  max-height: 600px;
  overflow-y: scroll;
}

.reference-room-download-item-container > div:last-child {
  border-bottom: none !important;
}
.layer-wrap.reference-room-download
  .contents-container
  .reference-room-download-item
  > div:last-child {
  border-bottom: none;
}

.layer-wrap.reference-room-download
  .contents-container
  .reference-room-download-item
  .item-title {
  width: 336px;
  text-align: left;
}

.layer-wrap.reference-room-download
  .contents-container
  .reference-room-download-item
  .item-title
  label {
  width: 100%;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layer-wrap.reference-room-download
  .contents-container
  .reference-room-download-item
  .item-download {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.layer-wrap.reference-room-download .reference-room-download-title {
  width: 200px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
  max-width: 200px;
}

.layer-wrap.reference-room-download .download-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.layer-wrap.reference-room-download .download-button-container .error-message {
  position: absolute;
  left: 33px;
  bottom: 25px;
  color: red;
}

.layer-wrap.reference-room-download .download-button-container div:first-child {
  padding-right: 10px;
}

.layer-wrap.reference-room-download .download-button-container div {
  height: 35px;
  cursor: pointer;
}

.layer-wrap.reference-room-download .download-button-container div img {
  height: 100%;
}

.layer-wrap.reference-room-preview .preview-pdf-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.layer-wrap.reference-room-preview .preview-pdf-container .loading {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 45%;
  top: 40%;
}

.layer-wrap.reference-room-preview
  .preview-pdf-container
  .ReactPDF__Page__canvas,
.layer-wrap.reference-room-preview
  .preview-pdf-container
  .react-pdf__Page__canvas {
  width: 100% !important;
}

.reference-room-container .paging-navigation {
  margin-bottom: 60px;
}

.reference-room-container .tab-type2 li.on a:after {
  background: url(https://s.wink.co.kr/pc/images/bg_edge_reference.png)
    no-repeat;
  background-size: contain;
}

.layer-wrap.small.reference-room-apply-modal .btn-type2.confirm {
  background-color: #08bbd5;
}

.layer-wrap.member .sign-up-banner {
  position: relative;
  margin-top: 20px;
}

.layer-wrap.member.login-modal {
  margin-top: -420px !important;
  overflow: inherit;
}

.layer-wrap.member.login-modal input[type='text']:focus,
.layer-wrap.member.login-modal input[type='password']:focus {
  outline-color: #e54179 !important;
}

.layer-wrap.member .sign-up-banner img {
  width: 100%;
  padding-bottom: 15px;
}

.layer-wrap.member .sign-up-banner img.button {
  left: 0;
  bottom: 0;
  cursor: pointer;
}

.layer-wrap.reference-sign-up .danger {
  color: #f03c78;
}

.layer .layer-wrap.reference-sign-up .btn-type2.confirm {
  background-color: #f03c78;
}
.layer-wrap.wait-payment-confirm {
  z-index: 99999999;
  padding: 60px !important;
}
.layer-wrap.wait-payment-confirm .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 5px;
}
.layer-wrap.wait-payment-confirm .content .modal-footer {
  margin-top: 40px;
}
.layer-wrap.wait-payment-confirm p {
  margin: 5px;
}

.main-content-item {
  position: relative;
}

.main-swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
}

@media (max-width: 1500px) {
  .main-swiper-container.wide {
    width: 100%;
    overflow: hidden;
  }
}

.main-swiper-container .swiper-wrapper .swiper-slide:before {
  content: none !important;
}

.main-swiper-container .swiper-wrapper .swiper-slide {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.main-swiper-container
  .main-swipe-wrapper-renewal
  .swiper-wrapper
  .swiper-slide {
  padding: 0 2.5%;
  box-sizing: border-box;
  opacity: 0.4;
}

.main-swiper-container
  .main-swipe-wrapper-renewal
  .swiper-wrapper
  .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.main-swiper-container
  .main-swipe-wrapper-renewal
  .swiper-wrapper
  .swiper-slide
  img {
  border: 4px solid white;
}

.main-swiper-container .swiper-pagination {
  position: static;
  margin-top: 3%;
}

.main-swiper-container .swiper-pagination .swiper-pagination-bullet {
  background-color: #d9d9d9;
  opacity: 1;
}

.main-swiper-container
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #15b73a;
}

.teacher-container .contents-container {
  width: 100%;
}

.teacher-container .contents-container .content-item {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.teacher-container .contents-container .content-item > div {
  width: 1920px;
}

.teacher-container.promotion .button,
.teacher-container.promotion .contents-container .content-item .button {
  width: auto;
  bottom: 3%;
  left: 50%;
}

.teacher-container.promotion .recruit-button {
  width: auto;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.teacher-container.promotion .button {
  bottom: 130px;
}

.teacher-container.promotion .contents-container .content-item .content-2,
.teacher-container.promotion .contents-container .content-item .content-3 {
  margin-bottom: 100px;
}

.teacher-container .contents-container img {
  width: 100%;
  display: flow-root;
}

.teacher-container h4,
.download-parent-app-container h4 {
  padding-top: 40px;
  width: 100%;
}

.teacher-container .recruit-title {
  margin-bottom: 20px;
}

.teacher-container h4 span,
.download-parent-app-container h4 span {
  position: relative;
  left: 50%;
  margin-left: -580px;
}

.download-parent-app-container h4 {
  border-bottom: 1px solid #dfdfdf;
}

.teacher-container .button {
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.download-parent-app-container {
  width: 100%;
}

.download-parent-app-container .contents-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.download-parent-app-container .contents-container .contents-item {
  width: 1320px;
  position: relative;
}

.download-parent-app-container .contents-container .contents-item img {
  width: 100%;
}

.download-parent-app-container .contents-container .button {
  position: absolute;
  left: 50px;
  top: 13.8%;
}

.download-parent-app-container .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  border-bottom: 1px solid #dfdfdf;
}

.download-parent-app-container .contents-container .button img,
.download-parent-app-container .button-container img {
  cursor: pointer;
}

#AboutDanbiEdu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#AboutDanbiEdu > div {
  width: 1920px;
}

#AboutDanbiEdu .content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

#AboutDanbiEdu .content-wrapper .content-container {
  width: 1920px;
  display: flow-root;
}

#AboutDanbiEdu .content-wrapper .content-container img {
  width: 100%;
  height: 100%;
}

.header .util-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header .util-wrapper .util-container {
  width: 1260px;
  position: relative;
}

.header .util-wrapper .util-container .util {
  display: flex;
  position: relative;
  right: 72px;
}

.header .util-wrapper .util-container .util img {
  width: 20px;
  height: 22px;
}

.header .util-wrapper .login-wrapper {
  position: relative;
  right: 75px;
}

.header .util-wrapper .login-wrapper .util {
  position: relative;
  right: 30px;
}

.teacher-container {
  width: 100%;
}

.swiper-container .swiper-slide:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 50px;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
  filter: blur(10px);
}

.subject-container.content-area.kor .next-subject-container {
  padding-top: 0;
}

.layer-wrap.product-recommend {
  font-family: 'Pretendard', sans-serif;
  font-size: 24px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 263px);
  padding: 45px 0;
  width: 525px;
  height: 530px;
}

.layer-wrap.product-recommend .contents-wrap {
  margin: 0 20px;
  text-align: center;
}

.layer-wrap.product-recommend .product-title h2 {
  color: #000000;
  font-size: 33px;
  line-height: 42px;
  margin-bottom: 21px;
  padding-bottom: 25px;
  font-weight: 700;
  border-bottom: 1px solid #e0e0e0;
}

.layer-wrap.product-recommend .contents-wrap .layer-body {
  margin: 35px 0;
}

.layer-wrap.product-recommend .contents-wrap .layer-body > p {
  font-size: 24px;
}

.layer-wrap.product-recommend .contents-wrap .layer-body .blue-emphasis {
  color: #09bad5;
  font-weight: 600;
}

.layer-wrap.product-recommend .contents-wrap .layer-body em {
  color: #f03c78;
  font-weight: 700;
}

.layer-wrap.product-recommend .fixed-btn-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.layer-wrap.product-recommend .fixed-btn-area .fixed-btn {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  flex: 1 1 auto;
  text-align: center;
  font-size: 24px;
  padding: 10px 0;
  background-color: #bcbcbc;
  color: #ffffff;
  width: 50%;
  line-height: 28px;
}

.layer-wrap.product-recommend .fixed-btn-area .fixed-btn.primary {
  background-color: #02bad3;
}

.layer-wrap.product-recommend .fixed-btn-area .fixed-btn.primary > span {
  color: #fef61a;
}

.layer-wrap.product-recommend-hello {
  position: absolute;
  left: calc(50% - 263px);
  padding: 45px 0;
  width: 525px;
  height: 330px;
}
.layer-wrap.product-recommend-hello .contents-wrap {
  margin: 0 20px;
  text-align: center;
}

.layer-wrap.product-recommend-hello .contents-wrap .layer-body {
  margin: 35px 0;
}

.layer-wrap.product-recommend-hello .contents-wrap .layer-body > p {
  font-size: 24px;
}

.layer-wrap.product-recommend-hello .contents-wrap .layer-body b {
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

.layer-wrap.product-recommend-hello .fixed-btn-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.layer-wrap.product-recommend-hello .fixed-btn-area .fixed-btn {
  flex: 1 1 auto;
  text-align: center;
  font-size: 24px;
  padding: 17px 0;
  background-color: #bcbcbc;
  color: #ffffff;
}

.layer-wrap.product-recommend-hello .fixed-btn-area .fixed-btn.primary {
  background-color: #02bad3;
}

.layer-wrap.product-recommend-hello .fixed-btn-area .fixed-btn.primary > span {
  color: #fef61a;
  font-family: 'NanumBarunGothic-Bold', sans-serif;
}

#contents.mypage-main,
#contents.my-page-study-info,
#contents.my-page-pay-list,
#contents.my-page-delivery-list,
#contents.my-page-pay-detail,
#contents.notice-list,
#contents.event-list,
#contents.question-list,
#contents.member-join-step-01,
#contents.member-join-finish {
  padding-bottom: 50px;
}

#mainContainer .content-area .cats-service {
  position: absolute;
  top: 13%;
  right: 19%;
  transform: translateX(-50%);
  cursor: pointer;
  max-width: 965px;
  max-height: 531px;
  aspect-ratio: 965 / 531;
  width: 13%;
}

#mainContainer .content-area .main-video-container,
#mainContainer .main-wrapper .main-video-container {
  position: absolute;
  top: 6%;
  left: 23%;
  padding: 20px;
  box-sizing: content-box;
  background-color: white;
}

#mainContainer .content-area .main-decorate-image-container {
  position: absolute;
}

#mainContainer .content-area .main-video-container.introduce-device {
  top: 80.65%;
  left: 39.4%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 580px;
  background-color: transparent;
  z-index: 2;
}

#mainContainer .content-area .main-video-container.school {
  top: 69.4%;
  left: 39.95%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 580px;
  background-color: transparent;
  z-index: 2;
}

#mainContainer .content-area .main-video-container.real-story {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 618px;
  height: 348px;
  padding: 0;
  border-radius: 35px;
  border: 10px solid #FFFFFF;
  box-shadow: 0 4px 1px #00000034;
  z-index: 1;
}

#mainContainer .content-area .main-video-container video,
#mainContainer .main-wrapper .main-video-container video {
  width: 100%;
  height: 100%;
}

#mainContainer .content-area .main-video-container.introduce-device video,
#mainContainer .content-area .main-video-container.school video {
  border-radius: 20px;
  aspect-ratio: 965 / 531;
}

#mainContainer .content-area .main-video-container.real-story video {
  border-radius: 25px;
  -o-object-fit: cover;
     object-fit: cover;
}

#mainContainer .content-area .main-video-container video[poster] {
  -o-object-fit: fill;
     object-fit: fill;
}

#mainContainer .content-area .main-video-container img,
#mainContainer .main-wrapper .main-video-container img {
  position: absolute;
  top: 38%;
  left: 45%;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

#mainContainer .content-area .main-video-container.introduce-device img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mainContainer .content-area .main-video-container.school img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mainContainer .content-area .main-video-container img.playing,
#mainContainer .main-wrapper .main-video-container img.playing {
  display: none;
}

#mainContainer .content-area .main-video-container:hover img.playing,
#mainContainer .main-wrapper .main-video-container:hover img.playing {
  display: block;
}

#mainContainer .content-area .main-video-container.self-solve {
  top: 50.5%;
  left: 53%;
  padding: 0;
  background: transparent;
}

#mainContainer .content-area .main-video-container.self-solve img {
  width: 315px;
  height: 174px;
  top: 2px;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 11px;
}

#mainContainer .content-area .main-video-container.checkpoint-1 {
  top: 53.5%;
  left: 18.4%;
  padding: 0;
  background: transparent;
}

#mainContainer .content-area .main-video-container.checkpoint-1 img {
  width: 229px;
  height: 134px;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 36px;
  border: 4px solid #ffffff;
}

#mainContainer .content-area .main-video-container.checkpoint-2 {
  top: 53.5%;
  left: 40.7%;
  padding: 0;
  background: transparent;
}

#mainContainer .content-area .main-video-container.checkpoint-2 img {
  width: 229px;
  height: 134px;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 36px;
  border: 4px solid #ffffff;
}

#mainContainer .content-area .main-video-container.checkpoint-3 {
  top: 53.5%;
  left: 63%;
  padding: 0;
  background: transparent;
}

#mainContainer .content-area .main-video-container.checkpoint-3 img {
  width: 229px;
  height: 134px;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 36px;
  border: 4px solid #ffffff;
}

#mainContainer .content-area .main-video-container.praise-present {
  top: 45.7%;
  left: 52.1%;
  padding: 0;
  background: transparent;
}

#mainContainer .content-area .main-video-container.praise-present img {
  width: 335px;
  height: 250px;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 36px;
}

.wink-apply-check-list-container {
  font-family: 'Pretendard', sans-serif;
  margin-bottom: 50px;
}

.wink-apply-check-list-container button {
  font-family: 'Pretendard', sans-serif;
}

/* 학습 단계 체크 리스트 일괄 UI */
.check-list-batch-container {
  display: flex;
  justify-content: space-between;
  gap: 13px;
  margin-top: 20px;
}

.check-list-batch-container .batch-select-box-button {
  height: 97px;
  width: 100%;
  line-height: 1.5;
  padding: 15px 5px;
  font-size: 22px;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px;
  box-shadow: unset;
  cursor: pointer;
}

.check-list-batch-container .batch-select-box-button.solid {
  color: #09bad5;
  background-color: #e9fcff;
  border: 1px solid #09bad5;
  font-weight: bold;
}

.check-list-batch-container .batch-select-box-button.outlined {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.check-list-batch-manually-container {
  display: flex;
  margin-top: 21px;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}

.check-list-batch-manually-container .batch-select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 124px;
  width: 100%;
  border-radius: 10px;
  background-color: #f3f3f3;
  padding: 20px 30px;
  box-sizing: border-box;
}

.check-list-manually-container img {
  width: 100%;
}

.level-select-manually-desc-container {
  width: 100%;
  font-size: 18px;
  line-height: 26px;
}

.check-list-batch-manually-container
  .batch-select-box
  .batch-select-box-text-container {
  line-height: 1.5;
  white-space: pre-line;
  text-align: start;
  font-size: 16px;
}

.check-list-batch-manually-container
  .batch-select-box
  .batch-select-box-text-container
  b {
  font-weight: 600;
  font-size: 18px;
}

.check-list-batch-manually-container
  .batch-select-box
  .batch-select-box-text-container
  strong {
  font-weight: 600;
  color: black;
  font-size: 16px;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container {
  flex: 0 68px;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container
  .batch-select-box-button {
  line-height: 38px;
  height: 38px;
  width: 108px;
  font-size: 18px;
  vertical-align: middle;
  border-radius: 30px;
  box-shadow: unset;
  cursor: pointer;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container
  .batch-select-box-button.solid {
  color: #ffffff;
  background-color: #ff1e7c;
  border: none;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container
  .batch-select-box-button.solid:active {
  background-color: #ff1e7c;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container
  .batch-select-box-button.outlined {
  color: #ff1e7c;
  background-color: #ffffff;
  border: 1px solid #ff1e7c;
}

.check-list-batch-manually-container
  .batch-select-box
  > .batch-select-box-button-container
  .batch-select-box-button.outlined:active {
  background-color: #dddddd;
}

.check-list-batch-notice {
  display: flex;
  align-items: center;
  gap: 8px;
}

.check-list-batch-notice .notice-text {
  font-size: 18px;
  color: #666666;
}

.check-list-batch-notice .notice-text-sub {
  font-size: 15px;
  color: #999999;
}

.check-list-item > div {
  display: flex;
  flex-direction: column;
}

.level-check-list-container:not(:last-of-type) {
  margin-bottom: 10px;
}

.level-check-list-container input[type='radio'] + label:before {
  position: absolute;
  content: '';
  left: 0;
  top: 4px;
  width: 18px;
  height: 19px;
  background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-radio-off.svg')
    no-repeat 0 0;
}

.level-check-list-container input[type='radio']:checked + label:before {
  background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-radio-on.svg')
    no-repeat 0 0;
}

.check-list-item.opened {
  margin-bottom: 17px;
}

.check-list-item .check-list-item-radio {
  display: flex;
  align-items: center;
  padding: 11px 0;
}

.check-list-item .check-list-item-child .check-list-item-radio {
  display: flex;
  align-items: center;
  padding: 0;
}

.check-list-item .check-list-item-desc {
  margin-left: 32px;
}

.check-list-item .check-list-item-child .check-list-item-desc {
  margin-left: 0;
}

.check-list-detail-toggle {
  width: 100px;
  text-align: end;
  color: #a6a6a6;
  font-size: 14px;
  cursor: pointer;
}

.check-list-item.check-list-notice-item .check-list-notice-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('https://us.wink.co.kr/pc/renewalV3/icons/exclamation_mark_blue.png')
    no-repeat;
  background-size: 20px 20px;
}

.check-list-item .check-list-normal-text {
  font-size: 18px;
  color: #000000 !important;
}

.check-list-item .check-list-level-text {
  font-size: 18px;
  font-weight: 600;
  color: #000000 !important;
}

.check-list-item.check-list-notice-item .check-list-level-text {
  margin-left: 12px;
}

.check-list-item .check-list-comment {
  font-size: 16px;
}

.check-list-item .check-list-item-child {
  margin-left: 41px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.check-list-item .check-list-item-child:nth-child(2) {
  margin-top: 10px;
}

.check-list-item .check-list-item-child:not(:nth-child(2)) {
  margin-top: 20px;
}

.check-list-top-description {
  padding: 15px 30px 15px 30px;
  font-size: 16px;
  background: #fffdd6;
}

.check-list-top-description.no-bg {
  background: transparent;
  padding: 0 30px;
}

.check-list-description {
  background-color: #f4f4f4;
  padding: 12px;
  font-size: 16px;
  margin-bottom: 5px;
}

.check-list-description .check-list-description-label {
  color: #333333;
  padding-left: 15px;
}

.check-list-description .check-list-description-desc {
  color: #8e8e8e;
  padding-left: 15px;
}

.check-list-comment {
  color: #8e8e8e;
}

/**
무료체험 신청 레벨 체크리스트
 */

.level-check-list li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.level-check-list-container .level-check-list-group {
  padding: 10px 0 20px;
  border-bottom: 1px solid #b3b3b3;
}

.level-check-list-container:last-of-type .level-check-list-group {
  border-bottom: none;
}

.level-check-list-container .level-check-list-title {
  display: flex;
  width: 1000px;
  padding: 8px 4px;
  align-items: flex-start;
  gap: 10px;
  font-weight: 600;
  color: black;
  font-size: 20px;
  margin-bottom: 4px;
}

.level-check-list-container .level-check-list-body {
  margin-bottom: 20px;
}

.agreement-checkbox + label:before {
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-image: none !important;
}

.agreement-checkbox:checked + label:before {
  content: url('https://us.wink.co.kr/pc/renewalV3/common/check-grey.png');
  background-image: none !important;
}

.coupon-count:after {
  content: '';
  display: block;
  width: 100%;
  margin-left: -16px;
  margin-top: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.coupon-select {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: auto;
  background-color: #fff;
  align-items: flex-start;
  padding: 20px 16px;
}
.coupon-select input[type='radio'] + label {
  font-size: 16px;
}
.coupon-select .coupon-wrap {
  display: flex;
  align-items: center;
  margin-top: 13px;
  width: 433px;
  height: 43px;
  position: relative;
}
.coupon-select .coupon-wrap label {
  font-size: 16px;
  width: auto;
}
.coupon-select .coupon-wrap div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 234px;
  height: 43px;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  position: absolute;
  right: 80px;
}
.coupon-select .coupon-wrap div[disabled] {
  background-color: #f5f5f5;
  color: #999999;
}
.coupon-select .coupon-wrap div span {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.coupon-select button {
  background-color: rgb(0, 187, 213);
  border-radius: 4px;
  width: 68px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.coupon-select button[disabled] {
  display: none;
}

.coupon-child-list li {
  border: 1px solid #dadada;
  border-top: none;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
}
.coupon-child-list li:first-child {
  height: 60px;
  border-top: 1px solid #dadada;
}
.coupon-child-list li:first-child:nth-last-child(1) {
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
}
.coupon-child-list li:last-child {
  border-top: none;
}
.coupon-child-list input[type='radio'] + label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 60px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}
.coupon-child-list input[type='radio'] + label:before {
  top: 50%;
  left: 50%;
  margin: -11px 0 0 -4px;
}
.coupon-child-list label ~ .tbl-type th {
  width: 50px;
}
.coupon-child-list p.coupon-none {
  width: calc(100% - 60px);
  text-align: left;
  margin-left: 60px;
  height: 60px;
  padding-left: 16px;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 60px;
  padding-top: 1px;
  font-family: 'NanumBarunGothic-Light';
}
.coupon-child-list div.coupon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
  text-align: left;
  margin-left: 60px;
  height: 100px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: 'NanumBarunGothic-Light';
}

.layer-wrap .optional-agreement-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 60px 0;
  text-align: center;
}

.optional-agreement-modal .btn-area {
  display: flex;
  gap: 10px;
}

.optional-agreement-modal .btn-area > button {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.optional-agreement-modal .btn-area > button > .small {
  font-size: 11px;
}

/* Fallback UI CSS */
.maintenance-container {
  margin: 0;
  height: 100vh;
  background: #f4f4f4;
}
.message {
  font-family: Noto Sans KR, sans-serif;
  color: #202020;
}
.emphasize {
  color: #393cdb;
}
.link {
  font-family: Noto Sans KR, sans-serif;
  text-decoration: none;
}
.message-inline-group {
  display: inline-flex;
  word-spacing: 2px;
}
.icon,
.icon::before {
  display: inline-block;
  height: 1rem;
  width: 1rem;
}
.icon::before {
  content: '';
}
.icon-shortcut::before {
  background: center/contain no-repeat
    url('https://s.wink.co.kr/maintenance/resources/images/shortcut.svg');
}
.icon-kakaotalk::before {
  background: center/contain no-repeat
    url('https://s.wink.co.kr/maintenance/resources/images/kakaotalk.svg');
}
.icon-arrow-right::before {
  background: center/contain no-repeat
    url('https://s.wink.co.kr/maintenance/resources/images/arrow-right.svg');
}

.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1280px;
}
.maintenance-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;
  min-height: 250px;
  background: #ffffff;
  padding-top: 5vh;
}
.maintenance-icon img {
  height: 150px;
}
.maintenance-big-message {
  margin-top: -30px;
  padding: 0 20px;
}
.maintenance-big-message .message {
  font-size: 3rem;
  font-weight: 700;
  color: #202020;
  letter-spacing: 0.01rem;
  text-align: center;
}
.maintenance-big-message .emphasize {
  position: relative;
}
.maintenance-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  min-height: 320px;
  width: 100%;
}
.maintenance-message-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.2vh;
  padding: 0 20px;
}
.maintenance-message-detail .message {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  word-break: keep-all;
  letter-spacing: -0.06rem;
}
.maintenance-message-detail .message .emphasize {
  font-weight: 500;
}
.maintenance-duration {
  display: flex;
  margin-top: 2vh;
}
.maintenance-duration .message {
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: -0.05rem;
}
.maintenance-detail-comment {
  margin: 2vh 0 1vh 0;
}
.maintenance-detail-comment .message {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: -0.06rem;
}
.maintenance-divider {
  width: 90%;
  margin: 0;
  border: 1px solid #b4b4b4;
}
.maintenance-footer {
  height: 5vh;
  width: 100%;
  max-width: 1024px;
}
.maintenance-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 1vh;
  gap: 20px;
}
.maintenance-link .link-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #888888;
  background: #ffffff;
  height: 70px;
  flex: 1;
  padding: 0 20px;
}
.maintenance-link .link-box .message-container-btn {
  flex: 1;
}
.maintenance-link .link-box .message {
  font-size: 1.1rem;
  font-weight: 700;
}
.maintenance-link .link-box .message.extra {
  font-size: 0.9rem;
  font-weight: 400;
}
.maintenance-link .link-box .link {
  font-size: 1rem;
  font-weight: 700;
  color: #5d5fef;
  display: flex;
  align-items: center;
}
.maintenance-link .link-box .link .icon {
  margin: 0 0.2rem;
}
.maintenance-comment .message {
  font-weight: 350;
  letter-spacing: -0.03rem;
  opacity: 0.5;
}

table td.wink-books-rental-row {
  color: #ef3b7a;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}

.rental-book-list-modal-content {
  font-family: Pretendard, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
}

.rental-book-list-modal-content .close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 28px;
  top: 23.5px;
  cursor: pointer;
}

.rental-book-list-modal-content button {
  font-family: Pretendard, 'sans-serif';
  display: flex;
  width: 100%;
  height: 73px;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0 0 10px 10px;
  background: #09bad5;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
}

.rental-book-list-modal-content button:disabled {
  font-weight: 500;
  background: #ccc;
  cursor: not-allowed;
}

.rental-book-list-modal-content .rental-book-list-modal-content-body {
  display: flex;
  width: 524px;
  padding: 10px 30px 0px 30px;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-header {
  display: flex;
  padding: 25px 10px 30px 10px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid #ccc;
  color: #333;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-list-wrapper {
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-list {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  color: #333;
  font-size: 22px;
  font-weight: 400;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-list
  li {
  display: flex;
  padding: 10px 0;
  align-items: flex-start;
  gap: 10px;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-desc {
  display: flex;
  gap: 11px;
  align-items: center;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 15px 5px;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-empty-container {
  display: flex;
  gap: 21.5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  margin: 0 auto;
}

.rental-book-list-modal-content
  .rental-book-list-modal-content-body
  .rental-book-list-modal-content-empty-container
  span {
  color: #333;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  padding: 10px 0;
  white-space: pre;
}

.premium-tag {
  color: #fff;
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  background: #ffc200;
  padding: 5px 10px;
  margin-left: 15px;
}

img.lazy {
  min-height: 500px;
}

img.lazy-small {
  min-height: 100px;
}

.top-btn {
  position: fixed;
  bottom: 30px;
  z-index: 1000;
  cursor: pointer;
}

.free-study-banner {
  position: fixed;
  bottom: -11px;
  left: 50%;
  z-index: 100;
  cursor: pointer;
}

.free-study-banner img {
  width: 1100px;
  transform: translateX(-50%);
}

