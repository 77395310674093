@charset "UTF-8";
@import url('https://s.wink.co.kr/resources/ltp/fonts/common/pretendard/static/pretendard-subset.css?v=1.0.0');
/* CSS Document */

@font-face {
    font-family: 'NanumBarunGothic-Light';
    font-style: normal;
    font-weight: 300;
    src: local('Nanum Barun Gothic Light'), local('Nanum Barun Gothic-Light'),
        local('NanumBarunGothic Light');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicLight.ttf')
            format('truetype');
}

@font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 400;
    src: local('Nanum Barun Gothic Regular'),
        local('Nanum Barun Gothic-Regular'), local('NanumBarunGothic Regular');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothic.ttf')
            format('truetype');
}

@font-face {
    font-family: 'NanumBarunGothic-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Nanum Barun Gothic Bold'), local('Nanum Barun Gothic-Bold'),
        local('NanumBarunGothic Bold');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.eot');
    src: url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.eot?#iefix')
            format('embedded-opentype'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.woff')
            format('woff'),
        url('https://s.wink.co.kr/pc/fonts/NanumBarunGothicBold.ttf')
            format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Thin.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Light.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Regular.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Medium.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Bold.otf');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url('https://s.wink.co.kr/pc/fonts/NotoSansKR-Black.otf');
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
html {
    height: 100%;
    /*
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
	*/
    min-width: 1260px;
    overflow: auto;
}
/* issue_1435 */
body {
    -webkit-user-select: none;
    -khtml-user-select: none;
}
/* issue_1435 */
body {
    height: 100%;
    color: #666;
    font-size: 16px;
    font-family: 'NanumBarunGothic', sans-serif;
    line-height: 1.6;
    word-break: keep-all;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}
header,
footer,
section,
article,
aside,
nav,
hgroup,
details,
menu,
figure,
figcaption {
    display: block;
}

img,
fieldset,
iframe,
button {
    border: 0 none;
}
ul,
ol {
    list-style: none;
}
em,
address,
i {
    font-style: normal;
}
b,
strong {
    font-weight: normal;
}
a {
    text-decoration: none;
    cursor: pointer;
}

/* Display */
.blind {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}

/* Img */
@-webkit-keyframes moreLoad {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
    }
}
@keyframes moreLoad {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
    }
}

img.loading {
    vertical-align: top;
    animation: moreLoad 1.8s infinite;
    -webkit-animation: moreLoad 1.8s infinite;
    -ms-animation: moreLoad 1.8s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

/* Title/Text */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h3 {
    margin-bottom: 14px;
    color: black;
    font-size: 32px;
}
h3.line {
    margin-bottom: 32px;
    padding-bottom: 11px;
    border-bottom: 4px solid #d7d7d7;
    position: relative;
}
h3.line:after {
    content: '';
    position: absolute;
    bottom: -7px;
    right: 0;
    width: 33px;
    height: 19px;
    background: url(https://s.wink.co.kr/pc/images/img_leaf.png) no-repeat;
}
h3.no-margin {
    margin-bottom: 0;
}

h4 {
    color: #212121;
    font-size: 30px;
    line-height: 1.4;
    position: relative;
    font-weight: bold;
}
/*h4:after{content:''; position:absolute; bottom:-11px; right:0; width:33px; height:33px; background:url(https://s.wink.co.kr/pc/renewal/img_leaf.png) no-repeat; background-size: contain;}*/
h4.no-line {
    margin-bottom: 0;
    border-bottom-width: 0;
}
h4.no-line:after {
    display: none;
}
h4.no-margin {
    margin-bottom: 0;
}

#ShippingAddress h4.no-line {
    font-size: 24px;
}

h5 {
    margin-bottom: 16px;
    color: #212121;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    position: relative;
}

.hgroup {
    position: relative;
}
.hgroup .right,
.hgroup .btn-type,
.hgroup .btn-type2,
.hgroup .buttons {
    position: absolute;
    right: 0;
    bottom: 15px;
}

a.go {
    padding-right: 14px;
    background: url(https://s.wink.co.kr/pc/images/bu_go.png) 100% 2px no-repeat;
    color: #212121;
    text-decoration: underline;
}
a.go.blu {
    background-image: url(https://s.wink.co.kr/pc/renewalV3/common/go_blue.png);
    color: #00a0e0;
}

.tag {
    display: inline-block;
    height: 28px;
    padding: 0 12px;
    border: 1px solid #e0e0e0;
    border-radius: 14px;
    box-sizing: border-box;
    vertical-align: middle;
    color: #212121;
    font-size: 14px;
    line-height: 28px;
}
.tag.category {
    border-width: 0;
    background: #bdbdbd;
    color: #fff;
}
.tag.off {
    border-width: 0;
    background: #f1f1f1;
}
.tag.eng {
    border-width: 0;
    background: #ffc200;
    color: #fff;
}
.tag.kor {
    border-width: 0;
    background: #4385ff;
    color: #fff;
}
.tag.math {
    border-width: 0;
    background: #1ad49d;
    color: #fff;
}
.tag.notice {
    border-width: 0;
    background: #90a4ae;
    color: #fff;
}
.tag.d-day {
    border-width: 0;
    background: #00bbd4;
    color: #fff;
}

.date {
    display: inline-block;
    padding-left: 23px;
    vertical-align: middle;
    background: url(https://s.wink.co.kr/pc/images/icon_time.png) no-repeat;
    color: #bdbdbd;
    font-size: 14px;
    line-height: 18px;
}

.notice-wrap p {
    margin-bottom: 5px;
    color: #212121;
    font-size: 18px;
}
.notice-wrap li {
    padding-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 10px no-repeat;
    color: #666;
    font-size: 14px;
    line-height: 1.7;
}

p.notice {
    padding-left: 31px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice.png) no-repeat;
}
p.search-result {
    margin-bottom: 10px;
    padding-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 10px no-repeat;
}
p.search-result strong {
    color: #f03c78;
}
p.validation-check {
    margin: 8px 0 0 4px;
    padding-left: 23px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice3.png) 0 3px
        no-repeat;
    color: #e83030;
    font-size: 14px;
}

/* 글자 색상 */
.text-color01 {
    color: #999;
}
.text-color02 {
    color: #00a0e0;
}
.text-color03 {
    color: red;
}
.text-color04 {
    color: #459fda;
}

.underline {
    text-decoration: underline;
}

/* Layout */
#wrap {
    position: relative;
    height: 100%;
    min-height: 1160px;
}
#google-apply-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 700px;
    min-height: 1160px;
}

.fixed .header {
    height: 56px;
    overflow: hidden;
}
.fixed #gnb {
    position: fixed;
    top: 0;
    left: 0;
}
#gnb.fix {
    position: fixed;
    top: 0;
    left: 0;
}
.fixed #lnb-wrap {
    position: fixed;
    top: 56px;
}

.header {
    font-family: 'Pretendard', sans-serif;
    position: relative;
    height: 80px;
    margin: 0 auto;
    z-index: 101;
    min-width: 1200px;
}
.header h1 {
    float: left;
}
.header h1 a {
    display: block;
    width: 106px;
    height: 44px;
    margin-top: 19px;
    background: url(https://s.wink.co.kr/pc/images/logo.png) no-repeat;
    font-size: 0;
    line-height: 0;
}
.header p {
    float: left;
    margin: 22px 0 0 20px;
}
.header ul.util {
    float: right;
    margin-top: 27px;
}
.header ul.util li {
    padding: 0 20px;
}
.header ul.util li:not(:last-child):after {
    content: '';
    height: 20px;
    width: 1px;
    background: #a3a3a3;
    position: absolute;
    margin-left: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.header ul.util li:last-of-type {
    padding-right: 0;
}
.header ul.util li .banner-text {
    color: #333333;
}
.header ul.util li .banner-blue-link {
    color: #0183ff;
}
.header ul.util li .banner-blue-link:hover {
    opacity: 0.8;
}
.header div.select-mode {
    float: right;
    width: auto;
    margin-top: 23px;
    margin-left: 15px;
}
.header div.select-mode > button {
    height: auto;
    padding: 0 26px 0 10px;
    border: 2px solid #1db9ff;
    border-radius: 16px;
    font-family: 'Pretendard', sans-serif;
}
.header div.select-mode button i {
    padding: 0;
    color: #212121;
    font-size: 14px;
    line-height: 28px;
}
.header div.select-mode > button:after {
    content: '';
    position: absolute;
    top: 14px;
    right: 14px;
    width: 8px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode.png')
        no-repeat;
}
.header div.select-mode div {
    top: 2px;
    padding-top: 32px;
    border: 2px solid #1db9ff;
    border-radius: 16px;
}
.header div.select-mode.on > button {
    border-color: #1db9ff;
}
.header div.select-mode.on > button:after {
    content: '';
    position: absolute;
    top: 11px;
    right: 10px;
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode_on.png')
        no-repeat;
}
.header div.select-mode.on div {
    border-radius: 16px;
    padding-bottom: 14px;
    -webkit-box-shadow: 3px 0 7px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 3px 0 7px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 0 7px rgba(0, 0, 0, 0.15);
}
.header div.select-mode.on ul {
    max-height: 74px;
}
.header div.select-mode.on ul li:hover {
}
.header div.select-mode.on ul button {
    height: auto;
}
.header div.select-mode.on ul button i {
    line-height: 26px;
    padding-left: 11px;
}
.header ul.util li a span {
    position: relative;
    font-size: 15px;
    color: #a3a3a3;
}

/* 로그인 전 회원가입 버튼 */
.header .btn-join {
    float: right;
    width: 77px;
    margin-top: 23px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 30px;
    color: #212121;
    border: 1px solid #ccc;
    text-align: center;
}
.toast .bi-check-circle-fill {
    background: url(https://us.wink.co.kr/pc/images/icon_check_circle.png) 0 0
        no-repeat;
    width: 22px;
    height: 22px;
}

.toast .bi-x {
    background: url(https://us.wink.co.kr/pc/images/icon_x_close.png) 0 0
        no-repeat;
    width: 15px;
    height: 15px;
}
.password-reset-modal .toast {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: -45px;
    left: 50%;
    padding: 11px;
    min-width: 430px;
    height: 25px;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    color: black;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 4px 0 #00000040;
}

.login-modal .toast {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 90px;
    left: 50%;
    padding: 11px;
    min-width: 260px;
    height: 25px;
    transform: translate(-50%, -50%);
    z-index: 3;
    background: white;
    color: black;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 4px 4px 0 #00000040;
}

.toast .bi.bi-x {
    cursor: pointer;
}

#gnb {
    font-family: 'Pretendard', sans-serif;
    min-width: 1260px;
    width: 100%;
    height: 75px;
    background: white;
    position: sticky;
    z-index: 100;
    border-bottom: 1px solid #d7d7d7;
    top: 0;
    margin: 0 auto;
}
#gnb > ul {
    width: 1260px;
    margin: 0 auto;
}

#gnb > ul li {
    float: left;
    width: 210px;
    position: relative;
}
#gnb > ul li a {
    position: relative;
    display: block;
    color: #333333;
    text-align: center;
    font-size: 18px;
    line-height: 75px;
}
#gnb > ul > li.on > a,
#gnb > ul > li.current > a {
    color: #1db9ff;
    background-repeat: no-repeat;
    font-weight: 600;
}
#gnb > ul li:first-child > ul li > ul {
    width: 178px !important;
}

#gnb > ul li.main-logo {
    height: 75px;
}

#gnb > ul li.main-logo a {
    height: 100%;
}

#gnb > ul li.main-logo a img {
    height: 160%;
    position: relative;
    top: -33%;
}

#gnb > ul li > ul {
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    margin: 0;
    transition: max-height 0.5s;
}

#gnb > ul li > ul > div {
    border: 1px solid #d7d7d7;
    background-color: white;
    border-radius: 10px;
}

#gnb > ul li > ul > div > li {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
}

#gnb > ul li > ul > div > li.two-lines {
    height: 60px;
    line-height: 80px;
}

#gnb > ul li > ul > div > li.two-lines a {
    line-height: 15px;
    white-space: pre;
}

#gnb > ul > li > ul li.new > a:after {
    display: inline-block;
    content: '';
    background-image: url('https://s.wink.co.kr/pc/renewalV3/icons/new_blink_square.gif');
    background-size: cover;
    width: 17px;
    height: 17px;
    transform: translate(2px, 4px) scale(0.8);
}

#gnb > ul li > ul > div > li:last-child {
    border-bottom: none;
}
#gnb > ul li.on > ul {
    max-height: 400px;
}
/*#gnb > ul li.on > ul{display:block;}*/
#gnb > ul li > ul li {
    float: none;
    width: auto;
    margin-top: 3px;
    padding: 0 16px;
}
#gnb > ul li > ul li a {
    display: inline-block;
    text-align: left;
    background: transparent !important;
    color: #666;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}
#gnb > ul li > ul li a:before {
    display: none;
}
#gnb > ul li > ul li a:hover {
    color: #1db9ff;
    font-weight: bold;
}
#gnb > ul li > ul li a.current {
    color: #1db9ff;
}
#gnb > ul li > ul li button {
    position: absolute;
    top: 5px;
    left: 154px;
    width: 13px;
    height: 13px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
#gnb > ul li > ul li button.open {
    background: url(https://s.wink.co.kr/pc/images/icon_open.png) 50% 50%
        no-repeat;
}
#gnb > ul li > ul li button.close {
    background: url(https://s.wink.co.kr/pc/images/icon_close.png) 50% 50%
        no-repeat;
}
#gnb > ul li > ul li b {
    font-weight: bold;
}
#gnb > ul li > ul li > ul {
    display: none;
    position: static;
    width: 179px;
    margin: 10px -16px 7px;
    padding: 10px 0;
    border-width: 0;
    box-shadow: none;
    background: #f5f5f5;
}
#gnb > ul li > ul li:last-child > ul {
    margin-bottom: -10px;
}
#gnb > ul li > ul li > ul li {
    padding-left: 25px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 16px 13px
        no-repeat;
}
#gnb > ul li > ul li > ul li:first-child {
    margin-top: 1px;
}
#gnb > ul li > ul li > ul li:last-child {
    margin-bottom: 0;
}
#gnb > ul li > ul li > ul li a {
    background: transparent !important;
}
/*#gnb li:hover > ul,
#gnb li > li:hover > ul{display:block;}*/

.lay-menu {
    position: relative;
    z-index: 101;
}
.lay-menu button.lay-open,
.lay-menu button.lay-close {
    position: absolute;
    top: 0;
    left: 50%;
    width: 56px;
    height: 56px;
    margin-left: 424px;
    padding: 24px 0 0 0;
    box-sizing: border-box;
    background-color: #676969;
    background-position: 50% 12px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -1px;
    cursor: pointer;
    z-index: 101;
}
.lay-menu button.lay-open {
    background-image: url(https://s.wink.co.kr/pc/images/icon_util_gnb.png);
}
.lay-menu button.lay-close {
    background-image: url(https://s.wink.co.kr/pc/images/icon_util_close.png);
}
.lay-menu .container {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: 961px;
    margin-left: -481px;
    border: 1px solid #00a5d5;
    border-top-width: 0;
    box-sizing: border-box;
    background: #fff url(https://s.wink.co.kr/pc/images/gnb_line.gif) repeat-y;

    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
}
.lay-menu .container .float-box {
    overflow: hidden;
    min-height: 370px;
}
.lay-menu .container .float-box:after {
    display: block;
    content: '';
    clear: both;
}
.lay-menu .menu-list {
    float: left;
    width: 181px;
    height: 100%;
    position: relative;
}
.lay-menu .menu-list > a,
.lay-menu .menu-list .blank {
    display: block;
    height: 56px;
    background: #08bbd5;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
}
.lay-menu .menu-list > span {
    text-indent: -9999px;
}
.lay-menu .menu-list > a:hover {
    background-color: #00a5d5;
}
.lay-menu .menu-list:first-of-type li > ul {
    width: 180px !important;
}
.lay-menu li {
    float: none;
    width: 100%;
    margin-top: 3px;
    padding: 0 16px;
    position: relative;
}
.lay-menu li:first-child {
    margin-top: 10px;
}
.lay-menu li:last-child {
    margin-bottom: 10px;
}
.lay-menu li a {
    color: #666;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}
.lay-menu li a:hover {
    color: #00a5d5;
}
.lay-menu li > ul > li > a {
    padding-left: 11px;
    background: url('https://s.wink.co.kr/pc/images/bu_dot2.png') 0 50%
        no-repeat;
}
.lay-menu li.new a {
    padding-right: 22px;
    background: url('https://s.wink.co.kr/pc/images/icon_new.png') right center
        no-repeat !important;
}
.lay-menu li button.open,
.lay-menu li button.close {
    position: absolute;
    top: 5px;
    left: 154px;
    width: 13px;
    height: 13px;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
.lay-menu li button.open {
    background-image: url(https://s.wink.co.kr/pc/images/icon_open.png);
}
.lay-menu li button.close {
    background-image: url(https://s.wink.co.kr/pc/images/icon_close.png);
}
.lay-menu li > ul {
    display: none;
    width: 100%;
    margin: 10px -16px 7px;
    padding: 1px 0 7px;
    background: #f5f5f5;
}
.lay-menu .etc {
    clear: both; /*padding-top:38px;*/
}
.lay-menu .etc:after {
    display: block;
    content: '';
    clear: both;
}
.lay-menu .etc .menu-list:first-of-type > a {
    border-right: 1px solid #e0e0e0;
}

.lay-floating {
    position: fixed;
    box-sizing: border-box;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lay-floating li {
    padding: 19px 0 17px;
    border-bottom: 1px solid #b0b0b0;
}
.lay-floating li a {
    display: block;
    font-size: 15px;
    color: #585858;
    font-family: 'NanumBarunGothic-Bold';
    padding-left: 45px;
    line-height: 30px;
    background-repeat: no-repeat;
    background-size: 41px 30px;
    cursor: pointer;
}

.lay-floating li a em {
    color: #f03c78;
}

.lay-floating li.lay-report a {
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-report.png');
}

.lay-floating li.lay-freestudy a span {
    color: #f03c78;
}

.lay-floating li.lay-paidstudy a {
    background-image: url('https://s.wink.co.kr/pc/1.1.0/common/floating/bg_lay_paidstudy.png');
    background-size: 30px;
    background-position: 5px 3px;
}

.lay-floating li.lay-recruit a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-recruit.png');
}

.lay-floating li.lay-chat a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/renewal/bg_lay_chat.png');
}

.lay-floating li.lay-counsel a {
    background-size: 30px;
    background-position: 8px 0;
    background-image: url('https://s.wink.co.kr/pc/images/bg_floating_recruit.png');
}

.lay-floating li.lay-happytalk {
    border-bottom: none;
}

.lay-floating li.lay-happytalk a {
    background-image: url('https://s.wink.co.kr/pc/images/bg_lay-happytalk.png');
    letter-spacing: -1px;
    cursor: default;
    line-height: 18px;
}

.lay-floating li.lay-download a {
    background-image: url('https://s.wink.co.kr/pc/renewal/bg_lay_download.png');
    background-size: 30px;
    background-position: 5px 0;
}

.lay-floating .happytalk-area {
    padding-bottom: 25px;
    border-bottom: 1px solid #b0b0b0;
    margin-bottom: 25px;
}

.lay-floating .happytalk-area p {
    font-size: 14px;
    letter-spacing: -1px;
    color: #222;
    margin-bottom: 25px;
    padding-left: 10px;
}
.lay-floating .happytalk-area p strong {
    font-family: 'NanumBarunGothic-Bold';
}

.lay-floating .lay-donation {
    position: relative;
    width: 200px;
}

.lay-floating .lay-donation > img {
    width: 200px;
}

.lay-floating .lay-donation .lay-donation-link {
    position: absolute;
    left: 50%;
    bottom: 17px;
    transform: translateX(-50%);
}

.lay-floating .lay-donation .lay-donation-link img {
    width: 160px;
}

.lay-floating .lay-additional-info {
    width: calc(100% - 4px);
    background-color: #ffffff;
    border-radius: 25px;
    border: 2px solid #dbdbdb;
    padding: 20px 0;
    box-shadow: 0 2px 0 #dbdbdb;
    margin-top: 3px;
}

.lay-floating .lay-reference-room {
    position: relative;
    padding-bottom: 32px;
}

.lay-floating .lay-reference-room-link {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.lay-floating .lay-reference-room-link > img {
    width: 160px;
}

.lay-floating .lay-reference-room > img {
    width: 152px;
}

.lay-floating .lay-group-request {
    position: relative;
    padding-bottom: 55px;
}

.lay-floating .lay-group-request .lay-group-request-text {
    text-align: center;
    font-weight: 700;
    color: #2e2e2e;
    font-size: 20px;
}

.lay-floating .lay-group-request-link {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}

.lay-floating .lay-group-request-link img {
    width: 160px;
}

.lay-floating .lay-group-request > img {
    width: 152px;
}

.lay-floating .lay-download-parent-app,
.lay-floating .lay-recruit-teacher,
.lay-floating .lay-recruit-promotion-teacher,
.lay-floating .lay-happy-talk,
.lay-floating .lay-close-button,
.lay-floating .lay-reference-room-button,
.lay-floating .lay-free-study {
    cursor: pointer;
}

.lay-floating .lay-reference-room-button {
    margin-top: -30px;
}

.lay-floating .lay-benefit-image .lay-free-study {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.lay-floating .lay-reference-room .lay-reference-room-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 48px;
    background: url('https://s.wink.co.kr/pc/renewalV3/floating_right_banner/reference_room_button_b.png');
    background-size: 200px 48px;
}

.lay-floating .lay-reference-room .lay-reference-room-button:hover {
    background: url('https://s.wink.co.kr/pc/renewalV3/floating_right_banner/reference_room_button_r.png');
    background-size: 200px 48px;
}

.lay-floating .lay-free-study {
    margin-top: -44px;
}

.lay-floating hr.lay-floating-divider {
    width: calc(100% - 40px);
    border: 1px solid #c4c4c4;
    margin: 15px 0;
}

.lay-floating .lay-additional-info ul.floating-menu-list {
    display: flex;
    flex-direction: column;
    gap: 13px;
    font-family: 'Pretendard', sans-serif;
    font-size: 18px;
    color: #2e2e2e;
    line-height: 26px;
}

.lay-floating .lay-additional-info ul.floating-menu-list li {
    display: grid;
    grid-template-columns: 21px 1fr;
    gap: 7px;
    border-bottom: none;
    padding: 0;
    white-space: pre;
}

.lay-floating .lay-additional-info ul.floating-menu-list li span {
    display: flex;
    justify-content: flex-end;
    transform: translateY(3px);
}

.lay-floating .lay-additional-info ul.floating-menu-list li span img {
    height: auto;
    width: 21px;
    object-fit: contain;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-download-parent-app
    span {
    padding-right: 2px;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-download-parent-app
    span
    img {
    height: auto;
    width: 12px;
    object-fit: contain;
}

.lay-floating
    .lay-additional-info
    ul.floating-menu-list
    li.lay-group-consultation
    span
    img {
    height: 17px;
    width: 17px;
    transform: translateY(3px);
}

.lay-floating .lay-cs-center {
    margin-top: 5px;
}

.lay-floating .lay-cs-center .lay-cs-center-text {
    text-align: center;
    font-weight: 500;
    color: #2e2e2e;
    font-size: 15px;
    line-height: 1.2;
}

.lay-floating .lay-cs-center .cs-center-tel {
    font-weight: 900;
    font-size: 26px;
    line-height: 1.5;
}

.lay-floating .lay-free-study img {
    width: 200px;
    margin-top: -4px;
}

.lay-floating .lay-free-study .free-study-text {
    display: block;
    font-size: 20px;
    font-weight: bold;
}

.lay-floating .lay-free-study .free-study-text span {
    color: #f03c78;
}

.lay-floating .lay-reference-room-button img {
    width: 200px;
}

.lay-floating .btn-happytalk {
    display: inline-block;
    width: 90%;
    margin: 0 5%;
    background: #ffd02c;
    font-size: 15px;
    color: #222;
    font-family: 'NanumBarunGothic-Bold';
    text-align: center;
    line-height: 55px;
    height: 54px;
    border-radius: 15px;
    cursor: pointer;
}

.lay-floating .cs-area {
    padding: 0 15px 15px;
    margin-top: 15px;
}

.lay-floating .cs-area .tit,
.lay-floating .cs-area .s-tit {
    font-size: 15px;
    color: #222;
}

.lay-floating .cs-area .tit {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 18px;
}

.lay-floating .cs-area .s-tit {
    text-align: center;
}

.lay-floating .cs-area .phone-number {
    display: inline-block;
    margin-top: 5px;
    font-size: 32px;
    color: #08bbd5;
    letter-spacing: -0.5px;
    font-weight: bold;
}

.lay-floating .cs-area .working-time {
    border-radius: 10px;
    text-align: center;
}

#container {
    position: relative;
    top: 0;
    bottom: 0;
    min-width: 1260px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 99;
    flex-direction: column;
}
#container:after {
    content: '';
    display: block;
    clear: both;
}

#lnb-wrap {
    float: left;
    position: relative;
}
#lnb {
    width: 200px;
    padding-top: 44px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    box-sizing: border-box;
}
#lnb h2 {
    margin-bottom: 32px;
    padding-left: 16px;
    color: #212121;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;
}
#lnb > ul > li {
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
}
#lnb li a {
    display: block;
    padding-left: 16px;
    color: #999;
    font-size: 18px;
    line-height: 56px;
}
#lnb li a:hover {
    color: #08bbd5;
}
#lnb li > ul {
    padding: 23px 0 21px;
    border-top: 1px solid #e0e0e0;
    background: #f5f5f5;
}
#lnb li > ul li {
    margin-top: 10px;
    padding-left: 16px;
    border-bottom-width: 0;
}
#lnb li > ul li:first-child {
    margin-top: 0;
}
#lnb li > ul li a {
    padding-left: 14px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot.png) 0 9px no-repeat;
    color: #999;
    font-size: 16px;
    line-height: 1.6;
}
#lnb li > ul li a:hover {
    color: #666 !important;
}
#lnb li > ul li > ul {
    padding: 14px 0 10px;
    border: none;
}
#lnb li > ul li > ul li {
    margin-top: 13px;
    padding-left: 14px;
}
#lnb li > ul li > ul li a {
    padding-left: 10px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot4.png) 0 9px no-repeat;
    font-size: 14px;
    font-weight: normal;
}
#lnb li > ul li > ul li.on a,
#lnb li > ul li > ul li a:hover {
    color: #08bbd5 !important;
}

#lnb li.on a {
    background-color: #08bbd5;
    color: #fff;
}
#lnb li.on > ul li a {
    background-color: transparent;
    color: #999;
}
#lnb li.on > ul li.on a {
    color: #666;
}
#lnb li.on > ul li.on > ul li a {
    color: #999;
}
/*#lnb li{min-height:56px; border-bottom:1px solid #e0e0e0; box-sizing:border-box;}
#lnb li a{display:block; padding-left:16px; color:#999; font-size:18px; font-weight:bold; line-height:56px;}
#lnb li.on{border-bottom-width:0}
#lnb li.on a{background:#08bbd5; color:#fff;}*/
#lnb .as-center {
    padding: 28px 15px 18px;
    background: #f5f7fa;
    color: #999;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e0;
}
#lnb .as-center strong {
    display: block;
    color: #212121;
    font-size: 16px;
}
#lnb .as-center strong a {
    display: table;
    margin: -3px 0 7px -3px;
    color: #00a5d5;
    font-size: 28px;
    font-weight: normal;
    font-weight: bold;
}
#lnb .as-center b {
    color: #212121;
}
/* #lnb-wrap.lnb-fix{position: fixed; top:56px;z-index:5000;} */
/* isseu_1171 */
#lnb-wrap.lnb-fix {
    position: fixed;
    top: 56px;
}
/* // isseu_1171 */

#contents {
    width: 1000px;
    padding-top: 39px;
    background: #fff;
}
#contents section {
    margin-bottom: 90px;
}
#contents section:last-of-type {
    margin-bottom: 0;
}

/* footer */

#footer {
    width: 100%;
    height: 250px;
    background-color: #eeeeee;
    color: #333333;
    font-size: 16px;
    padding: 69px 0 52px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 90px;
    font-family: Pretendard, 'sans-serif';
}

#footer * {
    font-family: Pretendard, 'sans-serif';
}

#footer .footer-info-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 38px;
}

#footer .footer-info-container .footer-menu-list {
    display: flex;
    font-weight: 500;
}

#footer .footer-info-container .footer-menu-list li {
    position: relative;
    margin: 0 20px;
}

#footer .footer-info-container .footer-menu-list li a {
    color: #333333;
}

#footer .footer-info-container .footer-menu-list li:not(:last-of-type):after {
    content: '';
    position: absolute;
    top: 4px;
    right: -20px;
    height: 65%;
    width: 1px;
    background: #a2a2a2;
}

#footer .footer-info-container .footer-menu-list li:first-child {
    margin-left: 0;
}

#footer .footer-info-container .footer-menu-list li:last-of-type {
    margin-right: 0;
}

#footer .footer-info-container .footer-menu-list li a.pink {
    color: #f03c78;
}

#footer .footer-info-container .footer-menu-list li a.blue {
    color: #1d93ff;
}

#footer .footer-info-container .footer-company-info {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-weight: 300;
}

#footer .footer-contact-container {
    font-weight: 500;
}

#footer .footer-contact-container .title {
    line-height: 21px;
}

#footer .footer-contact-container .cs-number {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    margin: 6px 0 3px;
}

#footer .footer-contact-container .time {
    line-height: 26px;
}

#footer .footer-contact-container .family-site-container {
    margin-top: 16px;
    position: relative;
}

#footer .footer-contact-container .family-site-container button {
    padding: 0 22px;
    border-radius: 5px;
    color: #ffffff;
    background: #c2c2c2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    width: 170px;
    height: 39px;
    font-size: 18px;
    cursor: pointer;
}

#footer .footer-contact-container .family-site-container button img {
    transition: transform ease-in-out 0.3s;
}

#footer .footer-contact-container .family-site-container button img.open {
    transform: rotate(180deg);
}

#footer .footer-contact-container .family-site-container .family-site-list {
    position: absolute;
    bottom: 39px;
    height: 0;
    overflow: hidden;
    transition: height ease-in-out 0.4s;
}

#footer
    .footer-contact-container
    .family-site-container
    .family-site-list.open {
    height: 78px;
    transition: height ease-in-out 0.4s;
}

#footer .footer-contact-container .family-site-container .family-site-list li {
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 170px;
    height: 39px;
    cursor: pointer;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
}

#footer
    .footer-contact-container
    .family-site-container
    .family-site-list
    li:not(:first-child) {
    border-top: none;
}

#footer .footer-contact-container .family-site-container .family-site-list a {
    color: #333333;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

/* footer */

/* Button */
.btn-area {
    margin-top: 24px;
    text-align: center;
}
.btn-area:after {
    content: '';
    display: block;
    clear: both;
}
.btn-area.justify .left {
    float: left;
}
.btn-area.justify .right {
    float: right;
}
.btn-area.right {
    text-align: right;
}
.btn-area.bottom {
    margin-top: 40px;
}
.btn-area a {
    margin: 0 2px;
}
/* issue_977 */
.btn-area.bottom .notice-text {
    text-align: center;
    color: red;
    margin-top: 40px;
}
/* // issue_977 */

.btn-type {
    overflow: hidden;
    display: inline-block;
    height: 40px;
    min-width: 80px;
    padding: 0 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #212121;
    font-size: 14px;
    line-height: 42px;
    cursor: pointer;
}
.btn-type:hover {
    border-color: #212121;
}
.btn-type.off {
    border-color: #e5e5e5;
    color: #bdbdbd;
    cursor: default;
}
.btn-type.go {
    border-width: 0;
    background: #95989f;
    color: #fff;
    text-decoration: none;
}
.btn-type.go:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/bu_go3.png) no-repeat;
    vertical-align: middle;
}
.btn-type.go:hover {
    background-color: #575b63;
}
.btn-type.save {
    border-width: 0;
    background: #95989f;
    color: #fff;
    text-decoration: none;
}
.btn-type.save:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 15px;
    margin-left: 6px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_save.png) no-repeat;
    vertical-align: middle;
}
.btn-type.save:hover {
    background-color: #575b63;
}
.btn-type.transfer {
    border-color: #e0e0e0;
    background: #f5f5f5;
    color: #999;
}
.btn-type.transfer.on {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}
.btn-type.transfer.on:hover {
    background: #e52565;
}
.btn-type.form {
    border-width: 0;
    background: #95989f;
    color: #fff;
}
.btn-type.form:hover {
    background: #575b63;
}
.btn-type.form.off {
    background: #e5e5e5;
    color: #bdbdbd;
}
.btn-type.on {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}

.btn-type2 {
    overflow: hidden;
    display: inline-block;
    height: 48px;
    min-width: 100px;
    padding: 0 24px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #212121;
    font-size: 16px;
    line-height: 50px;
}
.btn-type2:hover {
    border-color: #212121;
}
.btn-type2:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
}
.btn-type2.confirm-num {
    height: 40px; /*margin-left:4px;*/
    padding: 0 20px;
    font-size: 14px;
    line-height: 40px;
    color: #f03c78;
    border-radius: 4px;
}
.btn-type2.confirm-num:hover {
    border-color: #f03c78;
}
.btn-type2.color {
    color: #f03c78;
}
.btn-type2.color:hover {
    border-color: #f03c78;
}
.btn-type2.on {
    border-width: 0;
    background: #f03c78;
    color: #fff !important;
}
.btn-type2.on:hover {
    background: #e52565;
}
.btn-type2.on2 {
    border-width: 0;
    background: #95989f;
    color: #fff !important;
}
.btn-type2.on2:hover {
    background: #575b63;
}
.btn-type2.mms {
    color: #f03c78;
}
.btn-type2.mms:hover {
    border-color: #f03c78;
}
.btn-type2.mms:before {
    width: 19px;
    height: 16px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_mms.png) no-repeat;
}
.btn-type2.mms2:before {
    width: 19px;
    height: 16px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_mms2.png) no-repeat;
}
.btn-type2.qna:before {
    width: 19px;
    height: 19px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_qna.png) no-repeat;
}
.btn-type2.counsel:before {
    width: 14px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_counsel.png)
        no-repeat;
}
.btn-type2.recommend:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_recommend.png)
        no-repeat;
}
.btn-type2.apply:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply2.png)
        no-repeat;
}
.btn-type2.study:before {
    width: 19px;
    height: 18px;
    margin-right: 7px;
    background: url(https://s.wink.co.kr/pc/images/mykids/icon_btn_study.png)
        no-repeat 0 0;
}
.btn-type2.search {
    padding: 0 24px;
}
.btn-type2.search:before {
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_search.png)
        no-repeat;
}
.btn-type2.give-box:before {
    width: 23px;
    height: 22px;
    margin: 0 10px 4px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_give_box.png) no-repeat;
}
.btn-type2.lock:before {
    width: 18px;
    height: 19px;
    margin-top: -3px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_lock.png) no-repeat
        0 0;
}
.btn-type2.home:before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_home.png) no-repeat;
}
.btn-type2.free {
    background: #fff;
    color: #f03c78;
}
.btn-type2.free:hover {
    border-color: #f03c78;
}
.btn-type2.free:before {
    width: 19px;
    height: 18px;
    margin: 0 10px 1px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_free.png) no-repeat;
}
.btn-type2.disabled {
    cursor: not-allowed;
    border: 1px solid #ccc;
}

.btn-type3 {
    display: inline-block;
    height: 64px;
    padding: 0 48px;
    background: #00a5d5;
    border-radius: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 66px;
}
.btn-type3:hover {
    background: #006bb2;
}
.btn-type3.apply:before {
    content: '';
    display: inline-block;
    width: 23px;
    height: 21px;
    margin: 0 12px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply.png) no-repeat;
    vertical-align: middle;
}
.btn-type3.modal-footer-btn {
    height: 40px;
    padding: 0 200px;
    line-height: 45px;
}

.btn-play {
    display: inline-block;
    width: 56px;
    height: 56px;
    background: url(https://s.wink.co.kr/pc/images/btn_play.png) no-repeat;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.paging-navigation {
    margin-top: 40px;
    text-align: center;
}
.paging-navigation a.prev,
.paging-navigation a.next {
    display: inline-block;
    width: 10px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/btn_navigation.png) no-repeat;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
}
.paging-navigation a.prev {
    margin-right: 6px;
    background-position: 0 0;
}
.paging-navigation a.prev:hover {
    background-position: 0 -18px;
}
.paging-navigation a.next {
    margin-left: 6px;
    background-position: -10px 0;
}
.paging-navigation a.next:hover {
    background-position: -10px -18px;
}
.paging-navigation ul {
    display: inline-block;
    vertical-align: middle;
}
.paging-navigation ul:after {
    content: '';
    display: block;
    clear: both;
}
.paging-navigation li {
    float: left;
    margin: 0 4px;
}
.paging-navigation li a {
    display: block;
    width: 36px;
    height: 36px;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    box-sizing: border-box;
    color: #666;
    font-size: 14px;
    line-height: 36px;
}
.paging-navigation li a:hover {
    border-color: #6a6a6a;
    color: #666;
}
.paging-navigation li.on a,
.paging-navigation li.on a:hover {
    border-color: #00a0e0;
    color: #00a0e0;
}

.btn-enroll {
    display: inline-block;
    background: #f03c78;
    color: #fff;
    border: 1px solid #ca3f6e;
    border-radius: 30px;
    width: 393px;
    height: 61px;
    text-decoration: none;
    line-height: 63px;
    font-size: 26px;
    text-align: center;

    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.2);
}

/* 이전글 다음글 */
.text-navigation {
    margin-top: 64px;
    border: 1px solid #676969;
    border-width: 1px 0;
}
.text-navigation div:first-child {
    border-top: 0 none;
}
.text-navigation div {
    padding: 16px 16px 14px;
    border-top: 1px solid #e0e0e0;
}
.text-navigation div:first-child strong {
    background-image: url(https://s.wink.co.kr/pc/images/icon_text_prev.png);
}
.text-navigation div strong {
    margin-right: 40px;
    padding-left: 25px;
    font-weight: normal;
    color: #757575;
    background: url(https://s.wink.co.kr/pc/images/icon_text_next.png) no-repeat
        0 50%;
}
.text-navigation div strong,
.text-navigation div a {
    vertical-align: middle;
}
.text-navigation div a {
    display: inline-block;
    overflow: hidden;
    max-width: 575px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    color: #212121;
}

/* Tab */
.tab-type {
    height: 48px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 48px;
}
.tab-type:after {
    content: '';
    display: block;
    clear: both;
}
.tab-type li {
    float: left;
}
.tab-type li a {
    display: block;
    border: 1px solid #e0e0e0;
    border-left-width: 0;
    background: #f7f7f7;
    color: #666;
    height: 46px;
}
.tab-type li:first-child a {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left-width: 1px;
}
.tab-type li:last-child a {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.tab-type li.on a {
    border-bottom-width: 0;
    background: #fff;
    color: #f03c78;
}
.tab-type li.on:first-child a {
    border-bottom-left-radius: 0;
}
.tab-type li.on:last-child a {
    border-bottom-right-radius: 0;
}
.tab-type.cell-1 li {
    width: 100%;
}
.tab-type.cell-2 li {
    width: 50%;
}
.tab-type.cell-3 li {
    width: 33.33%;
}
.tab-type.cell-4 li {
    width: 25%;
}
.tab-type.cell-5 li {
    width: 20%;
}
.tab-type.cell-6 li {
    width: 16.66%;
}
.tab-type.cell-7 li {
    width: 14.28%;
}
.tab-type.cell-8 li {
    width: 12.5%;
}
.tab-type.cell-8 li a span {
    font-size: 14px;
}

.login-modal .tab-type li a {
    border-top-right-radius: 15px;
}

.login-modal .tab-type li:first-child a {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
}

.login-modal input {
    border-radius: 15px !important;
}

.login-modal .btn-type.form {
    border-radius: 15px;
}

.login-modal .phone .text-valid {
    padding-left: 8px;
    font-size: 16px;
}

.login-modal.member.layer-wrap .tab-type {
    margin-top: 0;
}

.login-modal input[type='checkbox'] + label:before {
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    margin-top: -15px;
}
.login-modal input[type='checkbox']:checked + label:before {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/checked.png)
        6px 6px no-repeat;
    background-size: 18px 18px;
}

.layer-wrap.member.login-modal .save input[type='checkbox'] + label {
    padding-left: 38px;
    line-height: 38px;
    font-size: 15px;
}

.layer-wrap.member.login-modal .id-login + .save {
    height: auto;
}

.layer .login-modal.layer-wrap button.close {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/close_button.png)
        no-repeat;
    width: 34px;
    height: 34px;
}

.layer-wrap.member.login-modal .tab-type {
    margin-bottom: 4px;
}

.layer-wrap.member.login-modal .reset-password {
    position: absolute;
    right: 40px;
    font-size: 15px;
    line-height: 38px;
    cursor: pointer;
}

.layer-wrap.member.password-reset-modal {
    width: 407px;
    overflow: inherit;
    margin-top: -296px;
}

.password-reset-step1 .text-valid {
    position: absolute;
    margin-top: 18px;
    right: 35px;
    font-size: 15px;
}

.password-reset-step1 .text-valid span {
    margin-left: 8px;
    color: #f03c78;
}

.password-reset-step1 .password-reset-banner img {
    width: 90%;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}

.password-reset-modal .code-error {
    display: flex;
    color: #f03c78;
    font-size: 12px;
    white-space: pre;
    margin-top: 10px;
}

.password-reset-modal .code-error > i {
    background: url(https://us.wink.co.kr/pc/images/icon_warning.png) 0 0
        no-repeat;
    width: 14px;
    height: 14px;
}

.password-reset-modal .new-password-wrapper {
    position: relative;
}
.password-reset-modal .new-password-wrapper .warning {
    background-color: #fff2f6;
}
.password-reset-step1 .bi.bi-exclamation-triangle-fill,
.password-reset-step2 .bi.bi-exclamation-triangle-fill {
    margin-right: 5px;
}

.layer-wrap.member.password-reset-modal button.close {
    background: url(https://us.wink.co.kr/pc/renewalV3/login-modal/close_button.png)
        no-repeat;
    width: 34px;
    height: 34px;
}
.layer-wrap.member.password-reset-modal input {
    width: 100%;
    border-radius: 15px !important;
    margin-top: 9px;
}

.layer-wrap.member.password-reset-modal input:disabled {
    border: 1px solid #d9d9d9a6;
    background-color: #f0f0f0;
}

.layer-wrap.member.password-reset-modal input[type='text']:focus,
.layer-wrap.member.password-reset-modal input[type='password']:focus,
.layer-wrap.member.password-reset-modal input[type='error']:focus {
    outline-color: #e54179 !important;
}

.layer-wrap.member.password-reset-modal input[type='error'] {
    background-color: #fff2f6;
}

.btn-type.form.resetmodal-certification {
    position: absolute;
    right: 32px;
    border-radius: 12px;
    margin-top: 9px;
    padding: 0;
}

.btn-type.form.resetmodal-certification {
    width: 113px;
    background-color: #f15083;
    color: #ffffff;
    line-height: 14px;
}

.btn-type.form.resetmodal-certification:disabled {
    background-color: #f0f0f0;
    color: #7a7a7a;
    border: 1px solid #d9d9d9;
}

.btn-type.form.password-reset-next {
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #f15083;
    color: #ffffff;
    font-size: 15px;
}

.btn-type.form.password-reset-next:disabled {
    background-color: #f0f0f0;
    color: #28282896;
}

.password-reset-step2 .password-reset-banner img {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
}
.password-reset-step2 .bi.bi-eyenew,
.password-reset-step2 .bi.bi-eyere {
    background: url('https://s.wink.co.kr/pc/images/icon_eye_open.png')
        no-repeat;
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.password-reset-step2 .bi.bi-eye-slashnew,
.password-reset-step2 .bi.bi-eye-slashre {
    background: url('https://s.wink.co.kr/pc/images/icon_eye_close.png')
        no-repeat;
    width: 20px;
    height: 20px;
}

.password-reset-step2 .bi.bi-eyenew,
.password-reset-step2 .bi.bi-eye-slashnew,
.password-reset-step2 .bi.bi-eyere,
.password-reset-step2 .bi.bi-eye-slashre {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.tab-nav {
    width: 720px;
    background: #fff;
}
.tab-nav-f {
    z-index: 99;
    position: fixed;
    top: 55px;
    padding-top: 39px;
}
.tab-nav:after {
    content: '';
    display: block;
    clear: both;
}
.tab-nav ul.tab-type2 {
    margin-bottom: 0px;
}
.tab-nav-slogan.slogan {
    padding: 50px 0 0;
}
.tab-type2 {
    height: 48px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 48px;
}
.tab-type2:after {
    content: '';
    display: block;
    clear: both;
}
.tab-type2 li {
    float: left;
}
.tab-type2 li a {
    display: block;
    height: 48px;
    border: 1px solid #e0e0e0;
    border-left-width: 0;
    box-sizing: border-box;
    background: #f7f7f7;
    color: #666;
}
.tab-type2 li:first-child a {
    border-left-width: 1px;
}
.tab-type2 li.on a {
    border-width: 0;
    background: #676969;
    color: #fff;
    position: relative;
}
.tab-type2 li.on a:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 12px;
    height: 5px;
    margin-left: -6px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge.png) no-repeat;
}
.tab-type2 li.on:first-child a {
    margin-left: 0;
}
.tab-type2 li.on:last-child a {
    margin-right: 0;
}
.tab-type2.cell-2 li {
    width: 50%;
}
.tab-type2.cell-3 li {
    width: 33.33%;
}
.tab-type2.cell-4 li {
    width: 25%;
}
.tab-type2.cell-5 li {
    width: 20%;
}
.tab-type2.cell-6 li {
    width: 16.66%;
}
.tab-type2.cell-7 li {
    width: 14.28%;
}

/* Form */
legend {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}
select,
textarea,
input,
label {
    display: inline-block;
    vertical-align: middle;
}
select,
textarea,
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}
input[type='search'] {
    -webkit-appearance: textfield;
}
input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
button i {
    position: relative;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

div.select-mode {
    display: inline-block;
    min-width: 80px;
    position: relative;
    z-index: 1;
    vertical-align: middle;
}
div.select-mode > button {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    line-height: 40px;
}
div.select-mode button i {
    display: block;
    padding: 0 20px 0 10px;
}
div.select-mode > button:after {
    content: '';
    position: absolute;
    top: 17px;
    right: 11px;
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode.png')
        no-repeat;
}
div.select-mode div {
    position: absolute;
    top: 38px;
    left: -10000px;
    border: 1px solid #212121;
    border-top: none;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
}
div.select-mode.on {
    z-index: 2;
}
div.select-mode.on > button {
    border-color: #212121;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
}
div.select-mode.on > button:after {
    content: '';
    width: 10px;
    height: 6px;
    background: url('https://s.wink.co.kr/pc/images/bg_select_mode_on.png')
        no-repeat;
    position: absolute;
    top: 16px;
    right: 10px;
}
div.select-mode.on div {
    left: 0;
    width: 100%; /*z-index:103;*/
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
}
/* issue_1111_02 */
div.select-mode.on ul {
    max-height: 78px;
    overflow-y: scroll;
}
.ver4 div.select-mode.on ul {
    max-height: 117px;
}
.ver5 div.select-mode.on ul {
    max-height: 156px;
}
.ver6 div.select-mode.on ul {
    max-height: 195px;
}

/* div.select-mode.on ul{max-height:195px; overflow-y:scroll;} */
.form.finish-form div.select-mode.on ul {
    max-height: 195px;
    overflow-y: scroll;
}

div.select-mode.on.size-1 ul {
    max-height: 40px !important;
}
div.select-mode.on.size-2 ul {
    max-height: 80px !important;
}
div.select-mode.on.size-3 ul {
    max-height: 120px !important;
}
div.select-mode.on.size-4 ul {
    max-height: 160px !important;
}
div.select-mode.on.size-5 ul {
    max-height: 200px !important;
}
div.select-mode.on.size-6 ul {
    max-height: 240px !important;
}
div.select-mode.on.size-7 ul {
    max-height: 280px !important;
}

/* // issue_1111_02 */
div.select-mode.on ul button {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    background: transparent;
    text-align: left;
    color: #999;
    font-size: 14px;
    line-height: 36px;
}
div.select-mode.on ul button:hover {
    background: #f6f6f6;
    color: #212121;
}
div.select-mode.selected > button {
    color: #212121;
}
div.select-mode.off > button {
    border-color: #e0e0e0;
    background: #f0f0f0;
    color: #bdbdbd;
}

textarea {
    width: 100%;
    min-height: 250px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    resize: vertical;
}
textarea + p {
    margin-top: 3px;
    text-align: right;
    color: #999;
    font-size: 14px;
}
textarea:focus {
    border-color: #212121;
    color: #212121;
}
textarea:focus + p b {
    color: #212121;
}
textarea.disabled {
    border-color: #e0e0e0;
    background: #f0f0f0;
    color: #bdbdbd;
}

input::-webkit-input-placeholder {
    color: #cccccc;
}
input:-moz-placeholder {
    color: #cccccc;
}
input::-moz-placeholder {
    color: #cccccc;
}
input:-ms-input-placeholder {
    color: #cccccc;
}

input[type='checkbox'] {
    position: absolute;
    opacity: 0;
}
input[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    margin-right: 28px;
    padding-left: 28px;
    color: #999;
    font-size: 16px;
    cursor: pointer;
}

input[type='checkbox'] + label:before {
    content: '';
    left: 0;
    top: 50%;
    margin-top: -10px;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url('https://s.wink.co.kr/pc/renewalV3/common/check-off.png')
        no-repeat;
}
input[type='checkbox']:checked + label {
    color: #212121;
}
input[type='checkbox']:checked + label:before {
    background: url('https://s.wink.co.kr/pc/renewalV3/common/bg-check-on.svg')
        no-repeat;
}
input[type='checkbox'].disabled + label {
    color: #bdbdbd;
}
input[type='checkbox'].disabled + label:before {
    background: url('https://s.wink.co.kr/pc/renewalV3/common/check-off.png')
        no-repeat;
}

input[type='radio'] {
    position: absolute;
    opacity: 0;
}
input[type='radio'] + label {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    padding-left: 32px;
    color: #999;
    font-size: 16px;
    cursor: pointer;
}
input[type='radio'] + label:before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    background: url('https://s.wink.co.kr/pc/renewalV3/common/bg_util_radio_color.png')
        no-repeat;
}
input[type='radio']:checked + label {
    color: #212121;
}
input[type='radio']:checked + label:before {
    background-position: -24px 0;
}
input[type='radio'].disabled + label {
    color: #bdbdbd;
}
input[type='radio'].disabled + label:before {
    background-position: -48px 0;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='error'] {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #cccccc;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    line-height: normal !important;
}
input[type='text']:focus,
input[type='password']:focus {
    border-color: #212121;
    color: #212121;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.disabled {
    border-color: #cccccc;
    background: #f3f3f3;
    color: #bdbdbd;
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input.on {
    color: #212121;
}
input.error,
input.error:focus {
    border-color: #e83030 !important;
}
input.full-size {
    width: 100%;
}

input[type='file'] {
    position: absolute;
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}
/* input file */
.file + .add-file {
    margin-top: 5px;
}
.add-file a {
    font-size: 14px;
    vertical-align: middle;
    text-decoration: underline;
    color: #212121;
}
.add-file .file-remove {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    background: url(https://s.wink.co.kr/pc/images/btn_add_file_remove.png)
        no-repeat 50% 50%;
}

fieldset.name input[type='text'] {
    width: 160px;
}
fieldset.password input[type='password'] {
    width: 230px;
}

fieldset.phone input[type='text'] {
    width: 190px;
}
/* issue_977 */
fieldset.phone input.long[type='text'] {
    width: 278px;
    margin-right: 5px;
}
/* // issue_977 */
fieldset.phone a.btn-type {
    margin-left: 4px;
}
fieldset.phone a.btn-type:first-child {
    margin-left: 0;
    margin-right: 4px;
}
fieldset.phone .text-valid {
    /*margin-left:4px; */
    font-size: 14px;
    line-height: 40px;
    vertical-align: middle;
}
fieldset.phone .text-valid span {
    color: #f15083;
}

fieldset.file input[type='text'] {
    width: 240px;
}
fieldset.file a.btn-type {
    margin-left: 4px;
}

fieldset.email input[type='text'] {
    width: 120px;
}
fieldset.email input[type='text'].single {
    width: 300px;
}
fieldset.email i {
    display: inline-block;
    margin: 0 3px;
    vertical-align: middle;
    font-size: 14px;
}
fieldset.email .select-mode {
    min-width: 150px;
    margin-left: 5px;
}

fieldset.address input[type='text'] {
    width: 470px;
    margin-top: 8px;
}
fieldset.address input[type='text'].post {
    width: 120px;
    margin-top: 0;
    margin-right: 4px;
}

fieldset.school input[type='text'] {
    width: 180px;
}
fieldset.school .select-mode {
    margin-right: 4px;
    margin-left: 20px;
}
fieldset.school .select-mode:first-of-type {
    width: 100px;
    margin-left: 4px;
}
fieldset.school span.option {
    display: block;
    margin-top: 16px;
}

fieldset.children span.option {
    display: block;
    margin-top: 14px;
}

fieldset.year .select-mode:first-of-type {
    width: 100px;
    margin-left: 0;
}
fieldset.year .select-mode {
    margin: 0 4px 0 20px;
}
fieldset.nationality .select-mode {
    width: 190px;
}
fieldset.radio-set input[type='text'] {
    width: 165px;
    margin: 0 4px;
}
fieldset.radio-set .select-mode {
    width: 100px;
    margin: 0 14px 0 4px;
}
fieldset.radio-set .select-mode:last-child {
    margin-right: 0;
}
fieldset.school .select-mode:first-of-type {
    width: 130px;
    margin-left: 0;
}
fieldset.school input.school-name {
    width: 300px;
}

fieldset.select-area .select-mode {
    width: 120px;
}
fieldset.select-area .select-mode.select-sm {
    width: 100px;
}

fieldset.message {
    height: 72px;
    padding: 16px 16px 16px 24px;
    box-sizing: border-box;
}
fieldset.message input[type='text'] {
    width: 350px;
    margin-right: 6px;
    border-width: 0;
}

fieldset.account input[type='text'] {
    width: 300px;
}
fieldset.account .btn-type {
    margin-left: 4px;
    border-width: 0;
    background: #95989f;
    color: #fff;
}
fieldset.account .btn-type:hover {
    background: #575b63;
}

.hidden-textarea {
    display: none;
    margin-top: 20px;
}

/* 양색자 선택 */
ul.family-select:after {
    content: '';
    display: block;
    clear: both;
}
ul.family-select li {
    float: left;
    margin-left: 16px;
    position: relative;
}
ul.family-select li:first-child {
    margin-left: 0;
}
ul.family-select li input[type='radio'] + label {
    width: 72px;
    height: 84px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    background-image: url(https://s.wink.co.kr/pc/images/member/bg_type.png);
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
}
ul.family-select li input[type='radio'] + label:before {
    display: none;
}
ul.family-select li input[type='radio'] + label:after {
    position: absolute;
    top: 60px;
    left: 6px;
    display: block;
    width: 60px;
    height: 24px;
    text-align: center;
    border-radius: 12px;
    background: #d6d6d6;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
}
ul.family-select li input[type='radio']:checked + label:before {
    display: block;
    top: -2px;
    left: auto;
    right: -2px;
    width: 22px;
    height: 22px;
    background: url(https://s.wink.co.kr/pc/renewalV3/common/check.png) 4px 4px
        no-repeat;
    background-size: 14px 14px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: #00a0e0;
}
ul.family-select li:nth-child(1) input[type='radio'] + label {
    background-position: 0 0;
}
ul.family-select li:nth-child(1) input[type='radio'] + label:after {
    content: '엄마';
}
ul.family-select li:nth-child(1) input[type='radio']:checked + label {
    background-position: 0 -72px;
}
ul.family-select li:nth-child(1) input[type='radio']:checked + label:after {
    background: #ff5252;
}
ul.family-select li:nth-child(2) input[type='radio'] + label {
    background-position: -72px 0;
}
ul.family-select li:nth-child(2) input[type='radio'] + label:after {
    content: '아빠';
}
ul.family-select li:nth-child(2) input[type='radio']:checked + label {
    background-position: -72px -72px;
}
ul.family-select li:nth-child(2) input[type='radio']:checked + label:after {
    background: #ff7e27;
}
ul.family-select li:nth-child(3) input[type='radio'] + label {
    background-position: -144px 0;
}
ul.family-select li:nth-child(3) input[type='radio'] + label:after {
    content: '할머니';
}
ul.family-select li:nth-child(3) input[type='radio']:checked + label {
    background-position: -144px -72px;
}
ul.family-select li:nth-child(3) input[type='radio']:checked + label:after {
    background: #ffc624;
}
ul.family-select li:nth-child(4) input[type='radio'] + label {
    background-position: -216px 0;
}
ul.family-select li:nth-child(4) input[type='radio'] + label:after {
    content: '할아버지';
}
ul.family-select li:nth-child(4) input[type='radio']:checked + label {
    background-position: -216px -72px;
}
ul.family-select li:nth-child(4) input[type='radio']:checked + label:after {
    background: #9bdbff;
}
ul.family-select li:nth-child(5) input[type='radio'] + label {
    background-position: -288px 0;
}
ul.family-select li:nth-child(5) input[type='radio'] + label:after {
    content: '기타';
}
ul.family-select li:nth-child(5) input[type='radio']:checked + label {
    background-position: -288px -72px;
}
ul.family-select li:nth-child(5) input[type='radio']:checked + label:after {
    background: #ae88c5;
}

/* 검색 */
div.search {
    margin-bottom: 32px;
    padding: 32px;
    border-radius: 8px;
    background: #f5f7fa;
}
div.search fieldset {
    overflow: hidden;
}
div.search fieldset:after {
    display: block;
    clear: both;
    content: '';
}
div.search fieldset input[type='text'] {
    float: left;
    width: 568px;
    border-width: 2px;
    border-color: #00a5d5;
}
div.search fieldset input[type='text'].on {
    color: #212121;
}
div.search fieldset button {
    float: right;
    height: 40px;
    padding: 0 28px;
    font-size: 14px;
    line-height: 40px;
}

div.search p {
    text-align: center;
    color: #999;
    font-size: 14px;
    line-height: 1.7;
}
div.search p .question-link {
    color: #00a5d5;
    text-decoration: underline;
}
div.search p b {
    color: #f03c78;
}

/* List */

.list-type {
    border-top: 2px solid #676969;
}
.list-type li {
    border-bottom: 1px solid #e0e0e0;
}
.list-type div.title {
    padding: 15px 40px 13px 16px;
    line-height: 1.75;
    position: relative;
    cursor: pointer;
    color: #212121;
}
.list-type a.title {
    display: block;
    padding: 15px 50px 13px 16px;
    box-sizing: border-box;
    color: #212121;
    line-height: 1.75;
    position: relative;
}
.list-type .title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    width: 14px;
    height: 7px;
    margin-top: -3.5px;
    background: url('https://s.wink.co.kr/pc/images/icon_arrow_down.png')
        no-repeat;
}
.list-type .title em.tag {
    margin-right: 5px;
}
.list-type .title span.date {
    position: absolute;
    top: 50%;
    right: 54px;
    height: 56px;
    margin-top: -28px;
    background: none;
    color: #757575;
    font-size: 16px;
    line-height: 56px;
}
.list-type .title img.new {
    margin-bottom: 2px;
    vertical-align: middle;
}
.list-type .ans {
    padding: 32px 16px;
}
.list-type li.on .title {
    background: #f5f5f5;
}
.list-type li.on .title:after {
    background: url('https://s.wink.co.kr/pc/images/icon_arrow_up.png')
        no-repeat;
}
.list-type li.on .ans {
    display: block;
}

/* 약관동의 */
.terms .all-check {
    position: relative;
    padding: 15px 0 13px;
    line-height: 1.75;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.terms .all-check label {
    color: #212121;
}
.terms .all-check label + small {
    font-size: small;
    margin-left: 25px;
}

/* issue_828 */
.agree-wrap {
    margin-top: 40px;
}
.agree-wrap p.title,
.agree-wrap > fieldset label {
    font-weight: bold;
    color: #212121;
}
.agree-wrap p.title {
    padding: 15px 0 13px;
    line-height: 1.75;
    font-size: 16px;
}
.agree-wrap div.agree-box {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
}
.agree-wrap .agree-box {
    /*margin-top:17px;*/
    border-top: 2px solid #676969;
}
.agree-wrap .agree-box > li > div {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 56px;
    color: #666;
}
.agree-wrap .agree-box > li {
    padding: 0 16px;
    border-bottom: 1px solid #e0e0e0;
}
.agree-wrap .agree-box > li > div input[type='checkbox'] + label {
    display: inline-block;
    padding: 0 50px 0 32px;
    margin-right: 0;
    color: #666;
    line-height: 27px;
}
.agree-wrap .agree-box .form-area {
    padding: 15px;
}
.font-color-black {
    color: black;
}
.font-weight-bold {
    font-weight: 700;
}
.agree-wrap .form-area {
    padding: 15px;
}
.agree-wrap
    .form-area.free-benefit-agreement
    input[type='radio']:checked
    + label {
    font-weight: bold;
}

.agree-box .btn-link,
.list-type .btn-link {
    background: #e9e9e9;
    color: #222;
    border-radius: 5px;
    display: inline-block;
    line-height: 20px;
    padding: 5px 10px;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -15px;
}
/* issue_1982 */

/*.agree-wrap .agree-box > li.on > div{background:url('https://s.wink.co.kr/pc/images/icon_arrow_up.png') right 50% no-repeat;}*/
/*.agree-wrap .agree-box > li > div.provision{display:none;overflow-x:hidden;overflow-y:scroll;height:310px;margin:0 -16px;padding:20px 16px;background:#f5f5f5;border-top:1px solid #e0e0e0;font-size:14px;line-height:24px;color:#666;}
.agree-wrap .agree-box > li > div.provision > strong{display:block;margin:24px 0;}
.agree-wrap .agree-box > li > div.provision > strong:first-child{margin-top:0;}*/
/*.agree-wrap .agree-box p{font-size:16px;}*/
/* issue_1982 */

/* Table */
table {
    border-collapse: collapse;
}
caption {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
}

table.form {
    width: 100%;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
table.form th,
table.form td {
    vertical-align: top;
}
table.form th {
    width: 104px;
    padding: 21px 0 0 16px;
    text-align: left;
    color: #212121;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.form tbody.family th {
    padding: 41px 0 0 0px;
    text-align: center;
}
table.form tbody.family th:nth-child(1) {
    width: 15%;
}
table.form tbody.family th:nth-child(2) {
    width: 15%;
}
table.form tbody.family th:nth-child(3) {
    width: 10%;
}
table.form tbody.family th:nth-child(4) {
    width: 30%;
}
table.form tbody.family th:nth-child(5) {
    width: 30%;
}
table.form tbody.family td {
    padding: 12px 5px;
}
table.form th em {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 1px 0 0 4px;
    vertical-align: top;
    background: url(https://s.wink.co.kr/pc/renewalV3/common/bu_important.svg)
        no-repeat;
    text-indent: -9999px;
}
table.form th small {
    display: block;
    color: #757575;
    font-size: 12px;
}
table.form td {
    padding: 12px 0;
}
table.form tr.radio th,
table.form tr.checkbox th,
table.form tr.text th {
    padding-top: 13px;
}
table.form tr:first-child th {
    padding-top: 41px;
}
table.form tr.radio:first-child th,
table.form tr.checkbox:first-child th,
table.form tr.text:first-child th {
    padding-top: 33px;
}
table.form tr:first-child td {
    padding-top: 32px;
}
table.form tr:last-child td {
    padding-bottom: 32px;
}
table.form tr.text td {
    color: #212121;
}

table.form p.notice {
    margin-top: 8px;
    padding-left: 9px;
    background-image: url(https://s.wink.co.kr/pc/images/bu_dot2.png);
    background-position: 0 10px;
    color: #999;
    font-size: 14px;
}
table.form + p.notice {
    padding-left: 27px;
    background-image: url(https://s.wink.co.kr/pc/images/icon_notice2.png);
    background-position: 0 1px;
}
table.form .item {
    margin-top: 10px;
}
table.form .item:first-of-type {
    margin-top: 0;
}
table.form .item label {
    font-size: 14px;
}

/* issue_614 권혁기 2017.06.27 */
.write-wrap table.form tr:last-child th {
    padding-bottom: 32px;
}
.write-wrap table.form tr.text:first-child th {
    padding-top: 41px;
}
.write-wrap .form .photo-add {
    margin-left: 73px;
}

table.tbl-list {
    table-layout: fixed;
    width: 100%;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
table.tbl-list th,
table.tbl-list td {
    padding: 16px 16px 14px;
}
table.tbl-list thead th {
    color: #212121;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.tbl-list tbody th,
table.tbl-list tbody td {
    border-top: 1px solid #e0e0e0;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* issue_1380 2017.10.16 */
table.tbl-list tbody td .user-id {
    display: inline-block;
}
/* issue_1380 2017.10.16 */
table.tbl-list tbody tr:first-child th.text-left {
    border-top: 1px solid #e0e0e0;
}
table.tbl-list tbody th.text-left {
    border-top: 0 none;
    text-align: left;
}
table.tbl-list tbody td.title {
    text-align: left;
}
table.tbl-list tbody td.title a {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #212121;
}
table.tbl-list tbody td.text-left {
    padding-left: 0;
    text-align: left;
}
table.tbl-list tbody td .tag {
    margin-right: 5px;
}

table.tbl-detail {
    width: 100%;
    width: 100%;
    border-top: 2px solid #676969;
}
table.tbl-detail th,
table.tbl-detail td {
    padding: 16px 16px 14px;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    color: #212121;
}
table.tbl-detail th {
    width: 72px;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
table.tbl-detail td {
    width: 224px;
}
table.tbl-detail td ul.file li {
    display: inline-block;
    margin-right: 20px;
}
table.tbl-detail td ul.file li a {
    color: #212121;
    font-size: 14px;
    text-decoration: underline;
}

table.qna thead tr th:nth-child(1) {
    width: 24px;
}
table.qna thead tr th:nth-child(2) {
    width: 94px;
}
table.qna thead tr th:nth-child(3) {
    width: 92px;
}
table.qna thead tr th:nth-child(4) {
    width: 295px;
}
table.qna thead tr th:nth-child(5) {
    width: 55px;
}

.relative-box {
    position: relative;
}
table .relative-box .btn-type {
    position: absolute;
    right: 0;
    top: -8px;
}
table .relative-box .btn-type.btn-remove {
    top: 4px;
    min-width: 56px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
}
table .relative-box .go {
    position: absolute;
    right: 0;
    top: 5px;
    line-height: 1.2;
}

.tbl-row {
    border-top: 2px solid #676969;
}
.tbl-row.no-line {
    border-top: 0 none;
}
.tbl-row table {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}
.tbl-row tr:first-child th,
.tbl-row tr:first-child td {
    padding-top: 28px;
}
.tbl-row tr:last-child th,
.tbl-row tr:last-child td {
    padding-bottom: 26px;
}
.tbl-row th,
.tbl-row td {
    padding: 7px 0;
    text-align: left;
    vertical-align: top;
    color: #212121;
}
.tbl-row th {
    padding-left: 16px;
    font-size: 14px;
    font-weight: bold;
}
.tbl-row td small {
    font-size: 14px;
}
.tbl-row td .sub-text {
    color: #999;
}
.tbl-row td.num {
    font-size: 32px;
    line-height: 1.2;
    vertical-align: middle;
    text-align: center;
    color: #f03c78;
}
.tbl-row td.num span {
    display: block;
    font-size: 16px;
}
.tbl-row .middle th,
.tbl-row .middle td {
    vertical-align: middle;
}
.tbl-row .btn-coupon {
    margin-left: 30px;
}

.layer-pay .layer-scroll .scroll > .section .tbl-row {
    width: 560px;
    margin-top: -32px;
}
.layer-pay .layer-scroll .scroll > .section .auto-pay {
    width: 560px;
}
.layer-pay .layer-scroll .scroll > .section .terms {
    width: 560px;
    margin-bottom: 48px;
}

/*질문/답변*/
div.qna {
    border-bottom: 1px solid #e0e0e0;
    background: #f5f5f5;
    color: #212121;
    line-height: 1.6;
}
div.qna p {
    margin: 0 32px;
    padding: 28px 0 26px 88px;
}
div.qna p.q {
    background: url(https://s.wink.co.kr/pc/images/bg_q.png) no-repeat 0 center;
}
div.qna p.a {
    border-top: 1px solid #e0e0e0;
    background: url(https://s.wink.co.kr/pc/images/bg_a.png) no-repeat 0 center;
}

/* 하단 설명 텍스트 box */
.footer-info-box {
    position: relative;
    margin-top: 96px;
    padding: 34px 30px 31px;
    border: 3px solid #e0e0e0;
    border-radius: 7px;
    background-color: #fff;
}
.footer-info-box strong {
    display: block;
    font-size: 24px;
    line-height: 32px;
    color: #212121;
}
.footer-info-box p {
    margin-top: 3px;
}

.footer-info-box.button-on-right .btn-type2 {
    position: absolute;
    right: 5%;
    bottom: 50%;
    transform: translate(0, 50%);
}

/* ul bullet dot style */
.list-style-dot {
    margin-top: 14px;
}
.list-style-dot:first-child {
    margin-top: 0;
}
p.list-style-dot,
.list-style-dot li {
    position: relative;
    padding-left: 8px;
}
p.list-style-dot:after,
.list-style-dot li:after {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 2px;
    height: 2px;
    background-color: #b1b1b1;
}
.list-style-dot.append-text li:after {
    top: 8px;
}
.list-style-dot li img {
    vertical-align: middle;
}
.list-style-dot li em {
    color: #08bbd5;
}

.append-text {
    font-size: 14px;
}
.append-text a {
    text-decoration: underline;
}

/* 하단 체크 */
.check-area {
    margin-top: 12px;
}
.check-area input[type='checkbox'] + label {
    font-size: 14px;
}

/* 문자메시지 */
.chat-wrap {
    margin: -32px 0;
    text-align: left;
}
.chat-wrap p.teacher {
    height: 80px;
    padding: 28px 0 0 24px;
    box-sizing: border-box;
    color: #212121;
    font-size: 24px;
}
.chat-wrap p.teacher em.tag {
    height: 32px;
    margin: 0 3px 2px 0;
    border-radius: 16px;
    font-size: 16px;
    line-height: 34px;
}
.chat-wrap p.teacher em.tag:first-child {
    margin-right: 1px;
}
.chat-wrap .notice {
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding-left: 55px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background: url(https://s.wink.co.kr/pc/images/icon_notice5.png) no-repeat
            24px 50%,
        rgba(0, 0, 0, 0.6);
}

.chat-list {
    position: relative;
    height: 360px;
    padding: 24px;
    background: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
}
.chat-list:after {
    content: '';
    display: block;
    clear: both;
}
.chat-list .message {
    display: inline-block;
    margin-top: 8px;
    padding: 13px 16px 11px;
    border-radius: 4px;
    position: relative;
}
.chat-list .message:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 10px;
}
.chat-list .message .media {
}
.chat-list .message .media button {
    background: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    cursor: pointer;
}
.chat-list .message .media span {
    display: inline-block;
    vertical-align: middle;
}
.chat-list .message .media .equalizer {
    position: relative;
    width: 77px;
    height: 30px;
    margin: 0 10px;
    background: url('https://s.wink.co.kr/pc/images/img_media_receive.png')
        no-repeat;
}
.chat-list .message .media .equalizer span {
    position: absolute;
    top: 0;
    left: 0;
    width: 77px;
    height: 30px;
    background: url('https://s.wink.co.kr/pc/images/img_media_receive_on.png')
        no-repeat;
}
.chat-list button.share {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 100%;
    background: url(https://s.wink.co.kr/pc/images/icon_share.png) no-repeat 3px
        50%;
    font-size: 0;
    line-height: 0;
}
.chat-list button.media {
    display: block;
    height: 31px;
    padding-left: 128px;
    background: url(https://s.wink.co.kr/pc/images/icon_media.png) no-repeat;
    color: #212121;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
}
.chat-list button.media.on {
    background-image: url(https://s.wink.co.kr/pc/images/icon_media_on.png);
}
.chat-list span.time {
    position: absolute;
    bottom: 0;
    color: #bdbdbd;
    font-size: 12px;
}
.chat-list span.date {
    clear: both;
    display: table;
    height: 28px;
    margin: 0 auto 32px;
    padding: 0 12px;
    border-radius: 14px;
    background: #e7e7e7;
    color: #757575;
    font-size: 14px;
    line-height: 28px;
}

.chat-list .receive {
    float: left;
    width: 260px;
    margin-left: 64px;
    margin-bottom: 32px;
}
.chat-list .receive figure {
    position: relative;
}
.chat-list .receive figure img {
    position: absolute;
    top: 0;
    left: -64px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.chat-list .receive figure img.default {
    border: 1px solid #e0e0e0;
}
.chat-list .receive figcaption {
    color: #212121;
}
.chat-list .receive figcaption em.tag {
    margin: 0 3px 4px 0;
}
.chat-list .receive .message {
    background: #fff;
    color: #212121;
}
.chat-list .receive .message:first-of-type {
    border-top-left-radius: 0;
}
.chat-list .receive .message:first-of-type:before {
    left: -8px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge_receive.png)
        no-repeat;
}
.chat-list .receive button.share {
    right: -40px;
}
.chat-list .receive span.time {
    right: -40px;
}
.chat-list .receive button.share + span.time {
    right: -78px;
}

.chat-list .transmit {
    float: right;
    width: 260px;
    margin-bottom: 32px;
}
.chat-list .transmit .message {
    float: right;
    clear: both;
    background: #5f7d8c;
    color: #fff;
}
.chat-list .transmit .message:first-of-type {
    border-top-right-radius: 0;
}
.chat-list .transmit .message:first-of-type:before {
    right: -8px;
    background: url(https://s.wink.co.kr/pc/images/bg_edge_transmit.png)
        no-repeat;
}
.chat-list .transmit span.time {
    left: -40px;
}
.chat-list .transmit button.share {
    left: -40px;
}
.chat-list .transmit button.share + span.time {
    left: -78px;
}

.chat-list .call {
    vertical-align: middle;
    margin-right: 5px;
}

.chat-area {
    position: relative;
}
.emoticon-view {
    display: none;
    position: absolute;
    left: 0;
    top: -150px;
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 20px 0 19px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
}
.emoticon-view .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 12px;
    height: 12px;
    text-indent: -999em;
    background: url(https://s.wink.co.kr/pc/images/mykids/btn_emoticon_close.png)
        no-repeat 0 0;
}
.chat-emoticon {
    padding-bottom: 6px;
}
.chat-emoticon .swiper-container {
    width: 100%;
    height: 73px;
    padding: 15px 0 20px;
    border-top: 1px solid #e0e0e0;
}
.chat-emoticon .swiper-slide {
    text-align: center;
    font-size: 0;
}
.chat-emoticon .swiper-slide a {
    display: inline-block;
    width: 20%;
}

/* Message */
.message-box {
    padding: 42px 0 48px;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
}
.message-box strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}
.message-box strong b {
    color: #f03c78;
}
.message-box small {
    display: block;
    margin-top: 30px;
    color: #999;
    font-size: 14px;
    line-height: 1.7;
}
.message-box img.loading {
    display: table;
    margin: 20px auto 0;
}
.message-box .btn-area {
    margin-top: 34px;
}
.message-box .notice-wrap {
    display: table;
    margin: 12px auto 40px;
    padding: 28px 32px 26px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #f5f5f5;
    text-align: left;
}
.message-box.loading {
    padding: 56px 0 62px;
    border-top-width: 0;
}
.message-box.no-line {
    border-width: 0;
}
.message-box.no-line-top {
    border: none;
}
.message-box.search-none {
    margin-top: 32px;
}
.message-box.search-none p {
    padding-top: 110px;
    background: url('https://s.wink.co.kr/pc/images/info/bg_none.gif') 50% 6px
        no-repeat;
}
/*.message-box.breakdown{padding:140px 0;}*/
.message-box.no-data {
    padding: 140px 0;
}
.message-box.no-data.no-line {
    border-bottom: none;
    border-top: none;
}
.message-box.no-data p {
    padding-top: 90px;
    background: url('https://s.wink.co.kr/pc/images/bg_nodata.png') 50% 0
        no-repeat;
}

.message-box.mykids-no-data p {
    padding-top: 80px;
    background: url('https://s.wink.co.kr/pc/images/mykids/icon_content_notice.png')
        50% 0 no-repeat;
}

.message-box.faq p {
    padding-top: 100px;
    background: url('https://s.wink.co.kr/pc/images/bg_nodata2.png') 50% 0
        no-repeat;
}
.message-box.shopping {
    margin-top: -32px;
    border-top-width: 0;
}
.message-box.shopping p {
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_message_transport.png)
        50% 0 no-repeat;
}

/* 정보 없음
.message-box.no-data {padding:110px 0;}
.message-box.no-data p {padding-top:110px; font-size:24px; background:url(https://s.wink.co.kr/pc/images/customer/icon_no_data.png) no-repeat 50% 0;}
.message-box.no-data p b {color:#f03c78;}

.message-box.no-data2 {padding:110px 0;}
.message-box.no-data2 p {padding-top:110px; font-size:24px; background:url(https://s.wink.co.kr/pc/images/customer/icon_no_data2.png) no-repeat 50% 0;} */

/* swiper css */
.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    direction: ltr;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /* Fix of Webkit flickering */
    z-index: 1;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    -webkit-transition-property: -webkit-transform, left, top;
    -webkit-transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    -webkit-transition-timing-function: ease;

    -moz-transition-property: -moz-transform, left, top;
    -moz-transition-duration: 0s;
    -moz-transform: translate3d(0px, 0, 0);
    -moz-transition-timing-function: ease;

    -o-transition-property: -o-transform, left, top;
    -o-transition-duration: 0s;
    -o-transform: translate3d(0px, 0, 0);
    -o-transition-timing-function: ease;
    -o-transform: translate(0px, 0px);

    -ms-transition-property: -ms-transform, left, top;
    -ms-transition-duration: 0s;
    -ms-transform: translate3d(0px, 0, 0);
    -ms-transition-timing-function: ease;

    -webkit-transition-property: left, top, -webkit-transform;

    transition-property: left, top, -webkit-transform;

    transition-property: transform, left, top;

    transition-property: transform, left, top, -webkit-transform;
    transition-duration: 0s;
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease;
    box-sizing: content-box;
}
.swiper-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    float: left;
    box-sizing: content-box;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
}

.swiper-container {
}
.swiper-slide {
}
.swiper-slide-active {
}
.swiper-slide-visible {
}
.swiper-pagination {
    text-align: center;
    line-height: 1.2;
}
.swiper-pagination-switch {
    display: inline-block;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border: 2px solid #ccc;
    border-radius: 100%;
    background-color: #fff;
}
.swiper-active-switch {
    border: 0 none;
    background-color: #00bbd4;
}
.swiper-visible-switch {
}

/* 댓글 */
section.reply h3 {
    margin-bottom: 8px;
    color: #212121;
    font-size: 20px;
    font-weight: bold;
}
section.reply h3 strong {
    color: #08bbd5;
    font-weight: normal;
}
section.reply fieldset {
    margin: 10px 0 32px;
}
section.reply fieldset button {
    width: 80px;
    height: 80px;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
    color: #999;
    font-size: 14px;
    line-height: 80px;
}
section.reply fieldset textarea {
    width: calc(100% - 88px);
    height: 80px;
    min-height: 0;
    margin-right: 4px;
}
section.reply fieldset textarea:focus ~ button {
    border-width: 0;
    background: #f03c78;
    color: #fff;
}
section.reply .reply-list {
    border-top: 2px solid #676969;
}
section.reply .reply-list li {
    padding: 26px 16px 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
}
section.reply .reply-list li > ul {
    margin: 0 -16px;
}
section.reply .reply-list li > ul li {
    padding-left: 64px;
    border-bottom-width: 0;
    border-top: 1px solid #e0e0e0;
    background: url(https://s.wink.co.kr/pc/images/icon_reply2.png) 38px 29px
        no-repeat;
}
section.reply .reply-list li p {
    margin: 5px 0 26px;
}
section.reply .reply-list li p ~ fieldset {
    margin-top: -8px;
}
section.reply .reply-list li span.writer {
    padding-left: 23px;
    background: url(https://s.wink.co.kr/pc/images/icon_user.png) no-repeat;
    color: #999;
    font-size: 14px;
}
section.reply .reply-list li span.time {
    margin: 0 4px;
    padding-left: 10px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 0 5px no-repeat;
    color: #999;
    font-size: 14px;
}
section.reply .reply-list li span.util {
    position: absolute;
    top: 26px;
    right: 16px;
}
section.reply .reply-list li span.util a {
    color: #666;
    font-size: 14px;
}
section.reply .reply-list li span.util a.cancel {
    text-decoration: underline;
}
section.reply .reply-list li span.util a:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 9px 2px 5px;
    vertical-align: middle;
    background: #bdbdbd;
}
section.reply .reply-list li span.util a:first-child:before {
    display: none;
}
section.reply .reply-list li a.reply {
    color: #08bbd5;
    font-size: 14px;
}
section.reply .reply-list li a.reply.cancel {
    text-decoration: underline;
}
section.reply .reply-list li.notice {
    padding: 16px 16px 14px;
}
section.reply p.notice {
    padding: 25px 16px 22px;
    border-bottom: 1px solid #e0e0e0;
    background: #f6f7fb;
    color: #212121;
}
section.reply p.notice span.date {
    display: table;
    margin-top: 7px;
    padding: 0;
    background: none;
}

/* 로딩 */
body.loading {
    background: none;
}
p.loading {
    padding-top: 250px;
    text-align: center;
    font-size: 18px;
}
p.loading img.loading {
    display: table;
    margin: 20px auto 0;
}

/* 오류 */
body.error {
    background: none;
}

p.error {
    padding: 270px 0 10px;
    background: url(https://s.wink.co.kr/pc/images/bg_error.png) 50% 123px
        no-repeat;
    text-align: center;
}
p.error strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}

/* 권한설정 로그인버튼 */
p.permission {
    padding: 270px 0 10px;
    text-align: center;
}
p.permission strong {
    display: block;
    margin-bottom: 2px;
    color: #212121;
    font-size: 24px;
}

/* 캘린더 날짜 선택 */
.calendar-selector {
    height: 32px;
    margin: 8px 0 32px;
    text-align: center;
    line-height: 32px;
    position: relative;
    z-index: 1;
}
.calendar-selector a,
.calendar-selector button,
.calendar-selector strong,
.calendar-selector .layer-calendar {
    display: inline-block;
    vertical-align: middle;
}
.calendar-selector a.prev,
.calendar-selector a.next {
    width: 32px;
    height: 32px;
    font-size: 0;
    line-height: 0;
}
.calendar-selector a.prev {
    background: url(https://s.wink.co.kr/pc/images/btn_prev.png) no-repeat;
}
.calendar-selector a.prev:hover {
    background: url(https://s.wink.co.kr/pc/images/btn_prev_on.png) no-repeat;
}
.calendar-selector a.next {
    background: url(https://s.wink.co.kr/pc/images/btn_next.png) no-repeat;
}
.calendar-selector a.next:hover {
    background: url(https://s.wink.co.kr/pc/images/btn_next_on.png) no-repeat;
}
.calendar-selector button.today {
    position: absolute;
    top: 0;
    right: 16px;
    width: 56px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    text-align: center;
    color: #212121;
    font-size: 12px;
    cursor: pointer;
}
.calendar-selector button.today:hover {
    border-color: #212121;
}

.layer-calendar {
    margin-top: 3px;
    position: relative;
    z-index: 99;
}
.layer-calendar button.picker {
    margin: 0 12px 2px 6px;
    padding-right: 20px;
    background: url(https://s.wink.co.kr/pc/images/btn_pull_down.png) 100% 50%
        no-repeat;
    color: #212121;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
.layer-calendar button.picker.on {
    background-image: url(https://s.wink.co.kr/pc/images/btn_pull_up.png);
}
.layer-calendar button.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/icon_util_close2.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
}
.layer-calendar .date-picker {
    display: none;
    position: absolute;
    top: 44px;
    left: -95px;
    width: 320px;
    padding: 20px 24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}
.layer-calendar .date-picker fieldset {
    margin: 12px 0;
    text-align: left;
    line-height: 40px;
}
.layer-calendar .date-picker fieldset .select-mode:first-of-type {
    width: 100px;
    margin-right: 4px;
}
.layer-calendar .date-picker th,
.layer-calendar .date-picker td {
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
}
.layer-calendar .date-picker th {
    color: #212121;
}
.layer-calendar .date-picker td {
    padding: 4px 0 0 8px;
    color: #bdbdbd;
}
.layer-calendar .date-picker td:first-child {
    padding-left: 0;
}
.layer-calendar .date-picker td a {
    display: block;
    height: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    color: #212121;
}
.layer-calendar .date-picker td a:hover {
    background: #f03c78;
    color: #fff;
}
.layer-calendar .date-picker td.today a {
    border: 1px solid #f03c78;
    color: #f03c78;
}
.layer-calendar .date-picker td.today a:hover {
    color: #fff;
}

.layer-calendar.layer-calendar-day .date-picker {
    left: -50px;
}

.calendar-selector.box {
    height: 80px;
    margin: 0 0 20px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background: #f5f5f5;
    line-height: 80px;
}
.calendar-selector.box button.today {
    top: 25px;
}
.calendar-selector.box .layer-calendar .date-picker {
    top: 68px !important;
}

/**/
.wink-user-post {
    border-bottom: 1px solid #e0e0e0;
    overflow: hidden;
}
.wink-user-post:after {
    content: '';
    display: block;
    clear: both;
}
.wink-user-post li {
    float: left;
    width: 220px;
    margin-left: 30px;
}
.wink-user-post li:nth-child(3n + 1) {
    margin-left: 0;
}
.wink-user-post li figure {
    position: relative;
}
.wink-user-post li figure > a {
    position: relative;
    display: block;
    width: 220px;
    height: 123px;
    border-radius: 8px;
    overflow: hidden;
}
.wink-user-post li figure img {
    vertical-align: top;
}
.wink-user-post li figure .thumb img {
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.wink-user-post li figcaption > a {
    display: block;
    margin: 19px 0 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #212121;
    font-size: 18px;
}
.wink-user-post li figcaption p {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 26px;
    font-size: 14px;
    line-height: 1.5;
}
.wink-user-post li .btn-play {
    position: absolute;
    top: 65px;
    right: 2px;
}
.wink-user-post li p.writer {
    margin-bottom: 27px;
    font-size: 14px;
    letter-spacing: -0.03em;
}
.wink-user-post li p.writer strong {
    display: block;
    padding-left: 22px;
    background: url(https://s.wink.co.kr/pc/images/icon_user.png) 0 3px
        no-repeat;
}
.wink-user-post li p.writer em {
    margin-left: 3px;
    padding-left: 9px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot2.png) 0 7px no-repeat;
    color: #999;
}
.wink-user-post li p.writer em:first-of-type {
    margin-left: 0;
    padding-left: 0;
    background: none;
}
.wink-user-post.section {
    margin-bottom: 90px;
}

/* Layer popup */
.layer-scroll {
    position: relative;
    margin-top: -32px;
}
.layer-scroll:after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    /*background:url(https://s.wink.co.kr/pc/images/customer/bg_gradaition_white.png) repeat-x 0 100%;*/
    background: -webkit-linear-gradient(
        transparent 8%,
        rgba(255, 255, 255, 0.9)
    );
    background: -o-linear-gradient(transparent 8%, rgba(255, 255, 255, 0.9));
    background: -moz-linear-gradient(transparent 8%, rgba(255, 255, 255, 0.9));
    background: linear-gradient(transparent 8%, rgba(255, 255, 255, 0.9));
}
.layer-scroll .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    max-height: 480px;
    margin-right: -32px;
    padding: 32px 16px 25px 0;
}
.layer-scroll .scroll.member {
    height: 386px;
}
.layer-scroll .scroll.chart {
    height: 417px;
}
.layer-scroll .scroll.pay {
    height: 325px;
    font-size: 16px;
}
.layer-scroll .scroll.tip {
    height: 367px;
}
.layer-scroll + .btn-area {
    margin-top: 15px !important;
}

.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

/* issue_939 2017.08.21 layer-extension */
.layer .layer-wrap .layer-extension h3 {
    padding-left: 17px;
    margin-bottom: 16px;
}

/* issue_612 layer초기 값*/
.scroll-wrap {
    padding: 15px;
    margin-bottom: 19px;
    border-bottom: 1px solid #e0e0e0;
    height: 230px;
    border-top: 2px solid #676969;
    overflow-x: none;
    overflow-y: auto;
}

.layer .dim {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 1;
}
.layer .layer-wrap {
    position: absolute;
    top: 50%;
    left: 50%; /*max-height:560px;*/
    margin-top: -280px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 2;
}
.layer .layer-wrap h2 {
    margin-bottom: 21px;
    color: #f03c78;
    font-size: 24px;
    line-height: 24px;
}
.layer .layer-wrap h2.line {
    margin-bottom: 32px;
    padding-bottom: 21px;
    border-bottom: 2px solid #676969;
}
.layer .layer-wrap h3 {
    margin-bottom: 9px;
    color: #212121;
    font-size: 18px;
}
.layer .layer-wrap .hgroup.line {
    margin-bottom: 32px;
    border-bottom: 2px solid #676969;
}
.layer .layer-wrap .hgroup h2 {
    margin-bottom: 10px;
}
.layer .layer-wrap .hgroup small {
    display: block;
    margin-bottom: 19px;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer .layer-wrap .btn-type2.confirm {
    border-width: 0;
    background: #53b7d2;
    color: #fff;
}
.layer .layer-wrap .btn-type2.confirm:hover {
    background: #575b63;
}
.layer .layer-wrap .btn-type2.confirm.off {
    background: #e5e5e5;
    color: #bdbdbd;
}
.layer .layer-wrap button.close {
    position: absolute;
    top: 31px;
    right: 31px;
    width: 18px;
    height: 18px;
    background: url(https://s.wink.co.kr/pc/images/icon_util_close2.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
    z-index: 3;
    cursor: pointer;
}
.layer .layer-wrap.small {
    width: 480px;
    margin-left: -240px;
    padding: 48px 0;
    text-align: center;
}
.layer .layer-wrap.large {
    width: 624px;
    margin-left: -312px;
    padding: 32px;
}
.layer .layer-wrap.larger {
    width: 784px;
    margin-left: -392px;
    padding: 32px;
}

.layer .layer-wrap.large table.form {
    width: 560px;
}
.layer .layer-wrap.large table.form fieldset.address input[type='text'].post {
    width: 120px;
}
.layer .layer-wrap.large table.form fieldset.address input[type='text'] {
    width: 406px;
}
.layer .layer-wrap.large .layer-scroll table.form {
    margin-top: -32px;
    border-top-width: 0;
}
.layer .layer-wrap.large .hidden-textarea textarea {
    width: 406px;
}

.layer-wrap .section {
    margin-top: 30px;
}
.layer-wrap .section:first-child {
    margin-top: 0 !important;
}
.layer-wrap .section .head {
    margin-bottom: 10px;
}
.layer-wrap .section .head h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #212121;
    line-height: 1.2;
}
.layer-wrap .section .head h3 small {
    font-size: 14px;
    color: #08bbd5;
}
.layer-wrap .section .head .notice {
    margin-bottom: 15px;
    padding-left: 27px;
    font-size: 14px;
    background: url(https://s.wink.co.kr/pc/images/icon_notice2.png) no-repeat 0
        0;
}
.layer-wrap .section .img-box {
    margin-top: 17px;
}
.layer-wrap .layer-scroll .section {
    margin-top: 30px;
}
.layer-wrap table.form + .check-area:last-child {
    margin-bottom: 20px;
}

.layer-download {
    margin-bottom: -6px;
}
.layer-download fieldset.phone {
    margin-bottom: 20px;
    padding-top: 8px;
}
.layer-download fieldset.phone input {
    width: 240px;
}

.layer-download .tip-img {
    width: 100%;
}

.layer-apply p.notice {
    margin-top: 12px;
}
.layer-apply p.notice:after {
    content: '';
    display: block;
    clear: right;
}
.layer-apply p.notice a.go {
    float: right;
    background-position: 100% 5px;
}

.layer-chat figure {
    position: relative;
    text-align: center;
}
.layer-chat figure img {
    vertical-align: top;
}
.layer-chat figcaption {
    position: absolute;
    bottom: 124px;
    right: 20px;
    width: 274px;
    height: 94px;
    padding: 11px 16px 0;
    box-sizing: border-box;
    text-align: left;
    background: rgba(41, 192, 211, 0.9);
    color: #fff;
    font-weight: bold;
    letter-spacing: -0.1px;
}
.layer-chat figcaption:after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: 44px;
    display: block;
    width: 37px;
    height: 50px;
    background: url(https://s.wink.co.kr/pc/images/myteacher/bg_line.png)
        no-repeat;
}

.layer-analysis .select-mode {
    width: 160px;
}
.layer-analysis .chart {
    margin-top: 16px;
    border-radius: 8px;
    overflow: hidden;
}
.layer-analysis .chart img.tmp {
    vertical-align: top;
}

.layer-share ul {
    text-align: center;
}
.layer-share ul:after {
    content: '';
    display: block;
    clear: both;
}
.layer-share li {
    display: inline-block;
    width: 80px;
}
.layer-share li a {
    display: block;
    color: #666;
    font-size: 14px;
}
.layer-share li a:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 12px;
    background: url(https://s.wink.co.kr/pc/images/icon_share3.png) no-repeat;
}
.layer-share li.facebook a:before {
    background-position: 0 0;
}
.layer-share li.cacaostory a:before {
    background-position: -64px 0;
}
.layer-share li.twitter a:before {
    background-position: -128px 0;
}
.layer-share li.mail a:before {
    background-position: -192px 0;
}
.layer-share fieldset {
    margin-top: 26px;
}
.layer-share fieldset label {
    width: 42px;
    margin-right: -4px;
    padding-left: 8px;
    text-align: left;
    color: #212121;
    font-size: 14px;
    font-weight: bold;
}
.layer-share fieldset input[type='text'] {
    width: calc(100% - 174px);
    margin-right: 4px;
    background: #fff;
    color: #212121;
}
.layer-share fieldset a.btn-type {
    border-width: 0;
    background: #95989f;
    color: #fff;
}
.layer-share fieldset a.btn-type:hover {
    background: #575b63;
}

.layer-post fieldset {
    margin-bottom: 16px;
}
.layer-post fieldset p {
    margin-bottom: 11px;
    color: #999;
    font-size: 14px;
}
.layer-post fieldset p b {
    display: block;
    color: #212121;
}
.layer-post fieldset input[type='text'] {
    width: 472px;
    margin-right: 4px;
}
.layer-post fieldset .btn-type {
    border-width: 0;
    background: #95989f;
    color: #fff;
    cursor: pointer;
}
.layer-post fieldset .btn-type:hover {
    background: #575b63;
}
.layer-post fieldset .select-mode {
    width: calc(50% - 4px);
    margin-bottom: 10px;
}
.layer-post fieldset .select-mode:first-of-type {
    margin-right: 4px;
}
.layer-post .scroll-list {
    border-radius: 4px;
    background: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
}
.layer-post .scroll-list.road {
    height: 184px;
}
.layer-post .scroll-list.number {
    height: 234px;
}
.layer-post .scroll-list table {
    width: 100%;
}
.layer-post .scroll-list td {
    padding: 12px 16px 10px 16px;
    color: #999;
    font-size: 14px;
}
.layer-post .scroll-list td a {
    color: #999;
}
.layer-post .scroll-list td:first-child {
    width: 62px;
}
.layer-post .scroll-list tr:hover td {
    background: #e0e0e0;
    color: #212121;
    cursor: pointer;
}
.layer-post .scroll-list tr:hover td a {
    color: #212121;
}
.layer-post .scroll-list.none {
    display: table;
    width: 100%;
}
.layer-post .scroll-list.none p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.layer-report .report-detail {
    margin-bottom: 35px;
    padding: 16px;
    border-radius: 8px;
    background: #f5f5f5;
}
.layer-report .report-detail table {
    width: 100%;
}
.layer-report .report-detail th,
.layer-report .report-detail td {
    padding: 4px 0 2px;
}
.layer-report .report-detail th {
    width: 100px;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-weight: bold;
}
.layer-report fieldset {
    padding: 16px;
    border-top: 2px solid #676969;
    border-bottom: 1px solid #e0e0e0;
}
.layer-report fieldset li {
    margin-top: 15px;
}
.layer-report fieldset textarea {
    display: none;
    min-height: 80px;
    margin: 16px 0;
}
.layer-report fieldset input#report4:checked ~ textarea {
    display: block;
}

.layer-play .player {
    height: 404px;
    margin-top: 19px;
    border-radius: 8px;
    overflow: hidden;
}
.layer-play .player p {
    line-height: 404px;
    background: #1f2d30;
    text-align: center;
    color: #fff;
    font-size: 24px;
}
.layer-play .player .video-js {
    background-color: #fff;
}
.layer-play .player .vjs-big-play-button {
    top: 170px;
    left: 315px;
}

.layer-photo .photo-slider {
    width: 720px;
    height: 404px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.layer-photo .photo-slider img {
    vertical-align: middle;
}
.layer-photo .photo-slider a.prev,
.layer-photo .photo-slider a.next {
    position: absolute;
    top: 50%;
    display: block;
    width: 48px;
    height: 48px;
    margin-top: -24px;
    background: url(https://s.wink.co.kr/pc/images/btn_navigation3.png)
        no-repeat;
    font-size: 0;
    line-height: 0;
}
.layer-photo .photo-slider a.prev {
    left: 0;
    background-position: 0 0;
}
.layer-photo .photo-slider a.prev:hover {
    background-position: 0 -48px;
}
.layer-photo .photo-slider a.next {
    right: 0;
    background-position: -48px 0;
}
.layer-photo .photo-slider a.next:hover {
    background-position: -48px -48px;
}
.layer-photo .photo-slider + .btn-area {
    margin-top: 16px;
}
.layer-photo .number-slider {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}
.layer-photo .number-slider strong {
    color: #08bbd5;
}

.layer-photo .photo-slider .slide-box {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}
.layer-photo .photo-slider .slide-box > ul {
    overflow: hidden;
    display: table;
    table-layout: fixed;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: 1000%; /*height:450px;*/
}
.layer-photo .photo-slider .slide-box > ul:after {
    display: block;
    clear: both;
    content: '';
}
.layer-photo .photo-slider .slide-box > ul > li {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    text-align: center; /*float:left;width:624px;*/
}
.layer-photo .photo-slider .slide-box > ul > li span {
    color: #fff;
}
.layer-photo .close {
    cursor: pointer;
}

.layer-special .benefit-wrap {
    border-bottom: 1px solid #e0e0e0;
}
.layer-special .benefit-scroll {
    height: 367px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -32px;
    padding-right: 16px;
}
.layer-special .guideline {
    width: 560px;
    height: 258px;
    padding-top: 28px;
    box-sizing: border-box;
    background: url(https://s.wink.co.kr/pc/images/bg_special.gif) no-repeat;
    text-align: center;
    color: #212121;
    font-size: 18px;
    line-height: 1.5;
}
.layer-special .guideline strong {
    color: #08bbd5;
}
.layer-special .guideline-top {
    width: 560px;
    height: 54px;
    margin: 2px 0 31px;
    text-align: center;
    line-height: 54px;
}
.layer-special .guideline-top strong {
    color: #08bbd5;
    font-size: 24px;
}
.layer-special ol {
    width: 560px;
    padding: 6px 0 27px;
    border-bottom: 1px solid #e0e0e0;
}
.layer-special li > dl dt {
    margin: 24px 0 6px;
    color: #212121;
    font-size: 18px;
}
.layer-special li > dl dt:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 11px 2px 0;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    background: #676969;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
}
.layer-special li > dl dd {
    color: #999;
    font-size: 14px;
}
.layer-special li > dl dd.dot {
    padding-left: 8px;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
}
.layer-special li > dl dd strong {
    color: #08bbd5;
    font-weight: bold;
}
.layer-special li > dl dd b {
    color: #212121;
}
.layer-special li:nth-child(1) > dl dt:before {
    content: '1';
}
.layer-special li:nth-child(2) > dl dt:before {
    content: '2';
}
.layer-special li:nth-child(3) > dl dt:before {
    content: '3';
}
.layer-special p.benefit-notice {
    width: 560px;
    margin-top: 12px;
    padding-left: 8px;
    box-sizing: border-box;
    background: url(https://s.wink.co.kr/pc/images/bu_dot3.png) 0 9px no-repeat;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer-special p.message-box {
    width: 560px;
    margin-bottom: 36px;
    padding: 28px 32px 26px;
    box-sizing: border-box;
    border-width: 0;
    border-radius: 8px;
    background: #f7f7f7;
    color: #999;
    font-size: 14px;
}
.layer-special p.message-box strong {
    margin-bottom: 5px;
    color: #212121;
    font-size: 18px;
}
.layer-special p.message-box b {
    color: #212121;
}
.layer-special p.message-box.benefit {
    margin: 35px 0 12px;
    text-align: left;
}
.layer-special p.notice {
    width: 560px;
    padding: 0 0 27px 28px;
    box-sizing: border-box;
    background-image: url(https://s.wink.co.kr/pc/images/icon_notice2.png);
    font-size: 14px;
}
.layer-special p.account {
    margin: 36px 0 11px;
    color: #999;
    font-size: 14px;
    line-height: 1.5;
}
.layer-special p.account strong {
    display: block;
    margin-bottom: 7px;
    color: #08bbd5;
    font-size: 18px;
}
.layer-special table.form {
    width: 560px;
}
.layer-special table.form:last-child {
    border-bottom-width: 0;
}
.layer-special td .select-mode {
    width: 196px;
}

/* issue_855 학습추가 select-class 2017.08.02 */
.select-class .add-class-warp {
    padding: 20px;
    background: #f5f5f5;
    text-align: center;
}
.select-class .add-class-warp h2 {
    font-size: 18px;
    color: #212121;
    margin-bottom: 10px;
}
.select-class .add-class-warp p {
    font-size: 14px;
}
.select-class .add-class-warp .inner {
    text-align: left;
    margin-bottom: 20px;
}
.select-class .add-class-warp .inner:last-child {
    margin-bottom: 0;
}
.select-class .add-class-warp .inner h3 {
    font-size: 16px;
    color: #212121;
    margin-bottom: 0;
}

/* issue_866 우리아이현황  app-setting 2017.08.04 */
.app-setting .footer-info-box {
    margin-top: 40px;
}
.app-setting .footer-info-box:after {
    clear: both;
    display: block;
    content: '';
    overflow: hidden;
}
.app-setting .footer-info-box strong {
    float: left;
    line-height: 40px;
    width: 50%;
}
.app-setting .footer-info-box .footer-form-box {
    float: right;
    width: 50%;
}
.app-setting .footer-info-box .btn-type2 {
    height: 38px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 12px;
    border-radius: 4px;
}
.app-setting .footer-info-box .btn-type2.mms2:before {
    background: none;
    width: 0;
    margin: 0;
}
.app-setting .footer-info-box .footer-check-box {
    margin-top: 10px;
    position: relative;
}
.app-setting .footer-info-box .footer-check-box input[type='checkbox'] + label {
    font-size: 12px;
    line-height: 25px;
    margin-right: 15px;
}
.app-setting .footer-info-box .footer-check-box .view-detail {
    font-size: 12px;
    text-decoration: none;
    color: #666;
    line-height: 25px;
    border-bottom: 1px solid #e0e0e0;
}
.app-setting .footer-info-box .footer-check-box .view-detail:after {
    content: '>';
    display: inline-block;
    margin-left: 3px;
    font-size: 10px;
    line-height: 25px;
}

.view-detail {
    position: relative;
    cursor: pointer;
}
.view-contents {
    position: absolute;
    left: 0;
    padding: 15px;
    border: 1px solid #e0e0e0;
    background: #fff;
    z-index: 500;
    display: none;
}
.view-contents p {
    font-size: 12px;
    line-height: 18px;
}
.view-contents .title {
    font-size: 14px;
    margin-bottom: 10px;
}
.view-contents .view-contents-inner {
    position: relative;
}
.view-contents-inner .view-detail-close {
    position: absolute;
    top: -19px;
    right: -16px;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    font-size: 16px;
}
.view-contents.on {
    display: block;
}

/* sample box */

/* 샘플 */
.sample-box {
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 38px 0 45px;
    border: 3px solid #c1e7e6;
    border-radius: 6px;
    text-align: center;
    background: url(https://s.wink.co.kr/pc/images/mykids/bg_%20jumbotron01.jpg)
        no-repeat 0 0/100% 100%;
}
.sample-box .title {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
}
.sample-box .title .dot {
    position: relative;
    color: #08bbd5;
}
.sample-box .title .dot:after {
    content: '';
    position: absolute;
    left: 50%;
    top: -8px;
    width: 4px;
    height: 4px;
    margin-left: -2px;
    border-radius: 100%;
    background-color: #ff9854;
}
.sample-box p {
    margin-top: 3px;
    line-height: 24px;
}
.sample-box p strong {
    font-weight: bold;
}
.sample-box p.notification {
    color: #f03c78;
    margin-top: 10px;
}
.sample-box p.notification + .btn-area {
    margin-top: 25px;
}
.sample-box .btn-area {
    margin-top: 35px;
}
.sample-box .btn-area .btn-type2 {
    width: 197px;
}

/* issue_888 2017.08.08 */
.top-text {
    padding: 0 5px 5px;
    position: relative;
}
.top-text p {
    margin-bottom: 15px;
}
.top-text small {
    font-size: 11px;
    color: #757575;
}
.top-text .btn-area {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

/* issue_936 내정보 mypage.css 와 동일 drag item */
.dragitem-wrap {
    padding: 32px 0;
    border-bottom: 1px solid #e0e0e0;
}
.dragitem-wrap.no-line {
    border-top: 0 none;
}
.dragitem {
    margin: 0 30px;
}
.dragitem:after {
    display: block;
    content: '';
    clear: both;
}
.dragitem .item.over {
    border: 1px dashed #e0e0e0;
}
.dragitem .item.move {
    border: 1px solid #f03c78;
}
.dragitem .item-add,
.dragitem .item {
    float: left;
    position: relative;
    box-sizing: border-box;
    width: 168px;
    height: 168px;
    margin: 16px 0 0 16px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
}

.dragitem .item.on {
    border: 2px solid #f03c78;
    box-sizing: border-box;
}
.dragitem .item .inner {
    position: relative;
    display: block;
    vertical-align: middle;
    padding: 19px 10px;
    box-sizing: border-box;
}
.dragitem .item.on .inner {
    width: 164px;
    height: 164px;
}

.dragitem .item .name {
    font-size: 24px;
    color: #212121;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.dragitem .item .btn-reverse {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin: -6px 0 0 5px;
    text-indent: -999em;
    vertical-align: middle;
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_reverse.png)
        no-repeat 0 0;
}
.dragitem .item small {
    display: block;
}
.dragitem .item span {
    font-size: 12px;
    padding-left: 20px;
    background: url(https://s.wink.co.kr/pc/images/mypage/icon_candle.png)
        no-repeat 0 50%;
}
.dragitem .item p {
    margin-top: 10px;
    font-size: 14px;
    color: #bdbdbd;
}
.dragitem .item .tag-area {
    margin-top: 4px;
}
.dragitem .item .bubble-study {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    right: 0;
    bottom: 2px;
    padding: 20px 14px 15px;
    border-radius: 6px;
    font-size: 14px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    width: 100%;
    min-width: 164px;
    height: 100%;
    min-height: 164px;
}

.dragitem .item .bubble-study strong {
    font-weight: bold;
    color: #212121;
}
.dragitem .item .bubble-study p {
    margin: 4px 0 2px;
    color: #666;
}
.dragitem .item .bubble-study span {
    padding-left: 0;
    color: #bdbdbd;
    background: none;
}

.dragitem .item .inner:hover .bubble-study,
.dragitem .item .bubble-study:hover {
    display: block;
}
.dragitem .item.on .bubble-study {
    display: block;
    background-color: #fff;
}

.dragitem .item .btn-delete {
    display: none;
    position: absolute;
    right: -12px;
    top: -12px;
    width: 33px;
    height: 33px;
    text-indent: -999em;
    background: url(https://s.wink.co.kr/pc/images/mypage/btn_delete.png)
        no-repeat 0 0;
}
.dragitem .item:hover .btn-delete,
.dragitem .item.on .btn-delete {
    display: inline-block;
}

.dragitem .item-add {
    background-color: #f7f7f7;
    color: #666;
}
.dragitem .item-add span {
    display: inline-block;
    position: relative;
    margin-top: 55px;
    padding-top: 47px;
    padding-left: 0;
    background: none;
}
.dragitem .item-add span:before,
.dragitem .item-add span:after {
    content: '';
    position: absolute;
    background-color: #ccc;
}
.dragitem .item-add span:before {
    left: 50%;
    top: 0;
    width: 1px;
    height: 31px;
}
.dragitem .item-add span:after {
    left: 50%;
    top: 15px;
    width: 31px;
    height: 1px;
    margin-left: -15px;
}

.dragitem .item.not-move {
    background-color: #f5f5f5;
}
.dragitem .item.not-move .name {
    color: #999;
}
.dragitem .item.not-move:hover .btn-delete {
    display: block;
}

.dragitem .item.ui-sortable-helper {
    border: 2px solid #f03c78;
    opacity: 0.8;
    background-color: #fff;
}
.dragitem .item.ui-sortable-helper .btn-delete {
    display: none;
}
.dragitem .item.ui-sortable-placeholder {
    visibility: visible !important;
    border: 1px solid transparent;
    background: url(https://s.wink.co.kr/pc/images/mypage/bg_dragitem.png)
        no-repeat 0 0 / cover;
}
/* //내정보 mypage.css 와 동일 drag item */

/* issue_936 2017.08.21 mypage-main */
.mypage-main .panel-list {
    margin-top: 32px;
}
/* issue 936 mypage-main .warning-box border-top 속성추가 */
.mypage-main .warning-box {
    padding: 16px;
    border-top: 1px solid #e0e0e0;
}
/* // issue 936 mypage-main .warning-box */
.mypage-main .warning-box::after {
    clear: both;
    content: '';
    display: block;
}
.mypage-main .warning-box .warning {
    float: left;
}
.mypage-main .warning-box .btn-area {
    float: right;
    margin-top: 0;
}
.mypage-main .warning-box .btn-area .btn-type {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 0;
}

.mypage-kid-info .dragitem .item {
    cursor: default;
}
.mypage-kid-info .dragitem .item.on {
    border: 1px solid #e0e0e0;
}
.mypage-kid-info .dragitem .item .btn-delete {
    display: inline-block;
}

/* issue_1048 로그인 레이어에서 사용중인 디자인 부분 css만 member.css에서 옮겨옴 */
.layer-wrap.member {
    width: 400px;
    margin-left: -200px;
    padding: 32px;
}
.layer-wrap.member .id-login input {
    width: 100%;
}
.layer-wrap.member .btn-type2.login {
    width: 100%;
    margin-top: 20px;
}
/* // issue_1048 */

.layer-wrap.pink-confirm .btn-type2.confirm {
    background-color: #f03c78;
}

@media (max-width: 960px) {
    body {
        background-position: 0 0;
    }
    #lnb-wrap {
        left: 0 !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 1360px) {
    footer:after {
        margin-left: 376px;
    }
    .footer:after {
        display: none;
    }
}

/* to-updating */
.to-updating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(233, 233, 233, 0.85);
    text-align: center;
    margin-right: 414px;
}

.to-updating .copy {
    font-size: 30px;
    font-family: 'NanumBarunGothic-Bold';
    color: #222;
}

/* video warp */
.video-warp {
    cursor: pointer;
}

.video-warp::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 56px;
    height: 59px;
    margin: -30px 0 0 -28px;
    background: url('https://s.wink.co.kr/pc/images/main/btn_video_play2.png')
        no-repeat 0 0/56px;
    cursor: pointer;
}

.new-wrapper {
    width: 100%;
}

.new-wrapper .red-line {
    color: #f03c78;
    border-bottom: 1px solid #f03c78;
}

.new-wrapper .black-line {
    color: #222;
    border-bottom: 1px solid #222;
    font-weight: 800;
}

#wrap .main-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #ffdb49;
}

#wrap .main-wrapper .main-top-container {
    width: 1260px;
}

#wrap .main-wrapper .main-top-container img {
    width: 100%;
    display: flow-root;
}

#wrap .main-wrapper .main-top-container .arrow {
    width: 50px;
    height: 20px;
    position: absolute;
    bottom: 25px;
}

.lay-floating-wrapper {
    position: absolute;
    z-index: 102;
}

.lay-floating-wrapper .slider-area {
    position: absolute;
    top: 660px;
    width: 242px;
    height: 110px;
    left: 50%;
    margin-left: -840px;
    border: 1px solid #ccc;
    overflow: hidden;
}

.lay-floating-wrapper .slider-area ul {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area ul::after {
    content: '';
    clear: both;
    display: block;
}

.lay-floating-wrapper .slider-area li {
    float: left;
    width: 242px;
    height: 110px;
}

.lay-floating-wrapper .slider-area li a {
    display: block;
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area li img {
    width: 100%;
    height: 100%;
}

.lay-floating-wrapper .slider-area li.list-none a {
    line-height: 347px;
    background: #fff;
}

.lay-floating-wrapper .main-dots {
    bottom: 10px;
}

.lay-floating-wrapper .main-dots li {
    float: none;
    width: 20px;
    height: 20px;
}

.main-warpper .pop-banner .pop-banner-title {
    display: inline-block;
    width: 234px;
    height: 45px;
    background-color: #f03c78;
    border-radius: 24px;
    font-size: 1.3em;
    line-height: 45px;
}

.new-wrapper .section {
    position: relative;
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
}

.about-danbiedu {
    color: #222;
    line-height: 1.6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.about-danbiedu .center {
    text-align: center;
}

.about-danbiedu .history-text {
    padding: 50px 0;
}

.about-danbiedu .img-area img {
    vertical-align: top;
}

.about-danbiedu .text {
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 1.8;
}

.about-danbiedu .point-text {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 20px;
}

.about-danbiedu .article {
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    background: #fcfcfc;
    border-radius: 10px;
    padding: 60px 40px;
}

.hole-section .text-area {
    height: 104px;
    background: #08bbd5;
    text-align: center;
}

.hole-section .text-area .title {
    line-height: 104px;
    color: #fff;
    font-size: 36px;
    font-family: 'NanumBarunGothic-Bold';
}

.hole-section .img-area,
.hole-section .sub-title {
    width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
}

.hole-section .sub-title {
    padding: 50px 0 60px;
    font-size: 23px;
}

.hole-section .sub-title em {
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-01 {
    padding: 44px 31px;
}

.about-danbiedu .section-01 > .text-area .title {
    font-family: 'NanumBarunGothic-Bold';
    font-size: 42px;
    color: #00aac8;
    margin: 50px 0;
    text-align: center;
}

.about-danbiedu .section-01 > .text-area .sub-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 30px;
}

.about-danbiedu .section-01 .article {
    padding: 60px 40px 40px;
    margin-bottom: 8px;
}

.about-danbiedu .section-01 .article .title {
    font-size: 24px;
    margin-bottom: 40px;
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-01 .img-area {
    margin-top: 20px;
}

.about-danbiedu .section-02 {
    padding: 44px 60px;
}

.about-danbiedu .section-02 .text-area {
    text-align: center;
    margin-bottom: 70px;
}

.about-danbiedu .section-02 .text {
    margin-bottom: 40px;
}

.about-danbiedu .section-02 .text-area .title {
    font-size: 32px;
    font-family: 'NanumBarunGothic-Bold';
}

.about-danbiedu .section-02 .point-text {
    margin: 40px 0;
}

.about-danbiedu .section-02 .article {
    margin: 50px 76px;
}

.about-danbiedu .section-02 .article .text {
    font-size: 20px;
    font-family: 'NanumBarunGothic-Bold';
    margin-bottom: 0;
}

.about-danbiedu .section-02 .article .text em {
    color: #f03c78;
}

.about-danbiedu .section-06 {
    margin-bottom: 50px;
}

/* new-btn-area */
.new-btn-area {
    padding: 20px 0;
    text-align: center;
}

.new-btn-area .new-btn {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 100px;
    height: 50px;
    text-align: center;
    line-height: 52px;
    margin: 0 5px;
    color: #222;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.new-btn-area .new-btn.btn-confirm {
    background: #f03c78;
    border-color: #f03c78;
    color: #fff;
}
.new-btn-area .new-btn.btn-apply {
    padding: 0 20px;
}
.new-btn-area .new-btn.btn-apply:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 18px;
    margin: 0 8px 2px 0;
    background: url(https://s.wink.co.kr/pc/images/icon_btn_apply2.png)
        no-repeat;
}

/* section-menu */
.section-menu ul {
    border-left: 1px solid #e0e0e0;
}
.section-menu ul::after {
    clear: both;
    display: block;
    content: '';
}

.section-menu li {
    float: left;
    text-align: center;
}

.section-menu li:last-child a {
    border-right: 1px solid #e0e0e0;
}

.section-menu li a {
    display: block;
    position: relative;
    color: #666;
    font-size: 0.9em;
    height: 48px;
    line-height: 48px;
    background: #fcfcfc;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    font-weight: bold;
    border-left: none;
    border-right: none;
}

.section-menu li a::before {
    content: '';
    position: absolute;
    top: 0;
    right: -43px;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-right: 21px solid transparent;
    border-left: 21px solid #e0e0e0;
    z-index: 10;
}

.section-menu li a::after {
    content: '';
    position: absolute;
    top: 0;
    right: -43px;
    border-top: 24px solid transparent;
    border-bottom: 23px solid transparent;
    border-right: 23px solid transparent;
    border-left: 20px solid #fcfcfc;
    z-index: 11;
}

.section-menu li:last-child a:after,
.section-menu li:last-child a:before {
    display: none;
}

.section-menu li.on a {
    background: #ffdc52;
    color: #222;
}

.section-menu li.on a:after {
    border-left-color: #ffdc52;
}

/* card style */
.card {
    display: block;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d2d2d2;
    overflow: hidden;
    margin-bottom: 10px;

    box-sizing: border-box;

    transition-duration: 0.5s;
    transition-property: height;

    height: 75px;
}

.card:nth-child(1).opened {
    height: 365px;
}
.card:nth-child(2).opened {
    height: 370px;
}
.card:nth-child(3).opened {
    height: 370px;
}
.card:nth-child(4).opened {
    height: 370px;
}
.card:nth-child(5).opened {
    height: 370px;
}
.card:nth-child(6).opened {
    height: 370px;
}
.card:nth-child(7).opened {
    height: 370px;
}
.card:nth-child(8).opened {
    height: 370px;
}

.card.parent:nth-child(1).opened {
    height: 370px;
}
.card.parent:nth-child(2).opened {
    height: 370px;
}
.card.parent:nth-child(3).opened {
    height: 340px;
}
.card.parent:nth-child(4).opened {
    height: 340px;
}
.card.parent:nth-child(5).opened {
    height: 340px;
}
.card.parent:nth-child(6).opened {
    height: 340px;
}
.card.parent:nth-child(7).opened {
    height: 340px;
}
.card.parent:nth-child(8).opened {
    height: 340px;
}

.card:last-child {
    margin-bottom: 0;
}

.card .card-top {
    padding: 0 35px;
}
.card .card-contents {
    padding: 16px 14px;
}
.card .card-bottom {
    padding: 10px;
}

.card .card-top {
    position: relative;
    background: #f4f4f4;
}

.card .card-top .title {
    margin-right: 50%;
    font-size: 15px;
    font-family: 'NanumBarunGothic-Bold';
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card .card-top .util-area {
    position: absolute;
    top: 50%;
    right: 5px;
}

.card .card-top .util-area::after {
    clear: both;
    display: block;
    content: '';
}

.card .card-top .util-area .price {
    color: #00a0e0;
    font-size: 18px;
}

.card .card-top .util-area .before-price {
    color: #00a0e0;
    font-size: 15px;
    text-decoration: line-through solid #222;
}

.card .card-top .util-area .after-price {
    color: #f03c78;
    font-weight: bold;
    font-size: 16px;
}

.card .card-top .util-area .discount-price {
    display: inline-block;
    padding: 7px 8px;
    position: relative;
    font-size: 11px;
    line-height: 12px;
    height: 26px;
    box-sizing: border-box;
    vertical-align: middle;
    background-color: #f03c78;
    color: #fff;
    margin: 0 20px 0 7px;
    border-radius: 5px 0 0 5px;
}

.card .card-top .util-area .discount-price::after {
    position: absolute;
    top: -8px;
    right: -15px;
    content: '';
    height: 0px;
    width: 0px;
    border-left: 15px solid #f03c78;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.card .card-top .util-area button {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 15px 5px;
    border-radius: 15px;
    border: 1px solid #d2d2d2;
    color: #565656;
    margin-left: 10px;
    vertical-align: middle;
    box-sizing: border-box;
}

/* .card.wink */
.card.wink {
    border: 2px solid #bee4be;
    margin-bottom: 25px;
}
.card.wink .card-top {
    background-color: #e0fbe0;
}

/* .card.event */
.card .card-top .tooltip img {
    vertical-align: middle;
    margin: 0 0 5px 15px;
}
/* .card.event */
.card .card-top .title-span {
    font-size: 24px;
    color: #333333;
}

.card .sale-text p {
    margin-bottom: 10px;
}
.card .sale-text p em {
    color: #f03c78;
}

/* select-mode */
/* 회원정보 등록 완료 */
.select-mode select {
    -webkit-appearance: menulist;
    appearance: menulist;

    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    color: #999;
    font-size: 14px;
    font-family: 'NanumBarunGothic';
    line-height: normal !important;
    width: 100%;
}

/* info-rec */
.info-rec {
    display: inline-block;
    padding-left: 25px;
    background: url('https://s.wink.co.kr/pc/images/icon_notice_color_rec.png')
        no-repeat 0 0 /20px;
    line-height: 21px;
}

/* new-layer */
.new-layer {
}

.new-layer .layer-wrap {
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    min-width: 380px;
}

.new-layer .layer-wrap .lay-container {
    padding: 32px;
}

.event-popup-content {
    text-align: center;
}

.event-popup-content .checkbox-area {
    margin-top: 25px;
}

.event-popup-content .content-wrapper a {
    display: inline-block;
}

.new-layer.layer .layer-wrap button.close {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-size: 18px;
    background-position: center;
}

.accordion {
    font-size: 16px;
    line-height: 56px;
    color: #666;
}

.accordion .accordion-animation {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.accordion .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion .accordion-header input[type='checkbox'] + label {
    display: inline-block;
    padding: 0 50px 0 32px;
    margin-right: 0;
    color: #666;
    line-height: 27px;
}

.accordion .accordion-body {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
}

.accordion .accordion-body.active {
    height: 500px;
}

.accordion .accordion-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(https://s.wink.co.kr/pc/images/icon_arrow_down.png) center
        center no-repeat;
    cursor: pointer;
}

.accordion .accordion-icon.active {
    background: url(https://s.wink.co.kr/pc/images/icon_arrow_up.png) center
        center no-repeat;
}

.accordion iframe {
    width: 100%;
    height: 100%;
}

.transparent-checkbox::before {
    width: 16px !important;
    height: 16px !important;
    background: transparent !important;
    border: 1px solid white;
    margin-right: 10px;
}
